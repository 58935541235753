import { Box, Modal, Typography } from "@mui/material";
import IMAGES from "../../../../assets/images/images";
import React, { useEffect, useState } from "react";
import { Add, Check } from "@mui/icons-material";
import QuestionListService from "../../../../services/api/QuestionListService";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import {
  addVideoQuestionAction,
  removeVideoQuestionAction,
} from "../../../../utils/redux/slices/videoQuestionSlice";
import VideoService from "../../../../services/api/VideoService";

const IntroOutroVideoDrawer = (props) => {
  const { open, setOpen } = props;
  const [readyToAddClass, setReadyToAddClass] = useState(false);
  const [videoQuestions, setVideoQuestions] = useState([]);
  const dispatch = useDispatch();
  const videoQuestionsFromStore = useSelector(
    (state) => state.videoQuestionSlice.videoQuestionsAssessment
  );

  const handleCloseModal = () => {
    setOpen(false);
  };
  useEffect(() => {
    setReadyToAddClass(true);
    VideoService.getVideos().then((res) => {
      setVideoQuestions(res?.data?.data);
    });
  }, [open]);

  const getQuestionStatus = (id) => {
    let found = false;
    videoQuestionsFromStore?.forEach((question) => {
      if (question._id === id) {
        found = true;
      }
    });
    return found;
  };

  const handleAdd = (questionRef) => {
    let found = false;
    videoQuestionsFromStore?.forEach((question) => {
      if (question._id === questionRef._id) {
        found = true;
      }
    });
    if (found) {
      dispatch(removeVideoQuestionAction(questionRef._id));
    } else {
      dispatch(addVideoQuestionAction(questionRef));
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={`drawer drawer-sm right ${readyToAddClass && "open"}`}>
        <Box className="modal-content">
          <Box
            className={`d-flex align-items-center justify-content-between pd-x-30 pd-y-30 border-bottom`}
          >
            <Typography id="modal-modal-title" variant="h2" component="h2">
              Intro/Outro Videos
            </Typography>
            <div>
              <button type="button" className={`btn btn-light-primary mg-r-12`}>
                <Add /> New Video
              </button>
              <button
                type={`button`}
                className={`btn btn-link text-decoration-none`}
                onClick={handleCloseModal}
              >
                <img src={IMAGES.XIcon} alt="close" />
              </button>
            </div>
          </Box>
          <Box className={`pd-x-30 pd-y-30`} sx={{overflowY:'auto',maxHeight:'800px'}}>
            <ul className={`list-unstyled video-list`}>
              {videoQuestions?.map((question, index) => (
                <li key={question._id}>
                  <div className="row g-3 align-items-center">
                    <div className="col">
                      <div className={`d-flex`}>
                        <div className={`img-asset img-asset-sm mg-r-14`}>
                          <img src={question.videoThumbnail} alt="demo" />
                        </div>
                        <Typography variant={"body1"} component={"p"}>
                          {question?.title}
                        </Typography>
                      </div>
                    </div>
                    <div className="col-auto">
                      <button
                        type={"button"}
                        className={
                          getQuestionStatus(question?._id)
                            ? `btn btn-snow text-primary drawer-btn`
                            : `btn btn-primary text-white drawer-btn`
                        }
                        onClick={() => handleAdd(question)}
                      >
                        {getQuestionStatus(question?._id) ? <Check /> : "Add"}
                      </button>
                    </div>
                  </div>
                </li>
              ))}

              {/* <li>
                <div className="row g-3 align-items-center">
                  <div className="col">
                    <div className={`d-flex`}>
                      <div className={`img-asset img-asset-sm mg-r-14`}>
                        <img src={IMAGES.Profile} alt="demo" />
                      </div>
                      <Typography variant={"body1"} component={"p"}>
                        Introduce yourself in 60 seconds and explain why you’re
                        a great candidate for this role.
                      </Typography>
                    </div>
                  </div>
                  <div className="col-auto">
                    <button
                      type={"button"}
                      className={`btn btn-snow text-primary drawer-btn`}
                    >
                      <Check />
                    </button>
                  </div>
                </div>
              </li> */}
            </ul>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default IntroOutroVideoDrawer;
