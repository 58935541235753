import TimeLimit from "../TimeLimit/TimeLimit";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  FormControlLabel,
  LinearProgress,
  Menu,
  MenuItem,
  Tab,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import styles from "./CodingQuestion.module.scss";
import {
  Add,
  KeyboardArrowDown,
  KeyboardArrowUp,
  ExpandMore,
  PlayCircle,
} from "@mui/icons-material";
import IMAGES from "../../../../assets/images/images";
import { IOSSwitch } from "../../../../utils/ui/IOSSwitch";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CodeEditor from "../../../CodeEditor/InitialCodeTab/Codemirror";
import { makeStyles } from "@mui/styles";
import RHFInputController from "../../../RHFControllers/RHFInputController";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import useInitialCode from "./GetInitialCode/useInitialCode";
import { useDispatch } from "react-redux";
import {
  getDataTypesAsync,
  getLanguagesAsync,
} from "../../../../utils/redux/slices/QuestionSlice";
import { useRef } from "react";
import { runTest } from "./RunningTest/helperFunction";
import FunctionSignatureChangeInterceptor from "././functionSignatureChangeInterceptor/Index";
import { getInitialCodeMap } from "./functionSignatureChangeInterceptor/Contants";
import { useSelector } from "react-redux";
import MuiMenuCommon from "../../../common/MuiMenuCommon/MuiMenuCommon";

const languages = [
  {
    label: "JavaScript",
    languageId: 63,
    tabIndex: -1,
  },
  {
    label: "Java",
    languageId: 62,
    tabIndex: -1,
  },
  {
    label: "Python",
    languageId: 70,
    tabIndex: -1,
  },
  {
    label: "PHP",
    languageId: 68,
    tabIndex: -1,
  },
  {
    label: "TypeScript",
    languageId: 74,
    tabIndex: -1,
  },
];

const useStyles = makeStyles({
  row: {
    maxHeight: "500px",
    overflowY: "auto",
    overflowX: "hidden",
  },
});

const CodingQuestion = ({ methods }) => {
  const classes = useStyles();
  const { control, setValue, watch, getValues } = methods;
  const [returnTypeAnchorEl, setReturnTypeAnchorEl] = useState(null);
  const openReturnType = Boolean(returnTypeAnchorEl);
  const [languageTabValue, setLanguageTabValue] = useState("");
  const [statusTabValue, setStatusTabValue] = useState("initialCode");
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [search, setSearch] = useState("");

  const [testError, setTestError] = useState(null);
  const [isTestRunning, setIsTestRunning] = useState(false);
  const [testResult, setTestResult] = useState(null);
  const countRef = useRef(0);
  const isCodeTested = useRef(false);
  const values = getValues();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDataTypesAsync());
    dispatch(getLanguagesAsync());
  }, []);
  // const languages2=useSelector((state) => state.QuestionReducer.P);
  // console.log(languages2)

  const dataTypesMap = useSelector(
    (state) => state.QuestionReducer.dataTypeMap
  );
  const returnTypes = useSelector((state) => state.QuestionReducer.returnTypes);

  const handleReturnTypeOpen = (event) => {
    setReturnTypeAnchorEl(event.currentTarget);
  };

  const returnTypeClose = () => {
    setReturnTypeAnchorEl(null);
  };
  const handleLanguageChange = (event, newValue) => {
    setLanguageTabValue(newValue);
    setTestResult("");
    setTestError("");
  };
  const handleStatusChange = (event, newValue) => {
    setStatusTabValue(newValue);
  };

  const {
    fields: parametersField,
    append: parameterAppend,
    remove: parameterRemove,
  } = useFieldArray({
    control,
    name: "parameters",
  });

  const {
    fields: testCasesField,
    append: testCaseAppend,
    remove: testCaseRemove,
  } = useFieldArray({
    control,
    name: "testCases",
  });

  const addParameter = () => {
    parameterAppend({
      pName: "",
      pType: "",
    });
  };
  const addTestCase = () => {
    testCaseAppend({
      parameters: [],
      result: "",
      isVisible: false,
    });
  };

  const parametersWatch = useWatch({
    control,
    name: "parameters",
  });

  const {
    fields: languageField,
    append: languageAppend,
    remove: languageRemove,
  } = useFieldArray({
    control,
    name: "langWiseCode",
  });

  const handleChecked = (e, languageId) => {
    const { functionName, parameters, returnType, langWiseCode } = getValues();
    if (e.target.checked) {
      let selected = e.target.name;
      const initialCode = getInitialCodeMap[languageId](
        functionName,
        parameters,
        returnType,
        dataTypesMap
      );
      languageAppend({
        languageName: selected,
        initialCode,
        verificationCode: initialCode,
        languageId,
      });
    } else {
      const tabIndex = langWiseCode.findIndex(
        ({ languageId: langId }) => langId === languageId
      );
      languageRemove(tabIndex);
    }
  };

  const setVerificationCode = (value, index) => {
    setValue(`langWiseCode.${index}.verificationCode`, value);
  };
  const setInitialCode = (value, index) => {
    setValue(`langWiseCode.${index}.initialCode`, value);
  };

  // There will be cases where you want to control the entire field array, which means each onChange reflects on the fields object.
  const languageWatch = watch("langWiseCode");
  const controlledLanguageFields = languageField.map((field, index) => {
    return {
      ...field,
      ...languageWatch[index],
    };
  });

  const filteredLanguages = useMemo(() => {
    return languages.filter(({ label }) =>
      label.toLowerCase().includes(search.toLowerCase())
    );
  }, [search]);

  useEffect(() => {
    if (languageWatch.length) {
      const firstTab = languageWatch[0].languageName;
      if (firstTab != languageTabValue) setLanguageTabValue(firstTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageWatch]);

  // const getInitialCode = useInitialCode(watch, setValue);
  // console.log(getInitialCode)

  const getCheckedValue = (languageId) => {
    return languageField.some((language) => language.languageId === languageId);
  };

  return (
    <FormProvider {...methods}>
      <FunctionSignatureChangeInterceptor />
      <Card className={`${styles.card} mg-b-34`}>
        <div className={`row g-0`}>
          <div className="col-md-6">
            <div className={styles.cardHeader}>
              <Typography
                variant={"h4"}
                component={"h4"}
                className={"font-weight-semi-bold"}
              >
                Function Signature
              </Typography>
            </div>
            <div className={"pd-x-20 pd-y-20"}>
              <div className="row g-2 mg-b-20">
                <div className="col-sm-6">
                  <Typography
                    variant={"h4"}
                    component={"h4"}
                    className={"mg-b-10"}
                  >
                    Function Name
                  </Typography>
                  <RHFInputController
                    RHFname="functionName"
                    type="text"
                    placeholder="Enter name"
                    className={"form-control form-control-sm"}
                  />
                </div>
                <div className="col-sm-6">
                  <Typography
                    variant={"h4"}
                    component={"h4"}
                    className={"mg-b-10"}
                  >
                    Return Type
                  </Typography>
                  <MuiMenuCommon
                    options={returnTypes}
                    RHFName={`returnType`}
                    placeholder="Select Type"
                  />
                
                </div>
              </div>
              <div>
                <Typography
                  variant={"h4"}
                  component={"h4"}
                  className={"pd-b-10 border-bottom"}
                >
                  Parameters
                </Typography>
                <div className={`pd-y-20`}>
                  <div className={`${classes.row}`}>
                    {parametersField.map((field, index) => (
                      <div
                        key={field.id}
                        className={`row g-2 align-items-center mg-b-15`}
                      >
                        <div className="col-sm">
                          <RHFInputController
                            RHFname={`parameters.${index}.pName`}
                            type="text"
                            placeholder="Parameter"
                            className={"form-control form-control-sm"}
                          />
                        </div>
                        <div className="col-sm">
                          <MuiMenuCommon
                            options={returnTypes}
                            RHFName={`parameters.${index}.pType`}
                            placeholder="Select Type"
                          />
                         
                        </div>
                        <div className="col-sm-auto">
                          <Button
                            variant={"text"}
                            className="btn btn-link pd-x-0"
                            onClick={() => parameterRemove(index)}
                          >
                            <img src={IMAGES.Trash} alt="trash" />
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>

                  <button
                    onClick={addParameter}
                    type="button"
                    className={`btn btn-link text-decoration-none p-0 font-weight-semi-bold font-size-sm`}
                  >
                    <Add className="mg-r-8" />
                    Add more
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={`col-md-6 ${styles.leftDivider}`}>
            <div className={styles.cardHeader}>
              <Typography
                variant={"h4"}
                component={"h4"}
                className={"font-weight-semi-bold"}
              >
                Test Cases
              </Typography>
            </div>
            <div className={"pd-x-20 pd-y-20"}>
              <div
                className={`pd-b-10 border-bottom d-flex align-items-center justify-content-between`}
              >
                <Typography
                  variant={"h4"}
                  component={"h4"}
                >
                  Test Cases
                </Typography>
                <div>
                  <button type="button" className={`btn btn-link`}>
                    <img src={IMAGES.Help} alt="help" />
                  </button>
                </div>
              </div>
              <div className={`pd-t-20 ${classes.row}`}>
                {testCasesField?.map((item, index) => (
                  <div key={item.id} className={`row g-2`}>
                    <div className="col">
                      <Accordion className={`accordion-wrap outline-white mg-b-15`}>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className={`w-100`}
                        >
                          <Typography>
                            Test Case {index + 1}
                          </Typography>
                          <Controller
                            name={`testCases.${index}.isVisible`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <FormControlLabel
                                onClick={(event) => event.stopPropagation()}
                                className={`switch-btn`}
                                control={
                                  <IOSSwitch
                                    onChange={onChange}
                                    checked={value}
                                    sx={{ m: 1 }}
                                  />
                                }
                                label="Visible"
                              />
                            )}
                          />
                        </AccordionSummary>
                        <AccordionDetails>
                          {parametersWatch?.map((param, ind) => (
                            <div
                              key={param.id}
                              className="row g-2 align-items-center mg-b-10"
                            >
                              <div className="col-md-4">
                                <Typography
                                  variant={"subtitle2"}
                                  component={"label"}
                                  className={`font-weight-medium ${styles.textBlack11}`}
                                >
                                  {param.pName}:
                                </Typography>
                              </div>
                              <div className={`col-md-8`}>
                                <Controller
                                  render={({ field }) => (
                                    <textarea
                                      // type="textarea"
                                      className="form-control"
                                      placeholder="Enter"
                                      {...field}
                                    />
                                  )}
                                  name={`testCases.${index}.parameters.${ind}`}
                                  control={control}
                                />
                              </div>
                            </div>
                          ))}
                          <div className="row g-2 align-items-center">
                            <div className="col-md-4">
                              <Typography
                                variant={"subtitle2"}
                                component={"label"}
                                className={`font-weight-medium ${styles.textBlack11}`}
                              >
                                Result:
                              </Typography>
                            </div>
                            <div className={`col-md-8`}>
                              <Controller
                                name={`testCases.${index}.result`}
                                control={control}
                                render={({ field }) => (
                                  <textarea
                                    // type="textarea"
                                    className="form-control"
                                    placeholder="Enter"
                                    {...field}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div className="col-sm-auto">
                      <Button
                        onClick={() => {
                          testCaseRemove(index);
                        }}
                        variant={"text"}
                        className="btn btn-link pd-x-0"
                      >
                        <img src={IMAGES.Trash} alt="trash" />
                      </Button>
                    </div>
                  </div>
                ))}

                <button
                  onClick={addTestCase}
                  type="button"
                  className="btn btn-link text-decoration-none p-0 font-weight-semi-bold font-size-sm    "
                >
                  <Add className="mg-r-8" />
                  Add more
                </button>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <div className="mg-b-34">
        <Typography variant={"h3"} component={"h3"} className={"mg-b-10"}>
          Programing Languages
        </Typography>
        <Card className={`${styles.card} position-relative`}>
          <div className={styles.selectLanguage}>
            <button
              type="button"
              onClick={handleReturnTypeOpen}
              className={`btn-toggle btn btn-link text-decoration-none`}
            >
              <span className="mg-r-4 pd-y-0 font-weight-medium font-size-sm">
                {false ? "SelectedValue" : "Select languages"}
              </span>
              {openReturnType ? (
                <KeyboardArrowUp className="toggle-icon" />
              ) : (
                <KeyboardArrowDown className="toggle-icon" />
              )}
            </button>
            <Menu
              id="role-menu"
              anchorEl={returnTypeAnchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={openReturnType}
              onClose={returnTypeClose}
            >
              <div className={`pd-x-24 mg-b-20 pd-t-16`}>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setSearch(e.target.value);
                  }}
                />
              </div>
              {filteredLanguages.map(({ label, languageId }) => {
                return (
                  <MenuItem
                    className="menu-item"
                    key={languageId}
                    disableRipple={true}
                  >
                    <div className="custom-checkbox">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id={`${label}`}
                        name={`${label}`}
                        checked={getCheckedValue(languageId)}
                        onChange={(e) => handleChecked(e, languageId)}
                      />
                      <label
                        className="custom-control-label text-capitalize font-size-base"
                        htmlFor={`${label}`}
                      >
                        {label}
                      </label>
                    </div>
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
          <Box className={"tabs-wrap code-tab"}>
            <TabContext value={languageTabValue}>
              <Box
                className={`pd-x-15`}
                sx={{ borderBottom: 1, borderColor: "divider" }}
              >
                <TabList
                  onChange={handleLanguageChange}
                  aria-label="lab API tabs example"
                >
                  {controlledLanguageFields?.map(({ languageName }, index) => (
                    <Tab
                      key={index}
                      label={languageName}
                      value={languageName}
                    />
                  ))}
                </TabList>
              </Box>
              {controlledLanguageFields?.map(
                (
                  { languageName, initialCode, verificationCode, languageId },
                  index
                ) => (
                  <TabPanel key={index} value={languageName}>
                    <Box className={"tabs-wrap code-tab pills-tab"}>
                      <TabContext value={statusTabValue}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <TabList
                            onChange={handleStatusChange}
                            aria-label="lab API tabs example"
                          >
                            <Tab label="Initial Code" value="initialCode" />
                            <Tab
                              label="Verification Code"
                              value="verificationCode"
                            />
                          </TabList>
                        </Box>
                        <TabPanel
                          sx={{ minHeight: 300 }}
                          value="initialCode"
                          className="pb-0"
                        >
                          <CodeEditor
                            readOnly={false}
                            initialCode={initialCode}
                            setCode={setInitialCode}
                            index={index}
                          />
                        </TabPanel>
                        <TabPanel
                          sx={{ minHeight: 300 }}
                          value="verificationCode"
                          className="pb-0"
                        >
                          <Box className={styles.codeWrap}>
                            <CodeEditor
                              readOnly={false}
                              initialCode={verificationCode}
                              setCode={setVerificationCode}
                              index={index}
                            />
                          </Box>
                          <Box className={`mg-t-34 ${styles.testOutputPanel}`}>
                            <Box
                              className={`${styles.outputTop} border-bottom`}
                            >
                              <div className="row g-2 align-items-center">
                                <div className="col">
                                  <Typography variant={"h3"} component={"h3"}>
                                    Test output
                                  </Typography>
                                </div>
                                {(testResult || testError) && (
                                  <>
                                    <div className="col">
                                      <Typography
                                        variant={"body1"}
                                        className={`text-success`}
                                      >
                                        OK: {testResult?.passed}
                                      </Typography>
                                    </div>
                                    <div className="col">
                                      <Typography
                                        variant={"body1"}
                                        className={`text-danger`}
                                      >
                                        Error: {testResult?.failed}
                                      </Typography>
                                    </div>
                                    <div className="col">
                                      <Typography variant={"body1"} noWrap>
                                        Total: {testResult?.total}
                                      </Typography>
                                    </div>
                                  </>
                                )}
                                <div className="col" align={"right"}>
                                  <Button
                                    variant={"contained"}
                                    color={"primary"}
                                    className={`text-nowrap`}
                                    onClick={() =>
                                      runTest(
                                        setIsTestRunning,
                                        setTestError,
                                        setTestResult,
                                        languageId,
                                        values,
                                        countRef,
                                        verificationCode,
                                        isCodeTested
                                      )
                                    }
                                  >
                                    <PlayCircle className={"mg-r-10"} />
                                    Run Test
                                  </Button>
                                </div>
                              </div>
                            </Box>
                            <Box className={styles.outputBody}>
                              {isTestRunning && (
                                <Box sx={{ width: "96%" }}>
                                  <LinearProgress />
                                </Box>
                              )}
                              <Typography>
                                Try and check your test or
                                <Button
                                  className={`mg-l-10`}
                                  variant={"text"}
                                  onClick={() =>
                                    setShowCustomInput(!showCustomInput)
                                  }
                                >
                                  Custom Input
                                </Button>
                                {showCustomInput && (
                                  <div className="mg-y-16">
                                    <textarea
                                      style={{ height: "70px" }}
                                      rows="1"
                                      cols="50"
                                      type="text"
                                      className="form-control"
                                      placeholder="Abc"
                                    />
                                  </div>
                                )}
                                <div
                                  style={{
                                    backgroundColor: "#002b36",
                                    maxHeight: "200px",
                                    color: "white",
                                    overflowY: "auto",
                                  }}
                                >
                                  {(testResult || testError) && (
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        fontFamily: "monospace",
                                      }}
                                    >
                                      Code Executed Successfully
                                    </p>
                                  )}
                                  {testError && (
                                    <p
                                      style={{
                                        color: "red",
                                        marginBottom: "0",
                                      }}
                                    >
                                      {testError}
                                    </p>
                                  )}
                                  {testResult?.result?.map((item, i) => (
                                    <div
                                      style={{ fontSize: "16px" }}
                                      key={item.title}
                                    >
                                      <p style={{ marginBottom: "0" }}>
                                        -------------------------------------------
                                      </p>
                                      <p style={{ marginBottom: "0" }}>
                                        Input Parameters: {item.parameters}{" "}
                                      </p>
                                      <p style={{ marginBottom: "0" }}>
                                        Result: {item.result}{" "}
                                      </p>
                                      {item.output === "OK" ? (
                                        <p
                                          style={{
                                            color: "green",
                                            marginBottom: "0",
                                          }}
                                        >
                                          {item.output}
                                        </p>
                                      ) : (
                                        <p
                                          style={{
                                            color: "red",
                                            marginBottom: "0",
                                          }}
                                        >
                                          Expected : {item.output}
                                        </p>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </Typography>
                            </Box>
                          </Box>
                        </TabPanel>
                      </TabContext>
                    </Box>
                  </TabPanel>
                )
              )}
            </TabContext>
          </Box>
        </Card>
      </div>
    </FormProvider>
  );
};

export default CodingQuestion;
