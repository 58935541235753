import React from "react";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { useEffect } from "react";

const CodeEditor = ({ initialCode,readOnly,setCode,index } ) => {
  const onChange = React.useCallback((value) => {
    setCode(value,index)
  }, [index]);
  return (
    <div>
      <CodeMirror
        readOnly={readOnly}
        value={initialCode}
        height="300px"
        theme="dark"
        extensions={[javascript({})]}
        onChange={onChange}
        
      />
    </div>
  );
};

export default CodeEditor;
