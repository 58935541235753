import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { converter, getDataType, getParametersString } from "./Contants";
const FunctionSignatureChangeInterceptor = () => {

  const { watch, setValue } = useFormContext();

  const dataTypesMap = useSelector(
    (state) => state.QuestionReducer.dataTypeMap
  );

  function updateInitialCode(action, languageId, initialCode, functionName, parameters, returnType) {
    if (['RTC', 'PTC'].includes(action) && [63, 68,70].includes(languageId)) return false;
    if (action === 'FNC') {
      return converter[languageId].FNC(initialCode, functionName)
    }
    else if (action === 'RTC') {
      const _returnType = getDataType(languageId, returnType, dataTypesMap);
      return converter[languageId].RTC(initialCode, _returnType)
    }
    else if (action === 'PAR') {
      const parametersString = getParametersString(Number(languageId), parameters, dataTypesMap);
      return converter[languageId].PC(initialCode, parametersString)
    }
    else if (['PNC', 'PTC'].includes(action)) {
      const parametersString = getParametersString(Number(languageId), parameters, dataTypesMap);
      return converter[languageId].PC(initialCode, parametersString)
    }

  }

  function updateVerificationCode(action, languageId, verificationCode, functionName, parameters, returnType) {
    if (['RTC', 'PTC'].includes(action) && [63, 68,70].includes(languageId)) return false;

    if (action === 'FNC') {
      return converter[languageId].FNC(verificationCode, functionName)
    }
    else if (action === 'RTC') {
      const _returnType = getDataType(languageId, returnType, dataTypesMap);
      return converter[languageId].RTC(verificationCode, _returnType)
    }
    else if (action === 'PAR') {
      const parametersString = getParametersString(Number(languageId), parameters, dataTypesMap);
      return converter[languageId].PC(verificationCode, parametersString)
    }
    else if (['PNC', 'PTC'].includes(action)) {
      const parametersString = getParametersString(Number(languageId), parameters, dataTypesMap);
      return converter[languageId].PC(verificationCode, parametersString)
    }
  }


  async function handler(action, value) {
    const langWiseCode = JSON.parse(JSON.stringify(value.langWiseCode))
    langWiseCode.forEach((langCode) => {
      const { functionName, parameters, returnType } = value;
      const { languageId, initialCode, verificationCode } = langCode
      const _initialCode = updateInitialCode(action, languageId, initialCode, functionName, parameters, returnType);
      if (_initialCode) {
        langCode.initialCode = _initialCode;
      }
      const _verificationCode = updateVerificationCode(action, languageId, verificationCode, functionName, parameters, returnType);
      if (_verificationCode) {
        langCode.verificationCode = _verificationCode;
      }
    })
    setValue('langWiseCode', langWiseCode);
    return;
  }



  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      //1. FNC
      if (name === "functionName" && type) {
        handler('FNC', value);
      }
      //  2.RTC
      else if (name === "returnType" && !type) {
        handler('RTC', value);
      }
      else if (name === "parameters" && !type) {
        handler('PAR', value);
      }
      //3.PTC
      else if (name.endsWith("pType") && !type) {
        handler('PTC', value);
      }
      // 4.PNC
      else if (name.endsWith("pName") && type) {
        handler('PNC', value);
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, dataTypesMap]);
  return null;
};
export default FunctionSignatureChangeInterceptor;
