import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import IMAGES from "../../../../assets/images/images";
import { Add } from "@mui/icons-material";
import React from "react";
import QuestionFooter from "../../../HomePage/NewQuestion/QuestionFooter/QuestionFooter";
import Header from "../../../Header/Header";
import Topbar from "../../../Topbar/Topbar";
import QuestionEditor from "../../../HomePage/Editor/QuestionEditor/QuestionEditor";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { useSnackbar } from "notistack";
import { Limits, QuestionSaveURL } from "../../../../utils/constants";
import axiosInstance from "../../../../services/api/client";
import RoutingPaths from "../../../../utils/RoutingPaths";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addQualifyingQuestionsAction } from "../../../../utils/redux/slices/customQuestionSlice";
import RHFInputController from "../../../RHFControllers/RHFInputController";

const QualifyingQuestion = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues: {
      questionDetails: "",
      answers: [{ answer: "", isCorrect: false }],
      detail: {
        video: "",
        langWiseDetail: [
          { language: "English", detail: "" },
          { language: "Hindi", detail: "" },
          { language: "Bangla", detail: "" },
        ],
      },
      selectedLanguage: "English",
      questionType: "MULTIPLE_CHOICE",
      type: "CUSTOM",
      title: "",
    },
  });
  const { control, getValues, handleSubmit } = methods;

  const {
    fields: textFields,
    append: addTextField,
    remove: removeField,
  } = useFieldArray({
    control,
    name: "answers",
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleAddField = () => {
    if (textFields.length < Limits.multipleChoiceQuestion) {
      addTextField({
        answer: "",
        isCorrect: false,
      });
    } else {
      enqueueSnackbar("You can provide maximum 12 answers", {
        variant: "error",
      });
    }
  };
  const multiChoiceAnswers = useWatch({
    control,
    name: "answers",
  });

  const addQualifyingQuestionToStore = (question) => {
    dispatch(addQualifyingQuestionsAction(question));
  };

  const getPayload = () => {
    const commonValues = getValues();
    let payload = {
      detail: commonValues.questionDetails,
      answers: commonValues.answers,
      questionType: "MULTIPLE_CHOICE",
      type: "CUSTOM",
      title: commonValues.title,
      isQualifying: true,
    };
    return payload;
  };

  const SaveQuestion = async () => {
    try {
      const payload = await getPayload();
      let response;
      response = await axiosInstance.post(QuestionSaveURL, payload);
      if (response.data.result === 1) {
        enqueueSnackbar(response.msg || "Saved Successfully", {
          variant: "success",
        });
        addQualifyingQuestionToStore(response?.data?.data);
        navigate(-1);
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.msg || "Something went wrong", {
        variant: "error",
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(SaveQuestion)}>
        <Header />
        <Topbar questionType={"Qualifying Question"} />

        <div className={`question-wrap pd-y-30`}>
          <Card className={`card-assessment mg-b-30`}>
            <CardContent>
              <Box className="mg-b-34">
                <Typography
                  variant={"h3"}
                  component={"h3"}
                  className={"mg-b-10"}
                >
                  Question
                </Typography>
                <div className="col pd-y-10">
                  <RHFInputController
                    RHFname="title"
                    type="text"
                    placeholder="Title"
                    className="form-control form-control-lg"
                    fieldName="title"
                  />
                </div>
                <QuestionEditor />
              </Box>
              <Box>
                <Typography
                  variant={"h3"}
                  component={"h3"}
                  className={"mg-b-10"}
                >
                  Answers
                </Typography>
                <Card className={`card-dashed`}>
                  <CardContent className={"pd-x-20 pd-y-20"}>
                    <ul className={`list-unstyled`}>
                      {textFields.map((fields, index) => {
                        return (
                          <li className="mg-b-20">
                            <Controller
                              key={fields.id}
                              render={({ field }) => (
                                <div className={`has-action`}>
                                  <input
                                    style={{ marginTop: "4px" }}
                                    {...field}
                                    type="text"
                                    className="form-control form-control-lg "
                                  />
                                  <div
                                    className={`input-action d-flex align-items-center`}
                                  >
                                    <Controller
                                      name={`answers.${index}.isCorrect`}
                                      control={control}
                                      render={({ field }) => (
                                        <div className="custom-checkbox">
                                          <input
                                            className="custom-control-input"
                                            type="checkbox"
                                            id={`category-1`}
                                            name={`category-1`}
                                            {...field}
                                            checked={getValues(
                                              `answers.${index}.isCorrect`
                                            )}
                                          />
                                          <label
                                            className="custom-control-label text-capitalize"
                                            htmlFor={`category-1`}
                                          ></label>
                                        </div>
                                      )}
                                    />
                                    <Button
                                      variant={"text"}
                                      className="btn btn-link"
                                      onClick={() => removeField(index)}
                                    >
                                      <img src={IMAGES.Trash} alt="trash" />
                                    </Button>
                                  </div>
                                </div>
                              )}
                              name={`answers.${index}.answer`}
                              control={control}
                            />
                          </li>
                        );
                      })}
                    </ul>
                    <div className="d-flex align-items-center justify-content-between mg-b-24">
                      <button
                        type="button"
                        className="btn btn-link text-decoration-none p-0 font-weight-semi-bold"
                        onClick={handleAddField}
                      >
                        <Add className="mg-r-8" />
                        Add more
                      </button>
                    </div>
                    {multiChoiceAnswers.every(({ isCorrect }) => !isCorrect) ? (
                      <Typography
                        variant={"body2"}
                        component={"p"}
                        className="warning-wrap font-weight-medium"
                      >
                        <img
                          className="icon-left"
                          src={IMAGES.Warning}
                          alt="warning"
                        />
                        The question must have at least one correct answer.
                      </Typography>
                    ) : null}
                  </CardContent>
                </Card>
              </Box>
            </CardContent>
          </Card>
          <QuestionFooter />
        </div>
      </form>
    </FormProvider>
  );
};

export default QualifyingQuestion;
