/*
@ transforms query params Obj to query string
@input queryParams { Object }
*/
// const qs = queryParamsToSring({ limit = 10, skip: 10 })
// console.log(qs); // "limit=10&skip=10"
const queryParamsToSring = (queryParams) => {
    return Object.keys(queryParams)
    .reduce(
        (a, c) => { 
            a && a.push(`${c}=${queryParams[c]}`); 
            return a; 
        }, [])
    .join("&");
}

export default queryParamsToSring;