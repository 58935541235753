import { createSlice } from "@reduxjs/toolkit";
import SkillsService from "../../../services/api/SkillsService";

export const SkillsSlice = createSlice({
  name: "Skills",
  initialState: {
    primarySkills:[]
  },
  reducers: {
    primarySkills: (state, action) => {
      state.primarySkills = action.payload;
    },
  },
});

export const getPrimarySkills = () => async (dispatch) => {
    try {
      const response = await SkillsService.getPrimarySkills();      
      dispatch(primarySkills(response?.data?.data));
    } catch (err) {
      throw new Error(err);
    }
  };

export const { primarySkills } = SkillsSlice.actions;
export default SkillsSlice.reducer;
