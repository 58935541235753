import { Button, Menu, MenuItem, TableCell } from "@mui/material";
import IMAGES from "../../../../assets/images/images";
import { Link } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AlertDialogSlide from "../../../Assesment/MyAssesment/CandidateList/Slider/PopUp/AlertDialogSlide";
import React, { useState } from "react";

const QBMoreMenu = (props) => {
  const { _id, handleDelete } = props;
  const [moreAnchorEl, setMoreAnchorEl] = useState(null);
  const openMore = Boolean(moreAnchorEl);

  const handleMoreOpen = (event) => {
    setMoreAnchorEl(event.currentTarget);
  };

  const moreClose = () => {
    setMoreAnchorEl(null);
  };
  return (
    <>
      <Button
        variant={"text"}
        className={`btn btn-link pd-x-16`}
        id="basic-button"
        onClick={handleMoreOpen}
        aria-controls={openMore ? `${_id}` : undefined}
        aria-haspopup="true"
        aria-expanded={openMore ? "true" : undefined}
      >
        <img src={IMAGES.Dots} alt="dots" />
      </Button>
      <Menu
        id={_id}
        anchorEl={moreAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openMore}
        onClose={moreClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem>
          <Link className={`text-decoration-none`} to={`/edit-question/${_id}`}>
            <span className={`d-inline-block`}>
              <Edit
                sx={{
                  fontSize: 24,
                  color: "#3699FF",
                }}
              />
            </span>
            <span className={"text-dark mg-l-10"}>Edit</span>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            className={`text-decoration-none`}
            to={`/clone-question/${_id}`}
          >
            <span className={`d-inline-block`}>
              <ContentCopyIcon
                sx={{
                  fontSize: 24,
                  color: "#3699FF",
                }}
              />
            </span>
            <span className={"text-dark mg-l-10"}>Clone</span>
          </Link>
        </MenuItem>
        <MenuItem>
          <span className={`d-inline-block`}>
            <AlertDialogSlide
              actionHandler={() => handleDelete(_id)}
              type="delete"
              isDeleteText={true}
            />
          </span>
        </MenuItem>
      </Menu>
    </>
  );
};

export default QBMoreMenu;
