import { createSlice } from "@reduxjs/toolkit";
import SkillsService from "../../../services/api/SkillsService";
import { apiStatusMap } from "../../constants";

export const SkillsSlice = createSlice({
  name: "skills",
  initialState: {
    status: apiStatusMap.idle,
    skills: [],
    skillMap: {},
    subSkillMap: {},
    error: null
  },
  reducers: {
    fetchSkills: (state, action) => {
      if(state.status == apiStatusMap.resolved) return;
      state.status = apiStatusMap.pending;
      state.error = null;
    },
    fetchSkillsSuccess: (state, action) => {
        state.status = apiStatusMap.resolved;
        state.skills = action.payload.skills;
        state.skillMap = action.payload.skillMap;
        state.subSkillMap = action.payload.subSkillMap;
    },
    fetchSkillsError: (state, action) => {
        state.status = apiStatusMap.rejected;
        state.error = action.payload;
    }
  },
});

export const fetchSkills  = () => async(dispatch) => {
  try{
    dispatch(SkillsSlice.actions.fetchSkills());
    const res = await SkillsService.getPrimarySkills();
    const _skills = res.data?.data;
    const subSkillsRes = await Promise.all(_skills.map(({_id}) => SkillsService.getSecondarySkills(_id)));
    const skills = _skills.map((skill, index) => ({...skill, subSkills: subSkillsRes[index].data.data}));
    const { skillMap, subSkillMap } = getSkillSubSkillMap(skills);
    dispatch(SkillsSlice.actions.fetchSkillsSuccess({skills, skillMap, subSkillMap}));
  }catch(err){
    console.log(err)
    let errMsg = err.message ? err.message : "Somthing Went Wrong";
    if(err.response){
        errMsg = err.response.data.msg;
    }
    dispatch(SkillsSlice.actions.fetchSkillsError(errMsg));
  }
}

function getSkillSubSkillMap(skills){
  const skillMap = {};
  const subSkillMap = {};
  skills.forEach(skill => {
    skillMap[skill._id] = skill;
    skill.subSkills.forEach(subSkill => subSkillMap[subSkill._id] = subSkill);
  });
  return {
    skillMap,
    subSkillMap
  }
}

export default SkillsSlice.reducer;
