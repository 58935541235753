/* eslint-disable no-unused-vars */
import { Close } from "@mui/icons-material";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import React from "react";
import "./SliderDrawer.scss";
import Button from "@mui/material/Button";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { TextField } from "@mui/material";
import LinkRoundedIcon from "@mui/icons-material/LinkRounded";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import Snackbars from "./Snackbar";

const SlideDrawer = ({ show, closeDrawer, currentRowAssessmentId }) => {
  let drawerClasses = show ? "side-drawer open" : "side-drawer";
  const baseURL = "/api/assessment/send-invitation";

  const [inputFields, setInputFields] = React.useState([
    { email: "", name: "", lastName: "" },
  ]);

  const [snack, setSnack] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [severity, setSeverity] = React.useState("");

  // const openSnackbar = () => {
  //   setSnack(true);
  // };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnack(false);
  };

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };
  const addFields = () => {
    let newfield = { email: "", name: "", lastName: "" };

    setInputFields([...inputFields, newfield]);
  };
  const submit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(baseURL, {
        assessmentId: currentRowAssessmentId,
        users: inputFields,
      });
      console.log(res);
      if (res.data.result === 1) {
        setSnack(true);
        setSeverity("success");
        setMsg("Invitation has been sent. Please Check your Email!");
        let newArr = inputFields.map((item) => {
          return {
            name: "",
            lastName: "",
            email: "",
          };
        });
        setInputFields(newArr);
      }
    } catch (error) {
      setSnack(true);
      setSeverity("error");
      const errMsg = error.response?.data?.msg;
      setMsg("Email is already registered with this assessment");
      console.log(errMsg);
    }
  };
  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };
  return (
    <div className={drawerClasses}>
      <div className="DrawerContainer">
        <div className="inviteCandidates customFonts">
          <div>Invite Candidates</div>
          <Button>
            <Close
              onClick={closeDrawer}
              sx={{ fontSize: 24, color: "#000000" }}
            />
          </Button>
        </div>
        <div id="border"></div>
        {/* <div style={{ display: "flex", alignItems: "center" }}>
          <span className="customFonts">By Public Link</span>
          <HelpRoundedIcon
            sx={{ fontSize: 24, color: "#3699FF", marginLeft: 1 }}
          />
        </div>

        <div className="publicLink">
          <div style={{ marginLeft: -10 }}>
            <Checkbox defaultChecked />
            <span style={{ color: "black", fontFamily: "Roboto" }}>
              General public link
            </span>
          </div>

          <Button
            variant="outlined"
            className="ButtonStyles"
            style={{
              backgroundColor: "#F1FAFF",
            }}
          >
            <ContentCopyRoundedIcon
              sx={{ fontSize: 24, marginRight: 1, color: "#40BAFF" }}
            />
           <span> Copy link</span>
          </Button>
        </div>

        <div className="publicLink">
          <TextField
            className="TextField"
            variant="standard"
            margin="normal"
            required
            name="candidateSource"
            autoFocus
            placeholder="Candidate source*"
            InputProps={{
              disableUnderline: true,
            }}
          />

          <Button
            variant="outlined"
            className="ButtonStyles"
            style={{
              marginTop: 5,
              backgroundColor: "#F1FAFF",
            }}
          >
            <LinkRoundedIcon
              sx={{ fontSize: 24, marginRight: 1, color: "#40BAFF" }}
            />
            <span>Create link</span>
          </Button>
        </div> */}

        {inputFields.map((input, index) => {
          return (
            <div className="inputFields" key={index}>
              <span className="customFonts">By Email</span>
              <TextField
                className="TextField"
                value={input.name}
                onChange={(event) => handleFormChange(index, event)}
                variant="standard"
                margin="normal"
                name="name"
                autoFocus
                placeholder="First name"
                InputProps={{
                  disableUnderline: true,
                }}
              />
              <TextField
                className="TextField"
                variant="standard"
                margin="normal"
                name="lastName"
                autoFocus
                placeholder="Last name"
                value={input.lastName}
                onChange={(event) => handleFormChange(index, event)}
                InputProps={{
                  disableUnderline: true,
                }}
              />
              <TextField
                className="TextField"
                variant="standard"
                margin="normal"
                required
                type="email"
                name="email"
                autoFocus
                placeholder="Email*"
                value={input.email}
                onChange={(event) => handleFormChange(index, event)}
                InputProps={{
                  disableUnderline: true,
                }}
              />
              {inputFields.length !== 1 && (
                <Button
                  id="removeButton"
                  variant="contained"
                  onClick={() => removeFields(index)}
                >
                  Remove
                </Button>
              )}
            </div>
          );
        })}

        <div className="inviteAddMoreButtonContainer">
          <div>
            <Button
              className="ButtonStyles"
              style={{
                backgroundColor: "#3CB371",
                marginRight: 10,
              }}
              variant="contained"
              onClick={addFields}
            >
              + Add More
            </Button>
            <Button
              onClick={submit}
              className="ButtonStyles"
              style={{
                backgroundColor: "#8950FC",
              }}
              variant="contained"
            >
              Invite
            </Button>
          </div>

          {/* <div style={{ color: "#5E6278", textDecoration: "underline",marginTop:5,fontFamily:'Roboto' }}>
            Invite Candidate in Bulk
          </div> */}
        </div>

        <div id="border"></div>
        <Button
          variant="outlined"
          className="ButtonStyles"
          style={{
            marginTop: 15,
            backgroundColor: "#F5F8FA",
          }}
        >
          <LinkRoundedIcon
            sx={{
              fontSize: 24,
              marginRight: 1,
              color: "#7E8299",
            }}
          />
          Customize invitation email
        </Button>
      </div>
      {snack && (
        <Snackbars
          text={msg}
          snack={snack}
          severity={severity}
          closeSnackbar={closeSnackbar}
        />
      )}
    </div>
  );
};

export default SlideDrawer;
