import axios from "axios";
import { useSnackbar } from "notistack";
import { fetchAccessToken } from "../../utils/constants";
import { showLoader } from "../../utils/redux/slices/loaderSlice";
import { store } from "../../utils/redux/store";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:5000/",
  headers: {
    "Content-type": "application/json",
  },
});

axiosInstance.interceptors.request.use((config) => {
  const accessToken =
    localStorage.getItem("accessToken") &&
    JSON.parse(localStorage.getItem("accessToken"));
  config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : "";
  store.dispatch(showLoader(true));
  return config;
});
axiosInstance.interceptors.response.use(
  (response) => {
    store.dispatch(showLoader(false));
    return response;
  },
  async (error) => {
    store.dispatch(showLoader(false));
    if (error.response) {
      // const statusCode = error.response.status;
      // handling unAuthorised token , invalid token with refresh token
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        console.log(error.config);
        originalRequest._retry = true;
        localStorage.removeItem("accessToken");
        const accessToken = await fetchAccessToken();
        error.config.headers.Authorization = accessToken
          ? `Bearer ${accessToken}`
          : "";
        return axiosInstance(originalRequest);
      }
      const data = error.response.data;
      if (data.result === 0) {
        console.log({ ErrorMsg: data.msg });
      }
    }
    throw error;
  }
);
export default axiosInstance;
