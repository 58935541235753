import {Box, Button, Modal, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";

const CreateHiringStateModal = (props) =>{
    const {open, setOpen} = props;
    const handleCloseModal = () => {
        setOpen(false);
    };
    return(
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modal-box">
                <Box className="modal-content tabs-wrap question-tabs">
                    <Box className={`pd-x-30`}>
                        <Box className={"pd-t-30 d-flex justify-content-end"}>
                            <Button variant={"text"} color={"inherit"} onClick={handleCloseModal}>
                                <Close/>
                            </Button>
                        </Box>
                        <Box>
                            <Typography variant="h2" component="h2" className={`mg-b-24`}>
                                Create Hiring stage
                            </Typography>
                            <input type="text" className={"form-control form-control-lg"} placeholder={"Enter name"}/>
                        </Box>
                        <div className={'pd-y-30'}>
                            <button type={"button"} className={`btn btn-snow btn-lg mg-r-10`} onClick={handleCloseModal}>
                                Cancel
                            </button>
                            <button onClick={handleCloseModal} type={"button"} className={`btn btn-primary btn-lg text-white`}>Save</button>
                        </div>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default CreateHiringStateModal;