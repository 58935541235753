import axiosInstance from "./client";

const createVideo = async (payload) => {
  return await axiosInstance.post(`/api/videos`, payload);
};
const getVideos = () => {
  return axiosInstance.get(`/api/videos`);
};

const VideoService = {
  createVideo,
  getVideos,
};

export default VideoService;
