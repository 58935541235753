import {Box, Modal, Typography} from "@mui/material";

const ConfirmationDialog = (props) => {
    const {open, setOpen, title, subtitle, okButtonText, cancelButtonText, confirmAction } = props;
    const handleCloseModal = () => {
        setOpen(false);
    };
    const handleOkButton = () => {
        confirmAction()
    };
    return(
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modal-box">
                <Box className="modal-content tabs-wrap question-tabs">
                    <Box className={`pd-x-30 text-center`}>
                        <Box className={`border-bottom pd-y-30`}>
                            {
                                title &&
                                <Typography variant="h2" component="h2" className={`heading1 mg-b-10`}>
                                    {title}
                                </Typography>
                            }
                            {subtitle &&
                            <Typography variant="body1" component="p" className={`font-size-lg`} color={"secondary"}>
                                {subtitle}
                            </Typography>
                            }
                        </Box>
                        <div className={'pd-y-30'}>
                            <button type={"button"} className={`btn btn-snow btn-lg mg-r-10`} onClick={handleCloseModal}>
                                {cancelButtonText}
                            </button>
                            <button onClick={handleOkButton} type={"button"} className={`btn btn-primary btn-lg text-white`}>{okButtonText}</button>
                        </div>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default ConfirmationDialog;