import Header from "../Header/Header";
import React from "react";
import NameTest from "./NameTest/NameTest";
import ChooseTestQuestions from "./ChooseTestQuestions/ChooseTestQuestions";
import CreateTestReview from "./CreateTestReview/CreateTestReview";
import Statusbar from "../Assesment/Statusbar/Statusbar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  decreaseActiveStepAction,
  increaseActiveStepAction,
} from "../../utils/redux/slices/createTestSlice";
import { FormProvider, useForm } from "react-hook-form";
import DragAndDrop from "../common/DragAndDrop/DragAndDrop";
import QuestionBankListService from "../../services/api/QuestionBankListService";
import { useState } from "react";
import { useEffect } from "react";
import useDebounce from "../../hooks/useDebounce";
const stepsArray = [
  { step: 0, label: "Name test" },
  { step: 1, label: "Test questions" },
  //   { step: 2, label: "Practice questions" },
  { step: 2, label: "Review" },
];
const CreateTest = () => {
  const activeStep = useSelector((state) => state.CreateTestSlice.activeStep);
  const createTestMethods = useForm({
    defaultValues: {
      testName: "",
      complexity: "",
      description: "",
      selectedTestQuestions:[],
    },
  });
  const {getValues}=createTestMethods
  const dispatch = useDispatch();
  const saveTest = () => {
    const values=getValues();
    console.log(values)
  };
  const increaseActiveStep = () => {
    if (activeStep < 2) {
      dispatch(increaseActiveStepAction());
    }
  };
  const decreaseActiveStep = () => {
    if (activeStep > 0) {
      dispatch(decreaseActiveStepAction());
    }
  };
  return (
    <FormProvider {...createTestMethods}>
      <Header />
      <Statusbar
        save={saveTest}
        activeStep={activeStep}
        title="Create New Test"
        stepsArray={stepsArray}
        decreaseActiveStep={decreaseActiveStep}
        increaseActiveStep={increaseActiveStep}
        lastStep={2}
      />
      {activeStep === 0 && <NameTest />}
      {activeStep === 1 && <ChooseTestQuestions />}
      {/* {activeStep === 2 && <DragAndDrop data={data} />} */}
      {activeStep === 2 && <CreateTestReview />}
    </FormProvider>
  );
};

export default CreateTest;
