import { InputBase, TextField } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import styles from './Controller.module.scss'

function TextFieldController({ name, label }) {
  const methods = useFormContext();
  const { control,formState: { errors } } = methods;
  return (
    <div style={{flex: 1 }}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <div style={{width:'100%'}}>
            <InputBase
              label={label}
              onChange={onChange}
              value={value}
              placeholder={label}
              className={styles.title}
              // error={errors.name?true:false}
            />
          </div>
        )}
      />
    </div>
  );
}

export default TextFieldController;
