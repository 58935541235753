export const getInitialCode = (
  functionName,
  parameters,
  placeholder,
  languageId,
  functionReturnType,
  paramsType,
  paramsForPhp,
  paramsForTS
) => {
  switch (languageId) {
    case 63:
      return `function ${functionName}(${parameters}) {
 //${placeholder}
}
        `;
    case 62:
      return `public static ${functionReturnType} ${functionName}(${paramsType}) {
//${placeholder}
}
            `;
    case 68:
      return ` function ${functionName}(${paramsForPhp}){
 //${placeholder}
} 
              
              `;
    case 70:
      return `def ${functionName}(${parameters}) :
      #${placeholder}
                  `;
    case 74:
      return `function ${functionName}(${paramsForTS}) : ${functionReturnType}{
  //${placeholder}
} 
                            
                            `;

    case 76:
      return `${functionReturnType} ${functionName}(${paramsType}) {
//${placeholder}
}
                                    `;
    case 2:
      return `function ${functionName}(${parameters}) {
 //${placeholder}
}
        `;
    default:
      return `${functionReturnType} ${functionName}(${paramsType}) {
        //${placeholder}
        }
                                            `; 
  }
};
