export const actions = {
  FUNCTION_NAME_CHANGE: "FNC",
  RETURN_TYPE_CHANGE: "RTC",
  PARAMETER_CHANGE: "PC",
  PARAMETER_REMOVE: "PR",
  PARAMETER_NAME_CHANGE: "PNC",
  PARAMETER_TYPE_CHANGE: "PTC",
};

export function getDataType(languageId, commonType, dataTypesMap) {
  return dataTypesMap[commonType] && dataTypesMap[commonType][languageId] ? dataTypesMap[commonType][languageId] : "";
}

export function getParametersString(languageId, parameters, dataTypesMap) {

  if (languageId === 62) {
    const arr = parameters.map(({ pName, pType }) => `${getDataType(languageId, pType, dataTypesMap)} ${pName}`);
    return arr.toString();
  }
  else if (languageId === 63) {
    const arr = parameters.map(({ pName }) => pName);
    return arr.toString();
  }
  else if (languageId === 68) {
    const arr = parameters.map(({ pName }) => `$${pName}`);
    return arr.toString();
  }
  else if (languageId === 70) {
    const arr = parameters.map(({ pName }) => pName);
    return arr.toString();
  }
  else if (languageId === 74) {
    const arr = parameters.map(({ pName, pType }) => `${pName}: ${getDataType(languageId, pType, dataTypesMap)}`);
    return arr.toString();
  }
  else {
    throw new Error("Incorrect languageId")
  }
}

export const converter = {
  62: {
    FNC: (codeString, functionName) => codeString.replace(/(?<=public\s+static\s+[A-Za-z\[\]]+\s+)[A-Za-z0-9_]*(?=\s*\()/, functionName),
    PC: (codeString, parameters) => codeString.replace(/(?<=public\s+static\s+[A-Za-z\[\]]+\s+[A-Za-z0-9_]+\s*\()[A-Za-z0-9_,\s]*(?=\))/, parameters),
    RTC: (codeString, returnType) => codeString.replace(/(?<=public\s+static\s+)[A-Za-z\[\]]+(?=\s+[A-Za-z0-9_]+\s*\()/, returnType),
  },
  63: {
    FNC: (codeString, functionName) => codeString.replace(/(?<=function\s+)[A-Za-z0-9_]*(?=\s*\()/, functionName),
    PC: (codeString, parameters) => codeString.replace(/(?<=function\s+[A-Za-z0-9_]*\s*\()[A-Za-z0-9_,\s]*(?=\))/, parameters),
  },
  68: {
    FNC: (codeString, functionName) => codeString.replace(/(?<=function\s+)[A-Za-z0-9_]*(?=\s*\()/, functionName),
    PC: (codeString, parameters) => codeString.replace(/(?<=function\s+[A-Za-z0-9_]*\s*\()[$A-Za-z0-9_,\s]*(?=\))/, parameters),
  },
  70: {
    FNC: (codeString, functionName) => codeString.replace(/(?<=def\s+)[A-Za-z0-9_]*(?=\s*\()/, functionName),
    PC: (codeString, parameters) => codeString.replace(/(?<=def\s+[A-Za-z0-9_]*\s*\()[A-Za-z0-9_,\s]*(?=\))/, parameters),
  },
  74: {
    FNC: (codeString, functionName) => codeString.replace(/(?<=function\s+)[A-Za-z0-9_]*(?=\s*\()/, functionName),
    PC: (codeString, parameters) => codeString.replace(/(?<=function\s+[A-Za-z0-9_]*\s*\()[A-Za-z0-9_,\s:]*(?=\))/, parameters),
    RTC: (codeString, returnType) => codeString.replace(/(?<=function\s+[A-Za-z0-9_]*\s*\([A-Za-z0-9_,\s:]*\))[:A-Za-z\[\]\s]*(?=\s*{)/, ` :${returnType} `),
  },
};

export const getInitialCodeMap = {
  62: (functionName, parameters, returnType, dataTypesMap) => {
    const parametersString = getParametersString(62, parameters, dataTypesMap);
    const _returnType = getDataType(62, returnType, dataTypesMap);
    return (`
    public static ${_returnType} ${functionName} (${parametersString}){
      // Type your code here...

    }
  `)
  },
  63: (functionName, parameters, returnType, dataTypesMap) => {
    const parametersString = getParametersString(63, parameters, dataTypesMap);
    return (`
    function ${functionName} (${parametersString}){
      // Type your code here...
  
    }
    `)
  },
  68: (functionName, parameters, returnType, dataTypesMap) => {
    const parametersString = getParametersString(68, parameters, dataTypesMap);
    return (`
    function ${functionName} (${parametersString}){
      // Type your code here...
  
    }
    `)
  },
  70: (functionName, parameters, returnType, dataTypesMap) => {
    const parametersString = getParametersString(70, parameters, dataTypesMap);
    return (`
def ${functionName} (${parametersString}):
  #Type your code here...
`)
  },
  74: (functionName, parameters, returnType, dataTypesMap) => {
    const parametersString = getParametersString(74, parameters, dataTypesMap);
    const _returnType = getDataType(74, returnType, dataTypesMap);
    return (`
    function ${functionName} (${parametersString}) : ${_returnType}{
      // Type your code here...
  
    }
    `)
  },

};