import {Box, Button, Modal, Typography} from "@mui/material";
import IMAGES from "../../../assets/images/images";
import React, {useEffect, useState} from "react";
import QuestionFooter from "../../HomePage/NewQuestion/QuestionFooter/QuestionFooter";
import CategoryInfo from "./CategoryInfo/CategoryInfo";

const DetailsDialog = (props) =>{
    const {open, setOpen} = props;
    const [readyToAddClass, setReadyToAddClass] = useState(false);
    const handleCloseModal = () => {
        setOpen(false);
    };
    useEffect(() => {
        setReadyToAddClass(true);
    }, [open]);
    return(
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={`drawer drawer-sm right ${readyToAddClass && "open"}`}>
                <Box className="modal-content">
                    <Box className={`d-flex align-items-start justify-content-between pd-x-30 pd-y-30 border-bottom`}>
                        <div className={`d-flex align-items-start`}>
                            <div
                                className={`img-asset-box img-asset-60 asset-bg mg-r-10`}
                            >
                                <img
                                    className={`img-fluid`}
                                    src={IMAGES.React}
                                    alt="..."
                                />
                            </div>
                           <div>
                               <Typography id="modal-modal-title" variant="h2" component="h2" className={`mg-r-20 mg-b-20`}>
                                   JavaScript (coding): Beginner level algorithms
                               </Typography>
                               <button type={"button"} className={'btn btn-snow text-primary border-primary'}>
                                   <i className={'icon-eye mg-r-10'}></i>
                                   View sample question
                               </button>
                           </div>
                        </div>
                        <button type={`button`} className={`btn btn-link text-decoration-none`} onClick={handleCloseModal}>
                            <img src={IMAGES.XIcon} alt="close"/>
                        </button>
                    </Box>
                    <Box className={'pd-x-30 pd-y-30'}>
                        <Typography
                            variant={"body1"}
                            component={"p"}
                            className={`mg-b-10 font-size-sm`}
                        >
                            This coding test evaluates a candidate's ability to manipulate data structures and data types. It tests intermediate JavaScript programming skills. This test will help you identify and hire developers with essential JavaScript knowledge.
                        </Typography>
                        <div className={`mg-b-10`}>
                            <CategoryInfo/>
                        </div>
                        <Typography
                            variant={"h4"}
                            component={"h4"}
                            className={`mg-b-10 font-size-base`}
                        >
                            Description
                        </Typography>
                        <Typography
                            variant={"body1"}
                            component={"p"}
                            className={`mg-b-10 font-size-sm`}
                        >
                            JavaScript is one of the core technologies of the World Wide Web. JavaScript is what often enables the many dynamic, interactive web pages we see and use on the Internet today. Many popular frameworks, such as AngularJS, Ember.js, Vue.js, React, and Node.js are all based on JavaScript.
                        </Typography>
                        <Typography
                            variant={"body1"}
                            component={"p"}
                            className={`mg-b-10 font-size-sm`}
                        >
                            Strong foundational knowledge of programming in JavaScript is essential for junior programmers. Those candidates are able to hit the ground running and set themselves up for further professional growth.
                        </Typography>
                    </Box>

                </Box>
            </Box>
        </Modal>
    )
}

export default DetailsDialog;