import { Typography } from "@mui/material";
import styles from "../NewQuestion.module.scss";
import React from "react";
import RHFInputController from "../../../RHFControllers/RHFInputController";
import { useFormContext, useWatch } from "react-hook-form";

const TimeLimit = () => {
  const { control } = useFormContext();
  const minutes = useWatch({
    control,
    name: "time.min",
  });
  const seconds = useWatch({
    control,
    name: "time.sec",
  });
  return (
    <>
      <div className="d-flex align-items-center mg-b-34 mg-t-20">
        <Typography className="mg-r-20 font-weight-medium">
          Time limit
        </Typography>
        <div className={`${styles.timeLimit} mg-r-10`}>
          <RHFInputController
            RHFname="time.min"
            type="text"
            placeholder="0"
            className="form-control form-control-sm number-icon-hidden"
            fieldName="time[min]"
          />
        </div>
        <Typography className="font-weight-medium">
          minutes
        </Typography>
        <div className={`${styles.timeLimit} mg-l-10`}>
          <RHFInputController
            RHFname="time.sec"
            type="text"
            placeholder="0"
            className="form-control form-control-sm number-icon-hidden"
            fieldName="time[sec]"
          />
        </div>
        <Typography className="font-weight-medium mg-l-10">
          Seconds
        </Typography>
      </div>
      {/* <div>
        {Number(minutes) >= 60 && (
          <p style={{ color: "red" }}>Minutes should be less than 60</p>
        )}
        {Number(seconds) < 30 && Number(minutes) <= 0 && (
          <p style={{ color: "red" }}>Seconds should be at least 30</p>
        )}
      </div> */}
    </>
  );
};

export default TimeLimit;
