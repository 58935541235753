import { useState, useEffect, createRef } from "react";
import { Box, Modal, Grid, TextareaAutosize, Avatar, Button } from "@mui/material";
import { useFormContext } from "react-hook-form";

const avatarAllowedSize = 1 * 1000 * 1000;
const avatarAllowedType = ["image/jpg", "image/jpeg", "image/png"];

const actionMap = {
    create: {
        ok: 'Create',
        cancel: 'Cancel'
    },
    update: {
        ok: 'Update',
        cancel: 'Cancel'
    }
}

const AuthorAddUpdateDialog = (props) => {
    const {open, setOpen , methods, confirmAction, actionType, isLoading, image, setImage } = props;
    const { register, reset, formState: { errors, isSubmitting }, setValue, handleSubmit} = methods;
    const handleCloseModal = () => {
        setOpen(false);
    };
 
    const inputFileRef = createRef(null);

    const cleanup = () => {
        URL.revokeObjectURL(image);
        if(inputFileRef.current) inputFileRef.current.value = null;
        setValue("profilePic", "");
        setImage(null)
    };

    const handleOnChange = (event) => {
        const file = event.target?.files?.[0];
        if(!file) return;
        if(file.size > avatarAllowedSize) return;
        if(!avatarAllowedType.includes(file.type)) return;
        const _image = URL.createObjectURL(file)
        setValue("profilePic", _image);
        setImage(_image);
    };

    const onSubmit = async(values) => {
        confirmAction(values);
    };

    useEffect(() => {
        // cleanup when dialog close
        !open && cleanup();
    }, [open])

    return(
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modal-box">
                <Box className="modal-content tabs-wrap question-tabs">
                    <Box className={`pd-x-30`}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                        <Box className={`border-bottom pd-y-30`}>
                            <Grid container spacing={1} >
                                <Grid item xs={6} >
                                    <div className="form-group" >
                                        <strong><label htmlFor="name">Author Name</label></strong>
                                        <input
                                            id="name"
                                            type="text"
                                            placeholder="Type Here..."
                                            className={"form-control form-control-lg"}
                                            {...register('name', { required: true, minLength: 3, maxLength: 30 })}
                                        />
                                        {errors.name && errors.name.type === "required" && <small className="text-danger">*This is required</small>}
                                        {errors.name && errors.name.type === "minLength" && <small className="text-danger">*Min 2 character expected</small> } 
                                        {errors.name && errors.name.type === "maxLength" && <small className="text-danger">*Max 30 character expected</small> }
                                    </div>
                                            
                                    <div className="form-group">
                                        <strong><label htmlFor="description">Author Description</label></strong>
                                        <TextareaAutosize
                                            minRows={3}
                                            maxRows={5}
                                            id="description"
                                            type="text"
                                            placeholder="Type Here..."
                                            className={"form-control form-control-lg"}
                                            {...register('description', { required: true, minLength: 30, maxLength: 250 })}
                                        />
                                        {errors.description && errors.description.type === "required" && <small className="text-danger">*This is required</small>}
                                        {errors.description && errors.description.type === "minLength" && <small className="text-danger">*Min 30 character expected</small> } 
                                        {errors.description && errors.description.type === "maxLength" && <small className="text-danger">*Max 250 character expected</small> }    
                                    </div>
                                </Grid>
                                <Grid item xs={6} >
                                    <div className="form-group">
                                        <strong><label>Author Image</label></strong>
                                        <div className={`pd-y-5`} style={{
                                            maxWidth: "100%",
                                            maxHeight: "auto"
                                        }}>
                                            <Avatar
                                                variant="rounded"
                                                src={image}
                                                sx={{ width: "100%", height: "auto" }}
                                            />
                                        </div>
                                    </div>
                                    <input
                                        id="profilePic"
                                        type="text"
                                        style={{ display: "none" }}
                                        {...register('profilePic', { required: true })}
                                    />
                                    {errors.profilePic && errors.profilePic.type === "required" && <small className="text-danger">*This is required</small>}
                                    <br/>
                                    <input
                                        id="select-image"
                                        name="selectedFile"
                                        type="file"
                                        style={{ display: "none" }}
                                        accept="image/*" 
                                        onChange={handleOnChange}
                                        ref={inputFileRef}
                                    />
                                    {image ? (
                                        <button onClick={cleanup} type="button" className={`btn btn-primary text-white`}>
                                            Remove
                                        </button>
                                    ) : (
                                        <label htmlFor="select-image">
                                            <Button variant="contained" color="primary" component="span">
                                                Upload Image
                                            </Button>
                                        </label>
                                    )}
                                    
                                </Grid>
                            </Grid>
                        </Box>
                        <p>
                            <small className="text-danger">*Must be jpg, jpeg or png</small>
                            <br/>
                            <small className="text-danger">*Must be less than 1 MB</small>
                        </p>
                        <div className={'pd-y-30'}>
                            <button type="button" className={`btn btn-snow mg-r-10`} onClick={handleCloseModal}>
                                {actionMap[actionType]?.cancel}
                            </button>
                            <button disabled={isLoading} type="submit" className={`btn btn-primary text-white`}>{isLoading ? "saving..." : actionMap[actionType]?.ok}</button>
                        </div>
                        </form>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default AuthorAddUpdateDialog;