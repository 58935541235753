import { Navigate, useLocation } from "react-router-dom";

const RouteInterceptor = () => {
  const location = useLocation();
  const accessToken = JSON.parse(localStorage.getItem("accessToken"));
  if (location.pathname === "/") {
    if (accessToken) {
      return <Navigate to="/dashboard" />;
    }
  }
  return null;
};

export default RouteInterceptor;
