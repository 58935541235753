import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Menu,
  MenuItem,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from "react-redux";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { useSnackbar } from 'notistack';
import Header from "../Header/Header";
import IMAGES from "../../assets/images/images";
import Loader from "../Loader/Loader";
import SkillsService from "../../services/api/SkillsService";
import FormDialog from "./FormDialog";
import ConfirmationDialog from "../common/ConfirmationDialog/ConfirmationDialog";
import RoutingPaths from "../../utils/RoutingPaths";

const actionTypeMap = { list: 'list', create: 'create', update: 'update', delete: 'delete' };

const SubSkill = () => {
  const { primarySkillId } = useParams();
  const methods = useForm({ defaultValues: { label: '' } });
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const loader = useSelector((state) => state.Loader.loading);
  const [data, setData] = useState({
      subSkills: [],
      actionType: actionTypeMap.list,
      subSkill: null,
      isFormOpen: false,
      isConfirmDialogOpen: false,
  });

  const setIsFormOpen = (isFormOpen) => setData(preData => ({...preData, isFormOpen }))
  const setIsConfirmDialogOpen = (isConfirmDialogOpen) => setData(preData => ({...preData, isConfirmDialogOpen }))
 
  const setListAction = () => setData(preData => ({...preData, actionType: actionTypeMap.list, isFormOpen: false }));
  const setCreateAction = () => setData(preData => ({...preData, actionType: actionTypeMap.create, subSkill: { primarySkillId, label: '' }, isFormOpen: true }));
  const setUpdateAction = (subSkill) => setData(preData => ({...preData, actionType: actionTypeMap.update, subSkill: {primarySkillId, ...subSkill}, isFormOpen: true }));
  const setDeleteAction = (subSkill) => setData(preData => ({...preData, actionType: actionTypeMap.delete, subSkill: {primarySkillId, ...subSkill}, isConfirmDialogOpen: true }));

  const handleAxiosError = (err) => {
    if(err.response){
      let { msg } = err.response.data;
      if(msg.match(/you entered is already registered/)) msg = "Skill Name Already Exists";
      enqueueSnackbar(msg, { variant: "error" })
      return;
    }
    enqueueSnackbar("Something went Wrong", { variant: "error" })
  }

  const handleCreateSubSkill = (payload, message) => {
    SkillsService.createSecondarySkill(payload)
    .then(() => {
      enqueueSnackbar(message, { variant: "success" })
      setListAction()
    })
    .catch((err) => handleAxiosError(err))
  }

  const handleUpdateSubSkill = (payload, message) => {
    SkillsService.updateSecondarySkill(data.subSkill._id, payload)
    .then(() => {
      enqueueSnackbar(message, { variant: "success" })
      setListAction()
    })
    .catch((err) => handleAxiosError(err))
  }

  const handleDeleteSubSkill = () => {
    SkillsService.deleteSecondarySkill(data.subSkill.primarySkillId, data.subSkill._id)
    .then(() => {
      enqueueSnackbar("Sub Skill Deleted", { variant: "success" })
      setListAction()
    })
    .catch((err) => handleAxiosError(err))
  }

  const dialogFormConfirmAction = (values) => {
    if(![actionTypeMap.create, actionTypeMap.update].includes(data.actionType)){
      return
    }
    const {label} = values;
    if(data.actionType === actionTypeMap.create){
      handleCreateSubSkill({ ...data.subSkill, label }, "Sub Skill created");
    }else{
      handleUpdateSubSkill({ label }, "Sub Skill Updated");
    }
  }

  useEffect(() => {
    async function getSecondarySkills(){
      SkillsService.getSecondarySkills(primarySkillId).then((response) => {
        const { result, data, msg } = response.data;
        if(!result) return console.log(msg);
        setData(preData => ({...preData, subSkills: data }))
      }).catch((err) => alert(err.message))
    }
    if(data.actionType == actionTypeMap.list){
      getSecondarySkills();
    }
  }, [data.actionType])

  if(loader){
      return (<Loader />)
  }

  return (
    <>
      <Header />
      <div className={`page-spacing pd-y-20`}>
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between flex-wrap mg-b-10">
            <div className={`d-flex align-items-center`}>
              <div className={"d-flex align-items-center flex-wrap"}>
                Sub Skills
              </div>
            </div>
            <div className={`d-flex align-items-center flex-wrap`}>
            <Button
                className={`text-nowrap`}
                variant={"contained"}
                color={"primary"}
                size={"small"}
                onClick={() => navigate(RoutingPaths.skills)}
              >
                Back To Skills
              </Button>
              &nbsp;
              <Button
                className={`text-nowrap`}
                variant={"contained"}
                color={"primary"}
                size={"small"}
                onClick={() => {
                  methods.setValue("label", "")
                  setCreateAction()
                }}
              >
                <i className={`icon-plus mg-r-6`}></i>
                New Secondary Skill
              </Button>
            </div>
          </div>
          
          <Card className={'card-table'}>
              <CardContent className="pd-x-0 pd-y-0">
                <TableContainer className="custom-table">
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>SN</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    { data.subSkills.length ? (
                      <TableBody>
                        {data.subSkills.map(
                          (subSkill,index) => {
                            return (
                              <TableRow
                                key={subSkill._id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>{index + 1}</TableCell>
                                <TableCell className="pd-l-0">
                                  <Box>
                                    <Link
                                      to={`#`}
                                      style={{
                                        color: "#000",
                                        textDecoration: "none",
                                      }}
                                    >
                                      <Typography className="table-title">
                                        {subSkill.label}
                                      </Typography>
                                    </Link>
                                  </Box>
                                </TableCell>
                                <TableCell className="pd-l-0">
                                  <button onClick={() => {
                                    methods.setValue("label", subSkill.label);
                                    setUpdateAction(subSkill);
                                  }}>
                                    <EditIcon color="primary" />
                                  </button>
                                  <button onClick={() => setDeleteAction(subSkill)}>
                                  <DeleteIcon color="primary" />
                                  </button>
                                  
                                </TableCell>
                              </TableRow>
                              
                            );
                          }
                        )}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={12}>
                            No Data Found!
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          {/* <Box
            className={`d-flex justify-content-between align-items-center mg-t-30`}
          >
            {data?.skills.length > 0 && !loader && (
              <Typography variant={"body2"} color={"secondary"}>
                Showing {(page - 1) * PER_PAGE + 1} to{" "}
                {page * PER_PAGE >= data.totalCount
                  ? data?.skills.length
                  : page * PER_PAGE}{" "}
                of {data?.skills.length} entries
              </Typography>
            )}

            {data?.skills.length > PER_PAGE && !loader && (
              <Pagination
                color={"primary"}
                className={"custom-pagination"}
                shape="rounded"
                count={data.totalCount}
                page={page}
                variant="outlined"
                onChange={() => {}}
              />
            )}
          </Box> */}
        </div>
      </div>
      <FormDialog 
        open={data.isFormOpen} 
        setOpen={setIsFormOpen}
        label="Seconday Skill"
        value={data.subSkill?.label}
        methods={methods}
        actionType={data.actionType}
        confirmAction={dialogFormConfirmAction}
      />
      <ConfirmationDialog
        open={data.isConfirmDialogOpen}
        setOpen={setIsConfirmDialogOpen}
        title="Are you sure want to delete this Sub Skill"
        subtitle=""
        okButtonText="Delete"
        cancelButtonText="Cancel"
        confirmAction={handleDeleteSubSkill}
      />
    </>
  );
};
export default SubSkill;
