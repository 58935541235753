import Header from "../../Header/Header";
import TopBar from "../../Topbar/Topbar";
import React from "react";
import styles from "./NewQuestion.module.scss";
import {Container, Card, CardContent, Divider} from "@mui/material";
import QuestionFooter from "./QuestionFooter/QuestionFooter";
import NewQuestionTop from "./NewQuestionTop/NewQuestionTop";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion/MultipleChoiceQuestion";
import QuestionBottom from "./QuestionBottom/QuestionBottom";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import {
  languageMap,
  Limits,
  QuestionSaveAsDraftURL,
  QuestionSaveURL,
  questionTypeMap,
  QuestionUpdateURL,
  Roles,
  setTimeInMinSec,
  statusMap,
} from "../../../utils/constants";
import CodingQuestion from "./CodingQuestion/CodingQuestion";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axiosInstance from "../../../services/api/client";
import ShortTextQuestion from "./ShortTextQuestion/ShortTextQuestion";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getUploadUrlAndObjectUrl,
  uploadFileToAWS,
} from "../../../services/api/aws";
import RoutingPaths from "../../../utils/RoutingPaths";
import { useDispatch, useSelector } from "react-redux";
import LinearProgressLine from "../../LinearProgress/LinearProgress";
import { useAuth } from "../../../hooks/useAuth";
import { useEffect } from "react";
import QuestionListService from "../../../services/api/QuestionListService";
import { useState } from "react";
import {
  addCustomQuestionAction,
  removeCustomQuestionAction,
} from "../../../utils/redux/slices/customQuestionSlice";
import VideoService from "../../../services/api/VideoService";
import {
  addInAllVideoQuestionAction,
  addInVideoQuestionLibraryAction,
  removeAllVideoFromQuestionLibraryAction,
} from "../../../utils/redux/slices/videoQuestionSlice";
import { generateVideoThumbnail } from "../../../utils/functions/generateVideoThumbnail";
import QuestionComments from "./QuestionComments/QuestionComments";
import ConfirmationDialog from "../../common/ConfirmationDialog/ConfirmationDialog";
import QuestionReview from "./QuestionReview/QuestionReview";
import { useRef } from "react";
import QuestionService from "../../../services/api/QuestionService";

const schema = yup.object().shape({
  title: yup.string().required("Title is required"),
  time: yup.object().shape({
    min: yup.string().required("Minutes is required!"),
    sec: yup.string().required("Seconds is required!"),
  }),
});

const typeMap = {
  CUSTOM: "custom",
  BANK: "bank",
};

const NewQuestion = () => {
  const skipIdsRef = useRef([]);
  const [open, setOpen] = useState(false);
  const [questionStatus, setQuestionStatus] = useState("");
  const params = useParams();
  const { user } = useAuth();
  const location = useLocation();
  const isCustomQuestion = location.state?.isCustomQuestion
    ? location.state?.isCustomQuestion
    : false;
  const isUpdateCustomQuestion = location.state?.isUpdateCustomQuestion
    ? location.state?.isUpdateCustomQuestion
    : false;
  const isEditable = location.pathname.toLowerCase().includes("edit-question");
  const isCloneMode = location.pathname.toLowerCase().includes("clone-question");
  const questionId = /[^/]*$/.exec(location.pathname)[0];
  const [type, setType] = useState(params.type);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const loader = useSelector((state) => state.Loader.loading);
  const [draft, setDraft] = useState(true);
  const dispatch = useDispatch();
  const [topBarMethods, setTopBarMethods] = useState("");

  const commonFieldsMethods = useForm({
    defaultValues: {
      title: "",
      selectedRole: "MULTIPLE_CHOICE",
      detail: {
        video: "",
        langWiseDetail: [
          { language: "English", detail: "" },
          { language: "Hindi", detail: "" },
          { language: "Bangla", detail: "" },
        ],
      },
      primarySkill: "",
      complexity: "",
      secondarySkills: [],
      time: {
        min: "0",
        sec: "0",
      },
      tags: [],
      videoBlob: null,
      selectedLanguage: "English",
      videoDescription: "",
      isPractice: false,
      comment: "",
    },
    resolver: yupResolver(schema),
  });

  const multipleChoiceMethods = useForm({
    defaultValues: {
      answers: [{ answer: "", isCorrect: false }],
      shuffle: true,
    },
    resolver: yupResolver(schema),
  });

  const codingMethods = useForm({
    defaultValues: {
      functionName: "",
      returnType: "",
      parameters: [],
      testCases: [],
      langWiseCode: [],
    },
    resolver: yupResolver(schema),
  });

  const shortTextMethods = useForm({
    defaultValues: {
      answers: [{ answer: "" }],
    },
    resolver: yupResolver(schema),
  });

  const {
    getValues,
    control,
    handleSubmit,
    setValue: setCommonFieldValue,
  } = commonFieldsMethods;
  const { getValues: multipleChoiceGetValues, setValue: multiChoiceSetValue } =
    multipleChoiceMethods;
  const { getValues: codingValues, setValue: setCodingValues } = codingMethods;
  const { getValues: getShortTextValues, setValue: shortTextSetValue } =
    shortTextMethods;
  const getPayloadFor = (questionType) => {
    if (questionType === "MULTIPLE_CHOICE") {
      const multipleChoiceValues = multipleChoiceGetValues();
      return {
        answers: multipleChoiceValues.answers,
        shuffleAnswers: multipleChoiceValues.shuffle,
      };
    }
    if (questionType === "SHORT_TEXT") {
      const shortTextValues = getShortTextValues();
      return {
        answers: shortTextValues.answers,
      };
    }
    if (questionType === "CODE") {
      const codingValue = codingValues();
      const testCases = codingValue.testCases;
      const specifications = codingValue.langWiseCode.map((language) => {
        const specification = {
          language: language.languageId,
          functionName: codingValue.functionName,
          parameters: codingValue.parameters,
          returnType: codingValue.returnType,
          initialCode: btoa(language.initialCode),
          verificationCode: btoa(language.verificationCode),
        };
        return specification;
      });
      return {
        specifications,
        testCases,
      };
    }
    if (["VIDEO", "DOCUMENT"].includes(questionType)) {
      return {};
    }
    throw new Error("Incorrect question type!");
  };

  const getPayload = async (isDraft) => {
    const commonFieldsValues = getValues();
    const dynamicFieldsValues = getPayloadFor(commonFieldsValues.selectedRole);
    let videoId;
    if (commonFieldsValues.videoBlob) {
      const videoThumbnailBlob = await generateVideoThumbnail(
        commonFieldsValues.videoBlob
      );
      const [x, y] = await Promise.all([
        getUploadUrlAndObjectUrl(commonFieldsValues.videoBlob.type),
        getUploadUrlAndObjectUrl(videoThumbnailBlob.type),
      ]);
      const { objectUrl: videoUrl, key: videoKey, preSignedUrl } = x;

      const { objectUrl: videoThumbnail, preSignedUrl: thumbnailUploadUrl } = y;

      await Promise.all([
        uploadFileToAWS(
          preSignedUrl,
          commonFieldsValues.videoBlob.type,
          commonFieldsValues.videoBlob
        ),
        uploadFileToAWS(
          thumbnailUploadUrl,
          videoThumbnailBlob.type,
          videoThumbnailBlob
        ),
      ]);

      const res = await VideoService.createVideo({
        title: commonFieldsValues.title,
        detail: "dummy detail",
        videoKey,
        videoUrl,
        videoThumbnail,
      });
      videoId = res?.data?.data?._id;
    }
    let payLoad;
    const totalTime =
      Number(commonFieldsValues.time.min) * 60 +
      Number(commonFieldsValues.time.sec);
    if (
      !isDraft &&
      (totalTime < Limits.totalTimeMin || totalTime > Limits.totalTimeMax)
    ) {
      enqueueSnackbar(
        "Minimum time must be 30 seconds and maximum time must be less than 60 minutes",
        {
          variant: "error",
        }
      );
      return;
    }

    payLoad = {
      questionType: commonFieldsValues.selectedRole,
      time: totalTime.toString(),
      title: commonFieldsValues.title,
      complexity: commonFieldsValues.complexity,
      // isPractice: commonFieldsValues.isPractice,
      comment: commonFieldsValues.comment,
      // isDraft: isDraft,
      detail: {},
      ...dynamicFieldsValues,
    };
    if (commonFieldsValues?.detail?.langWiseDetail[0]?.detail) {
      payLoad.detail.langWiseDetail = commonFieldsValues.detail.langWiseDetail;
    }
    if (commonFieldsValues.detail?.video) {
      payLoad.detail.video = commonFieldsValues.detail.video;
    }

    if (videoId) {
      payLoad.detail.video = videoId;
    }
    if (type === "custom") {
      payLoad.tags = commonFieldsValues.tags;
    }
    if (type === "bank") {
      payLoad.primarySkill = commonFieldsValues.primarySkill;
      payLoad.secondarySkills = commonFieldsValues.secondarySkills;
    }
    return payLoad;
  };

  const addCustomQuestionToStore = (question) => {
    dispatch(addCustomQuestionAction(question));
  };

  const removeCustomQuestionFromStore = (id) => {
    dispatch(removeCustomQuestionAction(id));
  };
  const getType = (qRoles) => {
    const _qRoles = {};
    qRoles.forEach((role) => (_qRoles[role] = true));
    if (_qRoles["CUSTOM"]) return "custom";
    else if (_qRoles["BANK"]) return "bank";
  };

  const selectedRole = useWatch({
    control,
    name: "selectedRole",
  });

  useEffect(() => {
    if (selectedRole === "MULTIPLE_CHOICE") {
      setTopBarMethods(multipleChoiceMethods);
    }
    if (selectedRole === "SHORT_TEXT") {
      setTopBarMethods(shortTextMethods);
    }
    if (selectedRole === "CODE") {
      setTopBarMethods(codingMethods);
    }
    // console.log(topBarMethods)
  }, [selectedRole]);
  const SaveQuestion = async (isDraft,isCloneMode) => {
    const values = getValues();
    const multiChoiceValues = multipleChoiceGetValues();
    const errorFields = [];
    if (!values.complexity && !isDraft) {
      errorFields.push("Complexity");
    }
    if (user.role === Roles.contributor && !values.primarySkill && !isDraft) {
      errorFields.push("Primary Skill");
    }
    if (!values.title && !isDraft) {
      errorFields.push("Title");
    }
    if (
      user.role === Roles.contributor &&
      !values.secondarySkills.length &&
      !isDraft
    ) {
      errorFields.push("secondarySkill");
    }

    if (
      selectedRole === "MULTIPLE_CHOICE" &&
      !isDraft &&
      (multiChoiceValues.answers.every(
        ({ isCorrect }) => isCorrect === false
      ) ||
        multiChoiceValues.answers.length < 2)
    ) {
      errorFields.push("Multiple Choice answers");
    }
    if (errorFields.length) {
      enqueueSnackbar(
        `Please fill ${errorFields.map((error) => error).join(", ")} properly`,
        {
          variant: "error",
        }
      );
    } else {
      try {
        const payload = await getPayload(isDraft);
        let response;
        //*new question on save press
        if (!isDraft && !isEditable && !isCloneMode) {
          response = await axiosInstance.post(QuestionSaveURL, payload);
        }
        //*old question on save press to update 
        if (isEditable && !isDraft && !isCloneMode) {
          response = await axiosInstance.put(
            `${QuestionUpdateURL}/${questionId}/save`,
            payload
          );
        }
        //*first time draft
        if (isDraft && !isEditable && !isCloneMode) {
          response = await axiosInstance.post(QuestionSaveAsDraftURL, payload);
        }
        //*second time draft again
        if (isDraft && isEditable && !isCloneMode) {
          response = await axiosInstance.put(
            `${QuestionUpdateURL}/${questionId}/draft`,
            payload
          );
        }
        // //* clone created question
        // if(isCloneMode && !isDraft){
        //   response = await axiosInstance.post(QuestionSaveURL, payload);
        // }
        // //*clone draft question
        // if(isCloneMode && isDraft){
        //   response = await axiosInstance.post(QuestionSaveAsDraftURL, payload);
        // }

        if (response.data.result === 1) {
          enqueueSnackbar(response.msg || "Saved Successfully", {
            variant: "success",
          });
          if (isUpdateCustomQuestion) {
            removeCustomQuestionFromStore(questionId);
            addCustomQuestionToStore(response?.data?.data);
          }
          if (isCustomQuestion) {
            addCustomQuestionToStore(response?.data?.data);
          }

          if (user.role === Roles.contributor || user.role === Roles.admin) {
            navigate(RoutingPaths.QuestionBankList);
          } else {
            if (isCustomQuestion || isUpdateCustomQuestion) {
              navigate(-1);
            } else {
              navigate(RoutingPaths.Questions);
            }
          }
        }
      } catch (error) {
        console.log(error);
        enqueueSnackbar(error.msg || "Something went wrong", {
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    if (isEditable || isCloneMode) {
      const id = /[^/]*$/.exec(location.pathname)[0];
      QuestionListService.getQuestionById(id).then((res) => {
        const result = res?.data?.data;
        setCommonFieldValue("title", result.title);
        setCommonFieldValue("selectedRole", result.questionType);
        setCommonFieldValue("complexity", result.complexity);
        setDraft(result.status);
        // console.log(result.status)
        const detail = {};
        if (result.detail.video) {
          detail.video = result.detail.video._id;
          dispatch(removeAllVideoFromQuestionLibraryAction());
          dispatch(addInVideoQuestionLibraryAction(result.detail.video));
        }
        if (result.detail.langWiseDetail.length) {
          detail.langWiseDetail = result.detail.langWiseDetail;
        } else {
          detail.langWiseDetail = [
            { language: "English", detail: "" },
            { language: "Hindi", detail: "" },
            { language: "Bangla", detail: "" },
          ];
        }
        setCommonFieldValue("isPractice", result.isPractice);
        setCommonFieldValue("detail", detail);
        setCommonFieldValue("time.min", setTimeInMinSec(result.time).min);
        setCommonFieldValue("time.sec", setTimeInMinSec(result.time).sec);

        setCommonFieldValue("tags", result.tags);
        if (getType(result.questionRoles) === "bank") {
          setCommonFieldValue("primarySkill", result.primarySkill?._id);
          setCommonFieldValue(
            "secondarySkills",
            result?.secondarySkills?.map((skill) => skill?._id)
          );
          setQuestionStatus(result.status);
        }
        setType(getType(result.questionRoles));
        if (result.questionType === "MULTIPLE_CHOICE") {
          multiChoiceSetValue("answers", result.answers);
          multiChoiceSetValue("shuffle", result.shuffleAnswers);
        }
        if (result.questionType === "SHORT_TEXT") {
          shortTextSetValue("answers", result.answers);
        }
        if (result.questionType === "CODE") {
          setCodingValues(
            "functionName",
            result?.specifications[0]?.functionName
          );
          setCodingValues("returnType", result?.specifications[0]?.returnType);
          setCodingValues("parameters", result?.specifications[0]?.parameters);
          setCodingValues("testCases", result?.testCases);
          const newLangWiseCodes = result.specifications.map((item) => {
            const obj = {};
            obj.languageName = languageMap[item.language];
            obj.initialCode = atob(item.initialCode);
            obj.verificationCode = atob(item.verificationCode);
            obj.languageId = item.language;
            return obj;
          });

          setCodingValues("langWiseCode", newLangWiseCodes);
        }
      });
    } else {
      dispatch(removeAllVideoFromQuestionLibraryAction());
    }
  }, [params?.id]);

  const handleNext = () => {
    skipIdsRef.current.push(params.id);
    const { primarySkill, secondarySkills, complexity } = getValues();
    QuestionService.getNextQuestion({
      primarySkill, secondarySkills: secondarySkills.toString(), complexity, skip: String(skipIdsRef.current)
    }).
    then(res => {
        const { _id } = res.data.data.question;
        navigate(RoutingPaths.EditQuestion.replace(':id', _id));
    }).catch(err => {
        console.log(err);
        navigate(RoutingPaths.QuestionBankList);
    })
  }

  return (
    <>
      <FormProvider {...commonFieldsMethods}>
        <Header />
        <TopBar
          save={SaveQuestion}
          handleNext={handleNext}
          questionType={questionTypeMap[selectedRole]}
          isDraft={draft}
          otherMethods={topBarMethods}
          isCloneMode={isCloneMode}
        />
        {loader && <LinearProgressLine />}
        <div className={`page-spacing pd-y-20`}>
          <div className={'container-fluid'}>
            <div className="row">
              <div className="col-md-8">
                <Card className="rounded-0 mg-b-30">
                  <CardContent className={`pd-y-30 pd-x-16 pd-x-md-30`}>
                    <NewQuestionTop />
                    {selectedRole === "MULTIPLE_CHOICE" && (
                        <MultipleChoiceQuestion methods={multipleChoiceMethods} />
                    )}
                    {selectedRole === "CODE" && (
                        <CodingQuestion methods={codingMethods} />
                    )}
                    {selectedRole === "SHORT_TEXT" && (
                        <ShortTextQuestion methods={shortTextMethods} />
                    )}
                    <QuestionBottom isEditable={isEditable} type={type} />
                  </CardContent>
                </Card>
                {open && (
                    <ConfirmationDialog
                        open={open}
                        setOpen={setOpen}
                        title={"Are you sure you want to delete this question?"}
                        subtitle={
                          "You can not see more this question later in the library."
                        }
                    />
                )}

                <QuestionFooter />
              </div>
              <div className="col-md-4">
                <Card className="rounded-0 mg-b-30">
                  <CardContent className={`pd-y-0 pd-x-0`}>
                    {[statusMap.CREATED.value, statusMap.APPROVED.value, statusMap.REJECTED.value].includes(questionStatus) && (
                        <>
                          <div className={`pd-y-30 pd-x-16 pd-x-md-30`}>
                            <QuestionReview
                                questionId={questionId}
                                status={questionStatus}
                                setStatus={setQuestionStatus}
                            />
                          </div>
                          <Divider/>
                        </>
                    )}
                    {!(user.role === Roles.user) && (
                        <div className={`pd-y-30 pd-x-16 pd-x-md-30`}>
                          <QuestionComments
                              isEditable={isEditable}
                              questionId={questionId}
                          />
                        </div>
                    )}
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </FormProvider>
    </>
  );
};

export default NewQuestion;
