import * as React from "react";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { Delete } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../../../../../common/ConfirmationDialog/ConfirmationDialog";

export default function AlertDialogSlide(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { actionHandler,isDeleteText, text, type, from, otherMethods, questionType } = props;
  const [open, setOpen] = React.useState(false);
  const commonFieldMethods = useFormContext();
  const [title, setTitle] = useState("");
  const [subtitle, setSubTitle] = useState("");

  const [isFormEditedCommonField, setIsFormEditedCommonField] = useState(false);
  const [isFormEditedOtherMethods, setIsFormEditedOtherMethods] =
    useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (type === "cancel") {
      setTitle("Are you sure want to go back");
      setSubTitle("You will loose all the current data");
    }
    if (type === "delete") {
      setTitle("Are you sure you want to delete?");
      setSubTitle(`You won't get it back`);
    }
  }, []);

  useEffect(() => {
    if (from === "topBar") {
      setIsFormEditedCommonField(commonFieldMethods?.formState?.isDirty);
      setIsFormEditedOtherMethods(otherMethods?.formState?.isDirty);
    }
  }, [
    commonFieldMethods?.formState?.isDirty,
    otherMethods?.formState?.isDirty,
  ]);

  const handleClickCancel = () => {
    if (!isFormEditedCommonField && !isFormEditedOtherMethods) {
      navigate(-1);
    } else {
      setOpen(true);
    }
  };

  const handleDeleteOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    if (type === "cancel") {
      navigate(-1);
    } else {
      setOpen(false);
      actionHandler();
      // enqueueSnackbar("Your question has been successfully deleted!", {
      //   variant: "success",
      // });
    }
  };

  return (
    <>
      {type === "cancel" ? (
        <Button
          variant={"outlined"}
          color={"primary"}
          className={`font-size-xs`}
          onClick={handleClickCancel}
          size={"small"}
        >
          Cancel
        </Button>
      ) : (
        <span onClick={handleDeleteOpen}>
          <Delete
              style={{ fontSize: 24, color: "#3699FF", cursor: "pointer" }}
          />
          {
            isDeleteText && <span className={"text-decoration-none text-dark mg-l-10"}>Delete</span>
          }
        </span>
      )}

      <ConfirmationDialog
        open={open}
        setOpen={handleClose}
        title={title}
        subtitle={subtitle}
        cancelButtonText="No"
        okButtonText="Yes"
        confirmAction={handleDelete}
      />
    </>
  );
}
