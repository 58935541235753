import styles from "./Statusbar.module.scss";
import { Button, Typography } from "@mui/material";
import IMAGES from "../../../assets/images/images";
import { useNavigate } from "react-router-dom";

const Statusbar = ({
  activeStep,
  save,
  title,
  stepsArray,
  increaseActiveStep,
  decreaseActiveStep,
  lastStep,
}) => {
  const navigate = useNavigate();

  const getProgressStepStyle = (step) => {
    let style = "";
    if (activeStep === step) {
      style = styles.active;
    } else if (activeStep > step) {
      style = styles.done;
    } else if (activeStep < step) {
    }
    return style;
  };

  return (
    <nav className={styles.statusbar}>
      <div className="container-fluid">
        <div className={`page-spacing`}>
          <div
            className={`d-flex justify-content-between align-items-center flex-wrap`}
          >
            <Typography
              component={"h4"}
              variant={"h4"}
              className={`mb-3 mb-lg-0`}
            >
              {title}
            </Typography>
            <div
              className={`${styles.progressBar} d-flex align-items-center mb-3 mb-lg-0`}
            >
              {stepsArray?.map(({ step, label }) => (
                <div
                  key={step}
                  className={`${styles.progressStep} ${getProgressStepStyle(
                    step
                  )} text-center`}
                >
                  <div className={`${styles.progressIcon}`}>
                    {activeStep > step && (
                      <img
                        className={`img-fluid`}
                        src={IMAGES.CheckCircle}
                        alt="check-circle"
                      />
                    )}
                    {activeStep === step && (
                      <i className={`icon-ellipse-check`}></i>
                    )}
                    {activeStep < step && (
                      <i className={`icon-ellipse-check`}></i>
                    )}
                  </div>

                  <Typography
                    variant={"h4"}
                    component={"p"}
                    className={styles.stepInfo}
                  >
                    {label}
                  </Typography>
                </div>
              ))}
            </div>

            <div>
              {/* <AlertDialogSlide
                del={() => navigate(-1)}
                text={
                  "You will loose all the current data, are you sure want to go back ?"
                }
                type="cancel"
              /> */}
              <Button
                variant={"outlined"}
                color={"primary"}
                className={`mg-l-10`}
                onClick={decreaseActiveStep}
                size={"small"}
              >
                Back
              </Button>

              {activeStep === lastStep ? (
                <Button
                  variant={"contained"}
                  color={"primary"}
                  className={`mg-l-10`}
                  onClick={save}
                  size={"small"}
                >
                  Save
                </Button>
              ) : (
                <Button
                  variant={"contained"}
                  color={"primary"}
                  className={`mg-l-10`}
                  onClick={increaseActiveStep}
                  size={"small"}
                >
                  Next
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Statusbar;
