import React from "react";
import GoogleSignIn from "./GoogleSignIn";
import Loader from "../../Components/Loader/Loader"

const LoginLoader = () => {
  const search = window.location.search; // could be '?code=r556ygy'
  const params = new URLSearchParams(search);
  const authCode = params.get("code");
  return (
    <div>
      {/* <h1>Loading...</h1> */}
      <Loader />
      <GoogleSignIn authCode={authCode} />
    </div>
  );
};

export default LoginLoader;
