import React from "react";
import styles from "./Topbar.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import AlertDialogSlide from "../Assesment/MyAssesment/CandidateList/Slider/PopUp/AlertDialogSlide";
import { useAuth } from "../../hooks/useAuth";
import { Roles } from "../../utils/constants";
import RoutingPaths from "../../utils/RoutingPaths";
import { Button } from "@mui/material";
const Topbar = ({
  save,
  handleNext,
  questionType,
  update,
  loading,
  isDraft,
  otherMethods,
  isCloneMode,
}) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  function navigateBack() {
    navigate(-1);
  }
  const handleNavigate = () => {
    if (user.role === Roles.user) {
      navigate(RoutingPaths.Questions);
    } else {
      navigate(RoutingPaths.QuestionBankList);
    }
  };
  return (
    <nav className={styles.header}>
      <div className={styles.container}>
        <div className={styles.menu}>
          <span style={{ cursor: "pointer" }} onClick={handleNavigate}>
            {user.role === Roles.user ? "My Question" : "Question Bank"}
          </span>
          <small>/</small>
          <span>{questionType}</span>
        </div>
        <div className={styles.buttonsWrap}>
          <AlertDialogSlide
            del={navigateBack}
            text="You will loose all the current data, are you sure want to go back ?"
            type="cancel"
            from="topBar"
            otherMethods={otherMethods}
            questionType={questionType}
          />
          {!update && (
            <>
              <Button
                variant={"outlined"}
                className={`font-size-xs mg-l-10`}
                onClick={() => save(false)}
                disabled={loading}
                size={"small"}
                color={"primary"}
              >
                {loading && (
                  <CircularProgress size="1rem" style={{ color: "white" }} />
                )}
                Save
              </Button>

              {((!["CREATED", "APPROVED", "REJECTED"].includes(isDraft)) || isCloneMode) && (
                <Button
                  variant={"contained"}
                  className={`font-size-xs mg-l-10`}
                  onClick={() => save(true)}
                  style={{ backgroundColor: "#8950FC" }}
                  disabled={loading}
                  size={"small"}
                >
                  {loading && (
                    <CircularProgress size="1rem" style={{ color: "white" }} />
                  )}
                  Save as draft
                </Button>
              )}
              {/* {isCloneMode && (
                <Button
                  variant={"contained"}
                  className={`font-size-xs mg-l-10`}
                  onClick={() => save(isDraft==='DRAFTED'?true:false,isCloneMode)}
                  style={{ backgroundColor: "#8950FC" }}
                  disabled={loading}
                >
                  {loading && (
                    <CircularProgress size="1rem" style={{ color: "white" }} />
                  )}
                  Clone
                </Button>
              )} */}
            </>
          )}
          {["CREATED", "APPROVED", "REJECTED"].includes(isDraft) && (
            <Button
              variant={"contained"}
              className={`font-size-xs mg-l-10`}
              onClick={handleNext}
              disabled={loading}
              size={"small"}
            >
              {loading && (
                <CircularProgress size="1rem" style={{ color: "white" }} />
              )}
              Next
            </Button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Topbar;
