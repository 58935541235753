import axiosInstance from "./client";
const getAllData = (page, PER_PAGE, title, questionType) => {
  return axiosInstance.get(
    `/api/question?questionRoles=CUSTOM&page=${page}&perPage=${PER_PAGE}&questionTypes=${questionType}&title=${title}`
  );
};
const getQualifyingQuestions = () => {
  return axiosInstance.get(
    `/api/question?questionRoles=CUSTOM&questionTypes=MULTIPLE_CHOICE&page=1&perPage=0&isQualifying=true`
  );
};

const getCustomQuestions = () => {
  return axiosInstance.get(`/api/question?questionRoles=CUSTOM&page=1&perPage=0`);
};

const getQuestionById = (id) => {
  return axiosInstance.get(`/api/question/${id}`, {});
};
const getVideoQuestions = () => {
  return axiosInstance.get(
    `/api/question?questionRoles=CUSTOM&page=1&perPage=0&questionTypes=VIDEO`
  );
};

const postComment = (id, comment) => {
  return axiosInstance.post(`/api/question/${id}/comments`, {
    comment: comment,
  });
};
const getComments = (id) => {
  return axiosInstance.get(`/api/question/${id}/comments?perPage=100`);
};

const updateComment = (questionId, commentId, comment) => {
  return axiosInstance.put(`/api/question/${questionId}/comments/${commentId}`, { comment });
};

const deleteComment = (questionId, commentId) => {
  return axiosInstance.delete(`/api/question/${questionId}/comments/${commentId}`);
};

const QuestionListService = {
  getAllData,
  getQuestionById,
  getQualifyingQuestions,
  getCustomQuestions,
  getVideoQuestions,
  postComment,
  getComments,
  updateComment,
  deleteComment
};

export default QuestionListService;
