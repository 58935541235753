import React from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

const RHFRadioGroupController = ({
  label1,
  label2,
  handleRadioIntroOutro,
  index,
}) => {
  const videoQuestionData = useSelector(
    (state) => state.videoQuestionSlice.videoQuestionsAssessment
  );

  const getRadioValue = () => {
    const radioValue = videoQuestionData[index].videoType;
    return radioValue;
  };
  return (
    <div>
      <div className="custom-radio mg-b-25">
        <input
          className="custom-control-input"
          type="radio"
          id={`introVideo${index}`}
          name={`introOutro${index}`}
          value="INTRO"
          checked={getRadioValue() === "INTRO"}
          onChange={(e) => handleRadioIntroOutro(e, index)}
        />
        <label
          className="custom-control-label text-black-11 text-nowrap"
          htmlFor={`introVideo${index}`}
        >
          {label1}
        </label>
      </div>
      <div className="custom-radio">
        <input
          className="custom-control-input"
          type="radio"
          id={`outroVideo${index}`}
          value="OUTRO"
          // onChange={(e) => handleRadioIntroOutro(e, index)}
          name={`introOutro${index}`}
          checked={getRadioValue() === "OUTRO"}
          onChange={(e) => handleRadioIntroOutro(e, index)}
        />
        <label
          className="custom-control-label text-black-11 text-nowrap"
          htmlFor={`outroVideo${index}`}
        >
          {label2}
        </label>
      </div>
    </div>
  );
};

export default RHFRadioGroupController;
