import { combineReducers } from "@reduxjs/toolkit";
import QuestionReducer from "./slices/QuestionSlice";
import Loader from "./slices/loaderSlice";
import SkillsSlice from "./slices/skillsSlice";
import CustomQuestionSlice from "./slices/customQuestionSlice";
import AssessmentSlice from './slices/assessmentSlice'
import videoQuestionSlice from "./slices/videoQuestionSlice";
import FilterSlice from "./slices/filterSlice";
import CreateTestSlice from "./slices/createTestSlice";
import authors from "./slices/authorSlice";
import skills from "./slices/skillSlice";
export const rootReducer = combineReducers({
  QuestionReducer,
  Loader,
  SkillsSlice,
  CustomQuestionSlice,
  AssessmentSlice,
  videoQuestionSlice,
  FilterSlice,
  CreateTestSlice,
  authors,
  skills
});
