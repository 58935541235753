import {Box, Button, Menu, MenuItem, Modal, Typography} from "@mui/material";
import {Close, KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import {useState} from "react";

const stage = [
    'Stage1', 'Stage2'
]

const DeleteHireStageModal = (props) => {
    const {open, setOpen} = props;
    const [stageAnchorEl, setStageAnchorEl] = useState(null);
    const openStage = Boolean(stageAnchorEl);
    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleStageOpen = (event) => {
        setStageAnchorEl(event.currentTarget);
    };
    const languageSelection = () => {
        stageClose();
    };
    const stageClose = () => {
        setStageAnchorEl(null);
    };
    return(
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modal-box">
                <Box className="modal-content tabs-wrap question-tabs">
                    <Box className={`pd-x-30`}>
                        <Box className={"pd-t-30 d-flex justify-content-end"}>
                            <Button variant={"text"} color={"inherit"} onClick={handleCloseModal}>
                                <Close/>
                            </Button>
                        </Box>
                        <Box>
                            <Typography variant="h2" component="h2" className={`mg-b-24`}>
                                You are deleting hiring stage <Typography component={"span"} color={"primary"}>(Background Check)</Typography>
                            </Typography>
                            <label className={"form-label"}>Choose Hiring Stage to be assigned instead of it</label>
                            <Button onClick={handleStageOpen}
                                    className={`btn-toggle w-100`}
                                    variant={"outlined"} color={"secondary"}>
                                <Typography noWrap
                                            className="mg-r-4 pd-y-0 font-weight-medium font-size-sm">{false ? "" : "Select"}</Typography>
                                {
                                    openStage ? <KeyboardArrowUp className="toggle-icon" /> : <KeyboardArrowDown className="toggle-icon" />
                                }
                            </Button>
                            <Menu
                                id="role-menu"
                                anchorEl={stageAnchorEl}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={openStage}
                                onClose={stageClose}
                            >
                                {
                                    stage.map((stage, index)=>{
                                        return(
                                            <MenuItem key={index} onClick={languageSelection}>
                                                <Typography textAlign="center">{stage}</Typography>
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Menu>
                        </Box>
                        <div className={'pd-y-30'}>
                            <button type={"button"} className={`btn btn-snow btn-lg mg-r-10`} onClick={handleCloseModal}>
                                Cancel
                            </button>
                            <button onClick={handleCloseModal} type={"button"} className={`btn btn-primary btn-lg text-white`}>Save</button>
                        </div>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default DeleteHireStageModal;