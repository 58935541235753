import { createSlice } from "@reduxjs/toolkit";

export const createTestSlice = createSlice({
  name: "createTestSlice",
  initialState: {
    activeStep: 0,
    createTestData: {},
  },
  reducers: {
    createTestData: (state, action) => {
      state.createTestData = action.payload;
    },

    increaseActiveStep: (state) => {
      state.activeStep = state.activeStep + 1;
    },
    decreaseActiveStep: (state) => {
      if (state.activeStep > 0) {
        state.activeStep = state.activeStep - 1;
      }
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
  },
});

export const createTestDataAction = (value) => async (dispatch) => {
  try {
    dispatch(createTestData(value));
  } catch (err) {
    throw new Error(err);
  }
};
export const increaseActiveStepAction = () => async (dispatch) => {
  try {
    dispatch(increaseActiveStep());
  } catch (err) {
    throw new Error(err);
  }
};
export const decreaseActiveStepAction = () => async (dispatch) => {
  try {
    dispatch(decreaseActiveStep());
  } catch (err) {
    throw new Error(err);
  }
};
export const setActiveStepAction = (value) => async (dispatch) => {
  try {
    dispatch(setActiveStep(value));
  } catch (err) {
    throw new Error(err);
  }
};

export const {
  createTestData,
  increaseActiveStep,
  decreaseActiveStep,
  setActiveStep,
} = createTestSlice.actions;
export default createTestSlice.reducer;
