import React from "react";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { userService } from "../../../services";
import { useSnackbar } from "notistack";
import { CircularProgress } from '@mui/material';

const UserRoleMenu = ({ userId, role }) => {
  const [selectedRole, setSelectedRole] = React.useState(role);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [categoryAnchorEl, setCategoryAnchorEl] = React.useState(null);
  const openCategory = Boolean(categoryAnchorEl);

  const handleCategoryOpen = (event) => {
    if(isUpdating) return;
    setCategoryAnchorEl(event.currentTarget);
  };

  const userRoleMap = {
    USER: 'User',
    CONTRIBUTOR: 'Contributor',
    ADMIN: 'Admin',
  };
  const categoryClose = () => {
    setCategoryAnchorEl(null);
  };

  const handleMenuItemClick = (role) => {
    setSelectedRole(role);
    setIsUpdating(true);
    userService.updateUser({ role }, userId)
    .then(res => {
        const { role } = res?.data.data;
        if(selectedRole !== role){
            setSelectedRole(role);
            return;
        }
        enqueueSnackbar("Role is Updated", { variant: "success" });
    })
    .catch(() => enqueueSnackbar("Failed to Upadate User Role", { variant: "error" }))
    .finally(() => setIsUpdating(false))
    categoryClose();
  };

  return (
    <>
      <Button
        onClick={handleCategoryOpen}
        className={`btn-toggle btn-sm w-100`}
        variant={"outlined"}
        color={"secondary"}
        size="small"
      >
        <Typography
          noWrap
          className="mg-r-4 pd-y-0 font-weight-medium font-size-sm font-family-roboto"
        >
          {userRoleMap[selectedRole]}
          {isUpdating && <CircularProgress />}
        </Typography>
        {openCategory ? (
          <KeyboardArrowUp className="toggle-icon" />
        ) : (
          <KeyboardArrowDown className="toggle-icon" />
        )}
      </Button>
      <Menu
        id="role-menu"
        anchorEl={categoryAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openCategory}
        onClose={categoryClose}
      >
        {Object.entries(userRoleMap).map(([value, label], index) => {
          return (
            <MenuItem
              key={index}
              selected={value === selectedRole}
              onClick={() => handleMenuItemClick(value)}
            >
              <Typography textAlign="center">
                {/* <i className={`${iconName} mg-r-10`}></i> */}
                {label}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default UserRoleMenu;
