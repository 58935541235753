export const toHour = (seconds) => {
    var date = new Date(null);
    date.setSeconds(seconds);
    return +date.toISOString().substr(11, 8).split(":")[0];
};

export const toMinutes = (seconds) => {
    var date = new Date(null);
    date.setSeconds(seconds);
    return +date.toISOString().substr(11, 8).split(":")[1];
};