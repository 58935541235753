import { Box, Typography, Modal, Button } from "@mui/material";
import { useContext } from "react";
import { useState } from "react";
import IMAGES from "../../../assets/images/images";
import { AccountContext } from "../../Auth/Account";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Logout } from "@mui/icons-material";

const ChangePassword = (props) => {
  const { open, setOpen } = props;
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { getSession, authenticate, logout } = useContext(AccountContext);

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleSave = (event) => {
    event.preventDefault();
    // const user = Pool.getCurrentUser();
    //   user.changePassword(password, newPassword, (err, result) => {
    //     if (err) console.error(err);
    //     console.log(result);
    //   });
    // }
    console.log({ newPassword, confirmPassword });
    if(!newPassword.length){
      alert("New password can not be empty")
      return;
    }
    if (newPassword !== confirmPassword) {
      alert("Passwords are not matching");
      return;
    }
    getSession()
      .then(({ user, email }) => {
        console.log("inside session");
        authenticate(email, password)
          .then(() => {
            user.changePassword(password, newPassword, (err, result) => {
              console.log(err);
              if (err) {
                alert(err.message || JSON.stringify(err));
                return;
              }
              console.log("changed");
              alert("Password Changed");
              logout();
              console.log(result);
            });
          })
          .catch((error) => {
            alert(`${error.message || "Something went wrong"}`);
          });
      })
      .catch((error) => {
        console.log(error);
        alert(`${error || "Please log in using Email & Password"}`);
      });
  };
  const [pwd, setPwd] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal-box">
        <Box className="modal-content tabs-wrap question-tabs">
          <Box
            className={`d-flex align-items-center justify-content-between pd-x-30 pd-t-10 border-bottom`}
          >
            <div className={`d-flex align-items-center`}>
              <Typography
                id="modal-modal-title"
                variant="h2"
                component="h2"
                className={`mg-r-20`}
              >
                Change your password
              </Typography>
            </div>
            <button
              type={`button`}
              className={`btn btn-link text-decoration-none`}
              onClick={handleCloseModal}
            >
              <img src={IMAGES.XIcon} alt="close" />
            </button>
          </Box>
          <Box className={`pd-x-30 pd-y-30`}>
            <div className={`mg-b-10`}>
              <label htmlFor="" className={`form-label font-weight-medium`}>
                Old password *
              </label>

              <input
                type={isRevealPwd ? "text" : "password"}
                name=""
                id=""
                className={`form-control form-control-lg`}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              {/* <Button
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
                onMouseDown={handleMouseDownPassword}
              >
                {isRevealPwd ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </Button> */}
              {/* <img
                title={isRevealPwd ? "Hide password" : "Show password"}
                src={isRevealPwd ? <VisibilityIcon /> : <VisibilityOffIcon />}
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              /> */}
            </div>
            <div className={`mg-b-10`}>
              <label htmlFor="" className={`form-label font-weight-medium`}>
                New password *
              </label>
              <input
                type="password"
                name=""
                id=""
                className={`form-control form-control-lg`}
                value={newPassword}
                onChange={(event) => setNewPassword(event.target.value)}
              />
            </div>
            <div className={`mg-b-10`}>
              <label htmlFor="" className={`form-label font-weight-medium`}>
                Confirm password *
              </label>
              <input
                type="password"
                name=""
                id=""
                className={`form-control form-control-lg`}
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
              />
            </div>
            <div className={"d-flex mg-t-30"}>
              <button
                onClick={handleCloseModal}
                type={"button"}
                className={`btn btn-snow btn-lg mg-r-10`}
              >
                Cancel
              </button>
              <Button
                onClick={handleSave}
                variant={"contained"}
                color={"primary"}
              >
                Save
              </Button>
            </div>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ChangePassword;
