import { Search } from "@mui/icons-material";
import { Checkbox, IconButton, InputAdornment, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@mui/material';
import {makeStyles} from "@mui/styles"
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { getQuestions } from './actions';
// import SearchBar from "material-ui-search-bar";

const useStyles = makeStyles({
    table: {
        width: "100%"
    },
    pagination: {
        "& p": {
            marginBottom: "0"
        }
    }
});



export default function AddQuestion({ open: questionTypeName, setOpen }) {
    // const [open, setOpen] = useState(false);
    const classes = useStyles();

    const { values, setFieldValue } = useFormikContext();
    const [qualifingQuestionData, setQualifingQuestionData] = useState([]);
    const [totalQuestions, setTotalQuestions] = useState(0);

    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPageNo(newPage);
        // getQuestions(newPage, pageSize, questionTypeName, values, setQualifingQuestionData, (res) => {
        //     setTotalQuestions(res?.result);
        // });
        makeSearch(newPage, pageSize, questionTypeName)
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value));
        setPageNo(1);
        // getQuestions(1, event?.target?.value, questionTypeName, values, setQualifingQuestionData, (res) => {
        //     setTotalQuestions(res?.result);
        // });
        makeSearch(1, event?.target?.value)
    };

    const makeSearch = (pageNm = pageNo, pageSz = pageSize) => {
        getQuestions(+pageNm + 1, pageSz, questionTypeName, values, setQualifingQuestionData, (res) => {
            setTotalQuestions(res?.result);
        });
    }

    // const handleClickOpen = (scrollType) => () => {
    //     setOpen(true);
    // };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCheck = (item, index) => e => {
        setQualifingQuestionData(prev => {
            const data = [...prev];
            data[index].isChecked = e?.target?.checked ? true : false;
            return data;
        });

        if (e.target.checked) {
            
            setFieldValue(questionTypeName, [...values?.[questionTypeName], item?._id]);
            // setFieldValue("totalTime", values?.totalTime + item?.time);
        } else {
            setFieldValue(questionTypeName, values?.[questionTypeName]?.filter(id => id !== item?._id));
            // setFieldValue("totalTime", values?.totalTime - item?.time);
        }
    }
    // const handleSave = () => {
    //     setOpen(false);
    // }

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (questionTypeName) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [questionTypeName]);

    useEffect(() => {
        if (questionTypeName) {
            // getQuestions(pageNo, pageSize, questionTypeName, values, setQualifingQuestionData, (res) => {
            //     setTotalQuestions(res?.result);
            // });
            makeSearch(pageNo, pageSize)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionTypeName])

    return (
        <div>
            <Dialog
                open={questionTypeName}
                onClose={handleClose}
                scroll={'body'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title" sx={{ display: 'flex', gap: 2, justifyContent: "space-between", alignItems: "center" }}>Add Questions <TextField
                    id="search-question"
                    label="Search"
                    value={values?.questionSearch}
                    onChange={(e) => {
                        setFieldValue('questionSearch', e.target.value);
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                        makeSearch(pageNo, pageSize)
                                    }}
                                // onMouseDown={handleMouseDownPassword}
                                >
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                /></DialogTitle>
                <Paper
                    style={{ padding: '20px' }}
                >


                    <TextField
                        variant="outlined"
                        label="Question Type Filter"
                        InputProps={{
                            disableUnderline: true,
                            placeholder: "questionType",
                        }}
                        name={"questionType"}
                        sx={{ width: "100%" }}
                        value={values?.questionType}
                        onChange={(e) => {
                            setFieldValue('questionType', e.target.value);
                        }}
                        select
                        disabled
                    // className={styles.inputBox}
                    >
                        <MenuItem value='CODE'>Code</MenuItem>
                        <MenuItem value='MULTIPLE_CHOICE'>Multiple Choise</MenuItem>
                    </TextField>
                    <TableContainer sx={{ padding: '20px' }}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Select</TableCell>
                                    <TableCell>Question</TableCell>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Skill Level</TableCell>
                                    <TableCell>Question Type</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {qualifingQuestionData.map((row, index) => (
                                    <TableRow key={row.name}>
                                        <TableCell><Checkbox checked={row?.isChecked} onClick={handleCheck(row, index)} /> </TableCell>
                                        <TableCell><div className="d-inline-block" dangerouslySetInnerHTML={{ __html: row?.detail }} /></TableCell>
                                        <TableCell>{row?.category}</TableCell>
                                        <TableCell>{row?.level}</TableCell>
                                        <TableCell>{row?.questionType}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            className={classes.pagination}
                            count={totalQuestions}
                            page={pageNo}
                            onPageChange={handleChangePage}
                            rowsPerPage={pageSize}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Paper>
            </Dialog>
        </div>
    );
}
