import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { ListItemIcon, ListItemText } from "@mui/material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SchoolIcon from '@mui/icons-material/School';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PeopleIcon from '@mui/icons-material/People';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LogoutIcon from "@mui/icons-material/Logout";
import RoutingPaths from "../../../utils/RoutingPaths";
import styles from '../Headers.module.scss';
import { useAuth } from "../../../hooks/useAuth";

export default function BasicMenu( props) {
  const { user } = useAuth();
  const {logout,firstLetter} = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigateToSkillsPage = () => {
    navigate(RoutingPaths.skills);
  };
  const navigateToAuthorsPage = () => {
    navigate(RoutingPaths.authors);
  };
  const navigateToUsersPage = () => {
    navigate(RoutingPaths.users);
  };
  const navigateToQuestionReportPage = () => {
    navigate(RoutingPaths.questionReports)
  }
  const handleLogoOut = () => {
    setAnchorEl(null);
    logout();
  };
  const handleProfile = () => {
    navigate(RoutingPaths.Profile);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant={"text"}
        className={`${styles.btnProfile}`}
      >
          {firstLetter}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Profile</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          {" "}
          <ListItemIcon>
            <ManageAccountsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>My account</ListItemText>
        </MenuItem> */}
        {/* <Link to={`/managehiringstages`}>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <ManageHistoryIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Manage Hiring Stages</ListItemText>
          </MenuItem>
        </Link> */}
        <MenuItem onClick={handleProfile}>
          <ListItemIcon>
            <ManageAccountsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Profile</ListItemText>
        </MenuItem>
        {user.role == "ADMIN" && (
          <>
            <MenuItem onClick={navigateToSkillsPage}>
              <ListItemIcon>
                <SchoolIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Manage Skills</ListItemText>
            </MenuItem>
            
            <MenuItem onClick={navigateToAuthorsPage}>
              <ListItemIcon>
                <AccountBoxIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Manage Authors</ListItemText>
            </MenuItem>

            <MenuItem onClick={navigateToUsersPage}>
              <ListItemIcon>
                <PeopleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Manage Users</ListItemText>
            </MenuItem>

            <MenuItem onClick={navigateToQuestionReportPage}>
              <ListItemIcon>
                <AssessmentIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Question Reports</ListItemText>
            </MenuItem>            
          </>
        )}
        <MenuItem onClick={handleLogoOut}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
