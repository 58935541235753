import Header from "../../Header/Header";
import React, { useState } from "react";
import Breadcrumb from "../../common/breadcrumb/Breadcrumb";
import IMAGES from "../../../assets/images/images";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider, Grid,
  Menu,
  MenuItem,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  ArrowDropDown,
  Close,
  ConnectingAirportsOutlined, FilterAlt, FilterOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import Loader from "../../Loader/Loader";
import {
  apiStatusMap,
  complexityOptions,
  complexityTypeMap,
  getDate,
  getTimeInMinSec,
  isEmptyObject,
  questionCategory,
  questionStatusFilterOptions,
  questionTypeMap,
  Roles,
  statusMap,
  statusOfQuestion,
  truncateString,
} from "../../../utils/constants";
import useDebounce from "../../../hooks/useDebounce";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import RoutingPaths from "../../../utils/RoutingPaths";
import QuestionListService from "../../../services/api/QuestionListService";
import MuiMenu from "../../common/MuiMenu/MuiMenu";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import QuestionBankListService from "../../../services/api/QuestionBankListService";
import QuestionStatusMenu from "../../QuestionStatusMenu/QuestionStatusMenu";
import { useAuth } from "../../../hooks/useAuth";
import MuiFilterMenu from "../../common/MuiFilterMenu/MuiFilterMenu";
import SkillsService from "../../../services/api/SkillsService";
import AlertDialogSlide from "../../Assesment/MyAssesment/CandidateList/Slider/PopUp/AlertDialogSlide";
import {
  resetAllFiltersAction,
  setAllFiltersAction,
  setComplexityFilter,
  setCreatedByFilterAction,
  setPrimarySkillFilterAction,
  setQuestionTypeFilterAction,
  setSecondarySkillFilterAction,
  setStatusFilterAction,
} from "../../../utils/redux/slices/filterSlice";
import QBMoreMenu from "./QBMoreMenu/QBMoreMenu";
import { useSnackbar } from "notistack";
import CustomDatePicker from "../../common/DatePicker/DatePicker";
import ReactDatePicker from "react-datepicker";
import { userService } from "../../../services";
import styles from './QuestionBankList.module.scss';
import Grid2 from "@mui/material/Unstable_Grid2";

const createdByNames = ["Sunil", "Tanjil", "Pankaj", "Arun"];

const QuestionBankList = () => {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const userRole = user.role;
  const filterMethods = useForm({
    defaultValues: {
      questionStatusFilter: {},
      questionTypeFilter: {},
      complexityFilter: {},
      primarySkillFilter: {},
      secondarySkillsFilter: {},
      createdBy: {},
      startDate: "",
      endDate: "",
    },
  });
  const { reset, getValues, control, watch, setValue } = filterMethods;
  const loader = useSelector((state) => state.Loader.loading);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const openFilter = Boolean(filterAnchorEl);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const debouncedSearch = useDebounce(searchTerm, 500, setPage);
  const PER_PAGE = 10;
  const [data, setData] = useState([]);
  const [secondarySkills, setSecondarySkills] = useState([]);
  const [primarySkillsOptions, setPrimarySkillsOptions] = useState([]);
  const [filterChips, setFilterChips] = useState([]);
  const filterValues = useSelector((state) => state.FilterSlice);
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const [sorting,setSorting]=useState(false);
  const [apiStatus, setApiStatus] = useState(apiStatusMap.idle);
  useEffect(() => {
    getAllQuestions();
  }, [page, debouncedSearch,sorting]);

  useEffect(() => {
    SkillsService.getPrimarySkills().then((response) => {
      setPrimarySkillsOptions(response.data.data);
    });
    const query = { roles: `${Roles.admin},${Roles.contributor}`}
    userService.getUsers(query)
    .then((res) => {
      const result = res?.data?.data.map((list) => {
        let obj = {};
        obj.label = list.firstName + " " + list.lastName;
        obj._id = list._id;
        return obj;
      });
      setUsers(result);
    });
    setValue("questionStatusFilter", filterValues.filters.status);
    setValue("questionTypeFilter", filterValues.filters.questionType);
    setValue("complexityFilter", filterValues.filters.complexity);
    setValue("primarySkillFilter", filterValues.filters.primarySkill);
    setValue("secondarySkillsFilter", filterValues.filters.secondarySkill);
  }, []);
  useEffect(() => {
    getAllQuestions();
    setPage(1);
  }, [filterValues.filters]);

  const onFilterClick = () => {
    const values = getValues();
    const tempObj = {
      status: values.questionStatusFilter,
      questionType: values.questionTypeFilter,
      complexity: values.complexityFilter,
      primarySkill: values.primarySkillFilter,
      secondarySkill: values.secondarySkillsFilter,
      createdBy: values.createdBy,
    };

    dispatch(setAllFiltersAction(tempObj));
    setPage(1);
    filterClose();
  };
  const onReset = () => {
    dispatch(resetAllFiltersAction());
    reset();
    setSecondarySkills([]);
    setPage(1);
    filterClose();
  };

  const handleStatusChange = (status, questionId) => {
    QuestionBankListService.QuestionStatusChange({ questionId, status }).then(
      () => {
        getAllQuestionAxios();
      }
    );
  };

  const handleDelete = (questionId) => {
    try {
      QuestionBankListService.deleteQuestionById(questionId).then((res) => {
        if (res?.data?.result === 1) {
          enqueueSnackbar("Question successfully deleted!", {
            variant: "success",
          });
          getAllQuestions();
        }
      });
    } catch (e) {
      enqueueSnackbar("Something went wrong!", {
        variant: "error",
      });
    }
  };

  // const sortedByDate = () => {
  //   const sortedData = data?.questions
  //     ?.slice()
  //     .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
  //     .reverse();
  //   setData(sortedData);
  // };

  const sortingHandler=()=>{
    setSorting(!sorting)
  }

  const getAllQuestionAxios = async () => {
    const values = getValues();
    const data = await QuestionBankListService.getAllQuestionAxios(
      page,
      PER_PAGE,
      debouncedSearch,

      filterValues.filters.status._id ?
      filterValues.filters.status._id : "",

      filterValues.filters.primarySkill._id ?
      filterValues.filters.primarySkill._id : "",

      filterValues.filters.secondarySkill._id ?
      filterValues.filters.secondarySkill._id : "",

      filterValues.filters.questionType._id ?
      filterValues.filters.questionType._id : "",

      filterValues.filters.complexity._id ?
      filterValues.filters.complexity._id : "",

      filterValues.filters.createdBy._id ?
      filterValues.filters.createdBy._id : "",

      values.startDate,
      getEndOfDay(values.endDate),
      sorting ? 'asc' : 'desc'
    );
    setData(data?.data?.data);
  };

  const getAllQuestions = async () => {
    const values = getValues();
    try{
      setApiStatus(apiStatusMap.pending);
      const data = await QuestionBankListService.questionBankGetAll(
        page,
        PER_PAGE,
        debouncedSearch,
        filterValues.filters.status._id ? filterValues.filters.status._id : "",
        filterValues.filters.primarySkill._id
          ? filterValues.filters.primarySkill._id
          : "",
        filterValues.filters.secondarySkill._id
          ? filterValues.filters.secondarySkill._id
          : "",
        filterValues.filters.questionType._id
          ? filterValues.filters.questionType._id
          : "",
        filterValues.filters.complexity._id
          ? filterValues.filters.complexity._id
          : "",
        filterValues.filters.createdBy._id
          ? filterValues.filters.createdBy._id
          : "",
        values.startDate,
        getEndOfDay(values.endDate),
        sorting ? 'asc' : 'desc'
      );
      setData(data?.data?.data);
      setApiStatus(apiStatusMap.resolved);
    }catch(err){
      setApiStatus(apiStatusMap.rejected);
    }
  };
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "primarySkillFilter") {
        setSecondarySkills([]);
        setValue("secondarySkillsFilter", {});
        if (!isEmptyObject(value?.primarySkillFilter)) {
          SkillsService.getSecondarySkills(value?.primarySkillFilter?._id).then(
            (response) => {
              setSecondarySkills(response.data.data);
            }
          );
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const getEndOfDay=(date)=>{
    if(!date){
      return ""
    }
    const _date=new Date(date)
    _date.setHours(23,59,59)
    return _date.toISOString()
  }

  const filterClose = () => {
    setFilterAnchorEl(null);
  };
  const handleFilterOpen = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const count = Math.ceil(data?.totalCount / PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
  };

  const handleClose = () => {
    console.log("CLose");
  };

  const getArrowIcon=()=>{
    if(sorting){
      return <KeyboardArrowDown className="toggle-icon" />

    }else{
      return <KeyboardArrowUp className="toggle-icon" />

    }
  }

  return (
    <FormProvider {...filterMethods}>
      <Header />
      <div className={`page-spacing pd-y-20`}>
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between flex-wrap mg-b-10">
            <div className={`d-flex align-items-center`}>
              <div className={"d-flex align-items-center flex-wrap"}>
                <div className={`mg-r-15`}>
                  <Button
                    onClick={handleFilterOpen}
                    variant={"text"}
                    size={"medium"}
                    color={"primary"}
                  >
                    <FilterAlt sx={{fontSize: '1.125rem'}} className={'mg-r-10'}/>
                    <Typography noWrap component={"span"}>
                      Add Filters
                    </Typography>
                  </Button>
                  <Menu
                    id="role-menu"
                    anchorEl={filterAnchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={openFilter}
                    onClose={filterClose}
                  >
                    <Box sx={{maxWidth: 800}}>
                      <Box className={`pd-x-24 pd-y-15 border-bottom`}>
                        <Typography
                          variant={"h3"}
                          component={"h3"}
                          className={`font-size-base`}
                        >
                          Filter Option
                        </Typography>
                      </Box>
                      <Box className={`pd-x-24 pd-y-15`}>
                        <Grid container spacing={2}>
                          <Grid item md={4}>
                            <Box>
                              <Typography
                                  className={
                                    "mg-b-10 font-weight-medium"
                                  }
                              >
                                Status
                              </Typography>
                              <MuiFilterMenu
                                  options={questionStatusFilterOptions}
                                  RHFName={"questionStatusFilter"}
                                  placeholder="Select Status"
                              />
                            </Box>
                          </Grid>
                          <Grid item md={4}>
                            <Box>
                              <Typography
                                  className={
                                    "mg-b-10 font-weight-medium"
                                  }
                              >
                                Question Type
                              </Typography>
                              <MuiFilterMenu
                                  options={questionCategory}
                                  RHFName={"questionTypeFilter"}
                                  placeholder="Select Question Type"
                              />
                            </Box>
                          </Grid>
                          <Grid item md={4}>
                            <Box>
                              <Typography
                                  className={
                                    "mg-b-10 font-weight-medium"
                                  }
                              >
                                Complexity
                              </Typography>
                              <MuiFilterMenu
                                  options={complexityOptions}
                                  RHFName={"complexityFilter"}
                                  placeholder="Select Complexity"
                              />
                            </Box>
                          </Grid>
                          <Grid item md={4}>
                            <Box>
                              <Typography
                                  className={
                                    "mg-b-10 font-weight-medium"
                                  }
                              >
                                Primary Skill
                              </Typography>
                              <MuiFilterMenu
                                  options={primarySkillsOptions}
                                  RHFName={"primarySkillFilter"}
                                  placeholder="Select Primary Skill"
                              />
                            </Box>
                          </Grid>
                          <Grid item md={4}>
                            <Box>
                              <Typography
                                  className={
                                    "mg-b-10 font-weight-medium"
                                  }
                              >
                                Secondary Skill
                              </Typography>
                              <MuiFilterMenu
                                  options={secondarySkills}
                                  RHFName={"secondarySkillsFilter"}
                                  placeholder="Select Secondary Skill"
                              />
                            </Box>
                          </Grid>
                          {userRole === Roles.admin && (
                          <Grid item md={4}>
                                <Box>
                                  <Typography
                                      className={
                                        "mg-b-10 font-weight-medium"
                                      }
                                  >
                                    CreatedBy
                                  </Typography>
                                  <MuiFilterMenu
                                      options={users}
                                      RHFName={"createdBy"}
                                      placeholder="Select Creator"
                                  />
                                </Box>
                          </Grid>
                              )}
                          {userRole === Roles.admin && (
                          <Grid item md={4}>
                                <Box>
                                  <Typography
                                      className={
                                        "mg-b-10 font-weight-medium"
                                      }
                                  >
                                    CreatedTime
                                  </Typography>
                                  <Stack direction="row" spacing={0.5}>
                                    <Controller
                                        control={control}
                                        name={"startDate"}
                                        render={({ field: { onChange, value } }) => (
                                            <ReactDatePicker
                                                className="form-control form-control-sm"
                                                placeholderText="Start date"
                                                onChange={onChange}
                                                selected={value}
                                                dateFormat="yyyy-MM-dd"
                                                // showTimeSelect
                                            />
                                        )}
                                    />
                                    <Typography
                                        className={
                                          "font-weight-medium d-flex align-items-center mg-b-5"
                                        }
                                    >
                                      To
                                    </Typography>
                                    <Controller
                                        control={control}
                                        name={"endDate"}
                                        render={({ field: { onChange, value } }) => (
                                            <ReactDatePicker
                                                className="form-control form-control-sm"
                                                placeholderText="End date"
                                                onChange={onChange}
                                                selected={value}
                                                dateFormat="yyyy-MM-dd"
                                            />
                                        )}
                                    />
                                  </Stack>
                                </Box>
                          </Grid>
                          )}
                        </Grid>
                        <Box
                          className={`d-flex align-items-center justify-content-end mg-t-15`}
                        >
                          <Button
                            variant={"text"}
                            color={"inherit"}
                            className={"mg-r-20"}
                            onClick={onReset}
                          >
                            Reset
                          </Button>
                          <Button
                            onClick={onFilterClick}
                            variant={"contained"}
                            color={"primary"}
                          >
                            Apply
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Menu>
                </div>

                <Stack direction="row" spacing={2}>
                  {!isEmptyObject(filterValues.filters.status) && (
                    <Chip
                      label={filterValues.filters.status.label}
                      onClick={handleClose}
                      onDelete={() => {
                        dispatch(setStatusFilterAction({}));
                        setValue("questionStatusFilter", {});
                      }}
                      className={"chip badge-white"}
                      deleteIcon={<Close className={styles.iconClose} />}
                    />
                  )}

                  {!isEmptyObject(filterValues.filters.primarySkill) && (
                    <Chip
                      className={"chip badge-white"}
                      label={filterValues.filters.primarySkill.label}
                      onClick={handleClose}
                      onDelete={() => {
                        dispatch(setPrimarySkillFilterAction({}));
                        setValue("primarySkillFilter", {});
                      }}
                      deleteIcon={<Close className={styles.iconClose} />}
                    />
                  )}
                  {!isEmptyObject(filterValues.filters.secondarySkill) && (
                    <Chip
                      className={"chip badge-white"}
                      label={filterValues.filters.secondarySkill.label}
                      onClick={handleClose}
                      onDelete={() => {
                        dispatch(setSecondarySkillFilterAction({}));
                        setValue("secondarySkillsFilter", {});
                      }}
                      deleteIcon={<Close className={styles.iconClose} />}
                    />
                  )}
                  {!isEmptyObject(filterValues.filters.questionType) && (
                    <Chip
                      className={"chip badge-white"}
                      label={filterValues.filters.questionType.label}
                      onClick={handleClose}
                      onDelete={() => {
                        dispatch(setQuestionTypeFilterAction({}));
                        setValue("questionTypeFilter", {});
                      }}
                      deleteIcon={<Close className={styles.iconClose} />}
                    />
                  )}
                  {!isEmptyObject(filterValues.filters.complexity) && (
                    <Chip
                      className={"chip badge-white"}
                      label={filterValues.filters.complexity.label}
                      onClick={handleClose}
                      onDelete={() => {
                        dispatch(setComplexityFilter({}));
                        setValue("complexityFilter", {});
                      }}
                      deleteIcon={<Close className={styles.iconClose} />}
                    />
                  )}
                  {!isEmptyObject(filterValues.filters.createdBy) && (
                    <Chip
                      className={"chip badge-white"}
                      label={filterValues.filters.createdBy.label}
                      onClick={handleClose}
                      onDelete={() => {
                        dispatch(setCreatedByFilterAction({}));
                        setValue("createdBy", {});
                      }}
                      deleteIcon={<Close className={styles.iconClose} />}
                    />
                  )}
                </Stack>
              </div>
            </div>
            <div className={`d-flex align-items-center flex-wrap`}>

              {!user.isUser && (
                <Link to={RoutingPaths.NewBankTypeQuestion} className={'text-decoration-none'}>
                  <Button
                    className={`text-nowrap`}
                    variant={"contained"}
                    color={"primary"}
                    size={"medium"}
                  >
                    <i className={`icon-plus mg-r-6`}></i>
                    New Question
                  </Button>
                </Link>
              )}
            </div>
          </div>
          {loader ? (
            <Loader />
          ) : (
            <Card className={`card-table`}>
              <CardContent className="pd-x-0 pd-y-0">
                <div className={`search-with-icon search-header`}>
                  <img src={IMAGES.Search} className="search-icon" alt="search" />
                  <input
                      type="search"
                      className="form-control form-control-sm search-input"
                      placeholder="Search"
                      onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <TableContainer className="custom-table">
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Title</TableCell>
                        <TableCell onClick={sortingHandler}>Date  <i className={'icon-sort'}></i></TableCell>
                        <TableCell>Primary Skill</TableCell>
                        <TableCell>Duration</TableCell>
                        <TableCell>Type</TableCell>
                        {userRole === Roles.admin && (
                          <TableCell>Created By</TableCell>
                        )}
                        {!(userRole === Roles.user) && (
                          <TableCell>Reviewer</TableCell>
                        )}

                        <TableCell>Complexity</TableCell>
                        {!(userRole === Roles.user) && (
                          <TableCell
                            className={
                              userRole === Roles.contributor
                                ? ""
                                : "text-center"
                            }
                          >
                            Status
                          </TableCell>
                        )}
                        {!(userRole === Roles.user) && (
                          <TableCell className="pd-r-32"></TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    {(apiStatus === apiStatusMap.resolved && data?.totalCount) ? (
                      <TableBody>
                        {data?.questions?.map(
                          (
                            {
                              questionType,
                              complexity,
                              title,
                              time,
                              _id,
                              createdBy,
                              approvedBy,
                              primarySkill,
                              secondarySkills,
                              status,
                              lastStatusChangedAt,
                              isDraft,
                            },
                            index
                          ) => {
                            return (
                              <TableRow
                                key={_id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>
                                  <Box>
                                    <Link
                                      to={`/edit-question/${_id}`} className={'text-dark text-decoration-none'}>
                                      <Typography className="font-weight-medium table-title">
                                        {truncateString(title, 30)}
                                      </Typography>
                                    </Link>
                                    <Typography
                                      className="font-size-sm"
                                      color={"secondary"}
                                    >
                                      {truncateString(secondarySkills.map((item) => item?.label).join(", "), 30)}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell>{lastStatusChangedAt?getDate(lastStatusChangedAt):''}</TableCell>
                                <TableCell>{primarySkill?.label}</TableCell>
                                <TableCell>{getTimeInMinSec(time)} min</TableCell>
                                <TableCell>
                                  {questionTypeMap[questionType]}
                                </TableCell>
                                {userRole === Roles.admin && (
                                  <TableCell>
                                    {createdBy
                                      ? createdBy.firstName +
                                        " "
                                      : ""}
                                  </TableCell>
                                )}

                                {!(userRole === Roles.user) && (
                                  <>
                                    <TableCell>
                                      {approvedBy
                                        ? approvedBy.firstName +
                                          " "
                                        : ""}
                                    </TableCell>
                                  </>
                                )}

                                <TableCell>
                                  {complexityTypeMap[complexity]}
                                </TableCell>

                                {userRole === Roles.admin &&
                                  status !== "DRAFTED" && (
                                    <TableCell className="text-center">
                                      <QuestionStatusMenu
                                        key={index}
                                        options={statusOfQuestion}
                                        setDropdown={(e) =>
                                          handleStatusChange(e, _id)
                                        }
                                        status={status}
                                      />
                                    </TableCell>
                                  )}
                                {userRole === Roles.admin &&
                                  status === "DRAFTED" && (
                                    <TableCell sx={{ textAlign: "center" }}>
                                      <span
                                        className={`badge ${statusMap[status].class}`}
                                      >
                                        {statusMap[status]?.label}
                                      </span>
                                    </TableCell>
                                  )}

                                {userRole === Roles.contributor && (
                                  <TableCell>
                                    <span
                                      className={`badge ${statusMap[status]?.class}`}
                                    >
                                      {statusMap[status]?.label}
                                    </span>
                                  </TableCell>
                                )}

                                {!(userRole === Roles.user) && (
                                  <TableCell className="pd-r-0" align={"right"}>
                                    <QBMoreMenu
                                      _id={_id}
                                      handleDelete={handleDelete}
                                    />
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          }
                        )}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={12}>
                            { apiStatus === apiStatusMap.pending ? <Loader /> : "No Data Found!" }
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          )}
          <Box
            className={`d-flex justify-content-between align-items-center mg-t-30`}
          >
            {data?.totalCount > 0 && !loader && (
              <Typography variant={"body2"} color={"secondary"}>
                Showing {(page - 1) * PER_PAGE + 1} to{" "}
                {page * PER_PAGE >= data?.totalCount
                  ? data?.totalCount
                  : page * PER_PAGE}{" "}
                of {data?.totalCount} entries
              </Typography>
            )}

            {data?.totalCount > PER_PAGE && !loader && (
              <Pagination
                color={"primary"}
                className={"custom-pagination"}
                shape="rounded"
                count={count}
                page={page}
                variant="outlined"
                onChange={handleChange}
              />
            )}
          </Box>
        </div>
      </div>
    </FormProvider>
  );
};
export default QuestionBankList;
