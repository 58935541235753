import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent } from "@mui/material";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../Header/Header";
import QuestionService from "../../../services/api/QuestionService";
import { apiStatusMap, complexityOptions, languageMap, questionTypeMap, questionTypeOptions, questionStatusFilterOptions } from "../../../utils/constants";
import { fetchSkills } from "../../../utils/redux/slices/skillSlice";
import Loader from "../../Loader/Loader";
import { setAllFiltersAction } from "../../../utils/redux/slices/filterSlice";


const QuestionReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status: skillsStatus, skills, skillMap, subSkillMap } = useSelector((state) => state.skills);
  const filter = useSelector((state) => state.FilterSlice);
  const [status, setStatus] = useState(apiStatusMap.idle);
  const [headerMap, setHeaderMap] = useState({ all: {}, code: {} })
  const [questionStatus, setQuestionStatus] = useState('');
  const [data, setData] = useState({});
  const [skill, setSkill] = useState('');
  const [complexity, setComplexity] = useState('');

  /* fetches skills if not fetched */
  useEffect(() => {
    if (skillsStatus == apiStatusMap.idle) {
      dispatch(fetchSkills());
    }
  }, [])

  /* set first skill selected ...*/
  useEffect(() => {
    if (skillsStatus == apiStatusMap.resolved && skills.length) {
      setSkill(skills[0]._id);
    }
  }, [skills])

  /* sets skill & complexity from redux */
  // useEffect(() => {
  //   filter.filters.primarySkill._id && setSkill(filter.filters.primarySkill._id);
  //   filter.filters.complexity._id && setComplexity(filter.filters.complexity._id);
  // }, [filter])

  /* fetches reports when skill or complexity change */
  useEffect(() => {
    if (!skill) return;
    setStatus(apiStatusMap.pending);
    QuestionService.getReports({
      primarySkill: skill,
      ...(complexity && { complexity }),
      ...(questionStatus && { status: questionStatus })
    })
      .then(res => {
        if (res.data.data?.length) {
          const all = res.data.data[0].allQuestionSkillWiseCount.reduce((a, c) => { a[c._id] = c.reports; return a; }, {});
          const code = res.data.data[0].codeQuestionSkillWiseCount.reduce((a, c) => { a[c._id] = c.reports; return a; }, {});
          setHeaderMap({all, code});
          setData(res.data.data[0]);
          setStatus(apiStatusMap.resolved)
        }
      }).catch(err => setStatus(apiStatusMap.rejected))
  }, [skill, complexity, questionStatus]);

  const navigateToQuestionBank = (questionType, subSkill) => {
    const _complexity = complexityOptions.find(({ _id }) => _id === complexity);
    const _primarySkill = skillMap[skill];
    const _secondarySkill = subSkillMap[subSkill]
    const _questionType = questionTypeOptions.find(({ _id }) => _id === questionType);
    const _status = questionStatusFilterOptions.find(({ _id }) => _id === questionStatus);
    const filterObj = {
      status: _status ? _status : {},
      questionType: _questionType ? _questionType : {},
      complexity: _complexity ? _complexity : {},
      primarySkill: _primarySkill ? _primarySkill : {},
      secondarySkill: _secondarySkill ? _secondarySkill : {},
      createdBy: {},
    };
    dispatch(setAllFiltersAction(filterObj));
    navigate('/question-bank');
  }

  const Dropdown = ({ label, value, options, handleChange, disabled }) => {
    return (
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small">{label}</InputLabel>
        <Select
          disabled={disabled}
          labelId="demo-select-small"
          id="demo-select-small"
          value={value}
          label={label}
          onChange={handleChange}
        >
          {options.map(({ _id, label }) => (
            <MenuItem key={_id} value={_id}>{label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  const ClickableCell = ({ title, subSkillId, questionType, style, navigate}) => {
    const props = { 
      ...(navigate && { onClick: () => navigateToQuestionBank(questionType, subSkillId) }),
      ...(style ? { style } : { style: {color: '#00A3FF', cursor: 'pointer'} })
    }
    return (
      <TableCell>
        <span {...props}>
          {title}
        </span>
      </TableCell>
    );
  }

  const AllQuestionReport = ({ allQuestionSubSkillWiseCount }) => {
    return (
      <>
        {allQuestionSubSkillWiseCount.map((({ _id, skill, reports }) => {
          return (
            <div key={_id}>
            <Card className={'card-table'}>
              <CardContent className="pd-x-0 pd-y-0">
                <TableContainer className="custom-table">
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <ClickableCell
                          title={`${headerMap.all[_id].skill} (${headerMap.all[_id].total})`}
                          questionType=""
                          subSkillId=""
                          navigate
                        />
                        {Object.entries(questionTypeMap).map(([k, v]) => (
                          <ClickableCell
                            title={`${v} (${headerMap.all[_id][k]})`}
                            questionType={k}
                            subSkillId=""
                            navigate
                          />
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reports.map((report) => {
                        return (
                          <TableRow>
                            <TableCell>{report.subSkill}</TableCell>
                            {Object.keys(questionTypeMap).map((type) => (
                              <ClickableCell
                                title={report[type]}
                                questionType={type}
                                subSkillId={report.subSkillId}
                                navigate
                              />
                            ))}
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
            <br />
            </div>
          )
        }))}
      </>
    );
  }

  const CodeQuestionReport = ({ codeQuestionSubSkillWiseCount }) => {
    return (
      <>
        {codeQuestionSubSkillWiseCount.map((({ _id, skill, reports, total, ...rest }) => {
          return (
            <div key={_id}>
            <Card className="md-3">
              <CardContent className="pd-x-20 pd-y-0">
                <TableContainer className="custom-table">
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <ClickableCell
                          title={`${headerMap.code[_id].skill} (${headerMap.all[_id].CODE})`}
                          questionType="CODE"
                          subSkillId={""}
                          navigate
                        />
                        {Object.entries(languageMap).map(([k,v]) => (
                          <TableCell>{v} ({headerMap.code[_id][k]})</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reports.map((report) => {
                        return (
                          <TableRow>
                            <TableCell>{report.subSkill}</TableCell>
                            {Object.keys(languageMap).map((language) => (
                              <ClickableCell
                                title={report[language]}
                                questionType="CODE"
                                subSkillId={report.subSkillId}
                                navigate
                              />
                            ))}
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
            <br />
            </div>
          )
        }))}
      </>
    );
  }

  return (
    <>
      <Header />
      <div className={`page-spacing pd-y-20`}>
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between flex-wrap mg-b-10">
            <div className={`d-flex align-items-center`}>
              <div className={"d-flex align-items-center flex-wrap"}>
                <b>Question Report</b>
              </div>
            </div>
            {skillsStatus === apiStatusMap.pending ? (
              <Loader />
            ) : (
              <div className={`d-flex align-items-center flex-wrap`}>
                <Dropdown
                  label="Skill"
                  value={skill}
                  options={skills}
                  handleChange={(e) => setSkill(e.target.value)}
                  disabled={status === apiStatusMap.pending}
                />
                <Dropdown
                  label="Complexity"
                  value={complexity}
                  options={[{ _id: '', label: 'All' }, ...complexityOptions]}
                  handleChange={(e) => setComplexity(e.target.value)}
                  disabled={status === apiStatusMap.pending}
                />
                <Dropdown
                  label="status"
                  value={questionStatus}
                  options={[{ _id: '', label: 'All' }, ...questionStatusFilterOptions]}
                  handleChange={(e) => setQuestionStatus(e.target.value)}
                  disabled={status === apiStatusMap.pending}
                />

              </div>
            )}

          </div>
          {status === apiStatusMap.pending ? (
            <Loader />
          ) : (
            <>
              {data.allQuestionSubSkillWiseCount && (
                <AllQuestionReport allQuestionSubSkillWiseCount={data.allQuestionSubSkillWiseCount} />
              )}
              {data.codeQuestionSubSkillWiseCount && (
                <CodeQuestionReport codeQuestionSubSkillWiseCount={data.codeQuestionSubSkillWiseCount} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default QuestionReport;
