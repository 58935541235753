import Header from "../../Header/Header";
import React, { useState } from "react";
import {
  Card,
  CardContent,
  Divider,
  Menu,
  MenuItem,
  Typography,
  Button, AccordionSummary, FormControlLabel, AccordionDetails, Accordion
} from "@mui/material";
import IMAGES from "../../../assets/images/images";
import {Close, ExpandMore, KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import styles from "./ChooseTestQuestions.module.scss";
import QuestionBankListService from "../../../services/api/QuestionBankListService";
import { useEffect } from "react";
import {
  complexityTypeMap,
  getTimeInMinSec,
  questionTypeMap,
} from "../../../utils/constants";
import HtmlParser from "react-html-parser";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DetailsDialog from "../../common/DetailsDialog/DetailsDialog";
import {Controller, useFieldArray, useFormContext} from "react-hook-form";
import useDebounce from "../../../hooks/useDebounce";
import { useSnackbar } from "notistack";
import {IOSSwitch} from "../../../utils/ui/IOSSwitch";

const ChooseTestQuestions = () => {
  const { setValue, control } = useFormContext();
  const [stageAnchorEl, setStageAnchorEl] = useState(null);
  const openStage = Boolean(stageAnchorEl);
  const [testQuestions, setTestQuestions] = useState();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const debouncedSearch = useDebounce(searchTerm, 500, setPage);
  const { enqueueSnackbar } = useSnackbar();
  const PER_PAGE = 10;
  const [totalData, setTotalData] = useState(0);
  const getAllQuestions = async () => {
    const data = await QuestionBankListService.questionBankGetAll(
      page,
      PER_PAGE,
      debouncedSearch
    );
    setTestQuestions(data?.data?.data.questions);
    setTotalData(data?.data?.data.totalCount);
  };
  useEffect(() => {
    getAllQuestions();
  }, [debouncedSearch, page]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "selectedTestQuestions",
  });

  const handleStageClose = () => {
    setStageAnchorEl(null);
  };
  const handleStageOpen = (event) => {
    setStageAnchorEl(event.currentTarget);
  };

  const id2List = {
    allQuestions: testQuestions,
    selectedQuestions: fields,
  };

  const getList = (id) => id2List[id];
  const findInArray = (myArray, item) => {
    return !!myArray.find((el) => el._id == item);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    const item = testQuestions[source.index];
    // console.log(selectedQuestions);
    // if (
    //   findInArray(fields, item._id) &&
    //   source.droppableId !== destination.droppableId
    // ) {
    //   return;
    // }
    if (
      !destination ||
      (source.droppableId === "selectedQuestions" &&
        destination.droppableId === "allQuestions")
    ) {
      return;
    }
    if (source.droppableId !== destination.droppableId) {
      const sourceItems = [...getList(source.droppableId)];
      const destItems = [...getList(destination.droppableId)];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      const isFound = findInArray(fields, removed._id);
      if (isFound) {
        enqueueSnackbar("Question already added!", {
          variant: "warning",
        });
        return
      } else {
        // selectedQuestions[removed._id] = true;

        setValue("selectedTestQuestions", destItems);
      }

      // setSelected(destItems);
      // setTestQuestions(sourceItems);
    } else {
      if (source.droppableId === "selectedQuestions") {
        const copiedItems = [...getList(source.droppableId)];
        const [removed] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0, removed);
        // setSelected(copiedItems);
        setValue("selectedTestQuestions", copiedItems);
      }
    }
  };
  const viewDetails = () => {
    setOpen(true);
  };
  const handleClose = (idx) => {
    // const temp = fields.filter((item) => item._id !== id);
    remove(idx);
    // selectedQuestions[fields[idx]._id] = false;
    // setSelected(temp);
    // setValue("selectedTestQuestions", temp);
  };
  return (
    <div className={`page-spacing pd-t-30`}>
      <div className={`container-fluid`}>
        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
          <div className="row">
            <div className="col-lg-7 col-xl-8">
              <div className={"d-flex align-items-center justify-content-between"}>
                <Typography variant={"h2"} component={"h2"} className={`mg-b-15`}>
                  Choose Test Questions{" "}
                  <Typography
                      variant={"body1"}
                      component={"span"}
                      color={"secondary"}
                  >
                    ({totalData})
                  </Typography>
                </Typography>
                <div>
                  <Typography
                      variant={"body1"}
                      component={"span"}
                      className={"text-info font-weight-semi-bold"}
                  >
                    #MCQ, #Skill
                  </Typography>
                </div>
              </div>
              <div className="row g-3">
                <div className="col">
                  <div className={`search-with-icon`}>
                    <img
                      src={IMAGES.Search}
                      className="search-icon"
                      alt="search"
                    />
                    <input
                      type="search"
                      className="form-control form-control-sm border-0"
                      placeholder="Search"
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-auto">
                  <div>
                    <div className={`btn-group `}>
                      <button
                        type="button"
                        onClick={handleStageOpen}
                        className={`btn btn-light btn-toggle btn-sm`}
                      >
                        <Typography
                          noWrap
                          component={"span"}
                          className="mg-r-4 pd-y-0 font-weight-medium font-size-sm"
                        >
                          {false ? "SelectedValue" : "Select"}
                        </Typography>
                        {openStage ? (
                          <KeyboardArrowUp className="toggle-icon" />
                        ) : (
                          <KeyboardArrowDown className="toggle-icon" />
                        )}
                      </button>
                      <button
                        type="button"
                        onClick={handleStageOpen}
                        className={`btn btn-light btn-toggle btn-sm`}
                      >
                        <Typography
                          noWrap
                          component={"span"}
                          className="mg-r-4 pd-y-0 font-weight-medium font-size-sm"
                        >
                          {false ? "SelectedValue" : "Select"}
                        </Typography>
                        {openStage ? (
                          <KeyboardArrowUp className="toggle-icon" />
                        ) : (
                          <KeyboardArrowDown className="toggle-icon" />
                        )}
                      </button>
                    </div>
                    <Menu
                      id="role-menu"
                      anchorEl={stageAnchorEl}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={openStage}
                      onClose={handleStageClose}
                    >
                      <MenuItem>TBD</MenuItem>
                      <MenuItem>TBD</MenuItem>
                    </Menu>
                  </div>
                </div>
                <div className="col-auto">
                  <button
                    type="button"
                    onClick={handleStageOpen}
                    className={`btn btn-light btn-toggle btn-sm`}
                  >
                    <Typography
                      noWrap
                      component={"span"}
                      className="mg-r-4 pd-y-0 font-weight-medium font-size-sm"
                    >
                      {false ? "SelectedValue" : "Select"}
                    </Typography>
                    {openStage ? (
                      <KeyboardArrowUp className="toggle-icon" />
                    ) : (
                      <KeyboardArrowDown className="toggle-icon" />
                    )}
                  </button>
                  <Menu
                    id="role-menu"
                    anchorEl={stageAnchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={openStage}
                    onClose={handleStageClose}
                  >
                    <MenuItem>TBD</MenuItem>
                    <MenuItem>TBD</MenuItem>
                  </Menu>
                </div>
              </div>
              <Droppable droppableId={`allQuestions`}>
                {(provided, snapshot) => {
                  return (
                    <ul
                      className={`list-unstyled mg-t-15`}
                      {...provided.droppableProps}
                      ref={provided?.innerRef}
                    >
                      {testQuestions?.map((question, index) => (
                        <Draggable
                          key={question._id}
                          draggableId={"a_" + question._id}
                          index={index}
                        >
                          {(provided, snapshot) => {
                            return (
                              <li
                                className={`mg-b-15`}
                                key={question._id}
                                ref={provided?.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Card>
                                  <CardContent className={`pd-b-16`}>
                                    <div className={`d-flex mg-b-10`}>
                                      <div
                                        className={`img-asset-box img-asset-32 asset-bg mg-r-10`}
                                      >
                                        <img
                                          className={`img-fluid`}
                                          src={IMAGES.React}
                                          alt="..."
                                        />
                                      </div>
                                      <div>
                                        <Typography
                                          variant={"h4"}
                                          component={"h4"}
                                          className={`mg-b-10 font-size-15`}
                                        >
                                          {question?.title}
                                        </Typography>
                                        <Typography
                                          variant={"body1"}
                                          component={"span"}
                                          color={"secondary"}
                                          className={`mg-b-10 font-size-sm`}
                                        >
                                          {HtmlParser(
                                            question?.detail?.langWiseDetail[0]
                                              .detail
                                          )}
                                        </Typography>
                                        <Typography
                                          variant={"body2"}
                                          className={`font-weight-medium hash-tag`}
                                        >
                                          {question?.primarySkill?.label}
                                        </Typography>
                                      </div>
                                    </div>
                                    <div
                                      className={`d-flex align-items-center justify-content-between`}
                                    >
                                      <Typography
                                        variant={"body2"}
                                        component={"p"}
                                        color={"secondary"}
                                      >
                                        {
                                          complexityTypeMap[
                                            question?.complexity
                                          ]
                                        }
                                        <span className={`mg-x-10`}>|</span>
                                        <span className={`text-nowrap`}>
                                          <i
                                            className={`icon-document mg-r-6`}
                                          ></i>
                                          {
                                            questionTypeMap[
                                              question?.questionType
                                            ]
                                          }
                                        </span>
                                        <span className={`mg-x-10`}>|</span>
                                        <span className={`text-nowrap`}>
                                          <i
                                            className={`icon-clock-time mg-r-6`}
                                          ></i>
                                          {getTimeInMinSec(question?.time)} min
                                        </span>
                                      </Typography>
                                      <button
                                        type={"button"}
                                        className={`btn btn-sm btn-snow mg-l-10`}
                                        onClick={viewDetails}
                                      >
                                        <i className={`icon-eye`}></i>
                                      </button>
                                    </div>
                                  </CardContent>
                                </Card>
                              </li>
                            );
                          }}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  );
                }}
              </Droppable>
            </div>
            <div className="col-lg-5 col-xl-4">
              <div className={`${styles.selectedPanel} pd-x-20 pd-y-20`}>
                <div
                  className={`d-flex align-items-center justify-content-between`}
                >
                  <Typography variant={"h2"} component={"h2"}>
                    Selected question{" "}
                    <Typography
                      variant={"body1"}
                      component={"span"}
                      color={"secondary"}
                    >
                      ({fields?.length})
                    </Typography>
                  </Typography>
                  <Typography
                    variant={"body1"}
                    component={"span"}
                    color={"primary"}
                    className={`font-weight-semi-bold`}
                  >
                    <i className={`icon-clock-circle`}></i> 15min
                  </Typography>
                </div>
                <Divider className={"mg-y-15"} />
                <DetailsDialog open={open} setOpen={setOpen} />
                <Droppable droppableId={`selectedQuestions`}>
                  {(provided, snapshot) => {
                    return (
                      <ul
                        className={`list-unstyled`}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        isDraggingOver={snapshot.isDraggingOver}
                        style={{
                          background: snapshot.isDraggingOver
                            ? "lightblue"
                            : "lightgrey",
                        }}
                      >
                        {fields?.map((question, index) => (
                          <Draggable
                            key={question._id}
                            draggableId={"s_" + question._id}
                            index={index}
                          >
                            {(provided, snapshot) => {
                              return (
                                <li
                                  className={`mg-b-15`}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Card className={`${styles.selectedCard}`}>
                                    <button
                                      type={"button"}
                                      className={"btn btn-x"}
                                      onClick={() => handleClose(index)}
                                    >
                                      <Close className={`close-icons`} />
                                    </button>
                                    <CardContent className={`pd-b-16`}>
                                      <div className={`d-flex mg-b-10`}>
                                        <div
                                          className={`img-asset-box img-asset-32 asset-success mg-r-10`}
                                        >
                                          <img
                                            className={`img-fluid`}
                                            src={IMAGES.React}
                                            alt="..."
                                          />
                                        </div>
                                        <div>
                                          <Typography
                                            variant={"h4"}
                                            component={"h4"}
                                            className={`mg-b-10 font-size-15`}
                                          >
                                            {question?.title}
                                          </Typography>
                                          <Typography
                                            variant={"body1"}
                                            component={"span"}
                                            color={"secondary"}
                                            className={`mg-b-10 font-size-sm`}
                                          >
                                            {
                                              question?.detail
                                                ?.langWiseDetail[0]?.detail
                                            }
                                          </Typography>
                                          <Typography
                                            variant={"body2"}
                                            className={`font-weight-medium hash-tag`}
                                          >
                                            {question?.primarySkill?.label}
                                          </Typography>
                                        </div>
                                      </div>
                                      <div
                                        className={`d-flex align-items-center justify-content-between`}
                                      >
                                        <Typography
                                          variant={"body2"}
                                          component={"p"}
                                          color={"secondary"}
                                        >
                                          {question?.complexity}
                                          <span className={`mg-x-10`}>|</span>
                                          <span className={`text-nowrap`}>
                                            <i
                                              className={`icon-document mg-r-6`}
                                            ></i>
                                            {question?.questionType}
                                          </span>
                                          <span className={`mg-x-10`}>|</span>
                                          <span className={`text-nowrap`}>
                                            <i
                                              className={`icon-clock-time mg-r-6`}
                                            ></i>
                                            {question?.time}
                                          </span>
                                        </Typography>
                                        <button
                                          type={"button"}
                                          className={`btn btn-sm btn-snow mg-l-10`}
                                        >
                                          <i className={`icon-eye`}></i>
                                        </button>
                                      </div>
                                    </CardContent>
                                  </Card>
                                </li>
                              );
                            }}
                          </Draggable>
                        ))}

                        {/* {selected?.map((question, index) => (
                          <li
                            key={question._id}
                            className={`mg-b-15`}
                            ref={provided?.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Card className={`${styles.selectedCard}`}>
                              <button
                                type={"button"}
                                className={"btn btn-x"}
                                onClick={() => handleClose(question._id)}
                              >
                                <Close className={`close-icons`} />
                              </button>
                              <CardContent className={`pd-b-16`}>
                                <div className={`d-flex mg-b-10`}>
                                  <div
                                    className={`img-asset-box img-asset-32 asset-success mg-r-10`}
                                  >
                                    <img
                                      className={`img-fluid`}
                                      src={IMAGES.React}
                                      alt="..."
                                    />
                                  </div>
                                  <div>
                                    <Typography
                                      variant={"h4"}
                                      component={"h4"}
                                      className={`mg-b-10 font-size-15`}
                                    >
                                      {question?.title}
                                    </Typography>
                                    <Typography
                                      variant={"body1"}
                                      component={"span"}
                                      color={"secondary"}
                                      className={`mg-b-10 font-size-sm`}
                                    >
                                      {
                                        question?.detail?.langWiseDetail[0]
                                          ?.detail
                                      }
                                    </Typography>
                                    <Typography
                                      variant={"body2"}
                                      className={`font-weight-medium hash-tag`}
                                    >
                                      {question?.primarySkill.label}
                                    </Typography>
                                  </div>
                                </div>
                                <div
                                  className={`d-flex align-items-center justify-content-between`}
                                >
                                  <Typography
                                    variant={"body2"}
                                    component={"p"}
                                    color={"secondary"}
                                  >
                                    {question?.complexity}
                                    <span className={`mg-x-10`}>|</span>
                                    <span className={`text-nowrap`}>
                                      <i className={`icon-document mg-r-6`}></i>
                                      {question?.questionType}
                                    </span>
                                    <span className={`mg-x-10`}>|</span>
                                    <span className={`text-nowrap`}>
                                      <i
                                        className={`icon-clock-time mg-r-6`}
                                      ></i>
                                      {question?.time}
                                    </span>
                                  </Typography>
                                  <button
                                    type={"button"}
                                    className={`btn btn-sm btn-snow mg-l-10`}
                                    onClick={viewDetails}
                                  >
                                    <i className={`icon-eye`}></i>
                                  </button>
                                </div>
                              </CardContent>
                            </Card>
                          </li>
                          
                        ))} */}

                        {provided.placeholder}
                      </ul>
                    );
                  }}
                </Droppable>
                <Accordion className={`accordion-wrap outline-white mg-b-15`}>
                  <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className={`w-100`}
                  >
                    <Typography>
                      React-Hooks
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={`list-unstyled card-list`}>
                      <li>
                        <Card className={`${styles.selectedCard}`}>
                          <CardContent className={`pd-b-16`}>
                            <div className={`d-flex mg-b-10`}>
                              <div
                                  className={`img-asset-box img-asset-32 asset-success mg-r-10`}
                              >
                                <img
                                    className={`img-fluid`}
                                    src={IMAGES.React}
                                    alt="..."
                                />
                              </div>
                              <div>
                                <Typography
                                    variant={"h4"}
                                    component={"h4"}
                                    className={`mg-b-10 font-size-15`}
                                >
                                  TBD
                                </Typography>
                                <Typography
                                    variant={"body1"}
                                    component={"span"}
                                    color={"secondary"}
                                    className={`mg-b-10 font-size-sm`}
                                >
                                  TBD
                                </Typography>
                                <Typography
                                    variant={"body2"}
                                    className={`font-weight-medium hash-tag`}
                                >
                                  TBD
                                </Typography>
                              </div>
                            </div>
                            <div
                                className={`d-flex align-items-center justify-content-between`}
                            >
                              <Typography
                                  variant={"body2"}
                                  component={"p"}
                                  color={"secondary"}
                              >
                                TBD
                                <span className={`mg-x-10`}>|</span>
                                <span className={`text-nowrap`}>
                                            <i
                                                className={`icon-document mg-r-6`}
                                            ></i>
                          TBD
                                          </span>
                                <span className={`mg-x-10`}>|</span>
                                <span className={`text-nowrap`}>
                                            <i
                                                className={`icon-clock-time mg-r-6`}
                                            ></i>
                          TBD
                                          </span>
                              </Typography>
                              <button
                                  type={"button"}
                                  className={`btn btn-sm btn-snow mg-l-10`}
                              >
                                <i className={`icon-eye`}></i>
                              </button>
                            </div>
                          </CardContent>
                          <div className={`pd-x-16 border-top`}>

                            <div className={"row align-items-center g-0"}>
                              <div className="col-6">
                                <div className="custom-checkbox">
                                  <input
                                      className="custom-control-input"
                                      type="checkbox"
                                      id={`practice-test`}
                                      name={`practice-test`}
                                  />
                                  <label
                                      className="custom-control-label text-capitalize font-size-sm"
                                      htmlFor={`practice-test`}
                                  >
                                    Practice question?
                                  </label>
                                </div>
                              </div>
                              <div className="col-6 text-center border-start">
                                <Button
                                    variant={"text"}
                                    color={"error"}
                                >
                                  <Close className={`close-icons`} />{" "}
                                  Remove
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </li>
                      <li>
                        <Card className={`${styles.emptyCard}`}>
                          <CardContent className={`pd-b-16 d-flex align-items-center justify-content-center`}>
                            <Typography variant={"body1"} component={"span"} color={"secondary"} className={`font-size-sm`}>Drag & drop test here</Typography>
                          </CardContent>
                        </Card>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion className={`accordion-wrap outline-white mg-b-15`}>
                  <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className={`w-100`}
                  >
                    <Typography>
                      React-Hooks
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={`list-unstyled card-list`}>
                      <li>
                        <Card className={`${styles.selectedCard}`}>
                          <CardContent className={`pd-b-16`}>
                            <div className={`d-flex mg-b-10`}>
                              <div
                                  className={`img-asset-box img-asset-32 asset-success mg-r-10`}
                              >
                                <img
                                    className={`img-fluid`}
                                    src={IMAGES.React}
                                    alt="..."
                                />
                              </div>
                              <div>
                                <Typography
                                    variant={"h4"}
                                    component={"h4"}
                                    className={`mg-b-10 font-size-15`}
                                >
                                  TBD
                                </Typography>
                                <Typography
                                    variant={"body1"}
                                    component={"span"}
                                    color={"secondary"}
                                    className={`mg-b-10 font-size-sm`}
                                >
                                  TBD
                                </Typography>
                                <Typography
                                    variant={"body2"}
                                    className={`font-weight-medium hash-tag`}
                                >
                                  TBD
                                </Typography>
                              </div>
                            </div>
                            <div
                                className={`d-flex align-items-center justify-content-between`}
                            >
                              <Typography
                                  variant={"body2"}
                                  component={"p"}
                                  color={"secondary"}
                              >
                                TBD
                                <span className={`mg-x-10`}>|</span>
                                <span className={`text-nowrap`}>
                                            <i
                                                className={`icon-document mg-r-6`}
                                            ></i>
                          TBD
                                          </span>
                                <span className={`mg-x-10`}>|</span>
                                <span className={`text-nowrap`}>
                                            <i
                                                className={`icon-clock-time mg-r-6`}
                                            ></i>
                          TBD
                                          </span>
                              </Typography>
                              <button
                                  type={"button"}
                                  className={`btn btn-sm btn-snow mg-l-10`}
                              >
                                <i className={`icon-eye`}></i>
                              </button>
                            </div>
                          </CardContent>
                          <div className={`pd-x-16 border-top`}>

                            <div className={"row align-items-center g-0"}>
                              <div className="col-6">
                                <div className="custom-checkbox">
                                  <input
                                      className="custom-control-input"
                                      type="checkbox"
                                      id={`practice-test`}
                                      name={`practice-test`}
                                  />
                                  <label
                                      className="custom-control-label text-capitalize font-size-sm"
                                      htmlFor={`practice-test`}
                                  >
                                    Practice question?
                                  </label>
                                </div>
                              </div>
                              <div className="col-6 text-center border-start">
                                <Button
                                    variant={"text"}
                                    color={"error"}
                                >
                                  <Close className={`close-icons`} />{" "}
                                  Remove
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </li>
                      <li>
                        <Card className={`${styles.emptyCard}`}>
                          <CardContent className={`pd-b-16 d-flex align-items-center justify-content-center`}>
                            <Typography variant={"body1"} component={"span"} color={"secondary"} className={`font-size-sm`}>Drag & drop test here</Typography>
                          </CardContent>
                        </Card>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </DragDropContext>
      </div>
    </div>
  );
};

export default ChooseTestQuestions;
