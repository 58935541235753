import React from "react";
import { Box, FormControl, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  apiStatusMap,
  complexityOptions,
} from "../../../../utils/constants";
import RHFTagsController from "../../../RHFControllers/RHFTagsController";
import { useEffect } from "react";
import MuiMenuCommon from "../../../common/MuiMenuCommon/MuiMenuCommon";
import SubSkillMultiSelect from "../../../common/SubSkillMultiSelect/SubSkillMultiSelect";
import Loader from "../../../Loader/Loader";
import { fetchSkills } from "../../../../utils/redux/slices/skillSlice";

const QuestionBottom = ({ type,isEditable }) => {
  const { status, skills } = useSelector((state) => state.skills);
  const dispatch = useDispatch()

  useEffect(() => {
    if(status == apiStatusMap.idle){
      dispatch(fetchSkills());
    }
  }, [])

  if([apiStatusMap.idle, apiStatusMap.pending].includes(status)){
    return <Loader />
  }

  return (
    <>
      <div className="row mg-b-34">
        {type === "bank" && (
          <div className="col-md-6">
            <Typography
              variant={"h4"}
              component={"h4"}
              className={"mg-b-10"}
            >
              Primary Skill
            </Typography>

            <MuiMenuCommon
              options={skills}
              RHFName={"primarySkill"}
              placeholder="Select Primary Skill"
              useId={true}
            />
          </div>
        )}
        <div className="col-md-6">
          <Typography
            variant={"h4"}
            component={"h4"}
            className={"mg-b-10"}
          >
            Complexity
          </Typography>
          <MuiMenuCommon
            options={complexityOptions}
            RHFName={"complexity"}
            placeholder="Select Complexity"
            useId={true}
          />
        </div>
      </div>
      {(type === "custom") && (
        <Box>
          <Typography
            variant={"h4"}
            component={"h4"}
            className={"mg-b-10"}
          >
            Tags
          </Typography>
          <FormControl className="w-100">
            <RHFTagsController isEditable={isEditable} RHFName="tags" />
          </FormControl>
        </Box>
      )}

      {type === "bank" && (
        <Box>
          <Typography
            variant={"h4"}
            component={"h4"}
            className={"mg-b-10"}
          >
            Secondary Skills
          </Typography>
          <div>
            <FormControl className="w-100">
              {/* <RHFMultiselectCheckboxController RHFname="secondarySkills" /> */}
              <SubSkillMultiSelect skills={skills}/>
            </FormControl>
          </div>
        </Box>
      )}
    </>
  );
};

export default QuestionBottom;
