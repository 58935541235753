import { useCallback, useRef, useState } from "react";
const constraints = { audio: false, video: { width: 700, height: 300 } };

const useWebCamRecorder = () => {
  const recorderRef = useRef(null);
  const [mediaStream, setMediaStream] = useState(null);
  const [videoBlob, setVideoBlob] = useState(null);

  const start = useCallback(async () => {
    if (mediaStream) return;
    try {
      let recorder;
      const chunks = [];
      const mediaStream = await navigator.mediaDevices.getUserMedia(
        constraints
      );
      setMediaStream(mediaStream);
      recorder = new MediaRecorder(mediaStream);
      recorderRef.current = recorder;
      recorder.ondataavailable = (e) => {
        chunks.push(e.data);
        if (recorder.state === "inactive") {
          const blob = new Blob(chunks, { type: "video/webm" });
          setVideoBlob(blob);
        }
      };
      recorder.start();
    } catch (error) {
      console.log({ VideoRecorderError: error.message });
    }
  }, [mediaStream]);

  const stop = useCallback(() => {
    console.log(recorderRef.current)
    if (recorderRef.current.state === "recording") {
      recorderRef.current.stop();
      recorderRef.current = null;
    }
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
      setMediaStream(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaStream]);

  return {
    start,
    stop,
    mediaStream,
    videoBlob,
  };
};

export default useWebCamRecorder;
