export const generateVideoThumbnail = (videoBlob) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const video = document.createElement("video");
    // this is important
    video.autoplay = true;
    video.muted = true;
    video.src = URL.createObjectURL(videoBlob);

    video.onloadeddata = () => {
      let ctx = canvas.getContext("2d");

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      video.pause();
      URL.revokeObjectURL(video.src);
    //   return resolve(canvas.toDataURL("image/png"));
      return canvas.toBlob(function (blob) {
          return resolve(blob);
      })
    };
  });
};
