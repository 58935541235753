import axiosInstance from "./client";
import queryParamsToSring from "../../utils/functions/queryParamsToString";

const getReports = (queryParams) => {
  const query = queryParamsToSring(queryParams);
  return axiosInstance.get(`/api/question/reports?${query}`);
};

const getNextQuestion = (queryParams) => {
  const query = queryParamsToSring(queryParams);
  return axiosInstance.get(`/api/question/next?${query}`);
};

const QuestionService = {
  getNextQuestion,
  getReports
};

export default QuestionService;
