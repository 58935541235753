import { Card, CardContent, Typography, TextareaAutosize } from "@mui/material";
import styles from "./QuestionComments.module.scss";
import IMAGES from "../../../../assets/images/images";
import { useEffect, useState } from "react";
import QuestionListService from "../../../../services/api/QuestionListService";
import { useLocation } from "react-router-dom";
import { getDate } from "../../../../utils/constants";
import { useSnackbar } from "notistack";
import { useFormContext } from "react-hook-form";
import { useAuth } from "../../../../hooks/useAuth";
import ConfirmationDialog from "../../../common/ConfirmationDialog/ConfirmationDialog";

const QuestionComments = ({ questionId, isEditable }) => {
  const actions = { list: "list", create: "create", update: "update", delete: "delete", default: "list" };
  const { user } = useAuth();
  const { setValue } = useFormContext();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState({
    isLoading: false,
    comments: [],
    commentText: { create: "", update: "" },
    action: { type: actions.default, payload: null },
    isConfirmDialogOpen: false
  });

  useEffect(() => {
    if(data.action.type == actions.list){
      getAllComments(questionId)
    }
  }, [data.action])

  const setCommentText = (comment, action) => setData(preData => ({...preData, commentText: { ...preData.commentText, [action]: comment }}));
  const setConfirmDiaLog = (isConfirmDialogOpen) => setData(preData => ({...preData, isConfirmDialogOpen}));
  const setUpdateAction = (comment) => setData(preData => ({...preData, commentText: {...preData.commentText, update: comment.comment }, action: { type: actions.update, payload: { comment }}}));
  const setDeleteAction = (comment) => setData(preData => ({...preData, isConfirmDialogOpen: true, action: { type: actions.delete, payload: { comment }}}));
  const setListAction = (preAction) => setData(preData => ({...preData, isConfirmDialogOpen: false, isLoading: false, commentText: { ...preData.commentText, ...(preAction && {[preAction]: ""}) }, action: { type: actions.list, payload: null  }}));
  const setComments = (comments) => setData(preData => ({...preData, comments}));
  const setLoading = (isLoading) => setData(preData => ({...preData, isLoading}));

  const getAllComments = (id) => {
    QuestionListService.getComments(id).then((res) => {
      if(!res?.data.result) throw new Error(res?.data.msg);
      setComments(res?.data?.data.comments)
    })
    .catch(err => { setLoading(false) })
    .finally(() => { })
  };

  const handleCreateComment = async(comment) => {
    QuestionListService.postComment(questionId, comment).then(() => {
      setListAction(actions.create)
    }).catch(err => { setLoading(false) })
  }

  const handleUpdateComment = async(commentId, comment) => {
    QuestionListService.updateComment(questionId, commentId, comment).then(() => {
      setListAction(actions.update)
    }).catch(err => { setLoading(false) })
  }

  const handleDeleteComment = async() => {
    const commentId = data.action.payload.comment._id; 
    QuestionListService.deleteComment(questionId, commentId).then(() => {
      setListAction()
    }).catch(err => { setLoading(false) })
  }

  useEffect(() => {
    if (isEditable) {
      const id = /[^/]*$/.exec(location.pathname)[0];
      getAllComments(id);
    }
  }, []);

  const handleCommentChange = (e, action) => {
    setCommentText(e.target.value, action);
    setValue("comment", e.target.value,{ shouldDirty: true });
  };

  const handleCommentOnEnterPress = (e, action) => {
    if (e.key !== "Enter" || e.shiftKey) {
      return;
    }
    setLoading(true);
    e.preventDefault();
    const _comment = data.commentText[action].trim();
    if (_comment.length < 3) {
      enqueueSnackbar("Minimum 3 characters required", {
        variant: "error",
      });
      setLoading(false);
      return;
    }
    if (_comment.length > 300) {
      enqueueSnackbar("Maximum length should 250 characters", {
        variant: "error",
      });
      setLoading(false);
      return;
    }
    if(!isEditable){
      setLoading(false);
      return;
    }

    if(action == actions.create){
      handleCreateComment(_comment);
    }
    else if(action == actions.update){
      handleUpdateComment(data.action.payload.comment._id, _comment)
    }
  };
  const getTime = (new_date) => {
    const date = new Date(new_date);
    const hoursAndMinutes = date.getHours() + ":" + date.getMinutes();
    return hoursAndMinutes;
  };

  return (
    <>
      <Typography
          variant={"h3"}
          component={"h3"}
          className={`font-size-base mg-b-20`}
      >
        Comments
      </Typography>
      <ul className={`list-unstyled ${styles.commentList} mg-b-0`}>
        <li className={`d-flex align-items-start`}>
          <div className={`img-asset img-asset-32 mg-r-10`}>
            <img
                className={"img-fluid"}
                src={IMAGES.ProfileEmptySquare}
                alt="profile"
            />
          </div>
          <div style={{ flex: 1 }}>
            <TextareaAutosize
                maxRows={7}
                disabled={data.isLoading}
                onChange={(e) => handleCommentChange(e, actions.create)}
                onKeyDown={(e)=>handleCommentOnEnterPress(e, actions.create)}
                type={`text`}
                className={`form-control form-control-lg`}
                placeholder={`Write a comment here...`}
                value={data.commentText.create}
            />
          </div>
        </li>
        <div style={{ maxHeight: "300px", overflow: "auto" }}>
          {data.comments?.map((comment,index) => (
              <li className={`d-flex align-items-start`} key={index}>
                <div className={`img-asset img-asset-32 mg-r-10`}>
                  <img
                      className={"img-fluid"}
                      src={IMAGES.ProfileEmptySquare}
                      alt="profile"
                  />
                </div>
                <div>
                  <div
                      className={`d-flex align-items-center flex-wrap mg-b-4`}
                  >
                    <Typography
                        variant={"h4"}
                        component={"h4"}
                        className={`mg-r-10`}
                    >
                      {comment.userId.firstName +
                      " " +
                      comment.userId.lastName}
                    </Typography>
                    <Typography
                        variant={"body2"}
                        color={"secondary"}
                        component={"p"}
                        className={`${styles.fontSize11}`}
                    >
                      {`${getDate(comment.createdAt)} at ${getTime(
                          comment.createdAt
                      )}`}
                    </Typography>
                  </div>
                  {data.action.type !== actions.update && (
                      <Typography
                          variant={"body1"}
                          component={"pre"}
                          className={`font-size-sm`}
                      >
                        {comment.comment}
                      </Typography>
                  )}
                  {user._id == comment.userId._id ? (
                      <>
                        {((data.action.type == actions.update) && (comment._id == data.action.payload.comment._id)) ? (
                            <div style={{ flex: 1 }}>
                            <textarea
                                onChange={(e)=>handleCommentChange(e, actions.update)}
                                onKeyDown={(e)=>handleCommentOnEnterPress(e, actions.update)}
                                type={`text`}
                                className={`form-control form-control-lg`}
                                placeholder={`Write a comment here...`}
                                value={data.commentText.update}
                            ></textarea>
                            </div>
                        ) : (
                            <>
                              <button className={"btn btn-link p-0 mg-r-10 text-decoration-none"} onClick={() => setUpdateAction(comment)}>Edit</button>
                              <button className={"btn btn-link p-0 mg-r-10 text-decoration-none"} onClick={() => setDeleteAction(comment)}>Delete</button>
                            </>)}
                      </>
                  ) : (
                      <Typography
                          variant={"body1"}
                          component={"pre"}
                          className={`font-size-sm`}
                      >
                        {comment.comment}
                      </Typography>
                  )}
                </div>
              </li>
          ))}
        </div>
      </ul>
      <ConfirmationDialog
        open={data.isConfirmDialogOpen}
        setOpen={setConfirmDiaLog}
        title="Are you sure want to Delete"
        subtitle=""
        okButtonText="Delete"
        cancelButtonText="Cancel"
        confirmAction={handleDeleteComment}
      />
    </>
  );
};

export default QuestionComments;
