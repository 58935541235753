import React from "react";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { useState } from "react";
// import { useEffect } from "react";

const MuiFilterMenu = ({ options, RHFName, placeholder }) => {
  const methods = useFormContext();
  const { setValue, control } = methods;

  const filterWatch = useWatch({
    control,
    name: RHFName,
  });
  // const [selectedRole, setSelectedRole] = useState("");

  // useEffect(() => {
  //   if (filterWatch==='') {
  //     setSelectedRole(placeholder);
  //   }
  // }, [filterWatch]);

  const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);
  const openCategory = Boolean(categoryAnchorEl);

  const handleCategoryOpen = (event) => {
    setCategoryAnchorEl(event.currentTarget);
  };

  const categoryClose = () => {
    setCategoryAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    setValue(RHFName, option);
    categoryClose();
  };

  const isEmptyObject = () => {
    return (
      Object.keys(filterWatch).length === 0 &&
      filterWatch.constructor === Object
    );
  };

  return (
    <>
      <Button
        onClick={handleCategoryOpen}
        className={`btn-toggle w-100`}
        variant={"outlined"}
        color={"secondary"}
        size={"medium"}
      >
        {!isEmptyObject() ? (
          <Typography
            noWrap
            className={`mg-r-4 pd-y-0 font-weight-medium font-size-sm  
            ${isEmptyObject()? 'text-secondary': 'text-dark'}`}
            component={"span"}
          >
            {filterWatch.label}
          </Typography>
        ) : (
          <Typography
            noWrap
            className="mg-r-4 pd-y-0 font-weight-medium font-size-sm"
            sx={{ color: "#9FA2B4" }}
          >
            {placeholder}
          </Typography>
        )}

        {openCategory ? (
          <KeyboardArrowUp className="toggle-icon" />
        ) : (
          <KeyboardArrowDown className="toggle-icon" />
        )}
      </Button>
      <Menu
        id="role-menu"
        anchorEl={categoryAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openCategory}
        onClose={categoryClose}
      >
        {options?.map((option) => {
          return (
            <MenuItem
              key={option._id}
              selected={option.label === filterWatch.label}
              onClick={() => handleMenuItemClick(option)}
            >
              <Typography textAlign="center">
                <i className={`${option.iconName} mg-r-10`}></i>
                {option.label}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default MuiFilterMenu;
