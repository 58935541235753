import { useNavigate } from "react-router-dom";
import { userService } from "../../services";
import { cognitoAuthTokenAPI, transformUser } from "../../utils/constants";
import RoutingPaths from "../../utils/RoutingPaths";
import jwt_decode from "jwt-decode";
import { useAuth } from "../../hooks/useAuth";
import axiosInstance from "../../services/api/client";
import axios from "axios";

const GoogleSignIn = ({ authCode }) => {
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const payload = new URLSearchParams({
    grant_type: "authorization_code",
    client_id: process.env.REACT_APP_CLIENT_ID,
    code: authCode,
    redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}`,
  });

  const headers = {
    "content-Type": "application/x-www-form-urlencoded",
  };

  const data = payload.toString();
  const fetchTokens = async () => {
    try {
      const response = await axios.post(cognitoAuthTokenAPI, data, {
        headers: headers,
      });
      const id_token = response.data.id_token;
      const refresh_token = response.data.refresh_token;

      localStorage.setItem("accessToken", JSON.stringify(id_token));
      localStorage.setItem("refreshToken", JSON.stringify(refresh_token));

      const decoded = jwt_decode(id_token);
      const fName = decoded["given_name"];
      const lName = decoded["family_name"];
      const userObj = {
        email: decoded["email"],
        userName: decoded["cognito:username"],
        firstName: fName,
        lastName: lName,
      };
      try {
        const res = await userService.search(userObj.email);
        setUser(transformUser(res.data.data));
        navigate(RoutingPaths.Dashboard);
      } catch (error) {
        navigate(RoutingPaths.GenericSignUp, {
          state: { userObj: userObj },
        });
      }
    } catch (error) {}
  };

  if (authCode) {
    fetchTokens();
  }
  return;
};

export default GoogleSignIn;
