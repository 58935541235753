import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Tab,
  Typography,
} from "@mui/material";
import IMAGES from "../../../../assets/images/images";
import React, { useState } from "react";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { FileUploader } from "react-drag-drop-files";
import IntroOutroVideoDrawer from "../IntroOutroVideoDrawer/IntroOutroVideoDrawer";
import useWebCamRecorder from "../../../HomePage/NewQuestion/VideoQuestion/useWebCamRecorder";
import { useEffect } from "react";
import { useRef } from "react";
import { useFormContext } from "react-hook-form";
import FileInput from "../../../Dropzone/FileInput";
import {
  getUploadUrlAndObjectUrl,
  uploadFileToAWS,
} from "../../../../services/api/aws";
import VideoService from "../../../../services/api/VideoService";
import { useDispatch, useSelector } from "react-redux";
import {
  addInVideoQuestionLibraryAction,
  addVideoQuestionAction,
} from "../../../../utils/redux/slices/videoQuestionSlice";
import { generateVideoThumbnail } from "../../../../utils/functions/generateVideoThumbnail";
// import Loader from "../../../Loader/Loader";
// const fileTypes = ["JPEG", "PNG", "GIF", "jpg", "docx", "pdf"];
const RecordVideoDialog = (props) => {
  const { open, setOpen } = props;
  const [recordVideoTabValue, setRecordVideoTabValue] = useState("upload");
  const [openVideoLibrary, setOpenVideoLibrary] = useState(false);
  const handleVideoLibrary = () => {
    setOpenVideoLibrary(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleRecordVideoTabChange = (event, newValue) => {
    setRecordVideoTabValue(newValue);
  };
  const { setValue, getValues } = useFormContext();
  const values = getValues();
  // console.log(values.videoBlob);

  // const [file, setFile] = useState(null);
  // const handleChange = (file) => {
  //   setFile(file);
  // };
  const videoRef = useRef(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const { start, stop, mediaStream, videoBlob } = useWebCamRecorder();
  const [videoTitle, setVideoTitle] = useState("");
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const getVideoId = async () => {
    setLoader(true);
    if (values.videoBlob) {
      const videoThumbnailBlob = await generateVideoThumbnail(values.videoBlob);
      const [x, y] = await Promise.all([
        getUploadUrlAndObjectUrl(values.videoBlob.type),
        getUploadUrlAndObjectUrl(videoThumbnailBlob.type),
      ]);
      const { objectUrl: videoUrl, key: videoKey, preSignedUrl } = x;
      const { objectUrl: videoThumbnail, preSignedUrl: thumbnailUploadUrl } = y;

      await Promise.all([
        uploadFileToAWS(preSignedUrl, values.videoBlob.type, values.videoBlob),
        uploadFileToAWS(
          thumbnailUploadUrl,
          videoThumbnailBlob.type,
          videoThumbnailBlob
        ),
      ]);

      const res = await VideoService.createVideo({
        title: videoTitle ? videoTitle : "Intro/Outro Video",
        detail: "dummy detail",
        videoKey,
        videoUrl,
        videoThumbnail,
      });
      if (res.data.result === 1) {
        dispatch(addVideoQuestionAction(res?.data?.data));
        setLoader(false);
        handleCloseModal();
      }
    }
  };

  useEffect(() => {
    if (mediaStream) {
      let video = videoRef.current;
      video.srcObject = mediaStream;
      video.onloadedmetadata = function (e) {
        video.play();
      };
    }
  }, [mediaStream]);
  // it(useEffect) is only for verify recording
  useEffect(() => {
    if (videoBlob) {
      const url = URL.createObjectURL(videoBlob);
      setValue("videoBlob", videoBlob);
      setVideoUrl(url);
    }
    return () => {
      URL.revokeObjectURL(videoUrl);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoBlob]);
  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal-box">
        <Box className="modal-content tabs-wrap question-tabs">
          <TabContext value={recordVideoTabValue}>
            <Box
              className={`d-flex align-items-center justify-content-between pd-x-30 pd-t-10 border-bottom`}
            >
              <div className={`d-flex align-items-center`}>
                <Typography
                  id="modal-modal-title"
                  variant="h2"
                  component="h2"
                  className={`mg-r-20`}
                >
                  Record Video
                </Typography>

                <Box sx={{ borderBottom: 1, borderColor: "transparent" }}>
                  <TabList
                    onChange={handleRecordVideoTabChange}
                    aria-label="question tabs"
                  >
                    <Tab label="Upload Video" value="upload" />
                    <Tab label="Record Video" value="record" />
                  </TabList>
                </Box>
              </div>
              <button
                type={`button`}
                className={`btn btn-link text-decoration-none`}
                onClick={handleCloseModal}
              >
                <img src={IMAGES.XIcon} alt="close" />
              </button>
            </Box>
            <Box className={`pd-x-30 pd-t-10 pd-b-30`}>
              <TabPanel value="upload">
                <div>
                  <input
                    type="text"
                    className="form-control form-control-lg mg-b-10"
                    placeholder="Video title"
                    value={videoTitle}
                    onChange={(e) => setVideoTitle(e.target.value)}
                  />
                  <div className="card mg-b-20">
                    <div className="card-body">
                      <div className={`d-flex align-items-center flex-column`}>
                        {/* <FileUploader
                          multiple={false}
                          name="file"
                          children={
                            <div
                              className={`upload-circle d-flex align-items-center justify-content-center mg-b-10`}
                            >
                              <i className="icon-arrow-solid-up"></i>
                            </div>
                          }
                          handleChange={handleChange}
                          types={fileTypes}
                        /> */}
                        {/* {file ? (
                          <Typography
                            variant={"body1"}
                            color={"secondary"}
                            className={`mg-b-10`}
                          >
                            {console.log(file)}
                            <video width="320" height="180" controls>
                              <source src={videoUrl} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                            {file.name}
                          </Typography>
                        ) : (
                          <Typography
                            variant={"body1"}
                            color={"secondary"}
                            className={`mg-b-10`}
                          >
                            Drag file to upload, or
                          </Typography>
                        )} */}

                        {loader ? (
                          <Box
                            sx={{
                              height: "10vh",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        ) : (
                          <FileInput
                            videoUrl={videoUrl}
                            setVideoUrl={setVideoUrl}
                            accept="image/png, image/jpg, image/jpeg, image/gif"
                            name="videoBlob"
                            label="File Upload"
                            mode="append"
                            component={
                              <div
                                className={`upload-circle d-flex align-items-center justify-content-center mg-b-10`}
                              >
                                <i className="icon-arrow-solid-up"></i>
                              </div>
                            }
                          />
                        )}

                        <div className={"d-flex align-items-center pd-y-10"}>
                          <div className="btn btn-link pd-x-0 pd-y-0 position-relative">
                            {/* <FileInput
                              videoUrl={videoUrl}
                              setVideoUrl={setVideoUrl}
                              accept="image/png, image/jpg, image/jpeg, image/gif"
                              name="videoBlob"
                              label="File Upload"
                              mode="append"
                              component={<Typography>Browse</Typography>}
                            /> */}
                            {/* <FileUploader
                              multiple={false}
                              name="file"
                              children={<Typography>Browse</Typography>}
                              handleChange={handleChange}
                              types={fileTypes}
                            /> */}
                            {/* <input name="file" type="file" className="hidden-input" /> */}
                          </div>
                          <Typography
                            variant={"body1"}
                            component={"span"}
                            className={`mg-x-10`}
                            color={"secondary"}
                          >
                            or
                          </Typography>
                          <IntroOutroVideoDrawer
                            open={openVideoLibrary}
                            setOpen={setOpenVideoLibrary}
                          />
                          <Button
                            variant={"contained"}
                            color={"primary"}
                            className={`text-white rounded-pill`}
                            onClick={handleVideoLibrary}
                          >
                            Choose from library
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`d-flex justify-content-end`}>
                    {/* <Button
                      variant={"contained"}
                      color={"primary"}
                      className={`mg-r-10`}
                      onClick={}
                    >
                      Add
                    </Button> */}
                    <Button
                      onClick={handleCloseModal}
                      variant={"contained"}
                      color={"error"}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      className={`mg-l-10`}
                      onClick={getVideoId}
                    >
                      Ok
                    </Button>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="record">
                <div>
                  <input
                    type="text"
                    className="form-control form-control-lg mg-b-10"
                    placeholder="Video title"
                  />
                  <Box className={`video-record mg-b-10`}>
                    <video
                      ref={videoRef}
                      className={`video`}
                      width="400"
                      controls
                    >
                      <source src="" type="video/mp4" />
                      <source src="" type="video/ogg" />
                      Your browser does not support HTML video.
                    </video>
                    {/* <Box className={`record-control-bar text-center`}>
                      <button
                        type={"button"}
                        className={"btn btn-link text-decoration-none"}
                      >
                        <img src={IMAGES.BtnPlay} alt="play" />
                      </button>
                      <Typography
                        className={`stream-time text-white font-family-roboto`}
                      >
                        00:00 / 1:00
                      </Typography>
                    </Box> */}
                  </Box>
                  <div className={`row g-2`}>
                    <div className="col">
                      <Button
                        onClick={start}
                        variant={"outlined"}
                        color={"secondary"}
                        className={`text-nowrap btn-dark font-weight-medium record-circle`}
                      >
                        Start Recording
                      </Button>
                    </div>
                    <div className="col-auto">
                      <Button
                        onClick={stop}
                        variant={"contained"}
                        color={"error"}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={stop}
                        variant={"contained"}
                        color={"primary"}
                        className={`mg-l-10`}
                      >
                        Ok
                      </Button>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Box>
          </TabContext>
        </Box>
      </Box>
    </Modal>
  );
};

export default RecordVideoDialog;
