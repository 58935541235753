import React, { createContext } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "../../utils/UserPool";
import { useNavigate } from "react-router-dom";
import RoutingPaths from "../../utils/RoutingPaths";
import axios from "axios";
const AccountContext = createContext();

const Account = (props) => {


  let navigate = useNavigate();
  const getSession = async () =>
    await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      console.log(user)
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject();
          } else {
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                } else {
                  const results = {};
                  for (let attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }
                  resolve(results);
                }
              });
            });

            resolve({
              user,
              ...session,
              ...attributes,
            });
          }
        });
      } else {
        reject();
      }
    });

  const authenticate = (Username, Password) => {
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool: Pool,
      });

      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log(data)
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
        },
        newPasswordRequired: (data) => {
          resolve(data);
        },
      });
    });
  };

  const logout = () => {
    window.location.reload();
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
    }
    const accessToken = JSON.parse(localStorage.getItem("accessToken"));
    const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));

    if (accessToken) {
      localStorage.removeItem("accessToken");
    }
    if (refreshToken) {
      localStorage.removeItem("refreshToken");
    }
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    if (authUser) {
      localStorage.removeItem("authUser");
    }
    navigate(RoutingPaths.Login);
  };
  return (
    <AccountContext.Provider value={{ authenticate, getSession, logout }}>
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
