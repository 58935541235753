import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import {
  useFormContext,
  useWatch,
} from "react-hook-form";

import {useSelector} from 'react-redux'

function LanguageSelectionDialog(props) {
    const languages = useSelector((state) => state.Questions.languages);
    const { onClose, open } = props;
    const methods = useFormContext();
    const {getValues}=methods
    const values=getValues("languages")

    const handleClose = () => {
      onClose();
    };
  
    const handleListItemClick = (value) => {
      props.appendLanguage(value)
      onClose(value);
      
    };

    const filteredLanguages=()=>{
      const selectedLanguages=values.map(({languageId})=>languageId)
      return languages.filter(({id})=>!selectedLanguages.includes(id))
    }
  
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Select Language</DialogTitle>
        <List sx={{ pt: 0 }}>
          {filteredLanguages().map((item,index) => (
            <ListItem button onClick={() => handleListItemClick(item)} key={item.id}>
              {/* <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar> */}
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    );
  }
  
  LanguageSelectionDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    // selectedValue: PropTypes.string.isRequired,
  };

export default LanguageSelectionDialog