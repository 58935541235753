import React from "react";
import { Controller, useFormContext } from "react-hook-form";

function RHFTextAreaController({ RHFname, type, placeholder, className }) {
  const methods = useFormContext();
  const { control } = methods;

  return (
    <Controller
      name={RHFname}
      control={control}
      render={({ field: { onChange, value } }) => (
        <>
          <textarea
            cols="10"
            rows="10"
            type={type}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            className={className}
            style={{ minHeight: "84px" }}
          />
        </>
      )}
    />
  );
}

export default RHFTextAreaController;
