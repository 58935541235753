import axios from "axios";
import { toHour, toMinutes } from "../../../utils/functions/dateTime";

export const getAssessmentById = async (id, setter) => {
    try {
        const res = await axios.get(`/api/assessment/${id}`);
        const modifiedData = {
            ...res?.data?.data,
            assesmentName: res?.data?.data?.title,
            durationMinute: toMinutes(res?.data?.data?.time),
            durationHour: toHour(res?.data?.data?.time),
            totalTime: +res?.data?.data?.time / 60,
        }
        setter(modifiedData);
    } catch (err) {
        console.error(err)
    }
};

export const getQuestions = async (pageNo = 1, pageSize = 10, selectedQuestions, values, setter, cb = () => { }) => {
    try {

        const res = await axios.post(`/api/question/search?page=${pageNo}&perPage=${pageSize}`, {
            // "tags": ["code", "programming"],
            title: values?.questionSearch,
            // "category": "",
            questionType: "MULTIPLE_CHOICE",
            // "level": 1,
            // "language": "62cfee173ed35c574d756a00"
        });
       
        setter([...res?.data?.data]?.map(item => ({ ...item, isChecked: values?.[selectedQuestions]?.includes(item._id) })));
        cb?.(res?.data);
    } catch (err) {
        setter([])

    }

}

export const createAssesmentPOST = async (values, cb) => {
    try {

        const res = await axios.post('/api/assessment', {
            title: values?.assesmentName,
            jobRole: values?.jobRole,
            description: values?.description,
            technology: values?.technology,
            experience: 3,
            skills: values?.skills,
            qualifyingQuestions: values?.qualifyingQuestions,
            questions: values?.questions,
            time: values?.totalTime * 60, // to seconds
            preferedLanguage: "English",
            welcomeVideo: values?.welcomeVideo,
            isAntiCheatingSettingEnabled: values?.isAntiCheatingSettingEnabled,
            allowAccommodation: [
                {
                    "for": "accommodation type",
                    "isAllowed": true
                }
            ]
        });
        cb?.(res?.data?.msg);
    } catch (err) {
     
    }
}

export const editAssesmentPUT = async (values, assessmentId, cb) => {
    try {
        // if (values?.questions?.length === 0) {
        //     return alert("Please select at least one question");
        // }
        const res = await axios.put(`/api/assessment/${assessmentId}`, {
            title: values?.assesmentName,
            jobRole: values?.jobRole,
            description: values?.description,
            technology: values?.technology,
            // experience: 3,
            skills: values?.skills,
            qualifyingQuestions: values?.qualifyingQuestions,
            questions: values?.questions,
            time: values?.totalTime * 60, // to seconds
            preferedLanguage: "English",
            welcomeVideo: values?.welcomeVideo,
            isAntiCheatingSettingEnabled: values?.isAntiCheatingSettingEnabled,
            allowAccommodation: [
                {
                    "for": "accommodation type",
                    "isAllowed": true
                }
            ]
        });
        cb?.(res?.data?.msg);
    } catch (err) {
        
    }
}