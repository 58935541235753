import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneIcon from "@mui/icons-material/Done";
import {
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { candidateStatusMap, getDate } from "../../../../utils/constants";



const iconMap = {
  PENDING: <DoneIcon sx={{ color: "gray", marginLeft: "3px" }} />,
  IN_PROGRESS: <DoneAllIcon sx={{ color: "gray", marginLeft: "3px" }} />,
  COMPLETED: <DoneAllIcon sx={{ color: "blue", marginLeft: "3px" }} />,
};

export default function MouseHoverStatus({ currentStatus, statusLog }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <span
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {iconMap[currentStatus]}
      </span>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        //   display:'flex',
        //   alignItems:'center',
        //   flexDirection:'column' 
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <List
          sx={{ width: "100%",minWidth:160, bgcolor: "background.paper"}}
        >
          {statusLog.map(({ createdAt, status,_id }) => {
            return (
              <ListItem key={_id}>
                <ListItemText primary={candidateStatusMap[status]} secondary={getDate(createdAt)} />
              </ListItem>
            );
          })}
        </List>
        {/* <Typography sx={{ p: 1 }}>I use Popover.</Typography> */}
      </Popover>
    </>
  );
}
