import React, { useState } from "react";
import Header from "../Header/Header";
import {
  Box,
  Button,
  Card,
  CardContent, Chip,
  Menu,
  MenuItem, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Typography,
} from "@mui/material";
import IMAGES from "../../assets/images/images";
import {Close, KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import styles from "./TestLibrary.module.scss";
import RoutingPaths from "../../utils/RoutingPaths";
import { Link } from "react-router-dom";
import DetailsDialog from "../common/DetailsDialog/DetailsDialog";
import MuiFilterMenu from "../common/MuiFilterMenu/MuiFilterMenu";
import {
  complexityOptions, complexityTypeMap, getDate, getTimeInMinSec,
  isEmptyObject,
  questionCategory,
  questionStatusFilterOptions, questionTypeMap,
  Roles, statusMap, statusOfQuestion, truncateString
} from "../../utils/constants";
import {Controller} from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import {
  setComplexityFilter, setCreatedByFilterAction,
  setPrimarySkillFilterAction, setQuestionTypeFilterAction,
  setSecondarySkillFilterAction,
  setStatusFilterAction
} from "../../utils/redux/slices/filterSlice";
import Loader from "../Loader/Loader";
import QuestionStatusMenu from "../QuestionStatusMenu/QuestionStatusMenu";
import QBMoreMenu from "../HomePage/QuestionBankList/QBMoreMenu/QBMoreMenu";
import TestLibraryMoreMenu from "./TestLibraryMoreMenu/TestLibraryMoreMenu";
import TestLibraryStatusMenu from "./TestLibraryStatusMenu/TestLibraryStatusMenu";

const TestLibrary = () => {
  const[openDetailsDialog,setOpenDetailsDialog] = useState(false)
  const [stageAnchorEl, setStageAnchorEl] = useState(null);
  const openStage = Boolean(stageAnchorEl);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const openFilter = Boolean(filterAnchorEl);
  const handleStageClose = () => {
    setStageAnchorEl(null);
  };
  const handleStageOpen = (event) => {
    setStageAnchorEl(event.currentTarget);
  };
  return (
    <>
      <Header />
      <div className={`page-spacing pd-y-20`}>
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between flex-wrap mg-b-10">
            <div className={`d-flex align-items-center`}>
              <div className={"d-flex align-items-center flex-wrap"}>
              {/*  filter and filter badge HTML will go here*/}
              {/*  Take Reference from Question Bank Filter*/}
              </div>
            </div>
            <div className={`d-flex align-items-center flex-wrap`}>
              <Link to={RoutingPaths.CreateTest} className={'text-decoration-none'}>
              <Button
                  className={`text-nowrap`}
                  variant={"contained"}
                  color={"primary"}
                  size={"medium"}
              >
                <i className={`icon-plus mg-r-6`}></i>
                New Test
              </Button>
            </Link>
            </div>
          </div>
          <Card className={'card-table'}>
            <CardContent className="pd-x-0 pd-y-0">
              <div className={`search-with-icon search-header`}>
                <img src={IMAGES.Search} className="search-icon" alt="search" />
                <input
                    type="search"
                    className="form-control form-control-sm border-0"
                    placeholder="Search"
                />
              </div>
              <TableContainer className="custom-table">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Duration (Minutes)</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Created By</TableCell>
                      <TableCell>REVIEWED By</TableCell>
                      <TableCell>Complexity</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell className="pd-r-32"></TableCell>
                    </TableRow>
                  </TableHead>
                  {true ? (
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <div className={`d-flex align-items-center mg-b-10`}>
                              <div
                                  className={`img-asset-box img-asset-50 asset-bg mg-r-10`}
                              >
                                <img
                                    className={`img-fluid`}
                                    src={IMAGES.React}
                                    alt="..."
                                />
                              </div>
                              <div>
                                <Typography
                                    variant={"h4"}
                                    component={"h4"}
                                    className={`mg-b-4 font-size-15`}
                                >
                                  {truncateString('Beginner level algorithms for Software', 40)}
                                </Typography>
                                <Typography
                                    variant={"body2"}
                                    color={"secondary"}
                                >
                                  {truncateString('React-Hooks, Flexbox, Redux', 40)}
                                </Typography>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>32 min</TableCell>
                          <TableCell>Short Text</TableCell>
                          <TableCell>Arun Kumar</TableCell>
                          <TableCell>Subham Agarwal</TableCell>
                          <TableCell>Intermediate</TableCell>
                          <TableCell>
                            <TestLibraryStatusMenu options={statusOfQuestion}/>
                          </TableCell>
                          <TableCell>
                            <TestLibraryMoreMenu/>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                  ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={9}>
                            No Data Found!
                          </TableCell>
                        </TableRow>
                      </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
          <Box
              className={`d-flex justify-content-between align-items-center mg-t-30`}
          >
            {true && (
                <Typography variant={"body2"} color={"secondary"}>
                  Showing TND to{" "}
                  TBD{" "}
                  of TBD entries
                </Typography>
            )}

            {true && (
                <Pagination
                    color={"primary"}
                    className={"custom-pagination"}
                    shape="rounded"
                    count={10}
                    page={1}
                    variant="outlined"
                />
            )}
          </Box>
        </div>
      </div>
    </>
  );
};

export default TestLibrary;
