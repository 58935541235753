import React from "react";
import {Card, CardContent, Box, Typography, Grid} from "@mui/material";
import IMAGES from "../../../../assets/images/images";
import styles from "./QuestionFooter.module.scss";

const QuestionFooter = () =>{
    return(
        <div className="row">
            <div className="col-md-4">
                <Card className="position-relative mg-b-8">
                    <img className={styles.imgShapes} src={IMAGES.Shape1} alt="shape1"/>
                    <CardContent className="pd-x-30 pd-y-18">
                        <Box className={`${styles.questionContent} d-flex align-items-center`}>
                            <Typography className={`font-weight-semi-bold ${styles.hasCircle} ${styles.danger}`}>
                                How should I format my questions?
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </div>
            <div className="col-md-4">
                <Card className="position-relative mg-b-8">
                    <img className={styles.imgShapes} src={IMAGES.Shape2} alt="shape2"/>
                    <CardContent className="pd-x-30 pd-y-18">
                        <Box className={`${styles.questionContent} d-flex align-items-center`}>
                            <Typography className={`font-weight-semi-bold ${styles.hasCircle} ${styles.success}`}>
                                How do I use the <br/>question editor?
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </div>
            <div className="col-md-4">
                <Card className="position-relative mg-b-8">
                    <img className={styles.imgShapes} src={IMAGES.Shape3} alt="shape3"/>
                    <CardContent className="pd-x-30 pd-y-18">
                        <Box className={`${styles.questionContent} d-flex align-items-center`}>
                            <Typography className={`font-weight-semi-bold ${styles.hasCircle} ${styles.primary}`}>
                                How do I use the <br/>formula editor?
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default QuestionFooter;