import { MenuItem, TextField } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const customStyles = {
  control: (base) => ({
    ...base,
    height: 50,
    minHeight: 35,
  }),
};

function SelectDropDownController({ name, label,options,disabled }) {
 
  const methods = useFormContext();
  const { control } = methods;
  return (
    <Controller
      name={name}
      render={({ field }) => (
        <TextField
          select
          styles={customStyles}
          label={label}
          sx={{ width: "100%" }}
          {...field}
          disabled={disabled}
        >
          {options?.map((lang, index) => {
            return (
              <MenuItem key={lang.id} value={lang.value}>
                {lang.title}
              </MenuItem>
            );
          })}
        </TextField>
      )}
      control={control}
      defaultValue=""
    />
  );
}

export default SelectDropDownController;
