import React, { useMemo } from "react";
import Header from "../../../Header/Header";
import styles from "../MyAssesment.module.scss";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  IconButton,
  InputBase,
  Paper,
  Popover,
  Popper,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import TextFieldController from "../../../CodeEditor/DynamicCodeEditor/MultiLanguageSelection/Controller/TextFieldController";
import ReturnTypeController from "../../../CodeEditor/DynamicCodeEditor/MultiLanguageSelection/Controller/ReturnTypeController";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getDataTypesAsync } from "../../../../utils/redux/slices/QuestionSlice";
import DropdownChipController from "../../../CodeEditor/DynamicCodeEditor/MultiLanguageSelection/Controller/DropdownChipController";
import RangeSlider from "../../../CodeEditor/DynamicCodeEditor/MultiLanguageSelection/Controller/RangeSliderController";
import { Link, useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TableComponent from "./TableComponent";
import RatingComponent from "./RatingComponent";
import PersonIcon from "@mui/icons-material/Person";
import { useState } from "react";
import IconPerson from "../../../../Files/img/IconPerson.svg";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SlideDrawer from "../SliderDrawer";
import BackDrop from "../BakcDrop";
import {
  candidateListsAPI,
  hiringStatusAPI,
  hiringStatusDropdownAPI,
} from "../../../../utils/constants";
import axios from "axios";
import CandidateTable from "./CandidateTable";
import debounce from "lodash.debounce";

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
  pagination: {
    "& p": {
      marginBottom: "0",
    },
  },
});

const ViewCandidateList = () => {
  // const [pageNo, setPageNo] = useState(0);
  // const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentRowAssessmentId, setCurrentRowAssessmentId] = useState(null);
  const [candidateLists, setCandidateLists] = useState([]);
  const assessmentId = useParams();
  const [hiringStatus, setHiringStatus] = useState([]);
  // console.log(assessmentId.id);

  useEffect(() => {
    dispatch(getDataTypesAsync());
    function fetchCandidateLists() {
      axios.get(candidateListsAPI).then((response) => {
        if (response.status === 200) {
          setCandidateLists(response.data.data.candidates);
          console.log(response.data.data);
        }
      });
    }
    function fetchHiringStatus() {
      axios.get(hiringStatusDropdownAPI).then((response) => {
        if (response.status === 200) {
          setHiringStatus(response.data.data);
        }
      });
    }
    fetchHiringStatus();
    fetchCandidateLists();
  }, []);

  // useEffect(() => {
  //   if (candidateLists.length) {
  //     const data = [
  //       {
  //         hiringStage: null,
  //         _id: "63241a7d92e9913fbccca668",
  //         inviteId: "63241a7d92e9913fbccca665",
  //         assessmentId: {
  //           _id: "6324175f43cf571e7024f4c7",
  //           title: "Test Assessment 1",
  //         },
  //         firstName: "Pankaj",
  //         lastName: "Pratap",
  //         currentStatus: {
  //           _id: "63241a7d92e9913fbccca669",
  //           status: "PENDING",
  //           createdAt: "2022-09-16T06:41:01.489Z",
  //         },
  //         statusLog: [
  //           {
  //             _id: "63241a7d92e9913fbccca66a",
  //             status: "PENDING",
  //             createdAt: "2022-09-16T06:41:01.489Z",
  //           },
  //         ],
  //         createdAt: "2022-09-16T06:41:01.501Z",
  //         updatedAt: "2022-09-16T08:34:08.885Z",
  //         rating: 5,
  //       },
  //       {
  //         hiringStage: null,
  //         _id: "63241a7d92e9913fbccca668",
  //         inviteId: "63241a7d92e9913fbccca665",
  //         assessmentId: {
  //           _id: "6324175f43cf571e7024f4c7",
  //           title: "Test Assessment 2",
  //         },
  //         firstName: "Arun",
  //         lastName: "Kumar",
  //         currentStatus: {
  //           _id: "63241a7d92e9913fbccca669",
  //           status: "IN_PROGRESS",
  //           createdAt: "2022-09-16T06:41:01.489Z",
  //         },
  //         statusLog: [
  //           {
  //             _id: "63241a7d92e9913fbccca66a",
  //             status: "IN_PROGRESS",
  //             createdAt: "2022-09-16T06:41:01.489Z",
  //           },
  //         ],
  //         createdAt: "2022-09-16T06:41:01.501Z",
  //         updatedAt: "2022-09-16T08:34:08.885Z",
  //         rating: 5,
  //       },
  //       {
  //         hiringStage: null,
  //         _id: "63241a7d92e9913fbccca668",
  //         inviteId: "63241a7d92e9913fbccca665",
  //         assessmentId: {
  //           _id: "6324175f43cf571e7024f4c7",
  //           title: "Test Assessment 3",
  //         },
  //         firstName: "Tanjil",
  //         lastName: "Hossain",
  //         currentStatus: {
  //           _id: "63241a7d92e9913fbccca669",
  //           status: "COMPLETED",
  //           createdAt: "2022-09-16T06:41:01.489Z",
  //         },
  //         statusLog: [
  //           {
  //             _id: "63241a7d92e9913fbccca66a",
  //             status: "PENDING",
  //             createdAt: "2022-09-10T06:41:01.489Z",
  //           },
  //           {
  //             _id: "63241a7d92e9913fbccca66x",
  //             status: "IN_PROGRESS",
  //             createdAt: "2022-09-14T06:41:01.489Z",
  //           },
  //           {
  //             _id: "63241a7d92e9913fbccca66c",
  //             status: "COMPLETED",
  //             createdAt: "2022-09-15T06:41:01.489Z",
  //           },
  //         ],
  //         createdAt: "2022-09-16T06:41:01.501Z",
  //         updatedAt: "2022-09-16T08:34:08.885Z",
  //         rating: 5,
  //       },
  //     ];
  //     setCandidateLists(data)
  //   }
  // }, [candidateLists]);

  // const dataTypes = useSelector((state) => state.Questions.data);

  const methods = useForm({
    defaultValues: {
      searchCandidates: "",
      scoringBenchMark: "",
      scoreRange: [0, 50],
      state: [],
      status: [],
    },
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickPopper = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const onSubmit = (data) => console.log(data);

  const { getValues, handleSubmit } = methods;
  const score = getValues("scoreRange");

  const modifiedValueText = `${score[0]}% to ${score[1]}%`;

  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //search functionality
  // useEffect(() => {
  //   if (searchValue === "") {
  //     // api call getting the data
  //   } else {
  //     setData(
  //       users.filter(
  //         (d) =>
  //           d.name.toLowerCase().includes(searchValue?.toLowerCase()) ||
  //           d.invitedOn.toLowerCase().includes(searchValue?.toLowerCase()) ||
  //           d.score.toLowerCase().includes(searchValue?.toLowerCase()) ||
  //           d.rating.toLowerCase().includes(searchValue?.toLowerCase()) ||
  //           d.date.toLowerCase().includes(searchValue?.toLowerCase())
  //       )
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [searchValue]);

  const handleBackdropClick = (id) => {
    setDrawerOpen(false);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  let listToDisplay = candidateLists;
  console.log(listToDisplay);
  if (searchValue !== "") {
    listToDisplay = candidateLists.filter(({ firstName,lastName}) => {
      let fullName = firstName+" "+lastName
      return fullName.toLowerCase().includes(searchValue.toLowerCase());
    });
  }

  const debouncedResults = useMemo(() => {
    return debounce(handleSearchChange, 500);
  }, []);

  useEffect(() => {
    console.log("dkfef ekf e");
    return () => {
      debouncedResults.cancel();
    };
  });

  //TODO: change the top ui
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Header />
        <div className={styles.Container}>
          <>
            <div className={styles.heading}>
              <div className={styles.headerTitle}>
                <div>
                  <h2>My Candidates</h2>
                  <h6 style={{ fontWeight: "600", whiteSpace: "break-spaces" }}>
                    More than 400+ new candidates
                  </h6>
                </div>
                <>
                  <Paper
                    component="form"
                    sx={{
                      maxWidth: "80%",
                      padding: "10px 14px",
                      height: "34px",
                      borderRadius: "6px",
                      display: "flex",
                      alignItems: "center",
                      width: 400,
                      boxShadow: "none",
                    }}
                  >
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search"
                    >
                      <SearchIcon />
                    </IconButton>
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search"
                      inputProps={{ "aria-label": "search" }}
                      // value={searchValue}
                      // onChange={(e) => setSearchValue(e.target.value)}
                      onChange={debouncedResults}
                    />
                  </Paper>
                </>
              </div>
              <div className={styles.filterSection}>
                <button
                  type="button"
                  aria-describedby={id}
                  className={styles.filterBtn}
                  // onClick={handleClick}
                >
                  <span>
                    Filter <KeyboardArrowDownIcon />
                  </span>
                </button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <Typography sx={{ p: 2 }}>
                    The content of the Filter.
                  </Typography>
                </Popover>
                {/* <Link to="/createAssesment"> */}
                <button
                  type="button"
                  className={styles.saveButton}
                  onClick={() => {
                    setCurrentRowAssessmentId(assessmentId.id);
                    setDrawerOpen(!drawerOpen);
                  }}
                >
                  <img
                    style={{ marginRight: "7px" }}
                    src={IconPerson}
                    alt="PersonIcon"
                  />
                  Invite Candidate
                </button>

                <SlideDrawer
                  closeDrawer={handleBackdropClick}
                  show={drawerOpen}
                  currentRowAssessmentId={currentRowAssessmentId}
                />
                {drawerOpen && <BackDrop closeDrawer={handleBackdropClick} />}
              </div>
            </div>
          </>
          <div style={{ marginTop: 20, width: "90%" }}>
            <TableComponent
              // hiringStatus={hiringStatus}
              candidateLists={listToDisplay}
              searchValue={searchValue}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default ViewCandidateList;
