import { contributorListApi } from "../../utils/constants";
import axiosInstance from "./client";
import silentAxiosInstance from "./silentAxiosClient";
const questionBankGetAll = (
  page,
  PER_PAGE,
  title,
  status,
  primarySkill,
  secondarySkills,
  questionType,
  complexity,
  createdBy,
  fromDate,
  toDate,
  sortingValue
) => {
  return axiosInstance.get(
    `/api/question?questionRoles=BANK&page=${page}&perPage=${PER_PAGE}&status=${
      status ? status : ""
    }&primarySkill=${primarySkill ? primarySkill : ""}&secondarySkills=${
      secondarySkills ? secondarySkills : ""
    }&questionTypes=${
      questionType ? questionType : ""
    }&complexity=${complexity}&title=${title}&createdBy=${createdBy}&fromDate=${fromDate}&toDate=${toDate}&order=${sortingValue}&sortBy=lastStatusChangedAt`
  );
};
const getAllQuestionAxios = (
  page,
  PER_PAGE,
  title,
  status,
  primarySkill,
  secondarySkills,
  questionType,
  complexity,
  createdBy,
  fromDate,
  toDate,
  sortingValue
) => {
  return silentAxiosInstance.get(
    `/api/question?questionRoles=BANK&page=${page}&perPage=${PER_PAGE}&status=${
      status ? status : ""
    }&primarySkill=${primarySkill ? primarySkill : ""}&secondarySkills=${
      secondarySkills ? secondarySkills : ""
    }&questionTypes=${
      questionType ? questionType : ""
    }&complexity=${complexity}&title=${title}&createdBy=${createdBy}&fromDate=${fromDate}&toDate=${toDate}&order=${sortingValue}&sortBy=lastStatusChangedAt`
  );
};
const QuestionStatusChange = (data) => {
  return silentAxiosInstance.patch(`/api/question/changeStatus`, data);
};

const deleteQuestionById = (id) => {
  return axiosInstance.delete(`/api/question/${id}`);
};
const getContributorList = () => {
  return axiosInstance.get(contributorListApi);
};

const QuestionBankListService = {
  questionBankGetAll,
  QuestionStatusChange,
  deleteQuestionById,
  getAllQuestionAxios,
  getContributorList,
};

export default QuestionBankListService;
