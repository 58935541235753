import axios from "axios";
import axiosInstance from "../../../services/api/client";

export const searchAllAssessment = async (values, setter, loader) => {
 

  //  config.headers = accessToken ? `Bearer ${accessToken}` : "";
  try {
    loader(true);
    const res = await axiosInstance.post(
      `/api/assessment/search?page=${+values?.pageNo + 1}&perPage=${
        values?.pageSize
      }`,
      {
        title: values?.searchValue,
        // "jobRole": "IT",
        // "skills": [
        //     {
        //         "technology": "React",
        //         "expLevel": "JUNIOR",
        //         "expYear": 1
        //     },
        //     {
        //         "technology": "Vanilla JavaScript",
        //         "expLevel": "JUNIOR",
        //         "expYear": 1
        //     }
        // ]
      }
    );
    setter(res?.data);
    loader(false);
  } catch {
    loader(true);
  }
};

export const searchAllQUestions = async (values, setter) => {
  try {
    const res = await axios.post(
      `/api/question/search?page=${+values?.pageNo + 1}&perPage=${
        values?.pageSize
      }`,
      {
        title: values?.searchValue,
        category: values.category,
      }
    );
    setter(res?.data);
  } catch {}
};
