import { Box, Modal, Typography } from "@mui/material";
import IMAGES from "../../../../assets/images/images";
import React, { useEffect, useState } from "react";
import { Add, Check } from "@mui/icons-material";
import RoutingPaths from "../../../../utils/RoutingPaths";
import { useNavigate } from "react-router-dom";
import QuestionListService from "../../../../services/api/QuestionListService";
import {
  addQualifyingQuestionsAction,
  removeQualifyingQuestionsAction,
} from "../../../../utils/redux/slices/customQuestionSlice";
import { useDispatch, useSelector } from "react-redux";

const QualifyingQuestionsDrawer = (props) => {
  const { open, setOpen } = props;
  const [readyToAddClass, setReadyToAddClass] = useState(false);
  const [qualifyingQuestions, setQualifyingQuestions] = useState([]);
  const handleCloseModal = () => {
    setOpen(false);
  };
  useEffect(() => {
    setReadyToAddClass(true);
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    QuestionListService.getQualifyingQuestions().then((res) => {
      setQualifyingQuestions(res?.data?.data.questions);
    });
  }, []);

  const qualifyingQuestionsFromStore = useSelector(
    (state) => state.CustomQuestionSlice.qualifyingQuestions
  );
  const addQualifyingQuestionToStore = (question) => {
    dispatch(addQualifyingQuestionsAction(question));
  };
  const removeQualifyingQuestionFromStore = (questionId) => {
    dispatch(removeQualifyingQuestionsAction(questionId));
  };

  const handleAdd = (questionRef) => {
    let found = false;
    qualifyingQuestionsFromStore?.forEach((question) => {
      if (question._id === questionRef._id) {
        found = true;
      }
    });
    if (found) {
      removeQualifyingQuestionFromStore(questionRef._id);
    } else {
      addQualifyingQuestionToStore(questionRef);
    }
  };
  const getQuestionStatus = (id) => {
    let found = false;
    qualifyingQuestionsFromStore?.forEach((question) => {
      if (question._id === id) {
        found = true;
      }
    });
    return found;
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={`drawer drawer-sm right ${readyToAddClass && "open"}`}>
        <Box className="modal-content">
          <Box
            className={`d-flex align-items-center justify-content-between pd-x-30 pd-y-30 border-bottom`}
          >
            <Typography id="modal-modal-title" variant="h2" component="h2">
              Qualifying Questions
            </Typography>
            <div>
              <button
                type="button"
                className={`btn btn-light-primary mg-r-12`}
                onClick={() => navigate(RoutingPaths.QualifyingQuestion)}
              >
                <Add /> New Question
              </button>
              <button
                type={`button`}
                className={`btn btn-link text-decoration-none`}
                onClick={handleCloseModal}
              >
                <img src={IMAGES.XIcon} alt="close" />
              </button>
            </div>
          </Box>
          <Box className={`pd-x-30 pd-y-30`}>
            <ul
              className={`list-unstyled video-list`}
              style={{
                maxHeight: "500px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {qualifyingQuestions?.map((question) => {
                return (
                  <li key={question._id}>
                    <div className="row g-3 align-items-center">
                      <div className="col">
                        <Typography variant={"h4"} component={"label"}>
                          {question?.title}
                        </Typography>
                        <div className={"d-flex mg-t-16"}>
                          {question.answers?.map((item, i) => {
                            return (
                              <div key={i} className="custom-checkbox mg-r-30">
                                <input
                                  className="custom-control-input"
                                  type="checkbox"
                                  id={i}
                                  name={`degree`}
                                />
                                <label
                                  className="custom-control-label text-black-11"
                                  htmlFor={i}
                                >
                                  {item?.answer}
                                </label>
                              </div>
                            );
                          })}
                          {/* <div className="custom-radio mg-r-30">
                              <input
                                className="custom-control-input"
                                type="radio"
                                id={`yes`}
                                name={`degree`}
                              />
                              <label
                                className="custom-control-label text-black-11"
                                htmlFor={`yes`}
                              >
                                Yes
                              </label>
                            </div>
                            <div className="custom-radio">
                              <input
                                className="custom-control-input"
                                type="radio"
                                id={`no`}
                                name={`degree`}
                              />
                              <label
                                className="custom-control-label text-black-11"
                                htmlFor={`no`}
                              >
                                No
                              </label>
                            </div> */}
                        </div>
                      </div>
                      <div className="col-auto">
                        <button
                          type={"button"}
                          className={
                            getQuestionStatus(question._id)
                              ? `btn btn-snow text-primary drawer-btn`
                              : `btn btn-primary text-white drawer-btn`
                          }
                          onClick={() => handleAdd(question)}
                        >
                          {/* Add */}
                          {getQuestionStatus(question._id) ? <Check /> : "Add"}
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })}
              {/*
               <li>
                <div className="row g-3 align-items-center">
                  <div className="col">
                    <Typography variant={"h4"} component={"label"}>
                      Do you have a bachelor’s degree?
                    </Typography>
                    <div className={"d-flex mg-t-16"}>
                      <div className="custom-radio mg-r-30">
                        <input
                          className="custom-control-input"
                          type="radio"
                          id={`yes`}
                          name={`degree`}
                        />
                        <label
                          className="custom-control-label text-black-11"
                          htmlFor={`yes`}
                        >
                          Yes
                        </label>
                      </div>
                      <div className="custom-radio">
                        <input
                          className="custom-control-input"
                          type="radio"
                          id={`no`}
                          name={`degree`}
                        />
                        <label
                          className="custom-control-label text-black-11"
                          htmlFor={`no`}
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <button
                      type={"button"}
                      className={`btn btn-primary text-white drawer-btn`}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </li>
              <li>
                <div className="row g-3 align-items-center">
                  <div className="col">
                    <Typography variant={"h4"} component={"label"}>
                      Do you have a bachelor’s degree?
                    </Typography>
                    <div className={"d-flex mg-t-16"}>
                      <div className="custom-radio mg-r-30">
                        <input
                          className="custom-control-input"
                          type="radio"
                          id={`yes`}
                          name={`degree`}
                        />
                        <label
                          className="custom-control-label text-black-11"
                          htmlFor={`yes`}
                        >
                          Yes
                        </label>
                      </div>
                      <div className="custom-radio">
                        <input
                          className="custom-control-input"
                          type="radio"
                          id={`no`}
                          name={`degree`}
                        />
                        <label
                          className="custom-control-label text-black-11"
                          htmlFor={`no`}
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <button
                      type={"button"}
                      className={`btn btn-snow text-primary drawer-btn`}
                    >
                      <Check />
                    </button>
                  </div>
                </div>
              </li> */}
            </ul>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default QualifyingQuestionsDrawer;
