import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./QuestionEditor.module.scss";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import React from "react";
import MuiMenuSmall from "../../../MuimenuSmall/MuiMenuSmall";
import ImageResize from "quill-image-resize-module-react";
Quill.register("modules/imageResize", ImageResize);

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
  ],
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize"],
  },
};

const QuestionEditor = () => {
  const methods = useFormContext();
  const { control } = methods;
  const selectedLanguage = useWatch({
    control,
    name: `selectedLanguage`,
  });

  const {
    fields: languageFields,
    append: appendLanguageFields,
    remove: removeLanguageFields,
  } = useFieldArray({
    control,
    name: "detail.langWiseDetail",
  });

  const langWiseDetailWatch = useWatch({
    control,
    name: "detail.langWiseDetail",
  });
  const getLanguageLists = () => {
    const languages = langWiseDetailWatch?.map(({ language }) => {
      let langObj = {
        label: language,
        _id: language,
      };
      return langObj;
    });
    return languages;
  };

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    "script",
    "blockquote",
    "background",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "code-block"
  ];

  // const isPracticeWatch = useWatch({
  //   control,
  //   name: "isPractice",
  // });
  return (
    <div className={styles.editorWrap}>
      {/* <div className={styles.practiceAction}>
        <Controller
          name={`isPractice`}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              onClick={(event) => event.stopPropagation()}
              className={`switch-btn`}
              control={
                <IOSSwitch {...field} checked={isPracticeWatch} sx={{ m: 1 }} />
              }
              label="Practice Question"
            />
          )}
        />
      </div> */}
      <div className={styles.editorAction}>
        <MuiMenuSmall
          options={getLanguageLists()}
          RHFName="selectedLanguage"
          placeholder=""
        />
      </div>
      {languageFields?.map((language, index) => (
        <div key={index}>
          {selectedLanguage === language.language && (
            <Controller
              key={index}
              name={`detail.langWiseDetail.${index}.detail`}
              control={control}
              render={({ field }) => (
                <ReactQuill
                  modules={modules}
                  theme="snow"
                  placeholder="Question"
                  formats={formats}
                  {...field}
                />
              )}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default QuestionEditor;
