import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import styles from "./SkillsTechnology.module.scss";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import IMAGES from "../../../../assets/images/images";
import { Add, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import React, { useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import MuiMenu from "../../../common/MuiMenu/MuiMenu";
import { complexityOptions, JobRoles } from "../../../../utils/constants";
import MuiMenuCommon from "../../../common/MuiMenuCommon/MuiMenuCommon";
import { useEffect } from "react";
import { getPrimarySkills } from "../../../../utils/redux/slices/skillsSlice";
import { useDispatch, useSelector } from "react-redux";
import SkillsService from "../../../../services/api/SkillsService";
import RHFMultiselectCheckboxNew from "../../../RHFControllers/RHFMultiselectCheckboxNew";

// const languages = ["React", "Angular"];

const SkillsTechnology = ({ increaseActiveStep, decreaseActiveStep,secondarySkillsListMap }) => {
  const { control,getValues } = useFormContext();
  const primarySkills=useSelector(state=>state.SkillsSlice.primarySkills)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPrimarySkills());
  }, []);
  const assessmentNameWatch = useWatch({
    control,
    name: "assessmentName",
  });

  const {
    fields: skillsField,
    append: addSkillsField,
    remove: removeSkillsField,
  } = useFieldArray({
    control,
    name: "skillsAndTechnology",
  });

  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
  const openLanguage = Boolean(languageAnchorEl);
  const handleLanguageOpen = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };
  const languageSelection = () => {
    languageClose();
  };
  const languageClose = () => {
    setLanguageAnchorEl(null);
  };

  const handleAdd = () => {
    if (skillsField.length < 5) {
      addSkillsField({ skill: "", subSkill: [], complexity: "" });
    }
  };
  const handleRemove = (index) => {
    removeSkillsField(index);
  };

  return (
    <>
      <Card className={`${styles.cardAssessment}`}>
        <CardContent>
          <div
            className={`d-flex justify-content-between align-items-start mg-b-40`}
          >
            <div>
              <Typography
                variant={"h2"}
                component={"h2"}
                className={`title26 mg-b-8`}
              >
                {assessmentNameWatch}
              </Typography>
              <Typography
                component={"p"}
                variant={"h3"}
                className={`font-weight-normal`}
                color={"secondary"}
              >
                If you need more info, please check out{" "}
                <Link to="/faq">FAQ Page</Link>
              </Typography>
            </div>
            <Typography
              variant={"p"}
              component={"p"}
              className={`${styles.timeTotal} d-flex`}
            >
              <i className={`icon-clock-circle mg-r-10`}></i>
              <span
                className={`font-weight-semi-bold ${styles.text}`}
              >
                1h 15min
              </span>
            </Typography>
          </div>
          <TableContainer className="custom-table without-border">
            <Table border={0}>
              <TableHead>
                <TableRow>
                  <TableCell>SKILLS (Choose upto 5 skills)</TableCell>
                  <TableCell>Sub-Skills (Choose upto 8 sub-skills)</TableCell>
                  <TableCell>Complexity</TableCell>
                  <TableCell>Duration</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              {true ? (
                <TableBody>
                  {skillsField?.map((skill, index) => (
                    <TableRow key={skill.id}>
                      <TableCell className={`ps-0`}>
                        <div className={`row g-3 align-items-center`}>
                          <div className={`col-auto`}>
                            <button
                              type="button"
                              className={`btn btn-link p-0 text-decoration-none`}
                            >
                              <i
                                className={`icon-grid ${styles.textBlack11}`}
                              ></i>
                            </button>
                          </div>
                          <div className={`col`}>
                            <MuiMenuCommon
                              options={primarySkills}
                              RHFName={`skillsAndTechnology.${index}.skill`}
                              placeholder="Select Skill"
                              useId={true}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <RHFMultiselectCheckboxNew
                          PrimarySkillRHFName={`skillsAndTechnology.${index}.skill`}
                          RHFName={`skillsAndTechnology.${index}.subSkill`}
                          placeholder="Select Sub Skill"
                          index={index}
                          secondarySkillsListMap={secondarySkillsListMap}
                        />
                      </TableCell>
                      <TableCell>
                        <MuiMenuCommon
                          options={complexityOptions}
                          RHFName={`skillsAndTechnology.${index}.complexity`}
                          placeholder="Select Complexity"
                          useId={false}
                        />
                      </TableCell>
                      <TableCell>15min</TableCell>
                      <TableCell align={"right"} className={`pe-0`}>
                        <button
                          className={`btn btn-link text-decoration-none p-0`}
                          onClick={() => handleRemove(index)}
                        >
                          <img src={IMAGES.Trash} alt="trash" />
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={5}>
                      No Data Found!
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <button
            onClick={handleAdd}
            type="button"
            className={`btn btn-light-primary btn-lg`}
          >
            <Add /> Add another
          </button>
          <Divider sx={{ margin: "24px 0" }} />
          <button
            type="button"
            className={`btn btn-snow btn-lg font-weight-bold font font-size-base pd-x-24`}
            onClick={decreaseActiveStep}
          >
            Back
          </button>
          <button
            type={`button`}
            onClick={increaseActiveStep}
            className={
              "btn btn-primary btn-lg font-weight-bold text-white font-size-base pd-x-24 mg-l-12"
            }
          >
            Next
          </button>
        </CardContent>
      </Card>
    </>
  );
};

export default SkillsTechnology;
