import { createSlice } from "@reduxjs/toolkit";

export const filterSlice = createSlice({
  name: "filters",
  initialState: {
    filters: {
      status: {},
      questionType: {},
      complexity: {},
      primarySkill: {},
      secondarySkill: {},
      createdBy: {},
    },
  },
  reducers: {
    setStatusFilter: (state, action) => {
      state.filters.status = action.payload;
    },
    setQuestionTypeFilter: (state, action) => {
      state.filters.questionType = action.payload;
    },
    setComplexityFilter: (state, action) => {
      state.filters.complexity = action.payload;
    },
    setPrimarySkillFilter: (state, action) => {
      state.filters.primarySkill = action.payload;
    },
    setSecondarySkillFilter: (state, action) => {
      state.filters.secondarySkill = action.payload;
    },
    setCreatedByFilter: (state, action) => {
      state.filters.createdBy = action.payload;
    },
    setAllFilters: (state, action) => {
      state.filters = action.payload;
    },

    resetAllFilters: (state) => {
      state.filters = {
        status: {},
        questionType: {},
        complexity: {},
        primarySkill: {},
        secondarySkill: {},
        createdBy: {},
      };
    },
  },
});

export const setAllFiltersAction = (values) => async (dispatch) => {
  try {
    dispatch(setAllFilters(values));
  } catch (err) {
    throw new Error(err);
  }
};

export const setStatusFilterAction = (values) => async (dispatch) => {
  try {
    dispatch(setStatusFilter(values));
  } catch (err) {
    throw new Error(err);
  }
};

export const setQuestionTypeFilterAction = (values) => async (dispatch) => {
  try {
    dispatch(setQuestionTypeFilter(values));
  } catch (err) {
    throw new Error(err);
  }
};
export const setComplexityFilterAction = (values) => async (dispatch) => {
  try {
    dispatch(setComplexityFilter(values));
  } catch (err) {
    throw new Error(err);
  }
};
export const setPrimarySkillFilterAction = (values) => async (dispatch) => {
  try {
    dispatch(setPrimarySkillFilter(values));
  } catch (err) {
    throw new Error(err);
  }
};
export const setSecondarySkillFilterAction = (values) => async (dispatch) => {
  try {
    dispatch(setSecondarySkillFilter(values));
  } catch (err) {
    throw new Error(err);
  }
};
export const setCreatedByFilterAction = (values) => async (dispatch) => {
  try {
    dispatch(setCreatedByFilter(values));
  } catch (err) {
    throw new Error(err);
  }
};
export const resetAllFiltersAction = () => async (dispatch) => {
  try {
    dispatch(resetAllFilters());
  } catch (err) {
    throw new Error(err);
  }
};

export const {
  setStatusFilter,
  setQuestionTypeFilter,
  setComplexityFilter,
  setPrimarySkillFilter,
  setSecondarySkillFilter,
  setCreatedByFilter,

  resetAllFilters,
  setAllFilters,
} = filterSlice.actions;
export default filterSlice.reducer;
