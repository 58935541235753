import React from "react";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { Button } from "@mui/material";
import {makeStyles} from "@mui/styles"
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";


const useStyles = makeStyles({
  button: {
    backgroundColor: "#46a997",
    "&:hover": {
      backgroundColor: "#f2ffe6",
      color: "black",
    },
  },
});

const VerificationCode = (props) => {

  const classes = useStyles();
  const {
    handleRunTest,
    setVerificationCode,
    verificationCode,
    testError,
    testResult,
    isTestRunning,
    index
  } = props;

  const onChange = React.useCallback((value) => {
    setVerificationCode(value,index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <CodeMirror
          // Value from API
          value={verificationCode}
          height="400px"
          theme="dark"
          // Extensions from API
          extensions={[javascript({})]}
          onChange={onChange}
        />
      </div>
      <div
        style={{
          height: "300px",
          backgroundColor: "#002b36",
          borderRadius: "5px",
        }}
      >
        <div
          style={{
            backgroundColor: "#073642",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "white",
          }}
        >
          <div>
            <div style={{ color: "white", paddingLeft: "10px" }}>
              Test output
            </div>
          </div>
          <div style={{ color: "green" }}>
     
            OK: {testResult?.passed || 0}
          </div>
          <div style={{ color: "red" }}>Error: {testResult?.failed || 0}</div>
          <div>Total: {testResult?.total || 0}</div>
          <div>
            <Button
              className={classes.button}
              disabled={isTestRunning}
              onClick={handleRunTest}
              variant="contained"
              // sx={{ backgroundColor: "#46a997" }}
            >
              <PlayArrowOutlinedIcon /> Run Test
            </Button>
          </div>
        </div>

       

        <div>
          {isTestRunning && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
        </div>
      
      

        <div
          style={{
            backgroundColor: "#002b36",
            maxHeight: "200px",
            color: "white",
            overflowY: "auto",
            padding: "10px",
          }}
        >
          
          {(testResult || testError) && (
            <p style={{ fontSize: "14px", fontFamily: "monospace" }}>
              Code Executed Successfully
            </p>
          )}
          {testError && <p style={{ color: "red", marginBottom: "0" }}>{testError}</p>}
          {testResult?.result?.map((item, i) => (
            <div style={{ fontSize: "16px" }} key={item.title}>

              <p style={{ marginBottom: "0" }}>
                -------------------------------------------
              </p>
              <p style={{ marginBottom: "0" }}>
                Input Parameters: {item.parameters}{" "}
              </p>
              <p style={{ marginBottom: "0" }}>Result: {item.result} </p>
              {item.output === "OK" ? (
                <p style={{ color: "green", marginBottom: "0" }}>
                  {item.output}
                </p>
              ) : (
                <p style={{ color: "red", marginBottom: "0" }}>
                  Expected : {item.output}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default VerificationCode;
