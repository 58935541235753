import { createSlice } from "@reduxjs/toolkit";
import { authorService } from "../../../services";
const statusMap = { idle: 'idle', pending: 'pending', resolved: 'resolved', rejected: 'rejected' };

const authorSlice = createSlice({
    name: "authors",
    initialState: {
        status: statusMap.idle,
        authors: [],
        error: null
    },
    reducers: {
        fetchAuthors: (state, action) => {
            state.status = statusMap.pending;
            state.error = null;
        },
        fetchAuthorsSuccess: (state, action) => {
            state.status = statusMap.resolved;
            state.authors = action.payload;
        },
        fetchAuthorsError: (state, action) => {
            state.status = statusMap.rejected;
            state.error = action.payload;
        }
    }
});

export const fetchAuthors  = () => (dispatch) => {
    dispatch(authorSlice.actions.fetchAuthors());
    authorService.getAuthors()
    .then((res) => {
        const authors = res.data?.data;
        dispatch(authorSlice.actions.fetchAuthorsSuccess(authors));
    })
    .catch(err => {
        let errMsg = "Somthing Went Wrong";
        if(err.response){
            errMsg = err.response.data.msg;
        }
        dispatch(authorSlice.actions.fetchAuthorsError(errMsg));
    })
}

export default authorSlice.reducer;