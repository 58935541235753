import React, { useState } from "react";
import Header from "../Header/Header";
import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import IMAGES from "../../assets/images/images";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import styles from "./Profile.module.scss";
import ChangePassword from "./ChangePassword/ChangePassword";
import { useAuth } from "../../hooks/useAuth";
import { FormProvider, useForm } from "react-hook-form";
import RHFInputController from "../RHFControllers/RHFInputController";
import MuiMenuCommon from "../common/MuiMenuCommon/MuiMenuCommon";
import { useEffect } from "react";

const profileVisibility = ["Only me", "Public"];

const Profile2 = () => {
  const user = useAuth();
  console.log(user);
  const methods = useForm({
    defaultValues: {
      visibility: [],
      pofilePic: "",
      firstName: "",
      lastName: "",
      phone: "",
      companyName: "",
      companyLogo: "",
      country: "",
      email: "",
    },
  });
  const { getValues, setValue } = methods;
  const values = getValues();
  console.log(values);
  const {
    user: { firstName, lastName, email },
  } = user;

  useEffect(() => {
    setValue("firstName", firstName);
    setValue("lastName", lastName);
    setValue("email", email);
  }, []);

  const [profileVisibilityAnchorEl, setProfileVisibilityAnchorEl] =
    useState(null);
  const openProfileVisibility = Boolean(profileVisibilityAnchorEl);
  const [open, setOpen] = useState(false);
  const handleOpenModal = () => setOpen(true);
  const handleProfileVisibilityOpen = (event) => {
    setProfileVisibilityAnchorEl(event.currentTarget);
  };
  const profileVisibilitySelection = () => {
    profileVisibilityClose();
  };
  const profileVisibilityClose = () => {
    setProfileVisibilityAnchorEl(null);
  };
  console.log(firstName);
  return (
    <>
      <Header />
      <FormProvider {...methods}>
        <Container maxWidth={"lg"}>
          <div className={"pd-t-30"}>
            <Card className={`card-md`}>
              <CardContent>
                <Typography
                  variant={"h2"}
                  component={"h2"}
                  className={`heading1 mg-b-8`}
                >
                  Profile
                </Typography>
                <Typography
                  variant={"h3"}
                  component={"p"}
                  className={`font-weight-normal mg-b-24`}
                >
                  Manage your personal information, and control which
                  information other people see and apps may access.
                </Typography>
                <div className={`row g-3`}>
                  <div className="col-auto">
                    <div className={`img-asset img-asset-90-90 circle`}>
                      <img src={IMAGES.ProfileEmpty} alt="profile" />
                      <div className={"profile-camera"}>
                        <i className={`icon-camera`}></i>
                      </div>
                      <input type={"file"} className={`hidden-input`} />
                    </div>
                  </div>
                  <div className={`col`}>
                    <div
                      className={`d-flex align-items-end justify-content-between flex-wrap`}
                    >
                      <div>
                        <Typography
                          variant={"body1"}
                          component={"p"}
                          className={`mg-b-10`}
                        >
                          Who can see your profile photo?
                        </Typography>
                        <div className={`mg-b-10 ${styles.btnVisibilityWrap}`}>
                          <Button
                            onClick={handleProfileVisibilityOpen}
                            className={`btn-toggle btn-sm w-100`}
                            variant={"outlined"}
                            color={"secondary"}
                          >
                            <Typography
                              noWrap
                              className={`pd-y-0 font-weight-medium font-size-sm`}
                            >
                              {false ? "Value" : "Visibility"}
                            </Typography>
                            {openProfileVisibility ? (
                              <KeyboardArrowUp className={`toggle-icon`} />
                            ) : (
                              <KeyboardArrowDown className={`toggle-icon`} />
                            )}
                          </Button>

                          <Menu
                            id="role-menu"
                            anchorEl={profileVisibilityAnchorEl}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            open={openProfileVisibility}
                            onClose={profileVisibilityClose}
                          >
                            {profileVisibility.map((visibility, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  onClick={profileVisibilitySelection}
                                >
                                  <Typography textAlign="center">
                                    {visibility}
                                  </Typography>
                                </MenuItem>
                              );
                            })}
                          </Menu>
                        </div>
                        <div>
                          <button
                            type={"button"}
                            className={`btn btn-snow btn-lg`}
                            onClick={handleOpenModal}
                          >
                            Change Password?
                          </button>
                          {open && (
                            <ChangePassword open={open} setOpen={setOpen} />
                          )}
                        </div>
                      </div>
                      <div className={"d-flex"}>
                        <button
                          type={"button"}
                          className={`btn btn-snow btn-lg mg-r-10`}
                        >
                          Cancel
                        </button>
                        <Button variant={"contained"} color={"primary"}>
                          Save Changes
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <Divider className={`mg-y-20`} />
                <div className="row g-3">
                  <div className="col-md-3">
                    <Typography
                      variant={"body1"}
                      component={"p"}
                      className={"font-weight-semi-bold"}
                    >
                      About you
                    </Typography>
                  </div>
                  <div className="col-md-9">
                    <div className={`mg-b-10`}>
                      <label
                        htmlFor=""
                        className={`form-label font-weight-medium`}
                      >
                        First name
                      </label>
                      <RHFInputController
                        RHFname="firstName"
                        type="text"
                        placeholder="First Name"
                        className="form-control form-control-lg"
                        fieldName="firstName"
                      />
                      {/* <input
                        type="text"
                        name=""
                        id=""
                        value={firstName || ""}
                        className={`form-control form-control-lg`}
                      /> */}
                    </div>
                    <div className={`mg-b-10`}>
                      <label
                        htmlFor=""
                        className={`form-label font-weight-medium`}
                      >
                        Last name
                      </label>
                      <RHFInputController
                        RHFname="lastName"
                        type="text"
                        placeholder="Last Name"
                        className="form-control form-control-lg"
                        fieldName="firstName"
                      />
                      {/* <input
                        type="text"
                        name=""
                        id=""
                        value={lastName || ""}
                        className={`form-control form-control-lg`}
                      /> */}
                    </div>
                    <div className={`mg-b-10`}>
                      <label
                        htmlFor=""
                        className={`form-label font-weight-medium`}
                      >
                        Phone number
                      </label>
                      <RHFInputController
                        RHFname="phone"
                        type="tel"
                        placeholder="Enter Phone Number"
                        className="form-control form-control-lg"
                        fieldName="phone"
                      />
                      {/* <input
                        type="tel"
                        name=""
                        id=""
                        className={`form-control form-control-lg`}
                      /> */}
                    </div>
                  </div>
                </div>
                <Divider className={`mg-y-20`} />
                <div className="row g-3">
                  <div className="col-md-3">
                    <Typography
                      variant={"body1"}
                      component={"p"}
                      className={"font-weight-semi-bold"}
                    >
                      About you
                    </Typography>
                  </div>
                  <div className="col-md-9">
                    <div className={"asset asset-lg mg-b-10"}>
                      <img
                        className={"img-fluid"}
                        src={IMAGES.ProfileEmpty}
                        alt="logo"
                      />
                    </div>
                    <div className={`mg-b-10`}>
                      <label
                        htmlFor=""
                        className={`form-label font-weight-medium`}
                      >
                        Company name
                      </label>
                      <RHFInputController
                        RHFname="companyName"
                        type="text"
                        placeholder="Enter Comapany Name"
                        className="form-control form-control-lg"
                        fieldName="companyName"
                      />
                      {/* <input
                        type="text"
                        name=""
                        id=""
                        className={`form-control form-control-lg`}
                      /> */}
                    </div>
                    <div>
                      <label
                        htmlFor=""
                        className={`form-label font-weight-medium`}
                      >
                        Country
                      </label>
                      <MuiMenuCommon
                        // options={complexityOptions}
                        RHFName={"complexity"}
                        placeholder="Select Complexity"
                        // isEditable={isEditable}
                      />
                      {/* <Button
                        onClick={handleProfileVisibilityOpen}
                        className={`btn-toggle w-100`}
                        variant={"outlined"}
                        color={"secondary"}
                      >
                        <Typography
                          noWrap
                          className={`pd-y-0 font-weight-medium font-size-sm`}
                        >
                          {false ? "Value" : "Visibility"}
                        </Typography>
                        {openProfileVisibility ? (
                          <KeyboardArrowUp className={`toggle-icon`} />
                        ) : (
                          <KeyboardArrowDown className={`toggle-icon`} />
                        )}
                      </Button>
                      <Menu
                        id="role-menu"
                        anchorEl={profileVisibilityAnchorEl}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={openProfileVisibility}
                        onClose={profileVisibilityClose}
                      >
                        {profileVisibility.map((visibility, index) => {
                          return (
                            <MenuItem
                              key={index}
                              onClick={profileVisibilitySelection}
                            >
                              <Typography textAlign="center">
                                {visibility}
                              </Typography>
                            </MenuItem>
                          );
                        })}
                      </Menu> */}
                    </div>
                  </div>
                </div>
                <Divider className={`mg-y-20`} />
                <div className="row g-3">
                  <div className="col-md-3">
                    <Typography
                      variant={"body1"}
                      component={"p"}
                      className={"font-weight-semi-bold"}
                    >
                      Contact
                    </Typography>
                  </div>
                  <div className="col-md-9">
                    <div>
                      <label
                        htmlFor=""
                        className={`form-label font-weight-medium`}
                      >
                        Email address
                      </label>
                      <RHFInputController
                        RHFname="email"
                        type="text"
                        placeholder="Enter Email"
                        className="form-control form-control-lg"
                        fieldName="email"
                      />
                      {/* <input
                        type="email"
                        name=""
                        id=""
                        value={email || ""}
                        className={`form-control form-control-lg`}
                      /> */}
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </Container>
      </FormProvider>
    </>
  );
};

export default Profile2;
