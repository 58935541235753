import axios from "axios";
import axiosInstance from "./client";

export const getUploadUrlAndObjectUrl = async (type) => {
  await new Promise(resolve => setTimeout(resolve, 3)); // 3 mili sec
  const key = Date.now();
  const {
    data: {
      data: { objectUrl, preSignedUrl },
    },
  } = await axiosInstance.get(
    `/api/utils/pre-signed-url?key=${key}&contentType=${type}`
  );
  return { key, objectUrl, preSignedUrl };
};

export const uploadFileToAWS = async (preSignedUrl, contentType, blob) => {
  console.log({preSignedUrl, contentType})
  const config = {
    method: "put",
    url: preSignedUrl,
    headers: {
      "Content-Type": contentType,
    },
    data: blob,
  };
  await axios(config);
};
