import {Box, Modal, TextField} from "@mui/material";
const actionMap = {
    create: {
        ok: 'Create',
        cancel: 'Cancel'
    },
    update: {
        ok: 'Update',
        cancel: 'Cancel'
    }
}

const FormDialog = (props) => {
    const {open, setOpen, label, methods, confirmAction, actionType } = props;
    const { register, formState: { errors, isSubmitting }, reset, handleSubmit} = methods;
    const handleCloseModal = () => {
        setOpen(false);
    };
    const onSubmit = (values) => {
        confirmAction(values);
    };

    return(
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modal-box">
                <Box className="modal-content tabs-wrap question-tabs">
                    <Box className={`pd-x-30`}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Box className={`border-bottom pd-y-30`}>
                                <strong><label htmlFor={label}>{label}</label></strong>
                                <input
                                    id={label}
                                    type="text"
                                    placeholder="Type Here..."
                                    className={"form-control form-control-lg"}
                                    {...register('label', { required: true, minLength: 3, maxLength: 30 })}
                                />
                                {errors.label && errors.label.type === "required" && <small className="text-danger">*This is required</small>}
                                {errors.label && errors.label.type === "minLength" && <small className="text-danger">*Min 3 character expected</small> } 
                                {errors.label && errors.label.type === "maxLength" && <small className="text-danger">*Max 30 character expected</small> }    
                            </Box>
                        
                        <div className={'pd-y-30'}>
                            <button type="button" className={`btn btn-snow mg-r-10`} onClick={handleCloseModal}>
                                {actionMap[actionType]?.cancel}
                            </button>
                            <button disabled={isSubmitting} type="submit" className={`btn btn-primary text-white`}>{actionMap[actionType]?.ok}</button>
                        </div>
                        </form>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default FormDialog;