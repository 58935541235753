import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

const MuiMenuSmall = ({ options, RHFName, placeholder }) => {
  const methods = useFormContext();
  const { setValue } = methods;
  const [selectedLanguage, setSelectedLanguage] = useState(
    !placeholder && "English"
  );

  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
  const openCategory = Boolean(languageAnchorEl);

  const handleLanguageOpen = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const languageClose = () => {
    setLanguageAnchorEl(null);
  };

  const handleMenuItemClick = (label, value) => {
    setSelectedLanguage(label);
    setValue(RHFName, value);
    languageClose();
  };

  return (
    <div>
      <Button
        onClick={handleLanguageOpen}
        className={`btn-toggle`}
        variant={"outlined"}
        color={"secondary"}
        size={"small"}
      >
        <Typography
          noWrap
          className="mg-r-4 pd-y-0 font-weight-medium font-size-sm"
          sx={{ color: "#5E6278" }}
        >
          {selectedLanguage ? selectedLanguage : placeholder}
        </Typography>
        {openCategory ? (
          <KeyboardArrowUp className="toggle-icon" />
        ) : (
          <KeyboardArrowDown className="toggle-icon" />
        )}
      </Button>
      <Menu
        id="role-menu"
        anchorEl={languageAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openCategory}
        onClose={languageClose}
      >
        {options?.map(({ label, _id, iconName }, index) => {
          return (
            <MenuItem
              key={index}
              selected={label === selectedLanguage}
              onClick={() => handleMenuItemClick(label, _id)}
            >
              <Typography textAlign="center">
                <i className={`${iconName} mg-r-10`}></i>
                {label}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default MuiMenuSmall;
