import { createSlice } from "@reduxjs/toolkit";

export const CustomQuestionSlice = createSlice({
  name: "customQuestion",
  initialState: {
    customQuestions: [],
    qualifyingQuestions: [],
  },
  reducers: {
    addCustomQuestion: (state, action) => {
      state.customQuestions.push(action.payload);
    },
    removeCustomQuestion: (state, action) => {
      const newQuestions = state.customQuestions.filter(
        (question) => question._id !== action.payload
      );
      state.customQuestions = newQuestions;
    },
    removeAllCustomQuestions: (state) => {
      state.customQuestions = [];
    },
    addQualifyingQuestion: (state, action) => {
      state.qualifyingQuestions.push(action.payload);
    },
    removeQualifyingQuestion: (state, action) => {
      const newQuestions = state.qualifyingQuestions.filter(
        (question) => question._id !== action.payload
      );
      state.qualifyingQuestions = newQuestions;
    },
    removeAllQualifyingQuestions: (state) => {
      state.qualifyingQuestions = [];
    },
  },
});

export const addCustomQuestionAction = (value) => async (dispatch) => {
  try {
    dispatch(addCustomQuestion(value));
  } catch (err) {
    throw new Error(err);
  }
};
export const removeCustomQuestionAction = (id) => async (dispatch) => {
  try {
    dispatch(removeCustomQuestion(id));
  } catch (err) {
    throw new Error(err);
  }
};
export const removeAllCustomQuestionAction = () => async (dispatch) => {
  try {
    dispatch(removeAllCustomQuestions());
  } catch (err) {
    throw new Error(err);
  }
};
//qualifying question
export const addQualifyingQuestionsAction = (value) => async (dispatch) => {
  try {
    dispatch(addQualifyingQuestion(value));
  } catch (err) {
    throw new Error(err);
  }
};
export const removeQualifyingQuestionsAction = (id) => async (dispatch) => {
  try {
    dispatch(removeAllQualifyingQuestions());
  } catch (err) {
    throw new Error(err);
  }
};
export const removeAllQualifyingQuestionAction = () => async (dispatch) => {
  try {
    dispatch(removeAllQualifyingQuestions());
  } catch (err) {
    throw new Error(err);
  }
};

export const {
  addCustomQuestion,
  removeCustomQuestion,
  removeAllCustomQuestions,

  addQualifyingQuestion,
  removeQualifyingQuestion,
  removeAllQualifyingQuestions
} = CustomQuestionSlice.actions;
export default CustomQuestionSlice.reducer;
