import { useEffect, useState } from "react";
import { Delete, Edit } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  CircularProgress,
  IconButton,
  InputBase,
  Paper,
  Popover,
  TablePagination,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Link, useNavigate } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import Icon1 from "../../../Files/img/abs1.png";
import Icon2 from "../../../Files/img/abs2.png";
import Icon3 from "../../../Files/img/abs3.png";
import Header from "../../Header/Header";
import JavaImg from "./../../../Files/img/stack-1.png";
import { searchAllAssessment } from "./actions";
import BackDrop from "./BakcDrop";
import styles from "./MyAssesment.module.scss";
import SlideDrawer from "./SliderDrawer";
import axios from "axios";
import { toHour, toMinutes } from "../../../utils/functions/dateTime";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ViewListIcon from "@mui/icons-material/ViewList";
import { useMemo } from "react";
import debounce from "lodash.debounce";
import AlertDialogSlide from "./CandidateList/Slider/PopUp/AlertDialogSlide";

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
  pagination: {
    "& p": {
      marginBottom: "0",
    },
  },
});

const MyAssesment = () => {
  const baseURL = "api/assessment/";
  const navigate = useNavigate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [gridData, setGridData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [currentRowAssessmentId, setCurrentRowAssessmentId] = useState(null);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    searchAllAssessment("", setGridData, setLoading);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
    searchAllAssessment(
      { searchValue, pageNo: newPage, pageSize },
      setGridData,
      setLoading
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value));
    setPageNo(0);
    searchAllAssessment(
      { searchValue, pageNo: 0, pageSize: event?.target?.value },
      setGridData,
      setLoading
    );
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteAssessment = (id) => {
    axios.delete(baseURL + id).then((response) => {
      if (response.status === 200) {
        setDeleteSuccess(true);
        navigate("/myAssesment");
      }
    });
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDeleteSuccess(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [drawerOpen, setDrawerOpen] = useState(false);

  // const handleOpenDrawerButton = () => {
  //   setDrawerOpen(!drawerOpen);
  // };
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleBackdropClick = (id) => {
    setDrawerOpen(false);
  };
  let listToDisplay = gridData.data;
  console.log(gridData);
  if (searchValue !== "") {
    listToDisplay = gridData.data.filter(({title}) => {
      return title.toLowerCase().includes(searchValue.toLowerCase());
    });
  }

   const debouncedResults = useMemo(() => {
     return debounce(handleSearchChange, 500);
   }, []);

   useEffect(() => {
     return () => {
       debouncedResults.cancel();
     };
   });

  useEffect(() => {
    searchAllAssessment(
      { searchValue: "", pageNo: 0, pageSize: 10 },
      setGridData
    );
  }, [deleteSuccess]);

  return (
    <>
      <Header />
      <div className={styles.Container}>
        <>
          <div className={styles.heading}>
            <div className={styles.headerTitle}>
              <div>
                <h2>My Assessments</h2>
                <h6 style={{ fontWeight: "600", whiteSpace: "break-spaces" }}>
                  More than 400+ new assessments
                </h6>
              </div>
              <>
                <Paper
                  component="form"
                  sx={{
                    maxWidth: "80%",
                    padding: "10px 14px",
                    height: "34px",
                    borderRadius: "6px",
                    display: "flex",
                    alignItems: "center",
                    width: 400,
                    boxShadow: "none",
                  }}
                >
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={() => {
                      setPageNo(0);
                      searchAllAssessment(
                        { searchValue, pageNo: 0, pageSize },
                        setGridData
                      );
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search by assessment name"
                    inputProps={{ "aria-label": "search" }}
                    onChange={debouncedResults}
                  />
                </Paper>
              </>
            </div>
            <div className={styles.filterSection}>
              <button
                type="button"
                aria-describedby={id}
                className={styles.filterBtn}
                onClick={handleClick}
              >
                <span>
                  Filter <KeyboardArrowDownIcon />
                </span>
              </button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                <Typography sx={{ p: 2 }}>
                  {" "}
                  The content of the Filter.
                </Typography>
              </Popover>
              <Link to="/createAssesment">
                <button type="button" className={styles.saveButton}>
                  New Assesment
                </button>
              </Link>
            </div>
          </div>
        </>
        <div className={styles.card}>
          <div className="table-responsive">
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // height: "100vh",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <table className="w-100">
                <thead>
                  <tr>
                    <th className={styles.th}>
                      <h6>NAME</h6>
                    </th>
                    <th className={styles.th}>
                      <h6>CANDIDATES</h6>
                    </th>
                    <th className={styles.th}>
                      <h6>PROGRESS</h6>
                    </th>
                    <th className={styles.th}>
                      <h6>DATE CREATED</h6>
                    </th>
                    <th className={styles.action}>
                      <h6>ACTION</h6>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <SlideDrawer
                    closeDrawer={handleBackdropClick}
                    show={drawerOpen}
                    currentRowAssessmentId={currentRowAssessmentId}
                  />
                  {drawerOpen && <BackDrop closeDrawer={handleBackdropClick} />}
                  {gridData?.data?.length > 0 &&
                    listToDisplay?.map((item, i) => (
                      <tr key={item?._id}>
                        <td className={styles.td}>
                          <div className="d-flex align-items-center mt-2 mb-2">
                            <div className={styles.avatar} style={{}}>
                              <img src={JavaImg} alt="" />
                            </div>
                            <div className="ms-3">
                              <p className="m-0">{item?.title}</p>
                              <div className={styles.icons}>
                                <div className="tableInfo">
                                  <img src={Icon1} alt="" />
                                  <span>{item?.jobRole}</span>
                                </div>
                                <div className="tableInfo">
                                  <img src={Icon2} alt="" />
                                  <span>
                                    {toHour(item?.time) > 0 &&
                                      `${toHour(item?.time)} ${
                                        toHour(item?.time) > 1
                                          ? "hours"
                                          : "hour"
                                      }`}{" "}
                                    {toMinutes(item?.time)} minutes{" "}
                                  </span>
                                </div>
                                <div className="tableInfo">
                                  <img src={Icon3} alt="" />
                                  <span>{item?.preferedLanguage}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <p className="m-0">{item?.noOfCandidates}</p>
                        </td>
                        <td>
                          <div
                            className="me-5 d-flex justify-content-start align-items-center"
                            style={{ minWidth: "20vw" }}
                          >
                            <>
                              <p
                                className="text-center m-0 p-1 rounded-start"
                                style={{
                                  background: "#0BB783",
                                  zIndex: "3",
                                  width:
                                    (item?.status?.completed /
                                      item?.noOfCandidates) *
                                      100 +
                                    "%",
                                  color: "white",
                                  display:
                                    item?.status?.completed > 0
                                      ? "block"
                                      : "none",
                                }}
                              >
                                {item?.status?.completed}
                              </p>
                            </>
                            <>
                              <p
                                className="text-center m-0 p-1"
                                style={{
                                  background: "#F2C94C",
                                  zIndex: "2",
                                  width:
                                    (item?.status?.started /
                                      item?.noOfCandidates) *
                                      100 +
                                    "%",
                                  marginLeft: "-15px",
                                  display:
                                    item?.status?.started > 0
                                      ? "block"
                                      : "none",
                                }}
                              >
                                {item?.status?.started}
                              </p>
                            </>
                            <>
                              <p
                                className="text-center m-0 p-1 rounded-end"
                                style={{
                                  background: "#F3F6F9",
                                  zIndex: "1",
                                  width:
                                    (item?.status?.notStarted /
                                      item?.noOfCandidates) *
                                      100 +
                                    "%",
                                  marginLeft: "-15px",
                                  display:
                                    item?.status?.notStarted > 0 &&
                                    item?.noOfCandidates > 0
                                      ? "block"
                                      : "none",
                                }}
                              >
                                {item?.status?.notStarted}
                              </p>
                            </>
                            <>
                              <p
                                className="text-center m-0 p-1 rounded-end"
                                style={{
                                  background: "#F3F6F9",
                                  zIndex: "1",
                                  width:
                                    (item?.status?.notStarted /
                                      item?.noOfCandidates) *
                                      100 +
                                    "%",
                                  marginLeft: "-15px",
                                  display:
                                    item?.noOfCandidates === 0
                                      ? "block"
                                      : "none",
                                }}
                              >
                                No invitation sent
                              </p>
                            </>
                          </div>
                        </td>
                        <td>
                          <p>
                            {new Intl.DateTimeFormat("en-IN").format(
                              new Date(item?.createdAt)
                            )}
                          </p>
                        </td>
                        <td>
                          <div className="d-flex justify-content-end align-items-center">
                            {/* <div className={`me-2 ${styles.icon}`}>
                              <Link to={`/viewcandidatelist/${item?._id}`}>
                                <ViewListIcon
                                  sx={{ fontSize: 24, color: "#3699FF" }}
                                />
                              </Link>
                            </div> */}
                            <div
                              className={`me-2 ${styles.icon}`}
                              onClick={() => {
                                setCurrentRowAssessmentId(item?._id);
                                setDrawerOpen(!drawerOpen);
                              }}
                            >
                              <PersonAddAltRoundedIcon
                                sx={{ fontSize: 24, color: "#3699FF" }}
                              />
                            </div>
                            <div className={`me-2 ${styles.icon}`}>
                              <Link to={`/editAssessment/${item?._id}`}>
                                <Edit sx={{ fontSize: 24, color: "#3699FF" }} />
                              </Link>
                            </div>
                            <div
                              // onClick={() => deleteAssessment(item?._id)}
                              className={`me-2 ${styles.icon}`}
                            >
                              {/* <Delete sx={{ fontSize: 24, color: "#3699FF" }} /> */}
                              <AlertDialogSlide
                                del={() => deleteAssessment(item._id)}
                                text={
                                  "Are you sure want to delete ?"
                                }
                                type="delete"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
                {deleteSuccess && (
                  <Snackbar
                    open={deleteSuccess}
                    autoHideDuration={3000}
                    onClose={handleCloseSnack}
                  >
                    <Alert
                      onClose={handleCloseSnack}
                      severity="success"
                      sx={{ width: "100%" }}
                    >
                      Deleted Successfully!
                    </Alert>
                  </Snackbar>
                )}
              </table>
            )}

            <TablePagination
              component="div"
              className={classes.pagination}
              count={gridData?.result || 0}
              page={pageNo}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAssesment;
