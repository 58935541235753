import { Card, CardContent, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import QuestionStatusMenu from "../../../QuestionStatusMenu/QuestionStatusMenu";
import { Roles, statusMap, statusOfQuestion } from "../../../../utils/constants";
import QuestionBankListService from "../../../../services/api/QuestionBankListService";
import QuestionListService from "../../../../services/api/QuestionListService";
import { useAuth } from "../../../../hooks/useAuth";
const QuestionReview = ({ questionId, status, setStatus }) => {
    const { user } = useAuth()
    const { enqueueSnackbar } = useSnackbar();

    const handleStatusChange = async(status, questionId) => {
        try{
            await QuestionBankListService.QuestionStatusChange({ questionId, status })
            const response = await QuestionListService.getQuestionById(questionId);
            if(!response.data.result) throw new Error(response.data.msg);
            setStatus(response.data.data.status);
            enqueueSnackbar("Status changed successfully", { variant: "success" });
        }catch(err){
            let errMsg = "Something went Wrong";
            if(err.isAxiosError){
                if(err.response){
                    if(!err.response.data.result) errMsg = err.response.data.msg;
                }
            }
            enqueueSnackbar(errMsg, { variant: "error" });
        }
    };

    return (
        <>
            <Typography
                variant={"h3"}
                component={"h3"}
                className={`font-size-base mg-b-20`}
            >
                Question Status
            </Typography>
            <div>
                {user.role === Roles.admin && (
                    <QuestionStatusMenu
                        options={statusOfQuestion}
                        setDropdown={(e) =>
                            handleStatusChange(e, questionId)
                        }
                        status={status}
                    />
                )}
                {user.role === Roles.contributor && (
                    <span className={`badge ${statusMap[status].class}`}>
                    {statusMap[status]?.label}
                </span>
                )}
            </div>
        </>
    );
};

export default QuestionReview;
