import {Button, Menu, MenuItem, Typography} from "@mui/material";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import TableCell from "@mui/material/TableCell";
import * as React from "react";
import {useState} from "react";

const action = [
    'Activate', 'Deactivate'
]

const HiringStageActionDropdown = () => {
    const [actionAnchorEl, setActionAnchorEl] = useState(null);
    const openAction = Boolean(actionAnchorEl);
    const handleActionOpen = (event) => {
        setActionAnchorEl(event.currentTarget);
    };
    const actionSelection = () => {
        actionClose();
    };
    const actionClose = () => {
        setActionAnchorEl(null);
    };
    return(
        <>
            <Button onClick={handleActionOpen}
                    className={`btn-toggle`}
                    variant={"outlined"} color={"secondary"} size={"small"}>
                <Typography noWrap
                            className="mg-r-4 pd-y-0 font-weight-medium font-size-sm">{false ? "" : "Select"}</Typography>
                {
                    openAction ? <KeyboardArrowUp className="toggle-icon" /> : <KeyboardArrowDown className="toggle-icon" />
                }
            </Button>
            <Menu
                id="role-menu"
                anchorEl={actionAnchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={openAction}
                onClose={actionClose}
            >
                {
                    action.map((stage, index)=>{
                        return(
                            <MenuItem key={index} onClick={actionSelection}>
                                <Typography textAlign="center">{stage}</Typography>
                            </MenuItem>
                        )
                    })
                }
            </Menu>
        </>
    )
}

export default HiringStageActionDropdown;