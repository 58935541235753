import Shape1 from './shape1.svg';
import Shape2 from './shape2.svg';
import Shape3 from './shape3.svg';
import Link from './link.svg';
import Trash from './trash.svg';
import Warning from './warning.svg';
import Help from './help.svg';
import React from './react.svg';
import Search from './search.svg';
import XIcon from './x-icon.svg';
import BtnPlay from './btn-play.svg';
import Dots from './Dots.svg';
import CheckCircle from './check-circle.svg';
import EditSquare from './edit-square.svg';
import Java from './java.svg';
import DotNet from './dot-net.svg';
import Profile from './profile.svg';
import ProfileEmpty from './profile-empty.svg';
import ProfileEmptySquare from './profile-empty-square.svg';
import ClockTime from './clock-time.svg';
import Award from './award.svg';
import Document from './document.svg';
import ArrowNext from './arrow-next.svg';
import PersonOnDesk from './person-on-desk.svg';
import LoginBanner from './login-banner.svg';
import Google from './google.svg';
import Logo from './logo.svg';
import Eye from './eye.svg';
import LogoWhite from './logo-white.svg';
import Notification from './notification.svg';

const IMAGES = {
    Shape1,
    Shape2,
    Shape3,
    Link,
    Trash,
    Warning,
    Help,
    React,
    Search,
    XIcon,
    BtnPlay,
    Dots,
    CheckCircle,
    EditSquare,
    Java,
    DotNet,
    Profile,
    ProfileEmpty,
    ProfileEmptySquare,
    ClockTime,
    Award,
    Document,
    ArrowNext,
    PersonOnDesk,
    LoginBanner,
    Google,
    Logo,
    Eye,
    LogoWhite,
    Notification
};

export default IMAGES;
