import { useState, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Box, Chip, InputLabel, MenuItem, Select } from "@mui/material";
import { Close, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { BootstrapInput } from "../../../utils/constants";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function SubSkillMultiSelect({ skills }) {
  const methods = useFormContext();
  const { setValue, watch,control, getValues } = methods;
  const [secondarySkills, setSecondarySkills] = useState([]);
  const [selectedSecondarySkills, setSelectedSecondarySkills] = useState([]);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (e) => {
    setOpen(true);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setValue(
      "secondarySkills",
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChipDelete = (value) => {
    const secondarySkills = getValues("secondarySkills");
    const result = secondarySkills.filter(item => item !== value);
    setValue("secondarySkills", result);
  }

  const skillWatch = useWatch({
    control,
    name: "primarySkill",
  });

  const secondarySkillsWatch = useWatch({
    control,
    name: "secondarySkills",
  });

  useEffect(()=>{
    const skill = skills.find(({_id}) => _id === skillWatch);
    skill && setSecondarySkills(skill.subSkills);

  },[skillWatch])


 
  useEffect(() => {
    setSelectedSecondarySkills(secondarySkillsWatch);
  }, [secondarySkillsWatch]);

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      if (name === "primarySkill") {
        setValue("secondarySkills", []);
        setSelectedSecondarySkills([]);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const getLabelFromValue = (chipValue) => {
    let chipLabel;
    secondarySkills.forEach(({ _id, label }) => {
      if (_id === chipValue) {
        chipLabel = label;
      }
    });
    if(!chipLabel) chipLabel = "Deleted";
    return chipLabel;
  };


  return (
    <Select
      id="demo-multiple-checkbox"
      className="multiSelect w-100"
      displayEmpty
      multiple
      value={selectedSecondarySkills}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      onChange={handleChange}
      input={<BootstrapInput />}
      IconComponent={() =>
        open ? (
          <KeyboardArrowUp className="toggle-icon" />
        ) : (
          <KeyboardArrowDown className="toggle-icon" />
        )
      }
      renderValue={(selected) => {
        if (selected.length === 0) {
          return (
            <InputLabel id="demo-multiple-checkbox-label">
              Select skills
            </InputLabel>
          );
        }
        return (
          <Box className="d-flex align-items-center flex-wrap">
            {selected?.map((value) => (
              <Chip
                key={value}
                label={getLabelFromValue(value)}
                onDelete={() => handleChipDelete(value)}
                deleteIcon={<Close onMouseDown={(event) => event.stopPropagation()} />}
                className="chip-purple mg-r-10 mg-y-4"
              />
            ))}
            <InputLabel id="demo-multiple-checkbox-label">
              Select skills
            </InputLabel>
          </Box>
        );
      }}
      MenuProps={MenuProps}
    >
      <MenuItem className="menu-item pd-y-0 mg-y-0" disableRipple={true}>
        <form className="w-100" onKeyDown={(e) => e.stopPropagation()}>
          <Box className="pd-y-24">
            <input
              type="search"
              className="form-control"
              placeholder="Search"
              autoComplete="off"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </Box>
        </form>
      </MenuItem>
      {secondarySkills
        ?.filter(({ label }) =>
          label.toLowerCase().includes(search.toLowerCase())
        )
        ?.map(({ label, _id }) => (
          <MenuItem
            className="menu-item"
            key={label}
            value={_id}
            disableRipple={true}
          >
            <div className="custom-checkbox">
              <input
                className="custom-control-input"
                type="checkbox"
                id={`${label}`}
                name={`${label}`}
                checked={selectedSecondarySkills.indexOf(_id) > -1}
                onChange={() => {}}
              />
              <label
                className="custom-control-label text-capitalize font-size-base"
                htmlFor={`${label}`}
              >
                {label}
              </label>
            </div>
          </MenuItem>
        ))}
    </Select>
  );
}

export default SubSkillMultiSelect;
