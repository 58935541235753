import axios from "axios";
import { fetchAccessToken } from "../../utils/constants";
const silentAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:5000/",
  headers: {
    "Content-type": "application/json",
  },
});
silentAxiosInstance.interceptors.request.use((config) => {
  const accessToken =
    localStorage.getItem("accessToken") &&
    JSON.parse(localStorage.getItem("accessToken"));
  config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : "";
  return config;
});
silentAxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        localStorage.removeItem("accessToken");
        const accessToken = await fetchAccessToken();
        error.config.headers.Authorization = accessToken
          ? `Bearer ${accessToken}`
          : "";
        return silentAxiosInstance(originalRequest);
      }
      const data = error.response.data;
      if (data.result === 0) {
      }
    }
    throw error;
  }
);
export default silentAxiosInstance;
