import React, {useEffect} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import RoutingPaths from "../../utils/RoutingPaths";
import styles from "./Login.module.scss";
import IMAGES from "../../assets/images/images";
import {Button, Container, Divider, Typography} from "@mui/material";

const Welcome = () => {
  let navigate = useNavigate();
  const changeAuthMode = () => {
    navigate(RoutingPaths.Login);
  };

  useEffect(()=>{
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  },[])

  return (
    <>
      {
        true ?
            <>
              <div>
                <div>
                  <h3>Authenticated!!Welcome to HireTripple</h3>
                  <p>
                    Please verify your email address from the link sent on your registered
                    email address and then
                    <span
                        className="link-primary"
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                        onClick={changeAuthMode}
                    >
            login
          </span>
                  </p>
                </div>
              </div></>
            :
            <>
              <div className={`row g-0`}>
                <div className="col-md-6">
                  <div className={`${styles.loginBanner}`}>
                    <img className={`img-fluid`} src={IMAGES.LoginBanner} alt="desk"/>
                  </div>
                </div>
                <div className="col-md-6">
                  <Container maxWidth={false}>
                    <div className={`${styles.loginPanel} d-flex justify-content-center align-items-center`}>
                      <form className={`${styles.loginForm}`}>
                        <div className={`mg-b-50 text-center`}>
                          <img className={`img-fluid`} src={IMAGES.Logo} alt="logo"/>
                        </div>
                        <Typography variant={"h2"} component={"h2"} className={`${styles.heading30} mg-b-10`}>
                          Welcome!
                        </Typography>
                        <Typography className={`${styles.heading24} mg-b-28`}>Tell us a little bit about you</Typography>
                        <div className={`mg-b-20`}>
                          <label className={`form-label font-weight-medium`}>What’s your name?</label>
                          <div className={'row g-3'}>
                            <div className="col-md-6">
                              <input
                                  type="text"
                                  className="form-control form-control-lg"
                                  placeholder="First name*"
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                  type="text"
                                  className="form-control form-control-lg"
                                  placeholder="Last name*"
                              />
                            </div>
                          </div>
                        </div>
                        <div className={`mg-b-20`}>
                          <label className={`form-label font-weight-medium`}>Where do you work?</label>
                          <input
                              type="text"
                              className="form-control form-control-lg"
                              placeholder="Company name"
                          />
                        </div>

                        <div>
                          <button type={"submit"} className={'btn btn-primary text-white btn-lg w-100'}>
                            Continue
                            <img className={`mg-l-10`} src={IMAGES.ArrowNext} alt="arrow-next"/>
                          </button>
                        </div>
                      </form>
                    </div>
                  </Container>
                </div>
              </div>
            </>
      }
    </>
  );
};

export default Welcome;
