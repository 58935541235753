import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Components/Auth/Login";
import Signup from "./Components/Auth/Signup";
import { Account } from "./Components/Auth/Account";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import RoutingPaths from "./utils/RoutingPaths";
import Dashboard from "./Components/Dashboard/Dashboard";
import Welcome from "./Components/Auth/Welcome";
import CreateAssessment from "./Components/Assesment/CreateAssesment/CreateAssesment";
import MyAssessment from "./Components/Assesment/MyAssesment/MyAssesment";
import RouteInterceptor from "./Components/routeInterceptor/RouteInterceptor";
import CodingQuestions from "./Components/CodeEditor/CodingQuestion2";
import Coding from "./Components/HomePage/Question/Coding/Coding";
import ViewCandidateList from "./Components/Assesment/MyAssesment/CandidateList/ViewCandidateList";
import ManageHiringStages from "./Components/Assesment/MyAssesment/CandidateList/ManageHiringStage/ManageHiringStages";
import NewQuestion from "./Components/HomePage/NewQuestion/NewQuestion";
import QuestionList from "./Components/HomePage/QuestionList/QuestionList";
import QuestionBankList from "./Components/HomePage/QuestionBankList/QuestionBankList";
import GenericSignUp from "./Components/Auth/GenericSignUp";
import PrivateRoutes from "./Components/PrivateRoutes";
import LoginLoader from "./Components/Auth/LoginLoader";
import NewAssessment from "./Components/Assesment/NewAssessment/NewAssessment";
import QualifyingQuestion from "./Components/Assesment/CreateAssesment/QualifyingQuestion/QualifyingQuestion";
import Profile from "./Components/Profile/Profile";
import DemoChangePassword from "./Components/Auth/DemoChangePassword";
import Profile2 from "./Components/Profile/Profile2";
import ManageHiringStage2 from "./Components/Assesment/MyAssesment/CandidateList/ManageHiringStage/ManageHiringStages2";
import ManageHiringStages2 from "./Components/Assesment/MyAssesment/CandidateList/ManageHiringStage/ManageHiringStages2";
import CreateTest from "./Components/CreateTest/CreateTest";
import TestLibrary from "./Components/TestLibrary/TestLibrary";
import Skill from "./Components/Skill/skill";
import SubSkill from "./Components/Skill/SubSkill";
import Author from "./Components/Author/Author";
import QuestionReport from "./Components/Reports/QuestionReport/QuestionReport";
import User from "./Components/User/User";


export default function routes() {
  return (
    <BrowserRouter>
      <Account>
        <RouteInterceptor />

        {/* //! ****************************** UnProtected Routes **************************************************** */}
        <Routes>
          <Route exact path={RoutingPaths.Login} element={<Login />} />
          <Route path={RoutingPaths.LoginLoader} element={<LoginLoader />} />
          <Route path={RoutingPaths.Signup} element={<Signup />} />
          <Route
            path={RoutingPaths.ForgotPassword}
            element={<ForgotPassword />}
          />
          <Route path={RoutingPaths.Welcome} element={<Welcome />} />
          <Route
            path={RoutingPaths.GenericSignUp}
            element={<GenericSignUp />}
          />

          {/* // ! ***************************** Protected Routes ***************************************************** */}
          <Route element={<PrivateRoutes />}>
            <Route path={RoutingPaths.Dashboard} element={<Dashboard />} />
            <Route
              path={RoutingPaths.CreateAssessment}
              element={<CreateAssessment />}
            />
            <Route
              path={RoutingPaths.CodingQuestion}
              element={<CodingQuestions />}
            />
            <Route
              path={RoutingPaths.MyAssessment}
              element={<MyAssessment />}
            />
            <Route path={RoutingPaths.Coding} element={<Coding />} />
            <Route
              path={RoutingPaths.manageHiringStages}
              element={<ManageHiringStages2 />}
            />
            <Route
              path={RoutingPaths.MyAssessment}
              element={<MyAssessment />}
            />
            <Route path="/editAssessment/:id" element={<CreateAssessment />} />
            <Route
              path={RoutingPaths.ViewCandidateList}
              element={<ViewCandidateList />}
            />
            <Route path={RoutingPaths.EditQuestion} element={<NewQuestion />} />
            <Route path={RoutingPaths.CloneQuestion} element={<NewQuestion />} />
            <Route path={RoutingPaths.NewQuestion} element={<NewQuestion />} />
            <Route path={RoutingPaths.Questions} element={<QuestionList />} />
            <Route
              path={RoutingPaths.QuestionBankList}
              element={<QuestionBankList />}
            />
            <Route
              path={RoutingPaths.NewAssessment}
              element={<NewAssessment />}
            />
            <Route
              path={RoutingPaths.QualifyingQuestion}
              element={<QualifyingQuestion />}
            />
            {/* <Route path={RoutingPaths.Profile} element={<Profile />} /> */}
            <Route path={RoutingPaths.Profile} element={<Profile2 />} />

            <Route
              path={RoutingPaths.ChangePassword}
              element={<DemoChangePassword />}
            />
            <Route
                path={RoutingPaths.TestLibrary}
                element={<TestLibrary />}
            />
            <Route
                path={RoutingPaths.CreateTest}
                element={<CreateTest />}
            />
            <Route
                path={RoutingPaths.skills}
                element={<Skill />}
            />
            <Route
                path={RoutingPaths.subSkills}
                element={<SubSkill />}
            />
            <Route
                path={RoutingPaths.authors}
                element={<Author />}
            />
            <Route
                path={RoutingPaths.questionReports}
                element={<QuestionReport />}
            />
            <Route
                path={RoutingPaths.users}
                element={<User />}
            />
          </Route>
        </Routes>
      </Account>
    </BrowserRouter>
  );
}
