import { CognitoUser } from "amazon-cognito-identity-js";
import { useSnackbar } from "notistack";
import React, {useEffect, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import RoutingPaths from "../../utils/RoutingPaths";
import Pool from "../../utils/UserPool";
import styles from "./Login.module.scss";
import IMAGES from "../../assets/images/images";
import {Button, Container, Divider, Typography, Link} from "@mui/material";

export default function ForgotPassword() {
  const [stage, setStage] = useState(1); // 1 = email stage, 2 = code stage
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();

  const getUser = () => {
    return new CognitoUser({
      Username: email.toLowerCase(),
      Pool,
    });
  };

  const sendCode = (event) => {
    event.preventDefault();

    getUser().forgotPassword({
      onSuccess: (data) => {
        // enqueueSnackbar("Verification code sent. Please check your email", {
        //   variant: "success",
        // });
      },
      onFailure: (err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      },
      inputVerificationCode: (data) => {
        enqueueSnackbar("Verification code sent. Please check your email", {
          variant: "success",
        });
        setStage(2);
      },
    });
  };

  const resetPassword = (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      enqueueSnackbar("Passwords are not the same", {
        variant: "error",
      });
      return;
    }

    getUser().confirmPassword(code, password, {
      onSuccess: (data) => {
        enqueueSnackbar("Password changed successfully", {
          variant: "success",
        });
        navigate(RoutingPaths.Login);
      },
      onFailure: (err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
        return
      },
    });
  };

  useEffect(()=>{
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  },[])

  return (
    <>
      {true? <div className="Auth-form-container">
        {stage === 1 && (
            <form className="Auth-form" onSubmit={sendCode}>
              <div className="Auth-form-content">
                <h3 className="Auth-form-title">Reset Password</h3>
                <div className="form-group mt-3">
                  <label htmlFor="email">Email</label>
                  <input
                      type="email"
                      className="form-control mt-1"
                      placeholder="Enter Registered Email"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                  />
                  <div className="d-grid gap-2 mt-3">
                    <button type="submit" className="btn btn-primary">
                      Send verification code
                    </button>
                  </div>
                </div>
              </div>
            </form>
        )}

        {stage === 2 && (
            <form className="Auth-form" onSubmit={resetPassword}>
              <div className="Auth-form-content">
                <h3 className="Auth-form-title">Reset Password</h3>
                <div className="form-group mt-3">
                  <label>Verification Code</label>
                  <input
                      type="password"
                      className="form-control mt-1"
                      placeholder="Enter Verification Code"
                      value={code}
                      onChange={(event) => setCode(event.target.value)}
                  />
                </div>
                <div className="form-group mt-3">
                  <label>New Password</label>
                  <input
                      type="password"
                      className="form-control mt-1"
                      placeholder="Enter New Password"
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                  />
                </div>
                <div className="form-group mt-3">
                  <label>Confirm Password</label>
                  <input
                      type="password"
                      className="form-control mt-1"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(event) => setConfirmPassword(event.target.value)}
                  />
                </div>
                <div className="d-grid gap-2 mt-3">
                  <button type="submit" className="btn btn-primary">
                    Change password
                  </button>
                </div>
              </div>
            </form>
        )}
      </div> :
          <>
            <div>
              <Container maxWidth={false}>
                <div className={`${styles.loginPanel} d-flex justify-content-center align-items-center`}>
                  <form className={`${styles.loginForm} ${styles.forgotPassword}`}>
                    <div className={`mg-b-50 text-center`}>
                      <img className={`img-fluid`} src={IMAGES.Logo} alt="logo"/>
                    </div>
                    {
                      stage === 1 ?
                          <>
                            <Typography variant={"h2"} component={"h2"} className={`${styles.heading30} mg-b-10`}>
                              Forgot your password?
                            </Typography>
                            <Typography className={`text-black-11 mg-b-30`}>Enter your email address to reset your password. You may need to check your spam folder or unblock no-reply@hiretriple.com</Typography>
                            <div className={`mg-b-20`}>
                              <input
                                  type="email"
                                  value={email}
                                  className="form-control form-control-lg"
                                  placeholder="Email*"
                                  onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </>
                          :
                          <>
                            <Typography variant={"h2"} component={"h2"} className={`${styles.heading30} mg-b-10`}>
                              Forgotten your password?
                            </Typography>
                            <Typography className={`text-black-11 mg-b-30`}>Enter a new password for your <Link href={'mailto:mukesh@metadesignsolutions.co.uk'} className={`btn btn-link p-0 border-0 text-decoration-none text-dark font-weight-medium`}>
                              mukesh@metadesignsolutions.co.uk
                            </Link> account.</Typography>
                            <div className={`mg-b-20`}>
                              <input
                                  type="password"
                                  className="form-control form-control-lg mg-b-20"
                                  placeholder="New password"
                                  value={password}
                                  onChange={(event) => setPassword(event.target.value)}
                              />
                              <input
                                  type="password"
                                  className="form-control form-control-lg"
                                  placeholder="Enter New Password"
                                  value={password}
                                  onChange={(event) => setPassword(event.target.value)}
                              />
                            </div>
                          </>
                    }
                    <div>
                      <button type={"submit"} className={'btn btn-primary text-white btn-lg w-100'}>
                        Submit
                        <img className={`mg-l-10`} src={IMAGES.ArrowNext} alt="arrow-next"/>
                      </button>
                    </div>
                    { stage === 1 &&
                    <Typography className={`text-black-11 mg-t-28`}>Email on <Link href={'mailto:contact@hiretriple.com'} className={`btn btn-link p-0 border-0 text-decoration-none text-dark font-weight-medium`}>
                      contact@hiretriple.com
                    </Link> if you can't recover your account using this page</Typography>
                    }
                    <div className={`${styles.dividerWithText} text-center`}>
                      <Divider className={styles.divider}/>
                      <Typography variant={"body1"} component={"span"} color={"secondary"} className={`${styles.text} font-size-lg font-weight-medium d-inline-block mg-y-20`}>
                        or
                      </Typography>
                    </div>
                    <Typography className={'text-center text-black-11'}>
                      <NavLink className={`btn btn-link p-0 text-decoration-none font-weight-medium`}
                               to={RoutingPaths.Login}>
                        Sign in
                      </NavLink> / <NavLink className={`btn btn-link p-0 text-decoration-none font-weight-medium`}
                                            to={RoutingPaths.Signup}>
                      Sign up
                    </NavLink>
                    </Typography>
                  </form>
                </div>
              </Container>
            </div>
          </>
      }
    </>
  );
}
