import { TextareaAutosize } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import styles from "./Controller.module.scss";

function TextAreaController({ name, label }) {
  const methods = useFormContext();
  const {
    control,
    formState: { errors },
  } = methods;
  return (
    <div style={{ flex: 1 }}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <div style={{ width: "100%" }}>
            <TextareaAutosize
              minRows={5}
              maxRows={5}
              onChange={onChange}
              value={value}
              placeholder={label}
              className={styles.textArea}
              // error={errors.name?true:false}
            />
          </div>
        )}
      />
    </div>
  );
}

export default TextAreaController;
