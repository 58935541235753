import Header from "../../Header/Header";
import React, { useState } from "react";
import Statusbar from "../Statusbar/Statusbar";
import SkillsTechnology from "../CreateAssesment/SkillsTechnology/SkillsTechnology";
import ReviewAndConfigure from "../CreateAssesment/ReviewAndConfigure/ReviewAndConfigure";
import { FormProvider, useForm } from "react-hook-form";
import NameAssessment from "../CreateAssesment/NameAssessment/NameAssessment";
import { useEffect } from "react";
import SkillsService from "../../../services/api/SkillsService";
import { useDispatch, useSelector } from "react-redux";
import {
  assessmentDataAction,
  decreaseActiveStepAction,
  increaseActiveStepAction,
  setActiveStepAction,
} from "../../../utils/redux/slices/assessmentSlice";
import { useSnackbar } from "notistack";
import axiosInstance from "../../../services/api/client";
import { getAssessmentAPI } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import RoutingPaths from "../../../utils/RoutingPaths";
import {
  removeAllCustomQuestionAction,
  removeAllQualifyingQuestionAction,
} from "../../../utils/redux/slices/customQuestionSlice";

const NewAssessment = () => {
  const navigate = useNavigate();
  const [secondarySkillsListMap, setSecondarySkillsListMap] = useState({});
  const activeStep = useSelector((state) => state.AssessmentSlice.activeStep);
  const dispatch = useDispatch();
  const storedAssessmentData = useSelector(
    (state) => state.AssessmentSlice.assessmentData
  );
  const qualifyingQuestionData = useSelector(
    (state) => state.CustomQuestionSlice.qualifyingQuestions
  );
  const customQuestionData = useSelector(
    (state) => state.CustomQuestionSlice.customQuestions
  );
  const videoQuestionData = useSelector(
    (state) => state.videoQuestionSlice.videoQuestions
  );

  const assessmentMethods = useForm({
    defaultValues: {
      assessmentName: "",
      jobRole: "",
      description: "",
      skillsAndTechnology: [{ skill: "", subSkill: [], complexity: "" }],
      videoBlob: [],
      isAntiCheatingSettingEnabled: false,
    },
  });

  const { getValues, setValue, watch } = assessmentMethods;
  const getPayloadAssessment = () => {
    const values = getValues();
    let skillsMap = [];
    let introOutroVideosArr = [];
    const qualifyingQuestionIds = qualifyingQuestionData?.map(({ _id }) => _id);
    const customQuestionIds = customQuestionData?.map(({ _id }) => _id);
    videoQuestionData?.forEach((question) => {
      let obj;
      if (question?.detail?.video) {
        obj = {
          video: question.detail.video,
          videoType: question.videoType ? question.videoType : "INTRO",
        };
      } else {
        obj = {
          video: question._id,
          videoType: question.videoType ? question.videoType : "INTRO",
        };
      }

      introOutroVideosArr.push(obj);
    });
    values.skillsAndTechnology.forEach(
      ({ skill: primarySkill, subSkill: secondarySkills, complexity }) => {
        let obj = {
          primarySkill: primarySkill,
          secondarySkills: secondarySkills,
          complexity: complexity,
        };
        skillsMap.push(obj);
      }
    );
    let payload;
    payload = {
      title: values.assessmentName,
      jobRole: values.jobRole,
      description: values.description,
      time: 3600,
      preferedLanguage: "English",
      skills: skillsMap,
      qualifyingQuestions: qualifyingQuestionIds,
      customQuestions: customQuestionIds,
      introOutroVideos: introOutroVideosArr,
      isAntiCheatingSettingEnabled: values.isAntiCheatingSettingEnabled,
      // introOutroVideos: [
      //   {
      //     video: "",
      //     videoType: "INTRO",
      //   },
      // ],
    };
    return payload;
  };

  const SaveQuestion = async () => {
    try {
      const payload = await getPayloadAssessment();
      const response = await axiosInstance.post(getAssessmentAPI, payload);

      if (response.data.result === 1) {
        enqueueSnackbar(response.msg || "Saved Successfully", {
          variant: "success",
        });
        dispatch(removeAllQualifyingQuestionAction());
        dispatch(removeAllCustomQuestionAction());
        dispatch(setActiveStepAction(0));
        navigate(RoutingPaths.Dashboard);
      }
    } catch (error) {
      enqueueSnackbar(error.msg || "Something went wrong", {
        variant: "error",
      });
    }
  };
  useEffect(() => {
    if (activeStep === 2) {
      setValue("assessmentName", storedAssessmentData.assessmentName);
      setValue("jobRole", storedAssessmentData.jobRole);
      setValue("description", storedAssessmentData.description);
      setValue("skillsAndTechnology", storedAssessmentData.skillsAndTechnology);
    }
  }, [activeStep]);
  console.log(getValues());
  const { enqueueSnackbar } = useSnackbar();

  const increaseActiveStep = () => {
    const values = getValues();
    console.log(values);
    if (values.assessmentName === "" || values.jobRole === "") {
      enqueueSnackbar("Please fill all the fields", {
        variant: "error",
      });
      return;
    } else {
      dispatch(increaseActiveStepAction());
    }
    if (activeStep === 1) {
      const values = getValues();
      console.log(values);
      dispatch(assessmentDataAction(values));
    }
  };
  const decreaseActiveStep = () => {
    dispatch(decreaseActiveStepAction());
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name.endsWith("skill")) {
        const index = name.split(".")[1];
        handlePrimarySkillChange(value.skillsAndTechnology[index].skill);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handlePrimarySkillChange = (primarySkillId) => {
    if (!secondarySkillsListMap[primarySkillId]) {
      SkillsService.getSecondarySkills(primarySkillId).then((response) => {
        const data = response?.data?.data;
        setSecondarySkillsListMap((prev) => ({
          ...prev,
          [primarySkillId]: data,
        }));
      });
    }
  };

  return (
    <FormProvider {...assessmentMethods}>
      <Header />
      <Statusbar saveAssessment={SaveQuestion} activeStep={activeStep} />
      <div className={`page-spacing pd-y-30`}>
        <div className={"container-fluid"}>
          {activeStep === 0 && (
            <NameAssessment
              increaseActiveStep={increaseActiveStep}
              decreaseActiveStep={decreaseActiveStep}
            />
          )}
          {activeStep === 1 && (
            <SkillsTechnology
              increaseActiveStep={increaseActiveStep}
              decreaseActiveStep={decreaseActiveStep}
              secondarySkillsListMap={secondarySkillsListMap}
            />
          )}
          {activeStep === 2 && (
            <ReviewAndConfigure decreaseActiveStep={decreaseActiveStep} />
          )}
        </div>
      </div>
    </FormProvider>
  );
};

export default NewAssessment;
