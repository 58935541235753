import Routes from "./Routes";
import "./Styles.scss";
import axios from "axios";
import { customTheme } from "./assets/themes/MUI-override/customTheme";
import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";

axios.defaults.baseURL =
  process.env.REACT_APP_API_URL || "http://localhost:5000";

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <SnackbarProvider
        maxSnack={1}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        preventDuplicate
      >
        <Routes />
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
