import React from "react";
import { Card, CardContent, Divider, Typography } from "@mui/material";
import IMAGES from "../../../assets/images/images";
import { useFormContext } from "react-hook-form";
import { complexityTypeMap } from "../../../utils/constants";
import DetailsDialog from "../../common/DetailsDialog/DetailsDialog";
import { useState } from "react";

const CreateTestReview = () => {
  const { getValues } = useFormContext();
  const [open, setOpen] = useState(false);
  const values = getValues();
  const viewDetails = () => {
    setOpen(true);
  };
  return (
    <>
      <div className={`page-spacing pd-t-30`}>
        <div className={`container-fluid`}>
          <Card className={`mg-b-30`}>
            <CardContent className={`p-0`}>
              <div
                className={`pd-x-16 pd-y-16 d-flex align-items-center justify-content-between`}
              >
                <Typography
                  variant={"h2"}
                  component={"h2"}
                  className={`heading2`}
                >
                  {values.testName}
                </Typography>
                <Typography variant={"body2"} component={"span"}>
                  <span className={`text-secondary`}>Complexity:</span>
                  {complexityTypeMap[values.complexity]}
                </Typography>
              </div>
              <Divider />
              <div className={`pd-x-16 pd-y-16`}>
                <Typography
                  variant={"h2"}
                  component={"h2"}
                  className={`mg-b-10`}
                >
                  Description
                </Typography>
                <Typography
                  variant={"body1"}
                  component={"span"}
                  color={"secondary"}
                  className={`mg-b-10 font-size-sm mg-b-10`}
                >
                  {values.description}
                </Typography>
                <div>
                  <button
                    type={"button"}
                    className={"btn btn-link text-decoration-none p-0"}
                  >
                    Read more...
                  </button>
                </div>
              </div>
            </CardContent>
          </Card>
          <section className={"mg-b-30"}>
            <div
              className={`d-flex align-items-center justify-content-between mg-b-15`}
            >
              <Typography
                variant={"h2"}
                component={"h2"}
                className={"heading2"}
              >
                Selected question{" "}
                <Typography
                  variant={"body1"}
                  component={"span"}
                  color={"secondary"}
                >
                  ({values.selectedTestQuestions.length})
                </Typography>
              </Typography>
              <Typography
                variant={"body1"}
                component={"span"}
                color={"primary"}
                className={`font-weight-semi-bold`}
              >
                <i className={`icon-clock-circle`}></i> 15min
              </Typography>
            </div>
            <DetailsDialog open={open} setOpen={setOpen} />
            <div className={`row g-3`}>
              {values.selectedTestQuestions.map((question) => (
                <div className={`col-md-6`}>
                  <Card>
                    <CardContent className={`pd-b-16`}>
                      <div className={`d-flex mg-b-10`}>
                        <div
                          className={`img-asset-box img-asset-32 asset-warning mg-r-10`}
                        >
                          <img
                            className={`img-fluid`}
                            src={IMAGES.React}
                            alt="..."
                          />
                        </div>
                        <div>
                          <Typography
                            variant={"h4"}
                            component={"h4"}
                            className={`mg-b-10 font-size-15`}
                          >
                            {question.title}
                          </Typography>
                          <Typography
                            variant={"body1"}
                            component={"span"}
                            color={"secondary"}
                            className={`mg-b-10 font-size-sm`}
                          >
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit sed do eius magna aliqua...
                          </Typography>
                          <Typography
                            variant={"body2"}
                            className={`font-weight-medium hash-tag`}
                          >
                            #NodeJs
                          </Typography>
                        </div>
                      </div>
                      <div
                        className={`d-flex align-items-center justify-content-between`}
                      >
                        <Typography
                          variant={"body2"}
                          component={"p"}
                          color={"secondary"}
                        >
                          Intermediate
                          <span className={`mg-x-10`}>|</span>
                          <span className={`text-nowrap`}>
                            <i className={`icon-document mg-r-6`}></i>Short Text
                          </span>
                          <span className={`mg-x-10`}>|</span>
                          <span className={`text-nowrap`}>
                            <i className={`icon-clock-time mg-r-6`}></i>15 min
                          </span>
                        </Typography>
                        <button
                          type={"button"}
                          className={`btn btn-sm btn-snow mg-l-10`}
                          onClick={viewDetails}
                        >
                          <i className={`icon-eye`}></i>
                        </button>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              ))}

              {/* <div className={`col-md-6`}>
                <Card>
                  <CardContent className={`pd-b-16`}>
                    <div className={`d-flex mg-b-10`}>
                      <div
                        className={`img-asset-box img-asset-32 asset-warning mg-r-10`}
                      >
                        <img
                          className={`img-fluid`}
                          src={IMAGES.React}
                          alt="..."
                        />
                      </div>
                      <div>
                        <Typography
                          variant={"h4"}
                          component={"h4"}
                          className={`mg-b-10 font-size-15`}
                        >
                          Beginner level algorithms for Software Engineering
                        </Typography>
                        <Typography
                          variant={"body1"}
                          component={"span"}
                          color={"secondary"}
                          className={`mg-b-10 font-size-sm`}
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit sed do eius magna aliqua...
                        </Typography>
                        <Typography
                          variant={"body2"}
                          className={`font-weight-medium hash-tag`}
                        >
                          #NodeJs
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={`d-flex align-items-center justify-content-between`}
                    >
                      <Typography
                        variant={"body2"}
                        component={"p"}
                        color={"secondary"}
                      >
                        Intermediate
                        <span className={`mg-x-10`}>|</span>
                        <span className={`text-nowrap`}>
                          <i className={`icon-document mg-r-6`}></i>Short Text
                        </span>
                        <span className={`mg-x-10`}>|</span>
                        <span className={`text-nowrap`}>
                          <i className={`icon-clock-time mg-r-6`}></i>15 min
                        </span>
                      </Typography>
                      <button
                        type={"button"}
                        className={`btn btn-sm btn-snow mg-l-10`}
                      >
                        <i className={`icon-eye`}></i>
                      </button>
                    </div>
                  </CardContent>
                </Card>
              </div> */}
            </div>
          </section>
          {/* <section className={"mg-b-30"}>
            <div
              className={`d-flex align-items-center justify-content-between mg-b-15`}
            >
              <Typography
                variant={"h2"}
                component={"h2"}
                className={"heading2"}
              >
                Practice questions{" "}
                <Typography
                  variant={"body1"}
                  component={"span"}
                  color={"secondary"}
                >
                  (1)
                </Typography>
              </Typography>
              <Typography
                variant={"body1"}
                component={"span"}
                color={"primary"}
                className={`font-weight-semi-bold`}
              >
                <i className={`icon-clock-circle`}></i> 15min
              </Typography>
            </div>
            <div className={`row g-3`}>
              <div className={`col-md-6`}>
                <Card>
                  <CardContent className={`pd-b-16`}>
                    <div className={`d-flex mg-b-10`}>
                      <div
                        className={`img-asset-box img-asset-32 asset-warning mg-r-10`}
                      >
                        <img
                          className={`img-fluid`}
                          src={IMAGES.React}
                          alt="..."
                        />
                      </div>
                      <div>
                        <Typography
                          variant={"h4"}
                          component={"h4"}
                          className={`mg-b-10 font-size-15`}
                        >
                          Beginner level algorithms for Software Engineering
                        </Typography>
                        <Typography
                          variant={"body1"}
                          component={"span"}
                          color={"secondary"}
                          className={`mg-b-10 font-size-sm`}
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit sed do eius magna aliqua...
                        </Typography>
                        <Typography
                          variant={"body2"}
                          className={`font-weight-medium hash-tag`}
                        >
                          #NodeJs
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={`d-flex align-items-center justify-content-between`}
                    >
                      <Typography
                        variant={"body2"}
                        component={"p"}
                        color={"secondary"}
                      >
                        Intermediate
                        <span className={`mg-x-10`}>|</span>
                        <span className={`text-nowrap`}>
                          <i className={`icon-document mg-r-6`}></i>Short Text
                        </span>
                        <span className={`mg-x-10`}>|</span>
                        <span className={`text-nowrap`}>
                          <i className={`icon-clock-time mg-r-6`}></i>15 min
                        </span>
                      </Typography>
                      <button
                        type={"button"}
                        className={`btn btn-sm btn-snow mg-l-10`}
                      >
                        <i className={`icon-eye`}></i>
                      </button>
                    </div>
                  </CardContent>
                </Card>
              </div>
              <div className={`col-md-6`}>
                <Card>
                  <CardContent className={`pd-b-16`}>
                    <div className={`d-flex mg-b-10`}>
                      <div
                        className={`img-asset-box img-asset-32 asset-warning mg-r-10`}
                      >
                        <img
                          className={`img-fluid`}
                          src={IMAGES.React}
                          alt="..."
                        />
                      </div>
                      <div>
                        <Typography
                          variant={"h4"}
                          component={"h4"}
                          className={`mg-b-10 font-size-15`}
                        >
                          Beginner level algorithms for Software Engineering
                        </Typography>
                        <Typography
                          variant={"body1"}
                          component={"span"}
                          color={"secondary"}
                          className={`mg-b-10 font-size-sm`}
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit sed do eius magna aliqua...
                        </Typography>
                        <Typography
                          variant={"body2"}
                          className={`font-weight-medium hash-tag`}
                        >
                          #NodeJs
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={`d-flex align-items-center justify-content-between`}
                    >
                      <Typography
                        variant={"body2"}
                        component={"p"}
                        color={"secondary"}
                      >
                        Intermediate
                        <span className={`mg-x-10`}>|</span>
                        <span className={`text-nowrap`}>
                          <i className={`icon-document mg-r-6`}></i>Short Text
                        </span>
                        <span className={`mg-x-10`}>|</span>
                        <span className={`text-nowrap`}>
                          <i className={`icon-clock-time mg-r-6`}></i>15 min
                        </span>
                      </Typography>
                      <button
                        type={"button"}
                        className={`btn btn-sm btn-snow mg-l-10`}
                      >
                        <i className={`icon-eye`}></i>
                      </button>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
          </section> */}
        </div>
      </div>
    </>
  );
};

export default CreateTestReview;
