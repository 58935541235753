import { Add, Delete, Edit, TripOrigin } from "@mui/icons-material";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import {
  Checkbox,
  Divider,
  Grid,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { Form, Formik } from "formik";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import JavaImg from "../../../Files/img/stack-1.png";
import Header from "../../Header/Header";
import {
  createAssesmentPOST,
  editAssesmentPUT,
  getAssessmentById,
} from "./actions";
import AddQuestion from "./AddQuestion";
import styles from "./CreateAssesment.module.scss";
import axios from "axios";
import { categoryAPI } from "../../../utils/constants";
import AlertDialogSlide from "../MyAssesment/CandidateList/Slider/PopUp/AlertDialogSlide";
import Snackbars from "../MyAssesment/Snackbar";
import Statusbar from "../Statusbar/Statusbar";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const steps = [
  "Name Assessment",
  "Role & Technology",
  // 'Add Question',
  "Review & Configure",
];

const initData = {
  assesmentName: "",
  jobRole: "",
  description: "",
  skills: Array(1).fill({
    technology: "",
    expLevel: "JUNIOR",
    // expYear: 1,
  }),
  experience: "",
  extraTime: "",
  qualifyingQuestions: [],
  questions: [],
  welcomeVideo: "",
  isAntiCheatingSettingEnabled: true,
  allowAccommodation: [{ for: "", isAllowed: true }],
  questionSearch: "",
  questionType: "MULTIPLE_CHOICE",
  totalTime: 30,
  durationHour: 0,
  durationMinute: 30,
};

const CreateAssesment = () => {
  const [skillsList, setSkillsList] = useState("Javascript");

  useEffect(() => {
    const getCategoryList = () => {
      axios.get(categoryAPI).then(function (response) {
        setSkillsList(response.data.data);
      });
    };
    getCategoryList();
  }, []);

  let navigate = useNavigate();
  let { id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [initialData, setInitialData] = useState(initData);
  const [open, setOpen] = useState(false);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [snack, setSnack] = React.useState(false);

  const handleAlertclose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpenAlert(false);
  };

  const handleInputChange = (setFieldValue) => (event) => {
    const { name, value } = event.target;
    setFieldValue(name, value);
  };

  const getSkillName = (id) => {
    const skillName = skillsList.filter((skill) => {
      if (id === skill._id) {
        return skill.categoryName;
      }
    });
    return skillName[0]?.categoryName;
  };

  const getFilteredSkills = (values, index) => {
    const filteredWhichAreNotSelected = skillsList.filter(
      ({ _id }) => !values?.skills?.some(({ technology }) => _id === technology)
    );
    const alreadySelectedValueBasedOnIndex = skillsList.filter(
      ({ _id }) => values?.skills?.[index]?.technology === _id
    );
    return [
      ...filteredWhichAreNotSelected,
      ...alreadySelectedValueBasedOnIndex,
    ];
  };

  const rowInputHandler = (index, values, setFieldValue) => (event) => {
    const { name, value } = event.target;
    const newGridData = [...values?.skills];
    newGridData[index][name] = value;
    setFieldValue(name, { ...values, skills: [...newGridData] });
  };

  const validationSchema = Yup.object().shape({
    assesmentName: Yup.string().required("Assessment Name is required"),
    jobRole: Yup.string().required("Job Role is required"),
    // description: Yup.string().required("Description is required"),
    skills: Yup.array()
      .of(
        Yup.object().shape({
          technology: Yup.string().required("Technology is required"),
          expLevel: Yup.string().required("Experience Level is required"),
          // expYear: Yup.number().required("Experience Year is required"),
        })
      )
      .required("Skills is required"),
  });

  useLayoutEffect(() => {
    if (id) {
      getAssessmentById(id, setInitialData);
    }
  }, [id]);
  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnack(false);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialData}
      validateOnBlur
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (id) {
          editAssesmentPUT(values, id, (msg) => {
            setIsOpenAlert("Edited Successfully");
            setTimeout(() => {
              navigate("/myAssesment");
            }, 1500);
          });
        } else {
          createAssesmentPOST(values, (msg) => {
            setIsOpenAlert("Created Successfully");
            setTimeout(() => {
              navigate("/myAssesment");
            }, 1500);
          });
        }
      }}
    >
      {({
        dirty,
        handleSubmit,
        handleBlur,
        resetForm,
        values,
        errors,
        touched,
        setFieldValue,
        isValid,
        setErrors,
      }) => (
        <>
          <Form>
            <div className={styles.main}>
              <Header />
              {/*<Statusbar/>*/}
              <div className={styles.m_containter}>
                <h1>New Assessment</h1>
                <div className={styles.steps}>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, i) => (
                      <Step
                        key={label}
                        onClick={() => {
                          if (
                            i !== 0 &&
                            (!values?.assesmentName ||
                              !values?.jobRole ||
                              !values?.description)
                          ) {
                            setSnack(true);
                            return;
                            //  alert("Please fill up all the fields");
                          }
                          setActiveStep(i);
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        <StepLabel style={{ color: "white" }}>
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </div>
                <div className={styles.buttons}>
                  {/* <button type="button" className={styles.cancelButton}> */}
                    {/* <Link to={`/myAssesment`}>Cancel</Link> */}
                    <AlertDialogSlide
                      del={() => navigate(-1)}
                      text={
                        "You will loose all the current data, are you sure want to go back ?"
                      }
                      type="cancel"
                    />
                  {/* </button> */}
                  <button
                    // onClick={() => createAssesment(stateValues)}
                    type="submit"
                    className={styles.saveButton}
                    // disabled={!dirty || activeStep !== 2}
                  >
                    Save
                  </button>
                </div>
              </div>

              <div className={`page-spacing pd-y-30`}>
                <div className={`container-fluid`}>
                {activeStep === 0 && (
                    <div className={styles._cardContainer}>
                      <div className={styles.flexResponsive}>
                        <div>
                          <h1>Give your assessment a name</h1>
                          <span>
                        If you need more info, please check out{" "}
                            <Link to="/faq">FAQ Page</Link>{" "}
                      </span>
                        </div>
                      </div>
                      <>
                        <h3>Name your Assessment</h3>
                        <TextField
                            variant="standard"
                            InputProps={{
                              disableUnderline: true,
                              placeholder: "Assessment Name",
                            }}
                            name={"assesmentName"}
                            value={values?.assesmentName}
                            onChange={handleInputChange(setFieldValue)}
                            className={styles.inputBox}
                            onBlur={handleBlur}
                            error={errors.assesmentName && touched.assesmentName}
                            label={
                              errors.assesmentName &&
                              touched.assesmentName &&
                              errors.assesmentName
                            }
                        />

                        <h3>Job/Role Name</h3>
                        <TextField
                            variant="standard"
                            InputProps={{
                              disableUnderline: true,
                              placeholder: "Job/Role Name",
                            }}
                            name={"jobRole"}
                            value={values?.jobRole}
                            onChange={handleInputChange(setFieldValue)}
                            select
                            className={styles.inputBox}
                            onBlur={handleBlur}
                            error={errors.jobRole && touched.jobRole}
                            label={
                              errors.jobRole && touched.jobRole && errors.jobRole
                            }
                        >
                          <MenuItem value="Software Developer">
                            Software Developer
                          </MenuItem>
                          <MenuItem value="HR">HR</MenuItem>
                          <MenuItem value="accountant">Accountant</MenuItem>
                        </TextField>
                        <h3>Description</h3>
                        <TextField
                            variant="standard"
                            multiline
                            rows={3}
                            InputProps={{
                              disableUnderline: true,
                              placeholder: "Description",
                            }}
                            name={"description"}
                            value={values?.description}
                            onChange={handleInputChange(setFieldValue)}
                            className={styles.inputBox}
                            onBlur={handleBlur}
                            // error={errors.description && touched.description}
                            // label={
                            //   errors.description &&
                            //   touched.description &&
                            //   errors.description
                            // }
                        />
                        <h3>Test Duration</h3>
                        <div className="d-flex justify-content-start align-items-center gap-3">
                          <TextField
                              variant="standard"
                              InputProps={{
                                disableUnderline: true,
                                placeholder: "Duration (Hours)",
                              }}
                              name={"durationHour"}
                              type="number"
                              value={values?.durationHour}
                              min={0}
                              onChange={(e) => {
                                setFieldValue("durationHour", e?.target?.value);
                                setFieldValue(
                                    "totalTime",
                                    +e?.target?.value * 60 + +values?.durationHour
                                );
                              }}
                              className={styles.inputBox}
                              onBlur={handleBlur}
                              error={errors.durationHour && touched.durationHour}
                              label={
                                errors.durationHour &&
                                touched.durationHour &&
                                errors.durationHour
                              }
                          />{" "}
                          <span>Hours</span>
                          <TextField
                              variant="standard"
                              InputProps={{
                                disableUnderline: true,
                                placeholder: "Duration (Minutes)",
                              }}
                              name={"durationMinute"}
                              type="number"
                              min={0}
                              value={values?.durationMinute}
                              onChange={(e) => {
                                setFieldValue("durationMinute", e?.target?.value);
                                setFieldValue(
                                    "totalTime",
                                    +values?.durationHour * 60 + +e?.target?.value
                                );
                              }}
                              className={styles.inputBox}
                              onBlur={handleBlur}
                              error={errors.durationMinute && touched.durationMinute}
                              label={
                                errors.durationMinute &&
                                touched.durationMinute &&
                                errors.durationMinute
                              }
                          />
                          <span>Minutes</span>
                        </div>
                      </>
                      <Divider sx={{ margin: "30px 0" }} />
                      <div className="d-flex justify-content-start align-items-center mt-5">
                        <button type="button" className={styles.backButton}>
                          <>Back</>
                        </button>
                        <button
                            type="button"
                            className={styles.saveButton}
                            onClick={() => {
                              if (
                                  !values?.assesmentName ||
                                  !values?.jobRole
                                  // !values?.description
                              ) {
                                setSnack(true);
                                return;
                              }
                              setActiveStep((prev) => prev + 1);
                            }}
                        >
                          Save & Next
                        </button>
                      </div>
                    </div>
                )}
                {snack && (
                    <Snackbars
                        text={"Please fill up all the fields"}
                        snack={snack}
                        closeSnackbar={closeSnackbar}
                        severity={"error"}
                    />
                )}
                {activeStep === 1 && (
                    <div className={styles._cardContainer}>
                      <div className={styles.flexResponsive}>
                        <div>
                          <h1>Select required skills</h1>
                          <span>
                        If you need more info, please check out{" "}
                            <Link to="/faq">FAQ Page</Link>{" "}
                      </span>
                        </div>
                      </div>
                      <>
                        <Grid
                            style={{
                              overflowY: "auto",
                              maxHeight: "400px",
                              marginTop: "10px",
                            }}
                            container
                            spacing={2}
                        >
                          {values?.skills?.map((item, index) => (
                              <React.Fragment key={index}>
                                <Grid item xs={12} md={6}>
                                  <h3>Skill</h3>
                                  <TextField
                                      variant="standard"
                                      InputProps={{
                                        disableUnderline: true,
                                        placeholder: "Skills",
                                      }}
                                      name={"technology"}
                                      value={item?.technology}
                                      onChange={rowInputHandler(
                                          index,
                                          values,
                                          setFieldValue
                                      )}
                                      select
                                      className={styles.inputBox}
                                  >
                                    {getFilteredSkills(values, index)?.map(
                                        (skill, index) => (
                                            <MenuItem key={skill._id} value={skill._id}>
                                              {skill.categoryName}
                                            </MenuItem>
                                        )
                                    )}
                                  </TextField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <h3>Experience Level</h3>
                                  <TextField
                                      variant="standard"
                                      InputProps={{
                                        disableUnderline: true,
                                        placeholder: "Experience Level",
                                      }}
                                      name={"expLevel"}
                                      value={item?.expLevel}
                                      onChange={rowInputHandler(
                                          index,
                                          values,
                                          setFieldValue
                                      )}
                                      select
                                      className={styles.inputBox}
                                  >
                                    <MenuItem value="JUNIOR">Junior</MenuItem>
                                    <MenuItem value="MID">Mid</MenuItem>
                                    <MenuItem value="SENIOR">Senior</MenuItem>
                                  </TextField>
                                </Grid>
                                {/* <div
                            className={styles.helpIconContainer}
                            // item
                            // xs={12}
                            // md={1}
                          >
                            <Tooltip title="Help about the question">
                              <IconButton>
                                <HelpIcon />
                              </IconButton>
                            </Tooltip>
                          </div> */}
                              </React.Fragment>
                          ))}
                        </Grid>
                        <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                        >
                          <button
                              onClick={() =>
                                  setFieldValue("skills", [
                                    ...values?.skills,
                                    {
                                      technology: "",
                                      expLevel: "JUNIOR",
                                      // expYear: 1,
                                    },
                                  ])
                              }
                              type="button"
                              className={styles.addButton}
                          >
                            {/* <img src={PlusSign} alt="" /> */}
                            <Add /> Add another
                          </button>
                        </div>
                      </>
                      <Divider sx={{ margin: "30px 0" }} />
                      <div className="d-flex justify-content-start align-items-center mt-5">
                        <button
                            onClick={() => setActiveStep((prev) => prev - 1)}
                            type="button"
                            className={styles.backButton}
                        >
                          <>Back</>
                        </button>
                        <button
                            type="button"
                            className={styles.saveButton}
                            onClick={() => {
                              const val = values?.skills.some(
                                  (skill) => !skill.technology
                              );
                              if (val) {
                                setSnack(true);
                                // alert("Please fill all the fields");
                              } else {
                                setActiveStep((prev) => prev + 1);
                              }
                            }}
                        >
                          Save & Next
                        </button>
                      </div>
                    </div>
                )}

                {activeStep === 2 && (
                    <>
                      {" "}
                      <div className={styles._cardContainer}>
                        <div className={styles.flexResponsive}>
                          <div className="d-flex justify-content-start align-items-center gap-3">
                            <div className={styles.SkillIcon}>
                              <img src={JavaImg} alt="" />
                            </div>
                            <div>
                              <h2>
                                {" "}
                                Assessment: {values?.assesmentName}
                                <VerifiedRoundedIcon sx={{ color: "#3584D9" }} />
                              </h2>
                              <span>{values?.jobRole}</span>
                            </div>
                          </div>
                          <div className={styles.estimatedTime}>
                            {values?.durationHour > 0 && (
                                <h3>
                                  {values?.durationHour}{" "}
                                  {values?.durationHour === 1 ? "Hour" : "Hours"} &{" "}
                                </h3>
                            )}
                            <h3>{values?.durationMinute} Minutes</h3>
                            <span>Assessment Time</span>
                          </div>
                        </div>
                        <hr className="mt-4 mb-4" />
                        <div className="table-responsive">
                          <table className="w-100">
                            <thead>
                            <tr>
                              <th>
                                <span>SKILLS, FRAMEWORKS AND TECHNOLOGY</span>
                              </th>
                              <th>
                                <span>EXPERTISE LEVEL</span>
                              </th>
                              {/* <th className="text-center">
                              <span>EXPERIENCE YEAR</span>
                            </th> */}
                              <th className="text-center">
                                <span>ACTION</span>
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                            {values?.skills?.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <b>{getSkillName(item?.technology)}</b>
                                  </td>
                                  <td>
                                    <b>{item?.expLevel}</b>
                                  </td>
                                  {/* <td className="text-center">
                                <b className={styles.expYear}>
                                  {item?.expYear}
                                </b>
                              </td> */}
                                  <td>
                                    <div className="d-flex justify-content-center align-items-center">
                                      <div className={`me-2 ${styles.icon}`}>
                                        <Edit
                                            sx={{ fontSize: 24, color: "#3699FF" }}
                                            onClick={() => {
                                              setActiveStep(1);
                                            }}
                                        />
                                      </div>
                                      <div className={`me-2 ${styles.icon}`}>
                                        <Delete
                                            sx={{ fontSize: 24, color: "#3699FF" }}
                                            onClick={() => {
                                              // setStateValues(prev => ({ ...prev, skills: prev?.skills.filter((item, i) => i !== index) }))
                                              setFieldValue(
                                                  "skills",
                                                  values?.skills?.filter(
                                                      (item, i) => i !== index
                                                  )
                                              );
                                            }}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                            ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className={styles._cardContainer}>
                        <div>
                          <h1>Advanced Options</h1>
                          <span>
                        If you need more info, please check out{" "}
                            <Link to="/faq">FAQ Page</Link>{" "}
                      </span>
                        </div>
                        <div>
                          <div className="mt-5 d-flex justify-content-start align-items-start">
                            <ul
                                className="nav nav-tabs pe-5 flex-column border-bottom-0"
                                id="myTab"
                                style={{ borderRight: "1px solid  #EFF2F5" }}
                                role="tablist"
                            >
                              <li className="nav-item" role="presentation">
                                <button
                                    className="btn btn-primary nav-link active"
                                    id="qualify-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#qualify"
                                    type="button"
                                    role="tab"
                                    aria-controls="qualify"
                                    aria-selected="true"
                                >
                                  Questions
                                </button>
                              </li>

                              <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="intro-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#intro"
                                    type="button"
                                    role="tab"
                                    aria-controls="intro"
                                    aria-selected="false"
                                >
                                  Intro/Outro Video
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="antiCheat-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#antiCheat"
                                    type="button"
                                    role="tab"
                                    aria-controls="antiCheat"
                                    aria-selected="false"
                                >
                                  Anti-Cheating Settings
                                </button>
                              </li>
                              {/* <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="extraTime-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#extraTime"
                              type="button"
                              role="tab"
                              aria-controls="extraTime"
                              aria-selected="false"
                            >
                              Extra Time for test
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="accomodation-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#accomodation"
                              type="button"
                              role="tab"
                              aria-controls="accomodation"
                              aria-selected="false"
                              style={{ textAlign: "left" }}
                            >
                              Accommodation for candidates
                            </button>
                          </li> */}
                            </ul>
                            <hr />
                            <div
                                className="tab-content mt-5 ms-5"
                                id="myTabContent"
                            >
                              <div
                                  className="tab-pane fade show active"
                                  id="qualify"
                                  role="tabpanel"
                                  aria-labelledby="qualify-tab"
                              >
                                <h1>Qualifying Questions</h1>
                                <p>
                                  Qualifying questions are presented to candidates
                                  ahead of the tests. The answers to these questions
                                  determine if candidates satisfy the essential
                                  requirements of the job. Only if all questions are
                                  answered as required, they proceed to the tests.
                                  You can add up to 5 questions. You are only being
                                  charged for candidates that qualify.
                                </p>
                                {<AddQuestion open={open} setOpen={setOpen} />}
                                <button
                                    onClick={() => setOpen("qualifyingQuestions")}
                                    type="button"
                                    className={styles.addButton}
                                >
                                  {/* <img src={PlusSign} alt="" /> */}
                                  <Add /> Add Question
                                </button>
                                <br />
                                <h1 className="mt-5">Main Questions</h1>
                                <p>
                                  The answers to these questions determine if
                                  candidates satisfy the essential requirements of
                                  the job. Only if all questions are answered as
                                  required, they proceed to the tests. You can add
                                  up to 5 questions. You are only being charged for
                                  candidates that qualify.
                                </p>
                                <button
                                    onClick={() => setOpen("questions")}
                                    type="button"
                                    className={styles.addButton}
                                >
                                  {/* <img src={PlusSign} alt="" /> */}
                                  <Add /> Add Question
                                </button>
                              </div>
                              <div
                                  className="tab-pane fade"
                                  id="intro"
                                  role="tabpanel"
                                  aria-labelledby="intro-tab"
                              >
                                <h1>Intro/Outro Video</h1>
                                <p>
                                  You can include a pre-recorded video to welcome
                                  your candidates to your assessment. By default,
                                  we'll display your video at the beginning of the
                                  assessment. If you prefer to show it at the end,
                                  tick the checkbox below:
                                </p>

                                <span>
                              <Checkbox defaultChecked />
                              Show video at the end of the assessment.
                            </span>
                                <br />
                                <TextField
                                    variant="standard"
                                    InputProps={{
                                      disableUnderline: true,
                                      placeholder: "Intro Video URL",
                                    }}
                                    name={"welcomeVideo"}
                                    value={values?.welcomeVideo}
                                    onChange={handleInputChange(setFieldValue)}
                                    className={styles.inputBox}
                                    onBlur={handleBlur}
                                    error={
                                      errors.welcomeVideo && touched.welcomeVideo
                                    }
                                    label={
                                      errors.welcomeVideo &&
                                      touched.welcomeVideo &&
                                      errors.welcomeVideo
                                    }
                                />
                                <br />
                                <button
                                    onClick={() => {}}
                                    type="button"
                                    className={styles.addButton}
                                >
                                  <TripOrigin /> Upload Video
                                </button>
                              </div>
                              <div
                                  className="tab-pane fade"
                                  id="antiCheat"
                                  role="tabpanel"
                                  aria-labelledby="antiCheat-tab"
                              >
                                <h1>Anti-Cheating Settings</h1>
                                <p>
                                  We take a screenshot of the candidate every 30
                                  seconds while they complete the assessment. These
                                  pictures will be included in your anti-cheating
                                  monitor.We ask each candidate for their permission
                                  before accessing their webcam.
                                </p>

                                <span>
                              <Checkbox
                                  defaultChecked
                                  value={values?.isAntiCheatingSettingEnabled}
                                  onChange={handleInputChange(setFieldValue)}
                              />
                              Snapshots of candidates will be taken every 30
                              seconds via webcam.
                            </span>
                              </div>
                              <div
                                  className="tab-pane fade"
                                  id="extraTime"
                                  role="tabpanel"
                                  aria-labelledby="extraTime-tab"
                              >
                                <h1>Extra Time for Tests</h1>
                                <p>
                                  Give all candidates extra time on their tests.
                                </p>
                                <p>
                                  The extra time applies to all tests in the test
                                  library, but not to custom questions.
                                </p>
                                <div className="d-flex justify-content-start align-items-center gap-3">
                                  <p style={{ whiteSpace: "nowrap" }}>
                                    Extra Time:{" "}
                                  </p>
                                  <TextField
                                      variant="standard"
                                      InputProps={{
                                        disableUnderline: true,
                                        placeholder: "Extra Time",
                                      }}
                                      name={"extraTime"}
                                      value={values?.extraTime}
                                      onChange={handleInputChange(setFieldValue)}
                                      select
                                      className={styles.inputBox}
                                  >
                                    <MenuItem value="SoftwareDeveloper">
                                      0%
                                    </MenuItem>
                                    <MenuItem value="HR">10%</MenuItem>
                                    <MenuItem value="accountant">25%</MenuItem>
                                  </TextField>
                                </div>
                              </div>
                              <div
                                  className="tab-pane fade"
                                  id="accomodation"
                                  role="tabpanel"
                                  aria-labelledby="accomodation-tab"
                              >
                                <h1>Accommodation for Candidates</h1>
                                <p>
                                  For fair treatment and equal opportunities,
                                  candidates can request an accommodation based on
                                  their English language proficiency or disability.
                                  By disabling these accommodations, you may be in
                                  violation of fair employment laws and regulations
                                  in your area. If you are unsure, we suggest
                                  seeking legal advice before proceeding.
                                </p>
                                <span>
                              <Checkbox defaultChecked />
                              Non-fluent English speakers.
                            </span>
                                <br />
                                <span>
                              <Checkbox defaultChecked />
                              Candidates with conditions that affect their
                              concentration or memory capacity.
                            </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                )}
              </div>
              <Stack spacing={2} sx={{ width: "100%" }}>
                <Snackbar
                    open={isOpenAlert}
                    autoHideDuration={6000}
                    onClose={handleAlertclose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <Alert
                      onClose={handleAlertclose}
                      severity="success"
                      sx={{ width: "100%" }}
                  >
                    {isOpenAlert}!
                  </Alert>
                </Snackbar>
              </Stack>
              </div>
              </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default CreateAssesment;
