import * as React from "react";
import {Container, Menu, MenuItem} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {Delete, KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import AlertDialogSlide from "../Slider/PopUp/AlertDialogSlide";
import {
    addHiringStageAPI,
    getAllHiringStagesAPI, statusOfQuestion, truncateString,
    updateHiringStageAPI,
} from "../../../../../utils/constants";
import {
    Box,
    Button, Card, CardContent,
    CircularProgress,
    Switch,
    TablePagination,
    TextField, Typography,
} from "@mui/material";
import { InputBase } from "@mui/material";
import styles from "./ManageHiringStage.module.scss";
import { makeStyles } from "@mui/styles";
import Header from "../../../../Header/Header";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import IMAGES from "../../../../../assets/images/images";
import {Link} from "react-router-dom";
import RoutingPaths from "../../../../../utils/RoutingPaths";
import TestLibraryStatusMenu from "../../../../TestLibrary/TestLibraryStatusMenu/TestLibraryStatusMenu";
import TestLibraryMoreMenu from "../../../../TestLibrary/TestLibraryMoreMenu/TestLibraryMoreMenu";
import CreateHiringStateModal from "./CreateHiringStateModal/CreateHiringStateModal";
import DeleteHireStageModal from "./DeleteHireStageModal/DeleteHireStageModal";
import HiringStageMoreMenu from "./HiringStageMoreMenu/HiringStageMoreMenu";
import HiringStageActionDropdown from "./HiringStageActionDropdown/HiringStageActionDropdown";

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
  pagination: {
    "& p": {
      marginBottom: "0",
    },
  },
});

export default function ManageHiringStages2() {
  const [candidateLists, setCandidateLists] = useState([]);
  const [checked, setChecked] = React.useState(true);
  const [label, setLabel] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleChange = (event, id) => {
    axios
      .put(`${updateHiringStageAPI}${id}`, { isActive: event.target.checked })
      .then((response) => {
        if (response.status === 200) {
          setChecked(!checked);
        }
      });
  };

  const handleText = (e) => {
    setLabel(e.target.value);
  };

  const addNewHiringStage = () => {
    axios
      .post(addHiringStageAPI, {
        label: label,
      })
      .then((response) => {
        if (response.status === 200) {
          setChecked(!checked);
          setLabel("");
        }
      });
  };
  const deleteStage = (id) => {
    axios
      .put(`${updateHiringStageAPI}${id}`, { isDeleted: true })
      .then((response) => {
        if (response.status === 200) {
          setChecked(!checked);
        }
      });
  };
  useEffect(() => {
    function fetchHiringStages() {
      axios.get(getAllHiringStagesAPI).then((response) => {
        if (response.status === 200) {
          setCandidateLists(response.data.data);
          setLoading(false);
        }
      });
    }
    fetchHiringStages();
  }, [checked]);

  // const [items, setItems] = useState(candidateLists);

 function onDragEnd(result) {
   if (!result.destination) {
     return;
   }
   const newItems = [...candidateLists];
   const [removed] = newItems.splice(result.source.index, 1);
   newItems.splice(result.destination.index, 0, removed);
   setCandidateLists(newItems);
 }
  return (
    <>
      <Header />
        <div className={`page-spacing pd-y-30`}>
            <Container maxWidth={"md"}>
                <div className="d-flex align-items-center justify-content-between flex-wrap mg-b-20">
                    <Typography variant={"h2"} component={"h1"}>
                        Hiring Stages
                    </Typography>
                    <Button
                        className={`text-nowrap`}
                        variant={"contained"}
                        color={"primary"}
                        size={"small"}
                        onClick={()=> setOpenCreateModal(true)}
                    >
                        <i className={`icon-plus mg-r-6`}></i>
                        Add New
                    </Button>
                    {openCreateModal && <CreateHiringStateModal open={openCreateModal} setOpen={setOpenCreateModal} />}
                </div>
                <Card>
                    <CardContent className="pd-x-20 pd-y-0">
                        <TableContainer className="custom-table">
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="pd-l-0">Stage</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Action</TableCell>
                                        <TableCell className="pd-r-32"></TableCell>
                                    </TableRow>
                                </TableHead>
                                {true ? (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="pd-l-0">
                                                <Typography
                                                    variant={"h4"}
                                                    component={"h4"}
                                                    className={`mg-b-4 font-size-15`}
                                                >
                                                    Interview first round
                                                </Typography>
                                            </TableCell>
                                            <TableCell>Pre-defined</TableCell>
                                            <TableCell>
                                                <HiringStageActionDropdown/>
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                <HiringStageMoreMenu/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="pd-l-0">
                                                <Typography
                                                    variant={"h4"}
                                                    component={"h4"}
                                                    className={`mg-b-4 font-size-15`}
                                                >
                                                    Interview first round
                                                </Typography>
                                            </TableCell>
                                            <TableCell>Pre-defined</TableCell>
                                            <TableCell>
                                                <HiringStageActionDropdown/>
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                <HiringStageMoreMenu/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={4}>
                                                No Data Found!
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </Container>
        </div>
        {false &&
        <>
            <div
                style={{
                    display: "flex",
                    margin: 20,
                    justifyContent: "center",
                    gap: 10,
                }}
            >
                <InputBase
                    style={{
                        border: "1px solid black",
                        backgroundColor: "white",
                        padding: 5,
                        borderRadius: "5px",
                    }}
                    value={label}
                    onChange={handleText}
                    placeholder="Type a Label"
                />
                <Button onClick={addNewHiringStage} variant="contained">
                    Add
                </Button>
            </div>
            <div className={styles.Container}>
                <div className={styles.card}>
                    <div>
                        {loading ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    // height: "100vh",
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        ) : (
                            <table className="w-100">
                                <thead>
                                <tr>
                                    <th className={styles.th}>
                                        <h6>NAME</h6>
                                    </th>
                                    <th className={styles.th} style={{ textAlign: "center" }}>
                                        <h6>ACTIVE</h6>
                                    </th>
                                    <th className={styles.action}>
                                        <h6>ACTION</h6>
                                    </th>
                                </tr>
                                </thead>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided) => (
                                            <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                            {candidateLists
                                                .slice(
                                                    page * rowsPerPage,
                                                    page * rowsPerPage + rowsPerPage
                                                )
                                                ?.map((item, index) => (
                                                    <Draggable
                                                        key={item._id}
                                                        draggableId={item._id}
                                                        index={index}
                                                    >
                                                        {/* {console.log("bhbhb",item)} */}
                                                        {(provided, snapshot) => (
                                                            // <div
                                                            //      ref={provided.innerRef}
                                                            //      {...provided.draggableProps}
                                                            //      {...provided.dragHandleProps}
                                                            //    >
                                                            //     <ListItem item={item} />
                                                            //    </div>
                                                            <tr
                                                                ref={provided.innerRef}
                                                                snapshot={snapshot}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                key={item?._id}
                                                            >
                                                                <td className={styles.td}>
                                                                    <div className="d-flex align-items-center mt-2 mb-2">
                                                                        <div className="ms-3">
                                                                            <p className="m-0">{item?.label}</p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                        <Switch
                                                                            checked={item.isActive}
                                                                            onChange={(e) =>
                                                                                handleChange(e, item._id)
                                                                            }
                                                                            inputProps={{
                                                                                "aria-label": "controlled",
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="d-flex justify-content-end align-items-center">
                                                                        <div className={`me-2 ${styles.icon}`}>
                                                                            <AlertDialogSlide
                                                                                del={() => deleteStage(item._id)}
                                                                                text={"Are you sure want to delete ?"}
                                                                                type="delete"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            </tbody>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </table>
                        )}

                        <TablePagination
                            component="div"
                            className={classes.pagination}
                            rowsPerPage={rowsPerPage}
                            count={candidateLists?.length}
                            page={page}
                            rowsPerPageOptions={[5, 10, 20, 30, 50]}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </div>
        </>}
    </>
  );
}
