import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import styles from "../NewQuestion.module.scss";
import IMAGES from "../../../../assets/images/images";
import { Add } from "@mui/icons-material";
import { Controller, FormProvider, useFieldArray } from "react-hook-form";
import { useSnackbar } from "notistack";
import { Limits } from "../../../../utils/constants";

const ShortTextQuestion = ({ methods }) => {
  const { control } = methods;
  const {
    fields: textFields,
    append: addTextField,
    remove: removeField,
  } = useFieldArray({
    control,
    name: "answers",
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleAddField = () => {
    if (textFields.length < Limits.shortTextAnswers) {
      addTextField({
        answer: "",
      });
    } else {
      enqueueSnackbar("You can provide maximum 12 answers", {
        variant: "error",
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <Box className="mg-b-34">
        <Typography variant={"h3"} component={"h3"} className={"mg-b-10"}>
          Answers
        </Typography>
        <Card className={styles.cardDashed}>
          <CardContent className={"pd-x-20 pd-y-20"}>
            <ul className={`list-group list-group-flush list-unstyled`}>
              <li className="mg-b-20">
                {textFields.map((fields, index) => {
                  return (
                    <Controller
                      key={fields.id}
                      render={({ field }) => (
                        <div className={styles.hasAction}>
                          <input
                            {...field}
                            type="text"
                            className="form-control form-control-lg mb-1"
                          />
                          <div
                            className={`${styles.inputAction} d-flex align-items-center`}
                          >
                            <Button
                              variant={"text"}
                              className="btn btn-link"
                              onClick={() => removeField(index)}
                            >
                              <img src={IMAGES.Trash} alt="trash" />
                            </Button>
                          </div>
                        </div>
                      )}
                      name={`answers.${index}.answer`}
                      control={control}
                    />
                  );
                })}
              </li>
            </ul>
            <div className="d-flex align-items-center justify-content-between mg-b-24">
              <button
                type="button"
                className="btn btn-link text-decoration-none p-0 font-weight-semi-bold"
                onClick={handleAddField}
              >
                <Add className="mg-r-8" />
                Add more
              </button>
            </div>
          </CardContent>
        </Card>
      </Box>
    </FormProvider>
  );
};

export default ShortTextQuestion;
