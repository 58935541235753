import Header from "../../Header/Header";
import React, { useState } from "react";
import Breadcrumb from "../../common/breadcrumb/Breadcrumb";
import IMAGES from "../../../assets/images/images";
import {
  Box,
  Button,
  Card,
  CardContent,
  Menu,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import Loader from "../../Loader/Loader";
import {
  complexityTypeMap,
  getTimeInMinSec,
  questionCategory,
  questionTypeMap,
  statusMap,
} from "../../../utils/constants";
import useDebounce from "../../../hooks/useDebounce";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import RoutingPaths from "../../../utils/RoutingPaths";
import QuestionListService from "../../../services/api/QuestionListService";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import MuiFilterMenu from "../../common/MuiFilterMenu/MuiFilterMenu";
import QuestionBankListService from "../../../services/api/QuestionBankListService";
import { Delete, Edit } from "@mui/icons-material";

const QuestionList = () => {
  const filterMethods = useForm({
    defaultValues: {
      questionType: "",
    },
  });
  const { reset, getValues } = filterMethods;
  const loader = useSelector((state) => state.Loader.loading);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const openFilter = Boolean(filterAnchorEl);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const debouncedSearch = useDebounce(searchTerm, 500, setPage);
  const PER_PAGE = 10;
  const [data, setData] = useState([]);
  useEffect(() => {
    getAllQuestions();
  }, [page, debouncedSearch]);

  const onFilterClick = () => {
    getAllQuestions();
    setPage(1);
  };
  const onReset = () => {
    reset();
    getAllQuestions();
    setPage(1);
  };

  const getAllQuestions = async () => {
    const { questionType } = getValues();
    const data = await QuestionListService.getAllData(
      page,
      PER_PAGE,
      debouncedSearch,
      questionType
    );
    setData(data?.data?.data);
  };

  const filterClose = () => {
    setFilterAnchorEl(null);
  };
  const handleFilterOpen = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const count = Math.ceil(data?.totalCount / PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
  };

  const handleDelete = (questionId) => {
    QuestionBankListService.deleteQuestionById(questionId).then((res) => {
      if (res.data.result === 1) {
        getAllQuestions();
      }
    });
  };

  return (
    <FormProvider {...filterMethods}>
      <Header />
      {/* <Breadcrumb page="My Questions" /> */}
      <div className={`page-spacing pd-y-30`}>
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between flex-wrap mg-b-34">
            <div className={`d-flex align-items-center`}>
              {/* <div className={styles.iconWrap}>
                <img className="img-fluid" src={IMAGES.React} alt={"icons"} />
              </div> */}
              <div className="mg-l-10">
                <Typography
                  variant={"h3"}
                  component={"h3"}
                  className={`mg-b-8`}
                >
                  My Questions
                </Typography>
                {/* <Typography
                  variant={"body2"}
                  component={"p"}
                  color={"secondary"}
                >
                  Total {data?.totalCount} questions
                </Typography> */}
              </div>
            </div>
            <div className={`d-flex align-items-center flex-wrap`}>
              <div className={`search-with-icon mg-r-15 mg-y-10`}>
                <img src={IMAGES.Search} className="search-icon" alt="search" />
                <input
                  type="search"
                  className="form-control form-control-sm border-0"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className={`mg-r-15 mg-y-10`}>
                <button
                  type="button"
                  onClick={handleFilterOpen}
                  className={`btn btn-light btn-toggle btn-sm`}
                >
                  <Typography
                    noWrap
                    component={"span"}
                    className="mg-r-4 pd-y-0 font-weight-medium font-size-sm"
                  >
                    {false ? "SelectedValue" : "Filter"}
                  </Typography>
                  {openFilter ? (
                    <KeyboardArrowUp className="toggle-icon" />
                  ) : (
                    <KeyboardArrowDown className="toggle-icon" />
                  )}
                </button>
                <Menu
                  id="role-menu"
                  anchorEl={filterAnchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={openFilter}
                  onClose={filterClose}
                >
                  <Box sx={{ width: "400px" }}>
                    <Box className={`pd-x-24 pd-y-20 border-bottom`}>
                      <Typography
                        variant={"h3"}
                        component={"h3"}
                        className={`font-size-base`}
                      >
                        Filter Option
                      </Typography>
                    </Box>
                    <Box className={`pd-x-24 pd-y-20`}>
                      <Box className={`mg-b-20`}>
                        <Typography
                          className={
                            "mg-b-10 font-weight-medium"
                          }
                        >
                          Question Type
                        </Typography>
                        <MuiFilterMenu
                          options={questionCategory}
                          RHFName={"questionType"}
                          placeholder="Select Type"
                        />
                      </Box>

                      <Box
                        className={`d-flex align-items-center justify-content-end mg-t-40`}
                      >
                        <Button
                          variant={"text"}
                          color={"inherit"}
                          className={"mg-r-20"}
                          onClick={onReset}
                        >
                          Reset
                        </Button>
                        <Button
                          onClick={onFilterClick}
                          variant={"contained"}
                          color={"primary"}
                        >
                          Apply
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Menu>
              </div>
              <Link to={RoutingPaths.NewCustomQuestion}>
                <Button
                  className={`text-nowrap`}
                  variant={"contained"}
                  color={"primary"}
                  size={"small"}
                >
                  <i className={`icon-plus mg-r-6`}></i>
                  New Question
                </Button>
              </Link>
            </div>
          </div>
          {loader ? (
            <Loader />
          ) : (
            <Card>
              <CardContent className="pd-x-20 pd-y-0">
                <TableContainer className="custom-table">
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="pd-l-0">Title</TableCell>
                        <TableCell>Duration (Minutes)</TableCell>
                        <TableCell>Type</TableCell>
                        {/* <TableCell>Created By</TableCell>
                        <TableCell>Approved By</TableCell> */}
                        <TableCell>Complexity</TableCell>
                        <TableCell className="text-center">Status</TableCell>
                        <TableCell className="pd-r-32"></TableCell>
                      </TableRow>
                    </TableHead>
                    {data?.totalCount ? (
                      <TableBody>
                        {data?.questions?.map(
                          ({
                            questionType,
                            complexity,
                            title,
                            time,
                            isDraft,
                            currentStatus,
                            tags,
                            _id,
                          }) => (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              key={_id}
                            >
                              <TableCell className="pd-l-0">
                                <Box>
                                  <Typography className="font-weight-semi-bold table-title">
                                    {title}
                                  </Typography>

                                  <Typography
                                    className="font-size-sm"
                                    color={"secondary"}
                                  >
                                    {tags?.length > 3
                                      ? `${tags?.slice(0, 3).join(", ")}...`
                                      : tags?.join(", ")}
                                  </Typography>
                                </Box>
                              </TableCell>

                              <TableCell>{getTimeInMinSec(time)}</TableCell>
                              <TableCell>
                                {questionTypeMap[questionType]}
                              </TableCell>
                              {/* <TableCell>{createdBy?.tempName}</TableCell>
                              <TableCell>-- -- -- -- -- -- -- </TableCell> */}
                              <TableCell>
                                {complexityTypeMap[complexity]}
                              </TableCell>
                              <TableCell className="text-center">
                                <span
                                  className={
                                    isDraft
                                      ? `badge badge-soft-warning`
                                      : `badge ${
                                          statusMap[currentStatus.status].class
                                        }`
                                  }
                                >
                                  {isDraft
                                    ? "Draft"
                                    : statusMap[currentStatus.status]?.label}
                                </span>
                              </TableCell>
                              <TableCell className="pd-r-0" align={"right"}>
                                {/* <Link to={`/edit-question/${_id}`}>
                                  <button
                                    type={`button`}
                                    className={`btn btn-snow`}
                                  >
                                    <img src={IMAGES.Dots} alt="dots" />
                                  </button>
                                </Link> */}
                                <Link to={`/edit-question/${_id}`}>
                                  <Edit
                                    sx={{
                                      fontSize: 24,
                                      marginRight: "5px",
                                      color: "#3699FF",
                                    }}
                                  />
                                </Link>
                                <Delete
                                  onClick={() => handleDelete(_id)}
                                  sx={{ fontSize: 24, color: "#3699FF" }}
                                />
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={12}>
                            No Data Found!
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          )}
          <Box
            className={`d-flex justify-content-between align-items-center mg-t-30`}
          >
            {data?.totalCount > 0 && !loader && (
              <Typography variant={"body2"} color={"secondary"}>
                Showing {(page - 1) * PER_PAGE + 1} to{" "}
                {page * PER_PAGE >= data?.totalCount
                  ? data?.totalCount
                  : page * PER_PAGE}{" "}
                of {data?.totalCount} entries
              </Typography>
            )}
            {data?.totalCount > PER_PAGE && !loader && (
              <Pagination
                color={"primary"}
                className={"custom-pagination"}
                shape="rounded"
                count={count}
                page={page}
                variant="outlined"
                onChange={handleChange}
              />
            )}
          </Box>
        </div>
      </div>
    </FormProvider>
  );
};
export default QuestionList;
