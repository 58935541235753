import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import styles from "./Dashboard.module.scss";
import JavaImg from "./../../Files/img/stack-1.png";
import CurvSVG from "./../../Files/img/curve.svg";
import { Delete, Edit } from "@mui/icons-material";
import Icon1 from "../../Files/img/abs1.png";
import Icon2 from "../../Files/img/abs2.png";
import Icon3 from "../../Files/img/abs3.png";
import { Link } from "react-router-dom";
import { searchAllAssessment } from "../Assesment/MyAssesment/actions";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import AlertDialogSlide from "../Assesment/MyAssesment/CandidateList/Slider/PopUp/AlertDialogSlide";
import GoogleSignIn from "../Auth/GoogleSignIn";
import RoutingPaths from "../../utils/RoutingPaths";

const Dashboard = () => {
  const baseURL = "api/assessment/";
  const [gridData, setGridData] = useState([]);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    searchAllAssessment("", setGridData, setLoading);
  }, [deleteSuccess]);

  const deleteAssessment = (id) => {
    axios.delete(baseURL + id).then((response) => {
      if (response.status === 200) {
        setDeleteSuccess(true);
      }
    });
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDeleteSuccess(false);
  };

  
  return (
    <>
      <Header />

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div className={styles.Container}>
          <div className={styles.infoContainer}>
            <div className={styles.left}>
              <h2>Potential Hire</h2>
              <h6>More than 200+ new candidates</h6>
              <div className={styles.indevidualCandidates}>
                <div className={styles.dp}>
                  {/* <img src="https://via.placeholder.com/150" alt="dp" /> */}
                  <p>H</p>
                </div>
                <div>
                  <b>Brad Simmons</b>
                  <br />
                  <b>TECH: </b> <span>ccNode Js</span>
                </div>
                <div className={styles.additionalInfo}>
                  <b>50%</b>

                  <span>Average score</span>
                </div>
                <div className={styles.additionalInfo}>
                  <b>Jun 26, 2022</b>

                  <span>Last Activity</span>
                </div>
                <div className={`text-right ${styles.additionalInfo}`}>
                  <b>Dhaka</b>

                  <span>Location</span>
                </div>
              </div>
            </div>
            <div className={styles.right}>
              <h2>Weekly Test Attempts</h2>
              <h6>500 candidates</h6>
              <div className={styles.widgetsContainer}>
                <div className={styles.widget}>
                  <div className="d-flex align-items-center">
                    <div className={styles.stackPic}>
                      <img src={JavaImg} alt="" />
                    </div>
                    <b className="ms-3">Java</b>
                  </div>
                  <span>250 Candidate</span>
                </div>
              </div>
              <img src={CurvSVG} alt="" className={styles.curveImg} />
            </div>
          </div>
          <div className={styles.bottomCard}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h2>My Assessments</h2>
                <h6 style={{ fontWeight: "600", whiteSpace: "break-spaces" }}>
                  More than 400+ new assessments
                </h6>
              </div>

              <Link to={RoutingPaths.NewAssessment}>
                <button type="button" className={styles.saveButton}>
                  New Assessment
                </button>
              </Link>
            </div>
            <div className="table-responsive">
              <table className="w-100">
                <thead>
                  <tr>
                    <th className={styles.th}>
                      <h6>NAME</h6>
                    </th>
                    <th className={styles.th}>
                      <h6>CANDIDATES</h6>
                    </th>
                    <th className={styles.th}>
                      <h6>PROGRESS</h6>
                    </th>
                    <th className={styles.action}>
                      <h6>ACTION</h6>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {gridData?.data?.length > 0 &&
                    gridData?.data?.slice(0, 5).map((item, index) => (
                      <tr key={item?._id}>
                        <td className={styles.td}>
                          <div className="d-flex align-items-center mt-2 mb-2">
                            <div className={styles.avatar} style={{}}>
                              <img src={JavaImg} alt="" />
                            </div>
                            <div className="ms-3">
                              <h6 style={{ color: "black" }}>{item?.title}</h6>
                              <div className={styles.icons}>
                                <div className="tableInfo">
                                  <img src={Icon1} alt="" />
                                  <span>{item?.jobRole}</span>
                                </div>
                                <div className="tableInfo">
                                  <img src={Icon2} alt="" />
                                  {/* <span>{item?.time} minutes</span> */}
                                </div>
                                <div className="tableInfo">
                                  <img src={Icon3} alt="" />
                                  <span>{item?.preferedLanguage}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <p className="m-0">{item?.noOfCandidates}</p>
                        </td>
                        <td>
                          <div
                            className="me-5 d-flex justify-content-start align-items-center"
                            style={{ minWidth: "20vw" }}
                          >
                            <>
                              <p
                                className="text-center m-0 p-1 rounded-start"
                                style={{
                                  background: "#0BB783",
                                  zIndex: "3",
                                  width:
                                    (item?.status?.completed /
                                      item?.noOfCandidates) *
                                      100 +
                                    "%",
                                  color: "white",
                                }}
                              >
                                {item?.status?.completed}
                              </p>
                            </>
                            <>
                              <p
                                className="text-center m-0 p-1"
                                style={{
                                  background: "#F2C94C",
                                  zIndex: "2",
                                  width:
                                    (item?.status?.started /
                                      item?.noOfCandidates) *
                                      100 +
                                    "%",
                                  marginLeft: "-15px",
                                }}
                              >
                                {item?.status?.started}
                              </p>
                            </>
                            <>
                              <p
                                className="text-center m-0 p-1 rounded-end "
                                style={{
                                  background: "#F3F6F9",
                                  zIndex: "1",
                                  width:
                                    (item?.status?.notStarted /
                                      item?.noOfCandidates) *
                                      100 +
                                    "%",
                                  marginLeft: "-15px",
                                }}
                              >
                                {item?.status?.notStarted}
                              </p>
                            </>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex justify-content-end align-items-center">
                            <div className={`me-2 ${styles.icon}`}>
                              <Link to={`/editAssessment/${item?._id}`}>
                                <Edit sx={{ fontSize: 24, color: "#3699FF" }} />
                              </Link>
                            </div>
                            {/* <div
                              onClick={() => deleteAssessment(item?._id)}
                              className={`me-2 ${styles.icon}`}
                            >
                              <Delete sx={{ fontSize: 24, color: "#3699FF" }} />
                            </div> */}
                            <AlertDialogSlide
                              del={() => deleteAssessment(item._id)}
                              text={"Are you sure want to delete ?"}
                              type="delete"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {deleteSuccess && (
                <Snackbar
                  open={deleteSuccess}
                  autoHideDuration={3000}
                  onClose={handleCloseSnack}
                >
                  <Alert
                    onClose={handleCloseSnack}
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    Deleted Successfully!
                  </Alert>
                </Snackbar>
              )}
            </div>

            {gridData?.data?.length > 0 && (
              <Link to="/myAssesment">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: 10,
                  }}
                >
                  <button type="button" className={styles.saveButton}>
                    See more
                  </button>
                </div>
              </Link>
            )}
          </div>
        </div>
      )}
    </>
  );
};;

export default Dashboard;
