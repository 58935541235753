import React from "react";
import CardComponent from "../../../SkillsAndTechnology/CardComponent";
import RIcon from "../../../../Files/img/r.png";
import cLanguageIcon from "../../../../Files/img/c-.png";
import javaLanguageIcon from "../../../../Files/img/java.png";
import javascriptLanguageIcon from "../../../../Files/img/javascript.png";
import pythonIcon from "../../../../Files/img/python.png";
import { useNavigate, useParams } from "react-router-dom";
import RoutingPaths from "../../../../utils/RoutingPaths";

const cardValues = [
  {
    questionType: "Javascript",
    // answerType: "One correct answer",
    image: javascriptLanguageIcon,
  },
  {
    questionType: "C++",
    // answerType: "Multiple correct answers",
    image: cLanguageIcon,
  },
  {
    questionType: "Java",
    // answerType: "One correct answer",
    image: javaLanguageIcon,
  },
  {
    questionType: "Python",
    // answerType: "Requires exact match",
    image: pythonIcon,
  },
  // {
  //   questionType: "R",
  //   // answerType: "Coding question",
  //   image: RIcon,
  // },
];
export default function Coding() {
  const { category } = useParams()
  // const navigate=useNavigate()
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
      }}
    >
      {cardValues.map((item,index)=>{
        return(
          //onClick={()=> navigate(RoutingPaths.CodingQuestion.slice(0, -9)  + item.questionType)}
          <div key={index} >
            <CardComponent
             image={item.image}
             questionType={item.questionType}
            //  answerType={item.answerType}
            category={category}
            />
          </div>
        )
      })}
    </div>
  );
}
