import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Delete } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import AlertDialogSlide from "../Slider/PopUp/AlertDialogSlide";
import {
  addHiringStageAPI,
  getAllHiringStagesAPI,
  updateHiringStageAPI,
} from "../../../../../utils/constants";
import {
  Box,
  Button,
  CircularProgress,
  Switch,
  TablePagination,
  TextField,
} from "@mui/material";
import { InputBase } from "@mui/material";
import styles from "./ManageHiringStage.module.scss";
import { makeStyles } from "@mui/styles";
import Header from "../../../../Header/Header";
import axiosInstance from "../../../../../services/api/client";
const useStyles = makeStyles({
  table: {
    width: "100%",
  },
  pagination: {
    "& p": {
      marginBottom: "0",
    },
  },
});

export default function BasicTable() {
  const [candidateLists, setCandidateLists] = useState([]);
  const [checked, setChecked] = React.useState(true);
  const [label, setLabel] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleChange = (event, id) => {
    axiosInstance
      .put(`${updateHiringStageAPI}${id}`, { isActive: event.target.checked })
      .then((response) => {
        if (response.status === 200) {
          setChecked(!checked);
        }
      });
  };

  const handleText = (e) => {
    setLabel(e.target.value);
  };

  const addNewHiringStage = () => {
    axiosInstance
      .post(addHiringStageAPI, {
        label: label,
      })
      .then((response) => {
        if (response.status === 200) {
          setChecked(!checked);
          setLabel("");
        }
      });
  };
  const deleteStage = (id) => {
    axiosInstance
      .put(`${updateHiringStageAPI}${id}`, { isDeleted: true })
      .then((response) => {
        if (response.status === 200) {
          setChecked(!checked);
        }
      });
  };
  useEffect(() => {
    function fetchHiringStages() {
      axios.get(getAllHiringStagesAPI).then((response) => {
        if (response.status === 200) {
          setCandidateLists(response.data.data);
          setLoading(false);
        }
      });
    }
    fetchHiringStages();
  }, [checked]);
  return (
    <>
      <Header />
      <div
        style={{
          display: "flex",
          margin: 20,
          justifyContent: "center",
          gap: 10,
        }}
      >
        <InputBase
          style={{
            border: "1px solid black",
            backgroundColor: "white",
            padding: 5,
            borderRadius: "5px",
          }}
          value={label}
          onChange={handleText}
          placeholder="Type a Label"
        />
        <Button onClick={addNewHiringStage} variant="contained">
          Add
        </Button>
      </div>
      <div className={styles.Container}>
        <div className={styles.card}>
          <div>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // height: "100vh",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <table className="w-100">
                <thead>
                  <tr>
                    <th className={styles.th}>
                      <h6>NAME</h6>
                    </th>
                    <th className={styles.th} style={{ textAlign: "center" }}>
                      <h6>ACTIVE</h6>
                    </th>
                    <th className={styles.action}>
                      <h6>ACTION</h6>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {candidateLists
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((item, i) => (
                      <tr key={item?._id}>
                        <td className={styles.td}>
                          <div className="d-flex align-items-center mt-2 mb-2">
                            <div className="ms-3">
                              <p className="m-0">{item?.label}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex justify-content-center align-items-center">
                            <Switch
                              checked={item.isActive}
                              onChange={(e) => handleChange(e, item._id)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="d-flex justify-content-end align-items-center">
                            <div className={`me-2 ${styles.icon}`}>
                              <AlertDialogSlide
                                del={() => deleteStage(item._id)}
                                text={"Are you sure want to delete ?"}
                                type="delete"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}

            <TablePagination
              component="div"
              className={classes.pagination}
              rowsPerPage={rowsPerPage}
              count={candidateLists?.length}
              page={page}
              rowsPerPageOptions={[5, 10, 20, 30, 50]}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
}
