import { createSlice } from "@reduxjs/toolkit";

export const assessmentSlice = createSlice({
  name: "assessmentSlice",
  initialState: {
    activeStep: 0,
    assessmentData: {},
    jobRoles: {},
  },
  reducers: {
    assessmentData: (state, action) => {
      state.assessmentData = action.payload;
    },
    jobRoleData: (state, action) => {
      state.jobRoles = action.payload;
    },

    increaseActiveStep: (state) => {
      if (state.activeStep < 2) {
        state.activeStep = state.activeStep + 1;
      }
    },
    decreaseActiveStep: (state) => {
      if (state.activeStep > 0) {
        state.activeStep = state.activeStep - 1;
      }
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
  },
});

export const assessmentDataAction = (value) => async (dispatch) => {
  try {
    dispatch(assessmentData(value));
  } catch (err) {
    throw new Error(err);
  }
};
export const jobRoleDataAction = (value) => async (dispatch) => {
  try {
    dispatch(jobRoleData(value));
  } catch (err) {
    throw new Error(err);
  }
};
export const increaseActiveStepAction = () => async (dispatch) => {
  try {
    dispatch(increaseActiveStep());
  } catch (err) {
    throw new Error(err);
  }
};
export const decreaseActiveStepAction = () => async (dispatch) => {
  try {
    dispatch(decreaseActiveStep());
  } catch (err) {
    throw new Error(err);
  }
};
export const setActiveStepAction = (value) => async (dispatch) => {
  try {
    dispatch(setActiveStep(value));
  } catch (err) {
    throw new Error(err);
  }
};

export const {
  assessmentData,
  jobRoleData,
  increaseActiveStep,
  decreaseActiveStep,
  setActiveStep,
} = assessmentSlice.actions;
export default assessmentSlice.reducer;
