import {createTheme} from "@mui/material";

export const customTheme = createTheme({
    palette: {
        success:{main: '#29CC97'},
        error:{main: '#F64E60'},
        secondary: {main: '#8C8C8C'},
        primary:{main: '#625AFA'},
        info: {main: '#8950FC'},
        text: {primary: '#333333', secondary: '#8C8C8C'}
    },
    typography: {
        fontFamily: "Mulish",
        h1:{
            fontWeight: "600",
            fontSize: "1.625rem"
        },
        h2:{
            fontWeight: "600",
            fontSize: "1.25rem"
        },
        h3:{
            fontWeight: "600",
            fontSize: "1.125rem"
        },
        h4: {
            fontWeight: "500",
            fontSize: ".875rem",
        },
        body2: {
            fontSize: ".75rem"
        }
    },
    components: {
        MuiButton:{
            styleOverrides: {
                root: {
                    textTransform: "capitalize",
                    borderRadius: ".375rem",
                    fontWeight: "600",
                    boxShadow: "none",
                },
                contained: {
                    border: `.0625rem solid`,
                    padding: "5px 15px",
                    '&:hover': {
                        boxShadow: "none",
                    },
                },
                containedError:{
                   borderColor: `#F64E60`,
                    '&:hover': {
                        borderColor: `#F64E60`,
                    },
                },
                containedPrimary:{
                    color: `#fff`,
                    borderColor: `#625AFA`,
                    '&:hover': {
                        borderColor: `#625AFA`,
                    },
                },
                outlinedPrimary:{
                    backgroundColor: '#fff',
                    '&:hover':{
                        backgroundColor: ' #E1F6FF',
                    }
                },
                outlinedSecondary:{
                    color: '#333333',
                    borderColor: '#E3E3E3'
                },
                sizeSmall: {
                    height: 34
                },
                sizeMedium: {
                    height: 40
                },
                sizeLarge: {
                    height: 48
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                    borderRadius:".75rem"
                }
            }
        },
        MuiMenu:{
           styleOverrides:{
               root: {
                   boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.15)',
                   borderRadius: '5px'
               }
           }
        },
        MuiOutlinedInput: {
            styleOverrides:{
                root: {
                    borderRadius: `.375rem`
                }
            }
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    borderColor: "#E4E6EF",
                    fontFamily: 'Mulish'
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: "#B5B5C3"
                }
            }
        },
        MuiChip:{
            styleOverrides:{
                root:{
                    fontSize: '.875rem',
                    fontFamily: 'Mulish',
                    '& .MuiChip-label':{
                        fontFamily: 'Mulish'
                    },
                    '& .MuiSvgIcon-root':{
                        marginRight: '.625rem'
                    }
                }
            },
        }
    },
})