import { Divider, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SkillsService from "../../../../services/api/SkillsService";
import { jobRoleDataAction } from "../../../../utils/redux/slices/assessmentSlice";
import MuiMenuCommon from "../../../common/MuiMenuCommon/MuiMenuCommon";
import RHFInputController from "../../../RHFControllers/RHFInputController";
import RHFTextAreaController from "../../../RHFControllers/RHFTextAreaController";
import styles from "./NameAssessment.module.scss";

const NameAssessment = ({ increaseActiveStep, decreaseActiveStep }) => {
  const [jobRoles, setJobRoles] = useState([]);
  const dispatch=useDispatch()
  useEffect(() => {
    SkillsService.getJobRoles().then((res) => {
      const data = res?.data?.data.map(({ _id, title: label }) => ({
        _id,
        label,
      }));
      setJobRoles(data);
      const jobRoleIdLabelMap={}
      data.forEach(({_id,label})=>jobRoleIdLabelMap[_id]=label)
      dispatch(jobRoleDataAction(jobRoleIdLabelMap))
    });
  }, []);


  return (
    <div className={styles.main}>
      <div className={`page-spacing pd-y-30`}>
        <div className={`container-fluid`}>
          <div className={styles._cardContainer}>
            <div className={styles.flexResponsive}>
              <div>
                <Typography variant={"h1"} component={"h1"}>Create your assessment</Typography>
                <Typography
                  component={"p"}
                  variant={"h3"}
                  className={`font-weight-normal`}
                  color={"secondary"}
                >
                  If you need more info, please check out{" "}
                  <Link to="/faq">FAQ Page</Link>
                </Typography>
              </div>
            </div>
            <>
              <h3>Name your Assessment</h3>
              <RHFInputController
                RHFname="assessmentName"
                type="text"
                placeholder="Assessment Name"
                className="form-control form-control-lg"
              />

              <h3>Job/Role Name</h3>
              <MuiMenuCommon
                options={jobRoles}
                RHFName="jobRole"
                placeholder="Select Role"
                useId={true}
              />
              <h3>Description</h3>
              <RHFTextAreaController
                RHFname="description"
                type="text"
                placeholder="Description"
                className="form-control form-control-lg"
              />
            </>
            <Divider sx={{ margin: "30px 0" }} />
            <button
              type="button"
              className={`btn btn-snow btn-lg font-weight-bold font font-size-base pd-x-24`}
              onClick={decreaseActiveStep}
            >
              Back
            </button>
            <button
              type={`button`}
              onClick={increaseActiveStep}
              className={
                "btn btn-primary btn-lg font-weight-bold text-white font-size-base pd-x-24 mg-l-12"
              }
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NameAssessment;
