import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Menu,
  MenuItem,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { useSnackbar } from 'notistack';
import Header from "../Header/Header";
import IMAGES from "../../assets/images/images";
import Loader from "../Loader/Loader";
import { useEffect } from "react";
import SkillsService from "../../services/api/SkillsService";
import FormDialog from "./FormDialog";
import ConfirmationDialog from "../common/ConfirmationDialog/ConfirmationDialog";

const actionTypeMap = { list: 'list', create: 'create', update: 'update', delete: 'delete' };

const Skill = () => {
  const loader = useSelector((state) => state.Loader.loading);
  const methods = useForm({ defaultValues: { label: '' } })
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState({
    skills: [],
    actionType: actionTypeMap.list,
    skill: null,
    isFormOpen: false,
    isConfirmDialogOpen: false,
  });

  const setIsFormOpen = (isFormOpen) => setData(preData => ({...preData, isFormOpen }))
  const setIsConfirmDialogOpen = (isConfirmDialogOpen) => setData(preData => ({...preData, isConfirmDialogOpen }))
 
  const setListAction = () => setData(preData => ({...preData, actionType: actionTypeMap.list, isFormOpen: false, isConfirmDialogOpen: false }));
  const setCreateAction = () => setData(preData => ({...preData, actionType: actionTypeMap.create, skill: { label: '' }, isFormOpen: true }));
  const setUpdateAction = (skill) => setData(preData => ({...preData, actionType: actionTypeMap.update, skill, isFormOpen: true }));
  const setDeleteAction = (skill) => setData(preData => ({...preData, actionType: actionTypeMap.delete, skill, isConfirmDialogOpen: true }));

  const handleAxiosError = (err) => {
    if(err.response){
      let { msg } = err.response.data;
      if(msg.match(/you entered is already registered/)) msg = "Skill Name Already Exists";
      enqueueSnackbar(msg, { variant: "error" })
      return;
    }
    enqueueSnackbar("Something went Wrong", { variant: "error" })
  }

  const handleCreateSkill = (payload, message) => {
    SkillsService.createPrimarySkill(payload)
    .then(() => {
      enqueueSnackbar(message, { variant: "success" })
      setListAction()
    })
    .catch((err) => handleAxiosError(err))
  }

  const handleUpdateSkill = (payload, message) => {
    SkillsService.updatePrimarySkill(data.skill._id, payload)
    .then(() => {
      enqueueSnackbar(message, { variant: "success" })
      setListAction()
    })
    .catch((err) => handleAxiosError(err))
  }

  const handleDeleteSkill = () => {
    SkillsService.deletePrimarySkill(data.skill._id)
    .then(() => {
      enqueueSnackbar("Skill Deleted", { variant: "success" })
      setListAction()
    })
    .catch((err) => handleAxiosError(err))
  }

  const dialogFormConfirmAction = (values) => {
    if(![actionTypeMap.create, actionTypeMap.update].includes(data.actionType)){
      return
    }
    const {label} = values;
    if(data.actionType === actionTypeMap.create){
      handleCreateSkill({label}, "Skill created");
    }
    else{
      handleUpdateSkill({label}, "Skill Updated");
    }
  }

  useEffect(() => {
    async function getPrimarySkills(){
      SkillsService.getPrimarySkills().then((response) => {
        const { result, data, msg } = response.data;
        if(!result) return console.log(msg);
        setData(preData => ({...preData, skills: data }))
      }).catch((err) => alert(err.message))
    }
    if(data.actionType == actionTypeMap.list){
      getPrimarySkills();
    }
  }, [data.actionType])


  if(loader){
      return (<Loader />)
  }

  return (
    <>
      <Header />
      <div className={`page-spacing pd-y-20`}>
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between flex-wrap mg-b-10">
            <div className={`d-flex align-items-center`}>
              <div className={"d-flex align-items-center flex-wrap"}>
                Skills
              </div>
            </div>
            <div className={`d-flex align-items-center flex-wrap`}>

              <Button
                className={`text-nowrap`}
                variant={"contained"}
                color={"primary"}
                size={"small"}
                onClick={() => {
                  methods.setValue("label", "")
                  setCreateAction()
                }}
              >
                <i className={`icon-plus mg-r-6`}></i>
                New Primary Skill
              </Button>
            </div>
          </div>
          
          <Card className={'card-table'}>
              <CardContent className="pd-x-0 pd-y-0">
                <TableContainer className="custom-table">
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>SN</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    { data.skills.length ? (
                      <TableBody>
                        {data.skills.map(
                          (skill,index) => {
                            return (
                              <TableRow
                                key={skill._id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>{index + 1}</TableCell>
                                <TableCell className="pd-l-0">
                                  <Box>
                                    <Link
                                      to={`/sub-skills/${skill._id}`}
                                      style={{
                                        color: "#000",
                                        textDecoration: "none",
                                      }}
                                    >
                                      <Typography className="table-title">
                                        {skill.label}
                                      </Typography>
                                    </Link>
                                  </Box>
                                </TableCell>
                                <TableCell className="pd-l-0">
                                  <button onClick={() => {
                                      methods.setValue("label", skill.label);
                                      setUpdateAction(skill);
                                    }}>
                                    <EditIcon color="primary" />
                                  </button>
                                  <button onClick={() => setDeleteAction(skill)}>
                                  <DeleteIcon color="primary" />
                                  </button>
                                  
                                </TableCell>
                              </TableRow>
                              
                            );
                          }
                        )}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={12}>
                            No Data Found!
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          {/* <Box
            className={`d-flex justify-content-between align-items-center mg-t-30`}
          >
            {data?.skills.length > 0 && !loader && (
              <Typography variant={"body2"} color={"secondary"}>
                Showing {(page - 1) * PER_PAGE + 1} to{" "}
                {page * PER_PAGE >= data.totalCount
                  ? data?.skills.length
                  : page * PER_PAGE}{" "}
                of {data?.skills.length} entries
              </Typography>
            )}

            {data?.skills.length > PER_PAGE && !loader && (
              <Pagination
                color={"primary"}
                className={"custom-pagination"}
                shape="rounded"
                count={data.totalCount}
                page={page}
                variant="outlined"
                onChange={() => {}}
              />
            )}
          </Box> */}
        </div>
      </div>
      <FormDialog 
        open={data.isFormOpen} 
        setOpen={setIsFormOpen}
        label="Primary Skill"
        value={data.skill?.label}
        methods={methods}
        actionType={data.actionType}
        confirmAction={dialogFormConfirmAction}
      />
      <ConfirmationDialog
        open={data.isConfirmDialogOpen}
        setOpen={setIsConfirmDialogOpen}
        title="Are you sure want to delete this skill"
        subtitle=""
        okButtonText="Delete"
        cancelButtonText="Cancel"
        confirmAction={handleDeleteSkill}
      />
    </>
  );
};
export default Skill;
