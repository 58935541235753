import React, { createContext, useState } from "react";
import { useEffect } from "react";
const authContext = createContext({});

const AuthProvider = (props) => {
  const initialStore = JSON.parse(localStorage.getItem("authUser")) || {};
  const [user, setUser] = useState(initialStore);
  useEffect(() => {
    localStorage.setItem("authUser", JSON.stringify(user));
  }, [user]);

  return (
    <authContext.Provider value={{ user, setUser }}>
      {props.children}
    </authContext.Provider>
  );
};

export { authContext, AuthProvider };
