import { CognitoUserPool } from "amazon-cognito-identity-js";

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_CLIENT_ID,
};

// export const getCurrentUser=()=> {
//   const userPool = new CognitoUserPool({
//    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
//    ClientId: process.env.REACT_APP_CLIENT_ID,
//   });
//   return userPool.getCurrentUser();
//  }

//  console.log(getCurrentUser())


export default new CognitoUserPool(poolData);
