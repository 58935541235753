import { Box, Button, Card, CardContent, Typography, TextareaAutosize } from "@mui/material";
import React from "react";
import styles from "../NewQuestion.module.scss";
import IMAGES from "../../../../assets/images/images";
import { Add } from "@mui/icons-material";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import RHFInputController from "../../../RHFControllers/RHFInputController";
import TimeLimit from "../TimeLimit/TimeLimit";
import { useSnackbar } from "notistack";
import { Limits } from "../../../../utils/constants";

const MultipleChoiceQuestion = ({ methods }) => {
  const { control, getValues } = methods;
  const {
    fields: textFields,
    append: addTextField,
    remove: removeField,
  } = useFieldArray({
    control,
    name: "answers",
  });

  const { enqueueSnackbar } = useSnackbar();

  const multiChoiceAnswers = useWatch({
    control,
    name: "answers",
  });

  const handleAddField = () => {
    if (textFields.length < Limits.multipleChoiceQuestion) {
      addTextField({
        answer: "",
        isCorrect: false,
      });
    } else {
      enqueueSnackbar("You can provide maximum 12 answers", {
        variant: "error",
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <Box className="mg-b-34">
        <Typography variant={"h3"} component={"h3"} className={"mg-b-10"}>
          Answers
        </Typography>
        <Card className={styles.cardDashed}>
          <CardContent className={"pd-x-20 pd-y-20"}>
            <ul className={`list-group list-group-flush list-unstyled`}>
              {textFields.map((fields, index) => {
                return (
                  <li className="mg-b-20" key={index}>
                    <Controller
                      key={fields.id}
                      render={({ field }) => (
                        <div className={styles.hasAction}>
                          {/* <input
                            {...field}
                            type="text"
                            className="form-control form-control-lg mb-1"
                          /> */}
                          <TextareaAutosize
                            maxRows={7}
                            {...field}
                            type="text"
                            className="form-control form-control-lg mb-1"
                          />
                          <div
                            className={`${styles.inputAction} d-flex align-items-center`}
                          >
                            <Controller
                              name={`answers.${index}.isCorrect`}
                              control={control}
                              render={({ field }) => (
                                <div className="custom-checkbox">
                                  <input
                                    className="custom-control-input"
                                    type="checkbox"
                                    id={`category-1`}
                                    name={`category-1`}
                                    {...field}
                                    checked={getValues(
                                      `answers.${index}.isCorrect`
                                    )}
                                  />
                                  <label
                                    className="custom-control-label text-capitalize"
                                    htmlFor={`category-1`}
                                  ></label>
                                </div>
                              )}
                            />
                            <Button
                              variant={"text"}
                              className="btn btn-link"
                              onClick={() => removeField(index)}
                            >
                              <img src={IMAGES.Trash} alt="trash" />
                            </Button>
                          </div>
                        </div>
                      )}
                      name={`answers.${index}.answer`}
                      control={control}
                    />
                  </li>
                );
              })}
            </ul>
            <div className="d-flex align-items-center justify-content-between mg-b-24">
              <button
                type="button"
                className="btn btn-link text-decoration-none p-0 font-weight-semi-bold"
                onClick={handleAddField}
              >
                <Add className="mg-r-8" />
                Add more
              </button>
              <div className="custom-checkbox">
                <Controller
                  name={`shuffle`}
                  control={control}
                  render={({ field }) => (
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id={`shuffle`}
                      name={`shuffle`}
                      {...field}
                      checked={getValues(`shuffle`)}
                    />
                  )}
                />
                <label
                  className="custom-control-label text-capitalize"
                  htmlFor={`shuffle`}
                >
                  Shuffle options
                </label>
              </div>
            </div>
            {multiChoiceAnswers.every(({ isCorrect }) => !isCorrect) ? (
              <Typography
                variant={"body2"}
                component={"p"}
                className="warning-wrap font-weight-medium"
              >
                <img className="icon-left" src={IMAGES.Warning} alt="warning" />
                The question must have at least one correct answer.
              </Typography>
            ) : null}
          </CardContent>
        </Card>
      </Box>
    </FormProvider>
  );
};

export default MultipleChoiceQuestion;
