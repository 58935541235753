import axiosInstance from "./client";
import queryParamsToString from "../../utils/functions/queryParamsToString";

const authorsUrl = "/api/authors"

const getAuthors = (queryParams = {}) => {
    const queryString = queryParamsToString(queryParams);
    return axiosInstance.get(`${authorsUrl}?${queryString}`);
}

const createAuthor = (payload) => {
    console.log(payload)
    return axiosInstance.post(authorsUrl, payload);
}

const updateAuthor = (authorId, payload) => {
    return axiosInstance.patch(`${authorsUrl}/${authorId}`, payload);
}

const deleteAuthor = (authorId) => {
    return axiosInstance.delete(`${authorsUrl}/${authorId}`);
}

const authorService = {
    getAuthors,
    createAuthor,
    updateAuthor,
    deleteAuthor
}

export default authorService;