import React, { useEffect } from "react";
import "./ReactTag.scss";

export default function ReactTag(props) {
  useEffect(() => {
    setTags(props.tags);
  }, [props.tags]);
  const [tags, setTags] = React.useState(props.tags);
  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };
  const addTags = (event) => {
    if (event.target.value !== " " && tags.length < 5) {
      const newTag=`#${event.target.value}`
      setTags([...tags,newTag ]);
      props.selectedTags([...tags, newTag]);
      event.target.value = "";
    }
  };

  return (
    <div className="App">
      <div className="tags-input">
        <ul id="tags">
          {tags?.map((tag, index) => (
            <li key={index} className="tag">
              <span className="tag-title">{tag}</span>
              <span
                className="tag-close-icon"
                onClick={() => removeTags(index)}
              >
                x
              </span>
            </li>
          ))}
        </ul>
        <input
          type="text"
          onKeyDown={(event) => (event.key === "Enter"|| event.code === "Space" ? addTags(event) : null)}
          onKeyUp={(event)=>(event.code === "Backspace" ? removeTags(tags.length-1) : null)}
          placeholder="Enter #tags"
        />
      </div>
    </div>
  );
}
