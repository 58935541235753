import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { authorService } from "../../services";
import Header from "../Header/Header";
import AuthorList from "./AuthorList";
import AuthorAddUpdateDialog from "./AuthorAddUpdateDialog";
import { useEffect } from "react";
import {
    getUploadUrlAndObjectUrl,
    uploadFileToAWS,
  } from "../../services/api/aws";
import ConfirmationDialog from "./ConfirmDialog";

const actions = { list: "list", create: "create", update: "update", delete: "delete" }

const Author = () => {

    const { enqueueSnackbar } = useSnackbar();
    const [image, setImage] = useState(null);
    const [data, setData] = useState({
        isLoading: false,
        isFormDialogOpen: false,
        isConfirmDialogOpen: false,
        authors: [],
        author: null,
        action: { type: actions.list, payload: null }
    })

    useEffect(() => {
        if(data.action.type === actions.list){
            handleGetAuthors();
        }
        else if(data.action.type === actions.create){
            setImage(null);
            methods.reset({ name: '', description: '', profilePic: ''});
        }
        else if(data.action.type === actions.update){
            const { name, description, profilePic } = data.action.payload.author;
            methods.reset({  name, description, ...(profilePic ? { profilePic } : { profilePic: '' })})
            profilePic ? setImage(profilePic) : setImage(null);
        }
    }, [data.action])

    const setLoading = (isLoading) => setData(preData => ({...preData, isLoading}));
    const setConfirmDialog = (isConfirmDialogOpen) => setData(preData => ({...preData, isConfirmDialogOpen}));
    const setFormDialogOpen = (isFormDialogOpen) => setData(preData => ({...preData, isFormDialogOpen}));
    const setAuthors = (authors) => setData(preData => ({...preData, isLoading: false, authors}));
    const setListAction = () => setData(preData => ({...preData, isConfirmDialogOpen: false, isFormDialogOpen: false, isLoading: true, action: { type: actions.list, payload: null }}))
    const setCreateAction = () => setData(preData => ({...preData, isFormDialogOpen: true, action: { type: actions.create, payload: null }}));
    const setUpdateAction = (author) => setData(preData =>({...preData, isFormDialogOpen: true, action: { type: actions.update, payload: { author } }}));
    const setDeleteAction = (author) => setData(preData =>({...preData, isConfirmDialogOpen: true, action: { type: actions.delete, payload: { author } }}));

    const methods = useForm({
        defaultValues: {
            name: "",
            description: "",
            profilePic: ""
        },
    })

    const handleGetAuthors = () => {
        setLoading(true)
        authorService.getAuthors()
        .then((response) => setAuthors(response.data.data))
        .catch(err => {
            let errMsg = "Something Went Wrong"
            if(err.response){
                errMsg = err.response.data.msg;
            };
            setLoading(false)
        })
    }

    const handleCreateOrUpdateAuthor = async(payload) => {
        setLoading(true);
        try{
            const { name, description } = payload
            let profilePic;
            if(payload.profilePic.startsWith("blob")){
                const profilePicBlob = await fetch(payload.profilePic).then(r => r.blob());
                const{ objectUrl, preSignedUrl } = await getUploadUrlAndObjectUrl(profilePicBlob.type);
                profilePic = objectUrl;
                await uploadFileToAWS(preSignedUrl, profilePicBlob.type, profilePicBlob);
            }
            if(data.action.type === actions.create){
                await authorService.createAuthor({ name, description, ...(profilePic && { profilePic }) });
                enqueueSnackbar("Author Created", { variant: "success" });
            }else if(data.action.type === actions.update){
                await authorService.updateAuthor(data.action.payload.author._id, { name, description, ...(profilePic && { profilePic }) });
                enqueueSnackbar("Author Updated", { variant: "success" });
            }
            setListAction();
        }catch(err){
            console.log(err)
            let errMsg = "Something Went Wrong"
            if(err.response){
                errMsg = err.response?.data?.msg;
            };
            setLoading(false);
        }
    }

    const handleDeleteAuthor = async() => {
        setLoading(true);
        try{
            await authorService.deleteAuthor(data.action.payload.author._id);
            enqueueSnackbar("Author Deleted", { variant: "success" });
            setListAction();
        }catch(err){
            let errMsg = "Something Went Wrong"
            if(err.response){
                errMsg = err.response?.data?.msg;
            };
            setLoading(false);
        }
    }
    

    return(
       <>
        <Header />
        <div className={`page-spacing pd-y-20`}>
            <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between flex-wrap mg-b-10">
                    <div className={`d-flex align-items-center`}>
                        <div className={"d-flex align-items-center flex-wrap"}>
                            Authors
                        </div>
                    </div>
                    <div className={`d-flex align-items-center flex-wrap`}>
                        <Button
                            className={`text-nowrap`}
                            variant={"contained"}
                            color={"primary"}
                            size={"small"}
                            onClick={() => {
                            setCreateAction()
                            }}
                        >
                            <i className={`icon-plus mg-r-6`}></i>New Author
                        </Button>
                    </div>

                </div>
            </div>
            <AuthorList
                isLoading={data.isLoading}
                authors={data.authors}
                setUpdateAction={setUpdateAction}
                setDeleteAction={setDeleteAction} 
            />
        </div>
        
        <AuthorAddUpdateDialog
            open={data.isFormDialogOpen}
            setOpen={setFormDialogOpen}
            methods={methods}
            confirmAction={handleCreateOrUpdateAuthor}
            actionType={data.action.type}
            isLoading={data.isLoading}
            image={image}
            setImage={setImage}
        />
        <ConfirmationDialog
            open={data.isConfirmDialogOpen}
            setOpen={setConfirmDialog}
            title="Are you sore want to delete"
            subtitle=""
            okButtonText={data.isLoading ? "Deleting..." : "Delete"}
            cancelButtonText="Cancel"
            confirmAction={handleDeleteAuthor}
        />
       </>
    );
}

export default Author;