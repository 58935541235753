import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { userService } from "../../services";
import Header from "../Header/Header";
import UserList from "./UserList";
import { Roles } from "../../utils/constants";

const statusMap = { idle: 'idle', pending: 'pending', resolved: 'resolved', rejected: 'rejected' };

const User = () => {

    const [filter, setFilter] = useState({ roles: `${Roles.admin},${Roles.contributor},${Roles.user}` });
    const [users, setUsers] = useState([]);
    const [status, setStatus] = useState(statusMap.pending);

    useEffect(() => {
        userService.getUsers(filter)
        .then(res => {
            if(!res.data.result) throw res.data.msg;
            const _users = res.data?.data
            setStatus(statusMap.resolved);
            setUsers(_users);
        })
        .catch(err => setStatus(statusMap.rejected))
    }, [])
    
    return(
       <>
        <Header />
        <div className={`page-spacing pd-y-20`}>
            <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between flex-wrap mg-b-10">
                    <div className={`d-flex align-items-center`}>
                        <div className={"d-flex align-items-center flex-wrap"}>
                            <b>Users</b>
                        </div>
                    </div>
                </div>
            </div>
            <UserList
                users={users}
                status={status}
            />
        </div>
       </>
    );
}

export default User;