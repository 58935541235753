import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../../services/api/client";
import { categoryAPI, datatypes_API, languageAPI } from "../../constants";

export const QuestionSlice = createSlice({
  name: "Questions",
  initialState: {
    data: [],
    languages: [],
    category: [],
    dataTypeMap: {},
    returnTypes: [],
  },
  reducers: {
    getDataTypes: (state, action) => {
      state.data = action.payload;
      let dataTypeMap = {};
      action.payload.forEach(
        ({ dataType, value }) => (dataTypeMap[value] = dataType)
      );
      state.dataTypeMap = dataTypeMap;
    },
    getReturnTypes: (state, action) => {
      state.returnTypes = action.payload;
    },
    getLanguages: (state, action) => {
      state.languages = action.payload;
    },
    getCategory: (state, action) => {
      state.category = action.payload;
    },
  },
});

export const getDataTypesAsync = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get(datatypes_API);
    let dataTypes = response.data.data;
    let returnTypes = dataTypes.map(({ value, name: label }) => ({
      label,
      value,
    }));
    dataTypes = dataTypes.map(({ id, value, name: title, dataType }) => ({
      id,
      title,
      value,
      dataType,
    }));
 
    dispatch(getDataTypes(dataTypes));
    dispatch(getReturnTypes(returnTypes));
  } catch (err) {
    throw new Error(err);
  }
};

export const getLanguagesAsync = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get(languageAPI);
    dispatch(getLanguages(response.data.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const getCategoryAsync = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get(categoryAPI);
    dispatch(getCategory(response.data.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const { getDataTypes, getLanguages, getCategory,getReturnTypes } =
  QuestionSlice.actions;
export default QuestionSlice.reducer;
