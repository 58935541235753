import {
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import IMAGES from "../../../../assets/images/images";
import React, { useEffect, useState } from "react";
import { Add, Check, TimeToLeaveOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import RoutingPaths from "../../../../utils/RoutingPaths";
import QuestionListService from "../../../../services/api/QuestionListService";
import {
  getTimeInMinSec,
  questionTypeMap,
  setTimeInMinSec,
} from "../../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  addCustomQuestionAction,
  removeCustomQuestionAction,
} from "../../../../utils/redux/slices/customQuestionSlice";

const CustomQuestionDrawer = (props) => {
  const { open, setOpen } = props;
  const [readyToAddClass, setReadyToAddClass] = useState(false);
  const [customQuestions, setCustomQuestions] = useState([]);
  const dispatch = useDispatch();
  const customQuestionsFromStore = useSelector(
    (state) => state.CustomQuestionSlice.customQuestions
  );

  const handleCloseModal = () => {
    setOpen(false);
  };
  useEffect(() => {
    setReadyToAddClass(true);
    QuestionListService.getCustomQuestions().then((res) => {
      setCustomQuestions(res?.data?.data.questions);
    });
  }, []);

  const getQuestionStatus = (id) => {
    let found = false;
    customQuestionsFromStore?.forEach((question) => {
      if (question._id === id) {
        found = true;
      }
    });
    return found;
  };

  const addCustomQuestionToStore = (question) => {
    dispatch(addCustomQuestionAction(question));
  };
  const removeCustomQuestionFromStore = (questionId) => {
    dispatch(removeCustomQuestionAction(questionId));
  };
  const handleAdd = (questionRef) => {
    let found = false;
    customQuestionsFromStore?.forEach((question) => {
      if (question._id === questionRef._id) {
        found = true;
      }
    });
    if (found) {
      removeCustomQuestionFromStore(questionRef._id);
    } else {
      addCustomQuestionToStore(questionRef);
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={`drawer right ${readyToAddClass && "open"}`}>
        <Box className="modal-content">
          <Box
            className={`d-flex align-items-center justify-content-between pd-x-30 pd-y-30 border-bottom`}
          >
            <Typography id="modal-modal-title" variant="h2" component="h2">
              Custom Questions
            </Typography>
            <div>
              <Link
                to={RoutingPaths.NewCustomQuestion}
                state={{ isCustomQuestion: true }}
              >
                <button
                  type="button"
                  className={`btn btn-light-primary mg-r-12`}
                >
                  <Add /> New Question
                </button>
              </Link>
              <button
                type={`button`}
                className={`btn btn-link text-decoration-none`}
                onClick={handleCloseModal}
              >
                <img src={IMAGES.XIcon} alt="close" />
              </button>
            </div>
          </Box>
          <Box className={`pd-x-30 pd-y-30`}>
            <TableContainer
              className="custom-table"
              style={{ maxHeight: "800px" }}
            >
              <Table border={0} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className={"text-dark"}>Name</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {true ? (
                  <TableBody>
                    {customQuestions?.map((question) => (
                      <TableRow key={question._id}>
                        <TableCell className={`ps-0`}>
                          <div>
                            <Typography
                              className={`font-size-15`}
                              variant={"h4"}
                              component={"h4"}
                            >
                              {question.title}
                            </Typography>
                            <Typography
                              className={`font-size-sm`}
                              variant={"body1"}
                              component={"p"}
                              color={"secondary"}
                            >
                              Tags:{" "}
                              {question.tags?.length > 3
                                ? `${question.tags?.slice(0, 3).join(", ")}...`
                                : question.tags?.join(", ")}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell>
                          {getTimeInMinSec(question.time)} Minutes
                        </TableCell>
                        <TableCell>
                          {questionTypeMap[question.questionType]}
                        </TableCell>
                        <TableCell className={"pd-r-0"} align={"right"}>
                          <button
                            type={"button"}
                            className={
                              getQuestionStatus(question._id)
                                ? `btn btn-snow text-primary drawer-btn`
                                : `btn btn-primary text-white drawer-btn`
                            }
                            onClick={() => handleAdd(question)}
                          >
                            {getQuestionStatus(question._id) ? (
                              <Check />
                            ) : (
                              "Add"
                            )}
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
                    {/* <TableRow>
                      <TableCell className={`ps-0`}>
                        <div>
                          <Typography
                            className={`font-size-15`}
                            variant={"h4"}
                            component={"h4"}
                          >
                            Question Title
                          </Typography>
                          <Typography
                            className={`font-size-sm font-family-roboto`}
                            variant={"body1"}
                            component={"p"}
                            color={"secondary"}
                          >
                            Tags: #Personality, #Communication
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell>15min</TableCell>
                      <TableCell>Short Text</TableCell>
                      <TableCell className={"pd-r-0"} align={"right"}>
                        <button
                          type={"button"}
                          className={`btn btn-primary text-white drawer-btn`}
                        >
                          Add
                        </button>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={`ps-0`}>
                        <div>
                          <Typography
                            className={`font-size-15`}
                            variant={"h4"}
                            component={"h4"}
                          >
                            Question Title
                          </Typography>
                          <Typography
                            className={`font-size-sm font-family-roboto`}
                            variant={"body1"}
                            component={"p"}
                            color={"secondary"}
                          >
                            Tags: #Personality, #Communication
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell>15min</TableCell>
                      <TableCell>Short Text</TableCell>
                      <TableCell className={"pd-r-0"} align={"right"}>
                        <button
                          type={"button"}
                          className={`btn btn-snow text-primary drawer-btn`}
                        >
                          <Check />
                        </button>
                      </TableCell>
                    </TableRow> */}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={5}>
                        No Data Found!
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomQuestionDrawer;
