import React from "react";
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  LensTwoTone,
} from "@mui/icons-material";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { useState } from "react";

const MuiMenuCommon = ({ options, RHFName, placeholder, useId }) => {
  const methods = useFormContext();
  const { setValue, control, getValues } = methods;
  const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);
  const openCategory = Boolean(categoryAnchorEl);

  const handleCategoryOpen = (event) => {
    setCategoryAnchorEl(event.currentTarget);
  };

  const categoryClose = () => {
    setCategoryAnchorEl(null);
  };

  const handleMenuItemClick = (value, _id) => {
    if (useId) {
      setValue(RHFName, _id, { shouldDirty: true });
    } else {
      setValue(RHFName, value, { shouldDirty: true });
    }

    categoryClose();
  };

  const RHFValue = useWatch({
    control,
    name: RHFName,
  });

  const getLabelFromId = (id) => {
    let label;
    options?.forEach((option) => {
      if (option._id === id) {
        label = option.label;
      }
    });
    return label;
  };
  const getLabelFromValue = (value) => {
    let label;
    options?.forEach((option) => {
      if (option.value === value) {
        label = option.label;
      }
    });
    return label;
  };

  return (
    <>
      <Button
        onClick={handleCategoryOpen}
        className={`btn-toggle`}
        variant={"outlined"}
        color={"secondary"}
        sx={{ width: "100%" }}
        size={"medium"}
      >
        {RHFValue ? (
          <Typography
            noWrap
            className="mg-r-4 pd-y-0 font-weight-medium font-size-sm"
            sx={{ color: "#5E6278" }}
          >
            {useId ? getLabelFromId(RHFValue) : getLabelFromValue(RHFValue)}
          </Typography>
        ) : (
          <Typography
            noWrap
            className="mg-r-4 pd-y-0 font-weight-medium font-size-sm"
          >
            {placeholder}
          </Typography>
        )}

        {openCategory ? (
          <KeyboardArrowUp className="toggle-icon" />
        ) : (
          <KeyboardArrowDown className="toggle-icon" />
        )}
      </Button>
      <Menu
        id="role-menu"
        anchorEl={categoryAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openCategory}
        onClose={categoryClose}
      >
        {options?.map(({ label, _id, value, iconName }, index) => {
          return (
            <MenuItem
              key={index}
              selected={useId ? _id === RHFValue : value === RHFValue}
              onClick={() => handleMenuItemClick(value, _id)}
            >
              <Typography textAlign="center">
                <i className={`${iconName} mg-r-10`}></i>
                {label}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default MuiMenuCommon;
