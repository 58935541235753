import React from "react";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";

const TestLibraryStatusMenu = ({ options, setDropdown, status }) => {
    const [selectedRole, setSelectedRole] = useState("");

    const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);
    const openCategory = Boolean(categoryAnchorEl);

    const handleCategoryOpen = (event) => {
        setCategoryAnchorEl(event.currentTarget);
    };

    const selectedStatusMap = {
        DRAFTED: 'DRAFTED',
        CREATED: 'CREATED',
        APPROVED: 'APPROVED',
        REJECTED: 'REJECTED',
    };
    const categoryClose = () => {
        setCategoryAnchorEl(null);
    };

    const handleMenuItemClick = (label, value) => {
        setSelectedRole(label);
        setDropdown(value);
        categoryClose();
    };

    return (
        <>
            <Button
                onClick={handleCategoryOpen}
                className={`btn-toggle btn-sm w-100`}
                variant={"outlined"}
                color={"secondary"}
                size="small"
            >
                <Typography
                    noWrap
                    className="mg-r-4 pd-y-0 font-weight-medium font-size-sm"
                >
                    {status ? selectedStatusMap[status] : "Select Status"}
                </Typography>
                {openCategory ? (
                    <KeyboardArrowUp className="toggle-icon" />
                ) : (
                    <KeyboardArrowDown className="toggle-icon" />
                )}
            </Button>
            <Menu
                id="role-menu"
                anchorEl={categoryAnchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={openCategory}
                onClose={categoryClose}
            >
                {options?.map(({ label, _id, iconName }, index) => {
                    return (
                        <MenuItem
                            key={index}
                            selected={label === selectedRole}
                            onClick={() => handleMenuItemClick(label, _id)}
                        >
                            <Typography textAlign="center">
                                <i className={`${iconName} mg-r-10`}></i>
                                {label}
                            </Typography>
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

export default TestLibraryStatusMenu;
