import React, {useEffect, useState} from "react";
import UserPool from "../../utils/UserPool";
import {NavLink, useNavigate} from "react-router-dom";
import RoutingPaths from "../../utils/RoutingPaths";
import { useSnackbar } from "notistack";
import styles from "./Login.module.scss";
import IMAGES from "../../assets/images/images";
import {Button, Container, Divider, Typography} from "@mui/material";

export default function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  const changeAuthMode = () => {
    navigate(RoutingPaths.Login)
  };

  const onSubmit = (e) => {
    e.preventDefault();
    UserPool.signUp(email, password, [], null, (err, data) => {
      if (err) {
        enqueueSnackbar(err.message || "Something went wrong", {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Registration Success!", {
          variant: "success",
        });
        navigate(RoutingPaths.Welcome);
      }
    });
  };

  useEffect(()=>{
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  },[])

  return (
    <>
      {true &&
      <div className="Auth-form-container">
        <form className="Auth-form" onSubmit={onSubmit}>
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Sign Up</h3>
            <div className="text-center">
              Already registered?
              <span
                  className="link-primary"
                  style={{ cursor: "pointer" }}
                  onClick={changeAuthMode}
              >
                Login
              </span>
            </div>
            <div className="form-group mt-3">
              <label>Email address</label>
              <input
                  type="email"
                  value={email}
                  className="form-control mt-1"
                  placeholder="Enter email"
                  onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group mt-3">
              <label>Password</label>
              <input
                  type="password"
                  value={password}
                  className="form-control mt-1"
                  placeholder="Set password"
                  onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="btn btn-primary">
                Signup
              </button>
            </div>
          </div>
        </form>
      </div>
      }
      {/* New Sign Up */}
      {false &&
      <>
        <div className={`row g-0`}>
          <div className="col-md-6">
            <div className={`${styles.loginBanner}`}>
              <img className={`img-fluid`} src={IMAGES.LoginBanner} alt="desk"/>
            </div>
          </div>
          <div className="col-md-6">
            <Container maxWidth={false}>
              <div className={`${styles.loginPanel} d-flex justify-content-center align-items-center`}>
                <form className={`${styles.loginForm}`} onSubmit={onSubmit}>
                  <div className={`mg-b-50 text-center`}>
                    <img className={`img-fluid`} src={IMAGES.Logo} alt="logo"/>
                  </div>
                  <div className={`d-flex justify-content-between align-items-center mg-b-30`}>
                    <Typography variant={"h2"} component={"h2"} className={`${styles.heading30}`}>
                      Sign up
                    </Typography>
                    <Typography variant={"body1"} component={"p"} color={"secondary"} className={`font-size-lg font-weight-medium`}>
                      or <button type={"button"} className={`btn btn-link p-0 text-decoration-none`}
                                 onClick={changeAuthMode}>Log in</button>
                    </Typography>
                  </div>
                  <Button color={"secondary"} variant={"outlined"} className={`btn-lg w-100 text-dark`}>
                    <img className={`img-fluid mg-r-12`} src={IMAGES.Google} alt="google"/>
                    Sign up with Google
                  </Button>
                  <div className={`${styles.dividerWithText} text-center`}>
                    <Divider className={styles.divider}/>
                    <Typography variant={"body1"} component={"span"} color={"secondary"} className={`${styles.text} font-size-lg font-weight-medium d-inline-block mg-y-20`}>
                      or
                    </Typography>
                  </div>
                  <div className={`mg-b-20`}>
                    <input
                        type="email"
                        value={email}
                        className="form-control form-control-lg"
                        placeholder="Email*"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className={`mg-b-20`}>
                    <input
                        type="password"
                        value={password}
                        className="form-control form-control-lg"
                        placeholder="Password*"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div>
                    <div className="custom-checkbox custom-checkbox-sm mg-b-20">
                      <input
                          className="custom-control-input"
                          type="checkbox"
                          id={`remind-me`}
                          name={`remind-me`}
                          defaultChecked={true}
                      />
                      <label
                          className={`custom-control-label text-capitalize font-weight-normal text-black-11`}
                      >I agree to the HireTriple <NavLink className={`btn btn-link p-0 font-weight-medium border-0 text-dark`}
                                                               to={RoutingPaths.ForgotPassword}>
                        Terms
                      </NavLink>. Find out more about how we use and protect your data in our <NavLink className={`btn btn-link p-0 border-0 text-dark font-weight-medium`}
                                                                                                                     to={RoutingPaths.ForgotPassword}>
                        Privacy Policy
                      </NavLink>.</label>
                    </div>
                    <button type={"submit"} className={'btn btn-primary text-white btn-lg w-100'}>
                      Create an account
                      <img className={`mg-l-10`} src={IMAGES.ArrowNext} alt="arrow-next"/>
                    </button>
                  </div>
                </form>
              </div>
            </Container>
          </div>
        </div>
      </>
      }
    </>
  );
}
