import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import IMAGES from "../../assets/images/images";

const fileTypes = [
  // "image/jpg",
  // "image/jpeg",
  "video/mp4",
  // "image/png",
  // "image/gif",
];

const fileSizeLimit = 15728640;
const FileInput = (props) => {
  const { name, videoUrl, setVideoUrl } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { register, unregister, setValue, watch, getValues } = useFormContext();
  const [droppedFile, setDroppedFile] = useState([]);
  const files = watch(name);
  const onDrop = useCallback(
    (droppedFiles) => {
      if (fileTypes.includes(droppedFiles[0]?.type)) {
        if (droppedFiles[0].size <= fileSizeLimit) {
          const blob = new Blob([droppedFiles[0]], {
            type: droppedFiles[0]?.type,
          });
          setDroppedFile(droppedFiles);
          setValue("videoBlob", blob);
          const url = URL.createObjectURL(blob);
          setVideoUrl(url);
        } else {
          enqueueSnackbar("Maximum file size is 5MB", {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar("Allowed file type is video/mp4", {
          variant: "error",
        });
      }

      // setValue(name, droppedFiles, { shouldValidate: true });
    },
    [setValue, name]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: props.accept,
  });
  useEffect(() => {
    register(name);
    // return () => {
    //   unregister(name);
    // };
  }, [register, unregister, name]);
  useEffect(() => {
    setValue("detail.video", videoUrl);
  }, [videoUrl]);

  const handleCrossClick = () => {
    setVideoUrl("");
    setDroppedFile([]);
    setValue("videoBlob", []);
  };
  return (
    <>
      {videoUrl && files?.type?.includes("video") ? (
        <>
          <button
            style={{ position: "relative", left: "155px", top: "8px" }}
            type={`button`}
            className={`btn btn-link text-decoration-none`}
            onClick={handleCrossClick}
          >
            <img src={IMAGES.XIcon} alt="close" />
          </button>
          <video key={videoUrl} width="320" height="180" controls>
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </>
      ) : (
        !droppedFile?.length && (
          <div
            {...getRootProps()}
            type="file"
            role="button"
            id={name}
            className="d-flex align-items-center flex-column"
            style={{ border: "1px dashed #009EF6", padding: "1em 6em" }}
          >
            {props.component}
            <input {...props} {...getInputProps()} />
            <Typography
              variant={"body1"}
              color={"secondary"}
              className={`mg-b-10`}
            >
              Click to Browse or Drag file to upload <span style={{color:'red'}}>(Max file size is 15MB)</span>
            </Typography>
          </div>
        )
      )}

      {!!droppedFile?.length && !droppedFile[0]?.type?.includes("video") && (
        <div className="pd-y-10">
          {/* {!!droppedFile?.length && !droppedFile?.type?.includes("video") && ( */}
          <button
            style={{ position: "relative", left: "155px", top: "8px" }}
            type={`button`}
            className={`btn btn-link text-decoration-none`}
            onClick={handleCrossClick}
          >
            <img src={IMAGES.XIcon} alt="close" />
          </button>
          {/* )} */}
          {/* {!!droppedFile?.length && ( */}
          <ul>
            {droppedFile?.map((file) => {
              return <li key={droppedFile.name}>{file.name}</li>;
            })}
          </ul>
          {/* )} */}
          {/* {files[0]?.name} */}
        </div>
      )}
    </>
  );
};

export default FileInput;
