import { Box, Button, Chip, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import {
  BootstrapInput,
  Limits,
  secondarySkillsOptions,
} from "../../utils/constants";
import { Close, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import IMAGES from "../../assets/images/images";
import { useSnackbar } from "notistack";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function RHFTagsController({ RHFName, isEditable }) {
  const { enqueueSnackbar } = useSnackbar();
  const methods = useFormContext();
  const { setValue, watch, control, getValues } = methods;
  const tagsWatch = useWatch({
    control,
    name: RHFName,
  });

  const [secondarySkills, setSecondarySkills] = React.useState(tagsWatch);
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = useState("");

  const handleClick = (e) => {
    console.log("You clicked the Chip.");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (e) => {
    setOpen(true);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // console.log(value)
    setValue(RHFName, typeof value === "string" ? value.split(",") : value);
  };
  // useEffect(() => {
  //   setValue(RHFName, secondarySkills);
  // }, [secondarySkills]);

  //   useEffect(() => {
  //     const subscription = watch((value, { name, type }) => {
  //       if (name === "primarySkill") {
  //         setSecondarySkills([]);
  //         setValue("secondarySkills", []);
  //         setSecondarySkillsMap(secondarySkillsOptions[value.primarySkill]);
  //       }
  //     });
  //     return () => subscription.unsubscribe();
  //   }, [watch]);

  //   const getLabelFromValue = (chipValue) => {
  //     let chipLabel;
  //     secondarySkillsMap.forEach(({ value, label }) => {
  //       if (value === chipValue) {
  //         chipLabel = label;
  //       }
  //     });
  //     return chipLabel;
  //   };

  const handleTagsOnEnterPress = (e) => {
    if (e.key === "Enter" && search !== "") {
      const prevTags = [...tagsWatch];
      if (prevTags?.length >= Limits.tags) {
        enqueueSnackbar("Maximum 12 tags are allowed", {
          variant: "error",
        });
        return;
      }
      prevTags.push(search);
      setValue(RHFName, prevTags);
      setSearch("");
    }
  };

  const handleTags = (value) => {
    const prevTags = [...tagsWatch];
    if (prevTags?.length >= Limits.tags) {
      enqueueSnackbar("Maximum 12 tags are allowed", {
        variant: "error",
      });
      return;
    }
    if (search !== "") {
      prevTags.push(value);
      setValue(RHFName, prevTags);
      setSearch("");
    }
  };

  return (
    <Select
      id="demo-multiple-checkbox"
      className="multiSelect"
      displayEmpty
      multiple
      value={tagsWatch}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      onChange={handleChange}
      input={<BootstrapInput />}
      IconComponent={() =>
        open ? (
          <KeyboardArrowUp className="toggle-icon" />
        ) : (
          <KeyboardArrowDown className="toggle-icon" />
        )
      }
      renderValue={(selected) => {
        if (selected.length === 0) {
          return (
            <InputLabel id="demo-multiple-checkbox-label">
              Select skills
            </InputLabel>
          );
        }
        return (
          <Box className="d-flex align-items-center flex-wrap">
            {selected?.map((value) => (
              <Chip
                key={value}
                label={value}
                onClick={handleClick}
                className="chip-purple mg-r-10 mg-y-4"
              />
            ))}
            <InputLabel id="demo-multiple-checkbox-label">
              Select skills
            </InputLabel>
          </Box>
        );
      }}
      MenuProps={MenuProps}
    >
      <MenuItem className="menu-item pd-y-0 mg-y-0" disableRipple={true}>
        <form className="w-100" onKeyDown={(e) => e.stopPropagation()}>
          <Box className="pd-y-24 d-flex">
            <input
              type="text"
              className="form-control"
              placeholder="Tags"
              autoComplete="off"
              value={search}
              onKeyDown={handleTagsOnEnterPress}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
            <Button
              variant="outlined"
              className="btn btn-link mg-l-10"
              onClick={() => handleTags(search)}
            >
              Add+
            </Button>
          </Box>
        </form>
      </MenuItem>
      {/* {console.log(secondarySkills)} */}
      {tagsWatch?.map((item) => (
        <MenuItem
          className="menu-item"
          key={item}
          value={item}
          disableRipple={true}
        >
          <div className="custom-checkbox">
            <input
              className="custom-control-input"
              type="checkbox"
              id={`${item}`}
              name={`${item}`}
              defaultChecked={tagsWatch.indexOf(item) > -1}
            />
            <label
              className="custom-control-label font-size-base"
              htmlFor={`${item}`}
            >
              {item}
            </label>
          </div>
        </MenuItem>
      ))}
    </Select>
  );
}

export default RHFTagsController;
