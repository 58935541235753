import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
} from "@mui/material";
import styles from "./MultiLanguage2.module.scss";
import TextFieldController from "./Controller/TextFieldController";
import ReturnTypeController from "./Controller/ReturnTypeController";
import Codemirror from "../../InitialCodeTab/Codemirror";
import VerificationCode from "../../VerificationCodeTab/VerificationCode";
import { useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect } from "react";
import { getInitialCode } from "../../InitialCodeTab/getInitialCode";
import { getTestResultURL, runTestURL } from "../../../../utils/constants";
import { useState } from "react";
import { useRef } from "react";
import axios from "axios";
import Snackbars from "../../../Assesment/MyAssesment/Snackbar";
const GET_RESULT_API_MAX_HIT_LIMIT = 3;
const GET_RESULT_API_HIT_INTERVAL = 3000;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MultiLanguage2() {
  const [value, setValues] = React.useState(0);
  const methods = useFormContext();
  const { control, getValues, setValue, watch } = methods;
  const dataTypes = useSelector((state) => state.Questions.data);
  const dataTypesMap = useSelector((state) => state.Questions.dataTypeMap);
  const [testError, setTestError] = useState(null);
  const [isTestRunning, setIsTestRunning] = useState(false);
  const [testResult, setTestResult] = useState(null);
  const countRef = useRef(0);
  const isCodeTested = useRef(false);
  const [indexValue, setIndexValue] = useState();
  const [snackText, setSnackText] = useState("");
  const [severity, setSeverity] = useState("");
  const [snack, setSnack] = useState(false);
  var interval;
  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnack(false);
  };

  const values = getValues();

  const handleChange = (event, newValue) => {
    setValues(newValue);
  };

  //useFieldArrays here
  const {
    fields: parameterfields,
    append: appendParameters,
    remove: removeParameters,
  } = useFieldArray({
    control,
    name: "parameters",
  });

  const {
    fields: testCaseFieldsDuringTest,
    append: testCaseAppendDuringTest,
    remove: testCaseRemoveDuringTest,
  } = useFieldArray({ control, name: "testCasesDuringTest" });

  const {
    fields: testCaseFieldsForEvaluation,
    append: testCaseAppendForEvaluation,
    remove: testCaseRemoveForEvaluation,
  } = useFieldArray({ control, name: "testCasesForEvaluation" });

  const testCasesDuringTest = useWatch({
    control,
    name: "testCasesDuringTest",
  });
  const testCasesForEvaluation = useWatch({
    control,
    name: "testCasesForEvaluation",
  });
  const languages = useWatch({
    control,
    name: "languages",
  });
  const funcName = useWatch({
    control,
    name: "function.functionName",
  });

  const parameters = useWatch({
    control,
    name: "parameters",
  });
  const languageId = Number(
    useWatch({
      control,
      name: "radioValue",
    })
  );

  const initialCode = languages.find(
    (language) => language.languageId === languageId
  )?.initialCode;

  const verificationCode = languages.find(
    (language) => language.languageId === languageId
  )?.verificationCode;

  const paramModified = parameters.map(
    ({ parameterName: name, returnType: pType }) => ({ name, pType })
  );

  const index = languages.findIndex(
    (language, langIndex) => language.languageId === languageId
  );

  const functionReturnType = getValues("function.functionReturnType");
  const AllTestCases = [...testCasesDuringTest, ...testCasesForEvaluation];

  const setInitialCode = (value, dataTypesMap) => {
    const functionName = value.function.functionName;
    const placeholder = "Insert your code here";
    const parameters = value.parameters;
    const getReturnType = (languageId, dataType) => {
      const res = dataTypesMap[dataType]
        ? dataTypesMap[dataType][languageId]
        : "";

      return res;
    };
    const getParameterType = (languageId, parameters) => {
      parameters = parameters.map(({ parameterName, returnType }) => {
        return {
          parameterName,
          returnType: getReturnType(languageId, returnType, dataTypesMap),
        };
      });
      return parameters;
    };
    const params = parameters
      ?.map(({ parameterName = "" }) => parameterName)
      .toString();

    value.languages.forEach((language, index) => {
      const functionReturnType = getReturnType(
        language.languageId,
        value.function.functionReturnType
      );
      const parametersType = getParameterType(
        language.languageId,
        value.parameters
      );
      const paramsType = parametersType
        ?.map(
          ({ returnType = "", parameterName = "" }) =>
            returnType + ` ${parameterName}`
        )
        .toString();
      const paramsForPhp = parametersType
        ?.map(({ parameterName = "" }) => "$" + parameterName)
        .toString();
      const paramsForTS = parametersType?.map(
        ({ returnType = "", parameterName = "" }) =>
          parameterName + ":" + returnType
      );
      //   .toString();

      const initialCode = getInitialCode(
        functionName,
        params,
        placeholder,
        language.languageId,
        functionReturnType,
        paramsType,
        paramsForPhp,
        paramsForTS
      );
      setValue(`languages.${index}.initialCode`, initialCode);
      setValue(`languages.${index}.verificationCode`, initialCode);
      setValue(`languages.${index}.isVerified`, false);
      // console.log(language)
    });
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // console.log({name,type,value})

      //1.functionName Change
      if (name.endsWith("functionName")) {
        setInitialCode(value, dataTypesMap);
      }
      //2.Function Return Type
      if (name === "function.functionReturnType") {
        setInitialCode(value, dataTypesMap);
      }
      // //3.Parameter Add or Remove
      if (name.startsWith("parameters") && !type) {
        setInitialCode(value, dataTypesMap);
      }

      // //4.Parameter Type Change
      if (name.endsWith("returnType") && type) {
        setInitialCode(value, dataTypesMap);
      }
      // //5.Language add remove
      if (name.endsWith("languages")) {
        setInitialCode(value, dataTypesMap);
      }
      // // 6.Parameter Name change
      if (name.endsWith("parameterName") && type) {
        setInitialCode(value, dataTypesMap);
      }
      // if(name.startsWith("radioValue")){
      //   setInitialCode();
      // }
    });
    return () => subscription.unsubscribe();
  }, [watch, dataTypesMap]);

  const getTestResult = async (token) => {
    try {
      const result = await axios.get(getTestResultURL + "/" + token);
      if (result.data.result === 1) {
        if (result.data.data.status > 2) {
          isCodeTested.current = true;
          clearInterval(interval);
          setIsTestRunning(false);
          if (result.data.data.status === 6)
            setTestError(result.data.data.compile_output);
          if (result.data.data.stderr) setTestError(result.data.data.stderr);
          if (result.data.data.status === 3) {
            setTestResult(result.data.data.result);
            if (result.data.data.result.failed > 0) {
              setValue(`languages.${index}.isVerified`, false);
            } else {
              setValue(`languages.${index}.isVerified`, true);
            }
          }
        }
      }
    } catch (e) {
      clearInterval(interval);
      setIsTestRunning(false);
      setSnack(true)
      setSeverity("error")
      setSnackText(e.message)
      // alert(e.message);
    }
  };

  const runTest = async () => {
    if (AllTestCases.length) {
      try {
        setTestError(null);
        setTestResult(null);
        const payload = newPayload();

        setIsTestRunning(true);
        const result = await axios.post(runTestURL, payload);

        if (result.data.result !== 1 || !result.data.data.token)
          throw new Error("Something went Wrong");

        interval = setInterval(() => {
          if (countRef.current > GET_RESULT_API_MAX_HIT_LIMIT) {
            clearInterval(interval);
            setIsTestRunning(false);
            throw new Error("Timeout");
          }
          countRef.current += countRef.current;
          getTestResult(result.data.data.token);
        }, GET_RESULT_API_HIT_INTERVAL);
      } catch (e) {
        setIsTestRunning(false);
        if (e.isAxiosError) {
          let message = "Something went Wrong";
          if (e.code === "ECONNABORTED") message = "Timeout";
          if (e.response) message = e.response.statusText;
           setSnack(true);
           setSeverity("error");
           setSnackText(message);
          // alert(message);
        } else{
           setSnack(true);
           setSeverity("error");
           setSnackText(e.message);
        //  alert(e.message);
        }
      }
    } else {
       setSnack(true);
       setSeverity("error");
       setSnackText("Please add a testcase");
      // alert();
    }
  };

  const newPayload = () => {
    const payload = {
      testType: "FOR_VERIFICATION",
      specification: {
        language: languages[index].languageId,
        functionName: funcName,
        returnType: functionReturnType,
        parameters: paramModified,
        verificationCode: btoa(languages[index].verificationCode),
      },
      testCases: AllTestCases,
    };
    return payload;
  };

  useEffect(() => {
    const index = languages.findIndex(
      (language, langIndex) => language.languageId === languageId
    );
    // console.log(index);
    setIndexValue(index);
  }, [languageId]);

  const verificationCodeSet = (value, index) => {
    setValue(`languages.${index}.verificationCode`, value);
    setValue(`languages.${index}.isVerified`, false);

    // values.languages[0].verificationCode="mo"

    // console.log(getValues(`languages.${indexValue}.verificationCode`),indexValue)

    // console.log(value)
  };

  const appendTestCasesDuringTest = () => {
    testCaseAppendDuringTest({
      name: "Validation",
      parameters: [],
      result: "",
      testType: "DURING_TEST",
    });
  };

  const appendTestCasesForEvaluation = () => {
    testCaseAppendForEvaluation({
      name: "DuringTest",
      parameters: [],
      result: "",
      testType: "FOR_VALIDATION",
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Function Signature" {...a11yProps(0)} />
          <Tab label="Initial Code" {...a11yProps(1)} />
          <Tab label="Test Cases" {...a11yProps(2)} />
          <Tab label="Verification Code" {...a11yProps(3)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <div className={styles.functionSignatureContainer}>
          <div
            style={{
              borderBottom: "1px solid black",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            Function
          </div>
          <div className={styles.function}>
            <div className={styles.textField}>
              <TextFieldController
                name={`function.functionName`}
                label="Function Name"
              />
            </div>
            <div className={styles.selectField}>
              <ReturnTypeController
                name={`function.functionReturnType`}
                label="Return Type"
                options={dataTypes}
              />
            </div>
          </div>

          <div style={{ marginTop: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 10,
                borderBottom: "1px solid black",
              }}
            >
              <div style={{ marginTop: 10, marginBottom: 10 }}>Parameters</div>
            </div>
            <Button
              onClick={() => {
                appendParameters({
                  date: new Date(),
                  parameterName: "",
                  returnType: "",
                });
              }}
              sx={{ width: 130 }}
              variant="outlined"
            >
              ADD
            </Button>
            <div className={styles.parameterContainer}>
              {parameterfields?.map((parameters, index) => (
                <div key={parameters.id} className={styles.parameters}>
                  <div className={styles.textField}>
                    <TextFieldController
                      name={`parameters.${index}.parameterName`}
                      label={`Parameter Name ${index + 1}`}
                    />
                  </div>
                  <div className={styles.selectField}>
                    <ReturnTypeController
                      name={`parameters.${index}.returnType`}
                      label="Parameter Type"
                      options={dataTypes}
                    />
                  </div>
                  <div>
                    <Button
                      onClick={() => removeParameters(index)}
                      sx={{ width: 130, height: 50 }}
                      variant="outlined"
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Codemirror readOnly={false} initialCode={initialCode} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div style={{ display: "flex", flexDirection: "column", height: 700 }}>
          <div style={{ flex: 0.5 }}>
            <div
              style={{
                display: "flex",

                marginBottom: 10,
                borderBottom: "1px solid black",
              }}
            >
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                Test Cases(During Test)
              </div>
            </div>
            <div>
              <Button
                onClick={appendTestCasesDuringTest}
                sx={{ width: 130 }}
                variant="outlined"
              >
                ADD
              </Button>
            </div>

            <div style={{ marginTop: 10 }}>
              <div
                style={{
                  overflowY: "auto",
                  maxHeight: "250px",
                  // height: "400px",
                  paddingBottom: 5,
                  paddingLeft: 3,
                }}
              >
                {testCaseFieldsDuringTest.map((item, index) => {
                  return (
                    <div
                      key={item.id}
                      style={{
                        display: "flex",
                        marginTop: 15,
                        paddingLeft: "5px",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <Controller
                          key={index}
                          name={`testCasesDuringTest.${index}.parameters`}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography>{`TestCase ${
                                  index + 1
                                }`}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {values.parameters.map((item, ind) => {
                                  return (
                                    <TextFieldController
                                      name={`testCasesDuringTest.${index}.parameters.${ind}`}
                                      label={`${item.parameterName}`}
                                    />
                                  );
                                })}
                                <TextFieldController
                                  name={`testCasesDuringTest.${index}.result`}
                                  label="Result"
                                />
                              </AccordionDetails>
                            </Accordion>
                          )}
                        />
                      </div>
                      <div>
                        <IconButton
                          onClick={() => testCaseRemoveDuringTest(index)}
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div style={{ flex: 0.5 }}>
            <div
              style={{
                display: "flex",

                marginBottom: 10,
                borderBottom: "1px solid black",
              }}
            >
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                Test Cases(For Evaluation)
              </div>
            </div>
            <div>
              <Button
                onClick={appendTestCasesForEvaluation}
                sx={{ width: 130 }}
                variant="outlined"
              >
                ADD
              </Button>
            </div>

            <div style={{ marginTop: 10 }}>
              <div
                style={{
                  overflowY: "auto",
                  maxHeight: "250px",
                  // height: "400px",
                  paddingBottom: 5,
                  paddingLeft: 3,
                }}
              >
                {testCaseFieldsForEvaluation.map((item, index) => {
                  return (
                    <div
                      key={item.id}
                      style={{
                        display: "flex",
                        marginTop: 15,
                        paddingLeft: "5px",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <Controller
                          key={index}
                          name={`testCasesForEvaluation.${index}.parameters`}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography>{`TestCase ${
                                  index + 1
                                }`}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {values.parameters.map((item, ind) => {
                                  return (
                                    <TextFieldController
                                      name={`testCasesForEvaluation.${index}.parameters.${ind}`}
                                      label={`${item.parameterName}`}
                                    />
                                  );
                                })}
                                <TextFieldController
                                  name={`testCasesForEvaluation.${index}.result`}
                                  label="Result"
                                />
                              </AccordionDetails>
                            </Accordion>
                          )}
                        />
                      </div>
                      <div>
                        <IconButton
                          onClick={() => testCaseRemoveForEvaluation(index)}
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        {languages.map((language, index) => {
          return (
            <>
              {language.languageId === languageId && (
                <VerificationCode
                  handleRunTest={runTest}
                  setVerificationCode={verificationCodeSet}
                  verificationCode={language.verificationCode}
                  testError={testError}
                  testResult={testResult}
                  isTestRunning={isTestRunning}
                  index={index}
                />
              )}
            </>
          );
        })}
      </TabPanel>
      {snack && (
        <Snackbars
          text={snackText}
          snack={snack}
          closeSnackbar={closeSnackbar}
          severity={severity}
        />
      )}
    </Box>
  );
}
