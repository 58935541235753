import { Card, CardContent, Container, Typography } from "@mui/material";
import React from "react";
import { complexityOptions } from "../../../utils/constants";
import MuiMenuCommon from "../../common/MuiMenuCommon/MuiMenuCommon";
import RHFInputController from "../../RHFControllers/RHFInputController";
import RHFTextAreaController from "../../RHFControllers/RHFTextAreaController";
import IMAGES from "../../../assets/images/images";
import {Close, Image} from "@mui/icons-material";

const NameTest = () => {
  return (
    <>
      <div className={`page-spacing pd-y-30`}>
        <>
          <Container maxWidth={"md"}>
            <Card className={`card-lg`}>
              <CardContent>
                <div className={`mg-b-30`}>
                  <Typography
                    variant={"h1"}
                    component={"h1"}
                    className={"mg-b-5"}
                  >
                    Create your test
                  </Typography>
                  <Typography
                    variant={"body"}
                    component={"p"}
                    className={`font-size-lg`}
                    color={"secondary"}
                  >
                    Fill required field and start creating new assessment!
                  </Typography>
                </div>
                <div className={`mg-b-30`}>
                  <label htmlFor="" className={`form-label font-weight-bold`}>
                    Name your test
                  </label>
                  <RHFInputController
                    RHFname="testName"
                    type="text"
                    placeholder="Enter test name"
                    className={`form-control form-control-lg ${false && 'error'}`}
                  />
                  {false && <Typography className={'error font-size-sm'}>Lorem ipsum dolar sit amet!</Typography>}
                </div>
                <div className={`mg-b-30`}>
                  <div className={`row`}>
                    <div className="col">
                      <label htmlFor="" className={`form-label font-weight-bold`}>
                        Choose Skill
                      </label>
                      <MuiMenuCommon
                          options={complexityOptions}
                          RHFName="complexity"
                          placeholder="Select Skill"
                          useId={false}
                      />
                    </div>
                    <div className="col-auto">
                      <div className={`asset asset-78 overflow-visible pd-x-4 pd-y-4`}>
                        {
                          true?
                           <>
                             <i className={`icon-img-upload heading1`}></i>
                             <input type={"file"} className={`hidden-input`} />
                           </> :
                              <>
                                <button type={"button"} className={`btn-x top-right`}>
                                  <Close/>
                                </button>
                                <img className={'img-fluid'} src={IMAGES.React} alt="profile" />
                              </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`mg-b-30`}>
                  <label htmlFor="" className={`form-label font-weight-bold`}>
                    Choose one or many sub-skills
                  </label>
                {/*  Use Already Created sub skill multiselect in Question Creation*/}
                </div>
                <div className={`mg-b-30`}>
                  <label htmlFor="" className={`form-label font-weight-bold`}>
                    Complexity
                  </label>
                  <MuiMenuCommon
                    options={complexityOptions}
                    RHFName="complexity"
                    placeholder="Select Complexity"
                    useId={false}
                  />
                </div>
                <div className={`mg-b-30`}>
                  <label htmlFor="" className={`form-label font-weight-bold`}>
                    Number of Questions per sub-skills
                  </label>
                  <MuiMenuCommon
                      options={complexityOptions}
                      RHFName="complexity"
                      placeholder="Select no. of Question"
                      useId={false}
                  />
                </div>
                <div className={`mg-b-30`}>
                  <label htmlFor="" className={`form-label font-weight-bold`}>
                    Duration Of Test (Mins)
                  </label>
                  <MuiMenuCommon
                      options={complexityOptions}
                      RHFName="complexity"
                      placeholder="Select test duration"
                      useId={false}
                  />
                </div>
                <div className={`mg-b-30`}>
                  <label htmlFor="" className={`form-label font-weight-bold`}>
                    Type of Questions
                  </label>
                  <MuiMenuCommon
                      options={complexityOptions}
                      RHFName="complexity"
                      placeholder="Select Category"
                      useId={false}
                  />
                </div>
                <div className={`mg-b-30`}>
                  <label
                    htmlFor="</div>"
                    className={`form-label font-weight-bold`}
                  >
                    Description
                  </label>
                  <RHFTextAreaController
                    RHFname="description"
                    type="text"
                    placeholder="Description"
                    className="form-control text-area"
                  />
                </div>
                <div className={`mg-b-30`}>
                  <label htmlFor="" className={`form-label font-weight-bold`}>
                    Author
                  </label>
                  <MuiMenuCommon
                      options={complexityOptions}
                      RHFName="complexity"
                      placeholder="Select Author"
                      useId={false}
                  />
                </div>
              </CardContent>
            </Card>
          </Container>
        </>
      </div>
    </>
  );
};

export default NameTest;
