import { getUsersApi } from '../../utils/constants';
import queryParamsToSring from '../../utils/functions/queryParamsToString';
import apiClient from './client';

const registerUser = (userObj) => {
    return apiClient.post('/api/users/register', userObj)
}

const search = (email) => {
    return apiClient.get(`/api/users/search?searchBy=email&value=${email}`)
}

const getUsers = (queryParamsObj = {}) => {
    const queryString = queryParamsToSring(queryParamsObj);
    return apiClient.get(`${getUsersApi}?${queryString}`)
}

const updateUser = (payload, userId) => {
    return apiClient.put(`${getUsersApi}/${userId}`, payload);
}

const userService = {
    registerUser,
    search,
    getUsers,
    updateUser
}

export default userService;
