import React, {useState} from "react";
import {Button, Menu, MenuItem} from "@mui/material";
import IMAGES from "../../../../../../assets/images/images";
import {Link} from "react-router-dom";
import DeleteHireStageModal from "../DeleteHireStageModal/DeleteHireStageModal";

const HiringStageMoreMenu = (props) => {
    const {_id, handleDelete} = props;
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [moreAnchorEl, setMoreAnchorEl] = useState(null);
    const openMore = Boolean(moreAnchorEl);

    const handleMoreOpen = (event) => {
        setMoreAnchorEl(event.currentTarget);
    };

    const moreClose = () => {
        setMoreAnchorEl(null);
    };
    return(
        <>
            <Button variant={"text"}
                    className={`btn btn-link pd-x-16`}
                    id="basic-button"
                    onClick={handleMoreOpen}
                    aria-controls={openMore ? `${_id}` : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMore ? 'true' : undefined}
            >
                <img src={IMAGES.Dots} alt="dots" />
            </Button>
            <Menu
                id={_id}
                anchorEl={moreAnchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={openMore}
                onClose={moreClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={()=>setOpenDeleteModal(true)}>
                    <span className={`d-inline-block`}>
                            <img width={18} height={18} src={IMAGES.Trash} alt="trash"/>
                    </span>
                    <span className={"text-dark mg-l-10"}>Delete</span>
                </MenuItem>
                {openDeleteModal && <DeleteHireStageModal open={openDeleteModal} setOpen={setOpenDeleteModal} />}
                <MenuItem>
                    <Link className={`text-decoration-none`} to={`/edit-question/${_id}`}>
                        <span className={`d-inline-block`}>
                            <img width={18} height={18} src={IMAGES.EditSquare} alt="trash"/>
                        </span>
                        <span className={"text-dark mg-l-10"}>Edit</span>
                    </Link>
                </MenuItem>
            </Menu>
        </>
    )
}

export default HiringStageMoreMenu;