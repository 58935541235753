import { Avatar, Box, Button, Card, CardContent } from "@mui/material";
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { CircularProgress } from '@mui/material';
import { apiStatusMap, Roles } from "../../utils/constants";
import UserRoleMenu from "../common/UserRoleMenu/UserRoleMenu";


const UserAvatar = ({ profilePic="", firstName }) => {
    if(profilePic) return(
        <Avatar sx={{ width: 30, height: 30 }} alt={firstName} src={profilePic} />
    );
    return (<Avatar sx={{ width: 30, height: 30 }} >{firstName[0].toUpperCase()}</Avatar>);
}


const Rows = ({ users, status }) => {

    if(status === apiStatusMap.idle || status === apiStatusMap.rejected) return(
        <TableRow>
            <TableCell align="center" colSpan={12} />
        </TableRow>
    );

    if(status === apiStatusMap.pending) return(
        <TableRow>
            <TableCell align="center" colSpan={12}>
                <CircularProgress />
            </TableCell>
        </TableRow>
    );

    if(!users.length) return (
        <TableRow>
            <TableCell align="center" colSpan={12}>
                No Data Found!
            </TableCell>
        </TableRow>
    );

    return users.map(({ _id, role, email, firstName, lastName, profilePic }, i) => (
        <TableRow key={_id}>
            {console.log({role})}
            <TableCell><UserAvatar profilePic={profilePic} firstName={firstName} /></TableCell>
            <TableCell>{ firstName}</TableCell>
            <TableCell>{lastName }</TableCell>
            <TableCell>{ email }</TableCell>
            <TableCell><UserRoleMenu userId={_id} role={role} /></TableCell>
        </TableRow>
    ));
}

const UserList = ({ users, status }) => {
    return (
        <Card className={'card-table'}>
            <CardContent className="pd-x-0 pd-y-0">
                <TableContainer className="custom-table">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Role</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <Rows users={users} status={status} />
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    )
}

export default UserList;