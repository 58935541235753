import axios from "axios";
import axiosInstance from "./client";

const getPrimarySkills = () => {
  return axiosInstance.get(`/api/skills/primary/dropdown`);
};

const getSecondarySkills=(primarySkill)=>{
    return axiosInstance.get(`/api/skills/secondary/dropdown/${primarySkill}`);
}

const createPrimarySkill = (payload) => {
  return axiosInstance.post(`/api/skills/primary`, payload);
};

const updatePrimarySkill = (id, payload) => {
  return axiosInstance.put(`/api/skills/primary/${id}`, payload);
};

const createSecondarySkill = (payload) => {
  return axiosInstance.post(`/api/skills/secondary`, payload);
};

const updateSecondarySkill = (id, payload) => {
  return axiosInstance.put(`/api/skills/secondary/${id}`, payload);
};

const deletePrimarySkill = (primarySkillId) => {
  return axiosInstance.delete(`/api/skills/primary/${primarySkillId}`);
};

const deleteSecondarySkill = (primarySkillId, secondarySkillId) => {
  return axiosInstance.delete(`/api/skills/secondary/${primarySkillId}/${secondarySkillId}`);
};

const getJobRoles=()=>{
  return axiosInstance.get(`/api/job-roles/dropdown`);
}


const SkillsService = {
    getPrimarySkills,
    getSecondarySkills,
    createPrimarySkill,
    createSecondarySkill,
    updatePrimarySkill,
    updateSecondarySkill,
    deletePrimarySkill,
    deleteSecondarySkill,
    getJobRoles
};

export default SkillsService;
