import { createSlice } from "@reduxjs/toolkit";

export const LoaderSlice = createSlice({
  name: "Loader",
  initialState: {
    loading: false,
  },
  reducers: {
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const showLoader = (value) => async (dispatch) => {
  try {
    dispatch(setLoader(value));
  } catch (err) {
    throw new Error(err);
  }
};

export const { setLoader } = LoaderSlice.actions;
export default LoaderSlice.reducer;
