/* eslint-disable default-case */
import multipleResponseIcon from "../Files/img/multipleResponse.png";
import multichoiceIcon from "../Files/img/multichoice.png";
import shortTextIcon from "../Files/img/shortText.png";
import trueFalseIcon from "../Files/img/truefalse.png";
import codeIcon from "../Files/img/code.png";
import { styled } from "@mui/material";
import InputBase from "@mui/material/InputBase";

export const apiStatusMap = { idle: 'idle', pending: 'pending', resolved: 'resolved', rejected: 'rejected' };

export const isEmptyObject = (obj) => {
  return (
    Object.keys(obj).length === 0 &&
    obj.constructor === Object
  );
};

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Mulish"
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

export const BootstrapOption = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#ffffff",
    border: "1px solid #ced4da",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: "Mulish",
    fontWeight: "500",
    color: "#5E6278",
    margin: 0,
    fontSize: "14px",
    lineHeight: "19px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const _timeOptions = [];
Array.from({ length: 30 }, (_, i) =>
  _timeOptions.push({ id: i + 1, value: (i + 1) * 60, title: `${i + 1} mins` })
);

export const timeOptions = _timeOptions;

export const testTimeOptions = [
  { id: 1, value: '600', label: '10 mins' },
  { id: 2, value: '900', label: '15 mins' },
  { id: 3, value: '1200', label: '20 mins' },
  { id: 4, value: '1500', label: '25 mins' },
  { id: 5, value: '1800', label: '30 mins' },
]

export const difficultyLevelOptions = [
  { id: 1, value: 1, title: "Level 1" },
  { id: 2, value: 2, title: "Level 2" },
  { id: 3, value: 3, title: "Level 3" },
  { id: 4, value: 4, title: "Level 4" },
  { id: 5, value: 5, title: "Level 5" },
];
export const categoryOptions = [
  { id: 1, value: "PRIOR_EXPERIENCE", title: "PRIOR_EXPERIENCE" },
  { id: 2, value: "MOTIVATION", title: "MOTIVATION" },
  { id: 3, value: "SOFT_SKILSS", title: "SOFT_SKILSS" },
  { id: 4, value: "JOB_SKILLS", title: "JOB_SKILLS" },
  { id: 5, value: "IT", title: "IT" },
];

export const truncateString = (str, limit) => {
  if (str.length > limit) {
    return str.slice(0, limit) + "...";
  } else {
    return str;
  }
};

export const cardValues = [
  {
    questionType: "Multiple Choice",
    answerType: "One correct answer",
    image: multichoiceIcon,
  },
  {
    questionType: "Multiple Response",
    answerType: "Multiple correct answers",
    image: multipleResponseIcon,
  },
  {
    questionType: "True False",
    answerType: "One correct answer",
    image: trueFalseIcon,
  },
  {
    questionType: "Short Text",
    answerType: "Requires exact match",
    image: shortTextIcon,
  },
  {
    questionType: "Coding",
    answerType: "Coding question",
    image: codeIcon,
  },
];
export const getQuestionType = (type) => {
  const defaultValue = "";
  const questionTypeMap = {
    MULTIPLE_CHOICE: "Multiple Choice",
    CODE: "Code",
    TRUE_FALSE: "True False",
    SHORT_TEXT: "Short Text",
    MULTIPLE_RESPONSE: "Multiple Response",
  };
  return questionTypeMap[type] || defaultValue;
};

export const getDate = (value) => {
  const date = new Date(value);
  const dateArr = date.toString().split(" ");

  return `${dateArr[1]} ${dateArr[2]}, ${dateArr[3]}`;
};
export const getTimeInMinSec = (time) => {
  // return Math.floor(value / 60) + ":" + (value % 60 ? value % 60 : '00')
  return Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2);
};
export const setTimeInMinSec = (time) => {
  const min = Math.floor(time / 60);
  const sec = time - min * 60;
  return {
    min,
    sec,
  };
};

//user roles
export const Roles = {
  user: "USER",
  contributor: "CONTRIBUTOR",
  admin: "ADMIN",
};


export const transformUser = (user) => {
  switch (user.role) {
    case Roles.admin:
      user.isAdmin = true;
      break;
    case Roles.contributor:
      user.isContributor = true;
      break;
    case Roles.user:
      user.isUser = true;
      break;
  }
  return user;
};
export const candidateStatusMap = {
  PENDING: "Pending",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
};
export const practiceQuestionOptions = [
  { label: "Main", _id: "false" },
  { label: "Practice", _id: "true" },
];

export const questionCategory = [
  {
    label: "Multiple Choice",
    _id: "MULTIPLE_CHOICE",
    iconName: "icon-category",
  },
  {
    label: "Coding",
    _id: "CODE",
    iconName: "icon-code",
  },
  {
    label: "Short text",
    _id: "SHORT_TEXT",
    iconName: "icon-document",
  },
  {
    label: "Video",
    _id: "VIDEO",
    iconName: "icon-video",
  },
  {
    label: "Document upload",
    value: "DOCUMENT",
    _id: "DOCUMENT",
    iconName: "icon-paper-upload",
  },
];
export const questionTypeMap = {
  MULTIPLE_CHOICE: "Multiple Choice",
  CODE: "Coding",
  SHORT_TEXT: "Short Text",
  VIDEO: "Video",
  DOCUMENT: "Document Upload",
};

export const questionTypeOptions = [
  { label: "Multiple Choice", _id: "MULTIPLE_CHOICE", iconName: "" },
  { label: "Coding", _id: "CODE", iconName: "" },
  { label: "Short Text", _id: "SHORT_TEXT", iconName: "" },
  { label: "Video", _id: "VIDEO", iconName: "" },
  { label: "Document Upload", _id: "DOCUMENT", iconName: "" }
];

export const complexityTypeMap = {
  BEGINNER: "Beginner",
  INTERMEDIATE: "Intermediate",
  EXPERT: "Expert",
};
export const complexityOptions = [
  { label: "Beginner", _id: "BEGINNER", iconName: "" },
  { label: "Intermediate", _id: "INTERMEDIATE", iconName: "" },
  { label: "Expert", _id: "EXPERT", iconName: "" },
];

export const statusOfQuestion = [
  { label: "APPROVE", _id: "APPROVED", iconName: "" },
  { label: "REJECT", _id: "REJECTED", iconName: "" },
];


export const questionStatusFilterOptions = [
  { label: "Created", _id: "CREATED", iconName: "" },
  { label: "Approved", _id: "APPROVED", iconName: "" },
  { label: "Rejected", _id: "REJECTED", iconName: "" },
  { label: "Drafted", _id: "DRAFTED", iconName: "" },
];

export const statusMap = {
  APPROVED: {
    label: "Approved",
    class: "badge-soft-success",
    value: "APPROVED",
  },
  DRAFTED: {
    label: "Drafted",
    class: "badge-soft-warning",
    value: "DRAFTED",
  },
  REJECTED: {
    label: "Rejected",
    class: "badge-soft-danger",
    value: "REJECTED",
  },
  CREATED: {
    label: "Created",
    class: "badge-soft-primary",
    value: "CREATED",
  },
};

export const languageMap={
  62:'Java',
  63:'JavaScript',
  68:'PHP',
  70:'Python',
  74:'TypeScript',

}

//Limits
export const Limits = {
  tags: 12,
  totalTimeMin: 30, //in seconds
  totalTimeMax: 3600, //in seconds
  multipleChoiceQuestion: 12,
  shortTextAnswers: 12,
};

export const JobRoles = [
  { label: "Software Developer", value: "software_developer" },
  { label: "HR", value: "hr" },
  { label: "Accountant", value: "accountant" },
];

//API's
export const datatypes_API = "api/question/datatypes";
export const categoryAPI = "api/question/categories";
export const languageAPI = "api/question/language";
export const runTestURL = "/api/question/run-test";
export const getTestResultURL = "/api/question/test-result";
export const QuestionSaveURL = "/api/question/save";
export const QuestionUpdateURL = "/api/question";
export const QuestionSaveAsDraftURL = "/api/question/draft";
export const candidateListsAPI = "api/candidates";
export const hiringStatusDropdownAPI = "/api/candidates/hiring-stages/dropdown";
export const updateHiringStatusAPI = "/api/candidates/hiring-stages/";
export const updateRatingAPI = "/api/candidates";
export const getAllHiringStagesAPI = "/api/candidates/hiring-stages";
export const updateHiringStageAPI = "/api/candidates/hiring-stages/";
export const addHiringStageAPI = "/api/candidates/hiring-stages";
export const saveURL = "/api/question";
export const categoriesListURL = "/api/question/categories";
export const getAssessmentAPI = "/api/assessment";
export const cognitoAuthTokenAPI = `${process.env.REACT_APP_AWS_COGNITO_DOMAIN}/oauth2/token`;
export const commentAPI = "/api/comments";
export const contributorListApi='/api/users?role=CONTRIBUTOR';
export const getUsersApi='/api/users'

//Get AccessToken from refresh token
export const fetchAccessToken = async () => {
  const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));
  const res = await fetch(`${cognitoAuthTokenAPI}`, {
    method: "POST",
    headers: new Headers({
      "content-type": "application/x-www-form-urlencoded",
    }),
    body: Object.entries({
      grant_type: "refresh_token",
      client_id: process.env.REACT_APP_CLIENT_ID,
      redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}`,
      refresh_token: refreshToken,
    })
      .map(([k, v]) => `${k}=${v}`)
      .join("&"),
  });
  if (!res.ok) {
    console.log(res);
    localStorage.clear();
    window.location.href = "/";
    throw new Error(await res.json());
  }
  const newTokens = await res.json();
  const accessToken = newTokens.id_token;
  localStorage.setItem("accessToken", JSON.stringify(accessToken));
  return accessToken;
};
