import {Box, Card, CardContent, Typography} from "@mui/material";
import styles from "./CategoryInfo.module.scss";
import IMAGES from "../../../../assets/images/images";
import React from "react";

const CategoryInfo = () => {
    return(
        <div className="row g-3">
            <div className="col-md-4">
                <Card className={`${styles.infoCard}`}>
                    <img className={styles.imgShapes} src={IMAGES.Shape1} alt="shape1"/>
                    <CardContent className="pd-x-20 pd-y-10">
                        <Box className={`${styles.questionContent} d-flex align-items-center`}>
                            <img className={`img-fluid ${styles.iconImg}`} src={IMAGES.Document} alt="Clock-time"/>
                            <Typography className={`font-weight-semi-bold font-size-xs`}>
                                16 questions
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </div>
            <div className="col-md-4">
                <Card className={`${styles.infoCard}`}>
                    <img className={styles.imgShapes} src={IMAGES.Shape2} alt="shape2"/>
                    <CardContent className="pd-x-20 pd-y-10">
                        <Box className={`${styles.questionContent} d-flex align-items-center`}>
                            <img className={`img-fluid ${styles.iconImg}`} src={IMAGES.ClockTime} alt="Clock-time"/>
                            <Typography className={`font-weight-semi-bold font-size-xs`}>
                                15 min
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </div>
            <div className="col-md-4">
                <Card className={`${styles.infoCard}`}>
                    <img className={styles.imgShapes} src={IMAGES.Shape3} alt="shape3"/>
                    <CardContent className="pd-x-20 pd-y-10">
                        <Box className={`${styles.questionContent} d-flex align-items-center`}>
                            <img className={`img-fluid ${styles.iconImg}`} src={IMAGES.Award} alt="Clock-time"/>
                            <Typography className={`font-weight-semi-bold font-size-xs`}>
                                Beginner level
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default CategoryInfo;