import axios from "axios";
import axiosInstance from "../../../../../services/api/client";
import { getTestResultURL, runTestURL } from "../../../../../utils/constants";
const GET_RESULT_API_MAX_HIT_LIMIT = 3;
const GET_RESULT_API_HIT_INTERVAL = 3000;
var interval;

const getTestResult = async (
  token,
  setIsTestRunning,
  setTestError,
  setTestResult,
  isCodeTested
) => {
  try {
    const result = await axios.get(getTestResultURL + "/" + token);
    if (result.data.result === 1) {
      if (result.data.data.status > 2) {
        isCodeTested.current = true;
        clearInterval(interval);
        setIsTestRunning(false);
        if (result.data.data.status === 6)
          setTestError(result.data.data.compile_output);
        if (result.data.data.stderr) setTestError(result.data.data.stderr);
        if (result.data.data.status === 3) {
          setTestResult(result.data.data.result);
          console.log(result.data.data.result)
          // if (result.data.data.result.failed > 0) {
          // //   setValue(`languages.${index}.isVerified`, false);
          // } else {
          //   setValue(`languages.${index}.isVerified`, true);
          // }
        }
      }
    }
  } catch (e) {
    clearInterval(interval);
    setIsTestRunning(false);
    // //   setSnack(true)
    //   setSeverity("error")
    //   setSnackText(e.message)
    // alert(e.message);
  }
};

const newPayload = (values, languageId, verificationCode) => {
  const payload = {
    testType: "FOR_VERIFICATION",
    specification: {
      language: languageId,
      functionName: values.functionName,
      returnType: values.returnType,
      parameters: values.parameters,
      verificationCode: btoa(verificationCode),
    },
    testCases: values.testCases,
  };
  return payload;
};

export const runTest = async (
  setIsTestRunning,
  setTestError,
  setTestResult,
  languageId,
  values,
  countRef,
  verificationCode,
  isCodeTested
) => {
  

  if (values.testCases.length) {
    console.log(values)
    try {
      setTestError(null);
      setTestResult(null);
      const payload = newPayload(values, languageId, verificationCode);
      console.log(payload)

      setIsTestRunning(true);
      const result = await axiosInstance.post(runTestURL, payload);

      if (result.data.result !== 1 || !result.data.data.token)
        throw new Error("Something went Wrong");

      interval = setInterval(() => {
        if (countRef.current > GET_RESULT_API_MAX_HIT_LIMIT) {
          clearInterval(interval);
          setIsTestRunning(false);
          throw new Error("Timeout");
        }
        countRef.current += countRef.current;
        getTestResult(
          result.data.data.token,
          setIsTestRunning,
          setTestError,
          setTestResult,
          isCodeTested
        );
      }, GET_RESULT_API_HIT_INTERVAL);
    } catch (e) {
      setIsTestRunning(false);
      if (e.isAxiosError) {
        let message = "Something went Wrong";
        if (e.code === "ECONNABORTED") message = "Timeout";
        if (e.response) message = e.response.statusText;
      }
    }
  }
};
