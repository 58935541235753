const RoutingPaths = {
  Login: "/",
  Signup: "/signup",
  ForgotPassword: "/forgotpassword",
  Dashboard: "/dashboard",
  Welcome: "/welcome",
  CreateAssessment: "/createAssessment",
  MyAssessment: "/myAssessment",
  Questions: "/questions",
  ViewCandidateList: "/viewcandidatelist/:id",
  manageHiringStages: "/managehiringstages",
  MyCandidates: "/candidates",
  Candidate: "/candidates/:id",
  NewQuestion: "/new-question/:type",
  EditQuestion:'/edit-question/:id',
  CloneQuestion:'/clone-question/:id',
  NewCustomQuestion: "/new-question/custom",
  NewBankTypeQuestion: "/new-question/bank",
  QuestionList: "/question-list",
  QuestionBankList: "/question-bank",
  GenericSignUp: "/generic-signup",
  GoogleSignIn: "/googlesignin",
  LoginLoader:"/login-loader",
  NewAssessment: "/new-assessment",
  QualifyingQuestion:"/qualifying-question",
  Profile:"/profile",
  ChangePassword:"/change-password",
  TestLibrary: "/test-library",
  CreateTest: "/create-test",
  skills: "/skills",
  subSkills: "/sub-skills/:primarySkillId",
  authors: "/authors",
  questionReports: "/question-reports",
  users: "/users",
};

export default RoutingPaths;
