import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Snackbars(props) {
  const [state, setState] = React.useState({
    vertical: "bottom",
    horizontal: "center",
  });

  const { vertical, horizontal } = state;

  return (
    <Stack sx={{ width: "100%" }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={props.snack}
        autoHideDuration={3000}
        onClose={props.closeSnackbar}
      >
        <Alert
          onClose={props.closeSnackbar}
          severity={props.severity}
          sx={{ width: "100%" }}
        >
          {props.text}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
