import { createSlice } from "@reduxjs/toolkit";

export const VideoQuestionSlice = createSlice({
  name: "videoQuestion",
  initialState: {
    videoQuestionsAssessment: [],
    videoQuestionsLibrary: [],
  },
  reducers: {
    addVideoQuestion: (state, action) => {
      const data = action.payload;
      data.videoType = "INTRO";
      state.videoQuestionsAssessment.push(data);
    },
    addInQuestionLibrary: (state, action) => {
      const data = JSON.parse(JSON.stringify(action.payload));
      data.videoType = "INTRO";
      state.videoQuestionsLibrary.push(data);
    },
    removeAllFromQuestionLibrary: (state) => {
      state.videoQuestionsLibrary=[];
    },
    
    removeFromQuestionLibrary: (state, action) => {
      const newQuestions = state.videoQuestionsLibrary.filter(
        (question) => question._id !== action.payload
      );
      state.videoQuestionsLibrary = newQuestions;
    },
    removeVideoQuestion: (state, action) => {
      const newQuestions = state.videoQuestionsAssessment.filter(
        (question) => question._id !== action.payload
      );
      state.videoQuestionsAssessment = newQuestions;
    },
    videoType: (state, action) => {
      state.videoQuestionsAssessment[action.payload.index].videoType =
        action.payload.value;
    },
    videoTypeQuestionLibrary: (state, action) => {
      state.videoQuestionsLibrary[action.payload.index].videoType =
        action.payload.value;
    },
  },
});

export const addVideoQuestionAction = (value) => async (dispatch) => {
  try {
    dispatch(addVideoQuestion(value));
  } catch (err) {
    throw new Error(err);
  }
};
export const addInVideoQuestionLibraryAction = (payload) => async (dispatch) => {
  try {
    dispatch(addInQuestionLibrary(payload));
  } catch (err) {
    throw new Error(err);
  }
};
export const removeAllVideoFromQuestionLibraryAction = () => async (dispatch) => {
  try {
    dispatch(removeAllFromQuestionLibrary());
  } catch (err) {
    throw new Error(err);
  }
};

export const removeFromVideoQuestionLibraryAction = (payload) => async (dispatch) => {
  try {
    dispatch(removeFromQuestionLibrary(payload));
  } catch (err) {
    throw new Error(err);
  }
};
export const removeVideoQuestionAction = (id) => async (dispatch) => {
  try {
    dispatch(removeVideoQuestion(id));
  } catch (err) {
    throw new Error(err);
  }
};
export const addVideoTypeAction = (value, index) => async (dispatch) => {
  try {
    const values = { value: value, index: index };
    dispatch(videoType(values));
  } catch (err) {
    throw new Error(err);
  }
};
export const addVideoTypeQuestionLibraryAction = (value, index) => async (dispatch) => {
  try {
    const values = { value: value, index: index };
    dispatch(videoTypeQuestionLibrary(values));
  } catch (err) {
    throw new Error(err);
  }
};

export const {
  addVideoQuestion,
  removeVideoQuestion,
  videoType,
  addInQuestionLibrary,
  videoTypeQuestionLibrary,
  removeFromQuestionLibrary,
  removeAllFromQuestionLibrary
} = VideoQuestionSlice.actions;
export default VideoQuestionSlice.reducer;
