import React from "react";
import { useState } from "react";
import styles from "./CodingQuestion.module.scss";
import axios from "axios";
import "react-quill/dist/quill.snow.css";
import {
  getDataTypesAsync,
  getLanguagesAsync,
} from "../../utils/redux/slices/QuestionSlice";
import { useDispatch } from "react-redux";
import {
  timeOptions,
  difficultyLevelOptions,
  categoryAPI,
  saveURL,
} from "../../utils/constants";
import Snackbars from "../Assesment/MyAssesment/Snackbar";
import QuestionEditor from "../HomePage/Editor/QuestionEditor/QuestionEditor";
import Topbar from "../Topbar/Topbar";
import Header from "../../Components/Header/Header";
import { useEffect } from "react";
import ReactTag from "../../utils/functions/TagInput/ReactTag";
import { useNavigate, useParams } from "react-router-dom";
import RoutingPaths from "../../utils/RoutingPaths";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  useForm,
  FormProvider,
  useFieldArray,
  Controller,
} from "react-hook-form";
import LanguageSelectionDialog from "./DynamicCodeEditor/MultiLanguageSelection/LanguageSelectionDialog";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import MultiLanguage2 from "./DynamicCodeEditor/MultiLanguageSelection/MultiLanguage2";
import TextFieldController from "./FieldController/TextFieldController";
import SelectDropDownController from "./FieldController/SelectDropdownController";
import TextAreaController from "./FieldController/TextAreaController";

export default function CodingQuestions() {
  const schema = yup
    .object({
      function: yup.object().shape({
        functionName: yup.string().required(),
      }),
      // parameters: yup
      //   .array()
      //   .of(
      //     yup.object().shape({
      //       parameterName: yup
      //         .string()
      //         .required("All parameter name are required!"),
      //     })
      //   )
      //   .optional(),

      // languages: yup
      //   .array()
      //   .of(
      //     yup.object().shape({
      //       languageName: yup.string().required(),
      //       languageId: yup.number().required(),
      //       returnType: yup.string().required(),
      //       initialCode: yup.string().required(),
      //       verificationCode: yup.string().required(),
      //       isVerified: yup.boolean().required(),
      //       parameters: yup.array().of(
      //         yup.object().shape({
      //           name: yup.string().required("All parameter name are required!"),
      //           pType: yup
      //             .string()
      //             .required("All parameter type are required!"),
      //         })
      //       ),
      //     })
      //   )
      //   .min(1, "Atleast one language is required!"),
      // testCasesForValidation: yup.array().of(
      //   yup.object().shape({
      //     name: yup.string().required(),
      //     parameters: yup.array(),
      //     result: yup.string().required(),
      //   })
      // ),
      // testCasesForVerification: yup.array().of(
      //   yup.object().shape({
      //     name: yup.string().required(),
      //     parameters: yup.array(),
      //     result: yup.string().required(),
      //   })
      // ),
    })
    .required();

  const { category: categorySlug } = useParams();
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      parameters: [{ parameterName: "", returnType: "" }],
      function: { functionName: "", functionReturnType: "" },
      languages: [],
      testCasesDuringTest: [],
      testCasesForEvaluation: [],
      radioValue: "",
      misc: {
        title: "",
        time: "",
        difficultyLevel: "",
        category: categorySlug === "any" ? "" : categorySlug,
        whatToLook: "",
        questionRelevant: "",
      },
    },
  });

  const { control } = methods;

  const {
    fields: languageFields,
    append: languageAppend,
    remove: languageRemove,
  } = useFieldArray({ control, name: "languages" });

  const appendLanguage = (value) => {
    languageAppend({
      languageId: value.id,
      languageName: value.name,
      initialCode: "",
      verificationCode: "",
      isVerified: false,
    });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDataTypesAsync());
    dispatch(getLanguagesAsync());
  }, []);
  const urlValue = useParams();
  const navigate = useNavigate();
  const [question, setQuestion] = useState("");
  const [tags, setTag] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackText, setSnackText] = useState("");
  const [severity, setSeverity] = useState("");
  var interval;

  useEffect(() => {
    function fetchCategories() {
      axios.get(categoryAPI).then((response) => {
        if (response.data.result === 1) {
          setCategoryList(
            response.data.data.map((item) => ({
              id: item._id,
              title: item.categoryName,
              value: item._id,
            }))
          );
        }
      });
    }
    fetchCategories();
    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  const [snack, setSnack] = React.useState(false);

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnack(false);
  };

  const getPayload = () => {
    const values = methods.getValues();
    const isAllVerified = values.languages.every(
      ({ isVerified }) => isVerified
    );
    if (!isAllVerified) {
      setSnack(true);
      setSnackText("Please run the test for all languages");
      setSeverity("error");
      return;
    }

    let payLoad;
    if (!values.misc.title) throw new Error("Title is required");
    if (!question) throw new Error("Description is required");
    if (!values.misc.time) throw new Error("Time is required");
    if (!values.misc.difficultyLevel) throw new Error("Level is required");
    if (!values.misc.category) throw new Error("Category is required");

    payLoad = {
      questionType: "CODE",
      time: values.misc.time.toString(),
      title: values.misc.title,
      level: values.misc.difficultyLevel,
      detail: question,
      category: values.misc.category,
      tags: tags,
      whatToLookFor: values.misc.whatToLook,
      whyQuestionIsRelevant: values.misc.questionRelevant,
    };

    const AllTestCases = [
      ...values.testCasesDuringTest,
      ...values.testCasesForEvaluation,
    ];

    const specifications = values.languages.map((language) => {
      const specification = {
        language: language.languageId,
        functionName: values.function.functionName,
        parameters: values.parameters.map(
          ({ parameterName: name, returnType: pType }) => ({
            name,
            pType,
          })
        ),
        returnType: values.function.functionReturnType,
        initialCode: btoa(language.initialCode),
        verificationCode: btoa(language.verificationCode),
      };
      return specification;
    });

    payLoad.specifications = specifications;
    payLoad.testCases = AllTestCases;

    return payLoad;
  };

  const navigateToQList = () => {
    const values = methods.getValues();
    navigate(RoutingPaths.Questions + "/" + values.misc.category);
  };

  const resetAll = () => {
    setQuestion("");
    setTag([]);
    setSeverity("");
  };

  const Save = async (cb) => {
    try {
      const payload = getPayload();
      setLoading(true);
      const response = await axios.post(saveURL, payload);

      if (response.data.result !== 1)
        throw new Error(response.data.msg || "Something went Wrong");
      setLoading(false);
      setSnack(false);
      cb();
    } catch (e) {
      setSnack(true);
      setSnackText(e.message);
      setSeverity("error");
      setLoading(false);
    }
  };
  const handleCancel = () => {
    let text =
      "You will loose all the current data\nAre you sure want to go back ?";
    // eslint-disable-next-line no-restricted-globals
    if (confirm(text) === true) navigate(-1);
  };
  const SaveAndNavigate = () => Save(navigateToQList);
  const SaveAndAddAnother = () => Save(resetAll);

  const questionText = (value) => {
    setQuestion(value);
  };
  const selectedTags = (tags) => {
    setTag(tags);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <FormProvider {...methods}>
      <form>
        <div className={styles.mainContainer}>
          <Header />
          <Topbar
            questionType="Coding-Question"
            loading={loading}
            loading2={loading}
            handleCancel={handleCancel}
            SaveAndAddAnother={SaveAndAddAnother}
            save={SaveAndNavigate}
          />
          <div className={styles.Container}>
            <div className={styles.questionContainer}>
              <div className={styles.left}>
                <p className={styles.question}>Question</p>

                <div className={styles.input}>
                  <TextFieldController name="misc.title" label="Title" />
                  <QuestionEditor
                    question={question}
                    questionText={questionText}
                    theme="snow"
                  />
                </div>

                <div className={styles.category}>
                  <ReactTag tags={tags} selectedTags={selectedTags} />
                </div>
                <div className={styles.languages}>
                  {/* Languages */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5>Languages</h5>
                      <div>
                        <Button
                          sx={{
                            marginLeft: 20,
                            backgroundColor: "#fff",
                          }}
                          variant="outlined"
                          onClick={handleClickOpen}
                        >
                          Add
                        </Button>
                        <LanguageSelectionDialog
                          open={open}
                          onClose={handleClose}
                          appendLanguage={appendLanguage}
                        />
                      </div>
                    </div>

                    {/* show languages */}
                    <div
                      style={{
                        overflowY: "auto",
                        maxHeight: "300px",
                        backgroundColor: "#fff",
                        marginTop: 5,
                        display: "flex",
                        padding: 5,
                      }}
                    >
                      <FormControl component="fieldset">
                        <Controller
                          rules={{ required: true }}
                          control={control}
                          name="radioValue"
                          render={({ field }) => {
                            return (
                              <RadioGroup {...field}>
                                {languageFields?.map((language, index) => (
                                  <div key={language.id}>
                                    <FormControlLabel
                                      value={language.languageId}
                                      control={<Radio />}
                                      label={language.languageName}
                                    />
                                    <Button
                                      variant="outlined"
                                      onClick={() => languageRemove(index)}
                                    >
                                      Remove
                                    </Button>
                                  </div>
                                ))}
                              </RadioGroup>
                            );
                          }}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.right}>
                <MultiLanguage2 />
              </div>
            </div>
            <div className={styles.itemContainer}>
              <div className={styles.timeContainer}>
                <SelectDropDownController
                  name="misc.time"
                  label="Time to answer the question"
                  options={timeOptions}
                  disabled={false}
                />
              </div>
              <div className={styles.timeContainer}>
                <SelectDropDownController
                  name="misc.category"
                  label="Question Category"
                  options={categoryList}
                  disabled={urlValue.category === "any" ? false : true}
                />
              </div>
            </div>
            <div className={styles.itemContainer}>
              <div style={{ flex: 1 }}>What to look for in the answer?</div>
              <div style={{ flex: 1 }}>Why is this question relevant?</div>
            </div>
            <div className={styles.itemContainer}>
              <div className={styles.releventText}>
                <TextAreaController
                  name="misc.whatToLook"
                  label="What to look for in the answer..."
                />
              </div>
              <div className={styles.releventText}>
                <TextAreaController
                  name="misc.questionRelevant"
                  label="Why is this question relevant..."
                />
              </div>
            </div>
            <div className={styles.itemContainer}>
              <div className={styles.difficultyLevel}>
                <SelectDropDownController
                  name="misc.difficultyLevel"
                  label="Difficulty Level"
                  options={difficultyLevelOptions}
                  disabled={false}
                />
              </div>
            </div>
            <div className={styles.bottomInfo}>
              <div className={styles.element}>
                <div className={styles.circle}></div>
                <div className={styles.text}>
                  How should I formate my questions?
                </div>
              </div>
              <div className={styles.element}>
                <div className={styles.circle2}></div>
                <div className={styles.text}>
                  How do I use the question editor?
                </div>
              </div>
              <div className={styles.element}>
                <div className={styles.circle3}></div>
                <div className={styles.text}>
                  How do I use the formula editor?
                </div>
              </div>
            </div>
          </div>
          {snack && (
            <Snackbars
              text={snackText}
              snack={snack}
              closeSnackbar={closeSnackbar}
              severity={severity}
            />
          )}
        </div>
      </form>
    </FormProvider>
  );
}
