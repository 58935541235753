import styles from "./ReviewAndConfigure.module.scss";
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import React from "react";
import IMAGES from "../../../../assets/images/images";
import { Add } from "@mui/icons-material";
import { Link } from "react-router-dom";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import QualifyingQuestionsDrawer from "../QualifyingQuestionsDrawer/QualifyingQuestionsDrawer";
import RoutingPaths from "../../../../utils/RoutingPaths";
import CustomQuestionDrawer from "../CustomQuestionDrawer/CustomQuestionDrawer";
import {
  complexityTypeMap,
  getTimeInMinSec,
  questionTypeMap,
} from "../../../../utils/constants";
import {
  removeCustomQuestionAction,
  removeQualifyingQuestionsAction,
} from "../../../../utils/redux/slices/customQuestionSlice";
import IntroOutroVideoDrawer from "../IntroOutroVideoDrawer/IntroOutroVideoDrawer";
import RecordVideoDialog from "../RecordVideoDialog/RecordVideoDialog";
import {
  addVideoTypeAction,
  removeVideoQuestionAction,
} from "../../../../utils/redux/slices/videoQuestionSlice";
import RHFRadioGroupController from "../../../RHFControllers/RHFRadioGroupController";

const ReviewAndConfigure = ({ decreaseActiveStep }) => {
  const { getValues, control, register } = useFormContext();
  const primarySkills = useSelector((state) => state.SkillsSlice.primarySkills);
  const jobRoleData = useSelector((state) => state.AssessmentSlice.jobRoles);
  const values = getValues();
  const assessmentNameWatch = useWatch({
    control,
    name: "assessmentName",
  });
  const [open, setOpen] = useState(false);
  const [openVideoLibrary, setOpenVideoLibrary] = useState(false);
  const customQuestions = useSelector(
    (state) => state.CustomQuestionSlice.customQuestions
  );
  const qualifyingQuestions = useSelector(
    (state) => state.CustomQuestionSlice.qualifyingQuestions
  );
  const videoQuestions = useSelector(
    (state) => state.videoQuestionSlice.videoQuestionsAssessment
  );
  const dispatch = useDispatch();

  const handleInsertMyQuestionsButton = () => {
    setOpen(true);
  };
  const handleVideoLibrary = () => {
    setOpenVideoLibrary(true);
  };
  const handleCustomQuestionDelete = (id) => {
    let found = false;
    customQuestions?.forEach((question) => {
      if (question._id === id) {
        found = true;
      }
    });
    if (found) {
      dispatch(removeCustomQuestionAction(id));
    }
  };
  const handleQualifyingQuestionDelete = (id) => {
    let found = false;
    qualifyingQuestions?.forEach((question) => {
      if (question._id === id) {
        found = true;
      }
    });
    if (found) {
      dispatch(removeQualifyingQuestionsAction(id));
    }
  };

  const [languageTabValue, setLanguageTabValue] = useState(
    "qualifyingQuestions"
  );
  const handleTabChange = (event, newValue) => {
    setLanguageTabValue(newValue);
  };
  const getLabelFromId = (id) => {
    let label;
    primarySkills?.forEach((option) => {
      if (option._id === id) {
        label = option.label;
      }
    });
    return label;
  };

  const handleRadioIntroOutro = (e, index) => {
    dispatch(addVideoTypeAction(e.target.value, index));
  };

  const [openUploadVideoModal, setOpenUploadVideoModal] = useState(false);
  const handleUploadVideoModal = () => setOpenUploadVideoModal(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const closeDrawer = () => {
    setDrawerOpen(false);
  };
  return (
    <>
      <Card className={`card-assessment mg-b-30`}>
        <CardContent>
          <div
            className={`d-flex justify-content-between align-items-start mg-b-40`}
          >
            <div>
              <Typography
                variant={"h2"}
                component={"h2"}
                className={`title26 mg-b-8`}
              >
                {assessmentNameWatch}
              </Typography>
              <Typography
                component={"p"}
                variant={"body2"}
                className={`font-weight-normal mg-b-8`}
                color={"primary"}
              >
                {jobRoleData[values.jobRole]}
              </Typography>
              <Typography
                component={"p"}
                variant={"body2"}
                className={`font-weight-normal`}
                color={"secondary"}
              >
                {values.description}
              </Typography>
            </div>
            <div className={"d-flex"}>
              <Typography
                variant={"p"}
                component={"p"}
                className={`time-total d-flex`}
              >
                <i className={`icon-clock-circle mg-r-10`}></i>
                <span
                  className={`font-weight-semi-bold text`}
                >
                  1h 15min
                </span>
              </Typography>
              <button
                type="button"
                className={`btn btn-light-primary btn-lg mg-l-12`}
                onClick={decreaseActiveStep}
              >
                <img
                  className={`img-fluid align-text-top mg-r-12`}
                  src={IMAGES.EditSquare}
                  alt="edit"
                  width={18}
                />
                <span>Edit</span>
              </button>
            </div>
          </div>
          <TableContainer className="custom-table without-border has-dashed-body">
            <Table border={0}>
              <TableHead>
                <TableRow>
                  <TableCell className={"text-dark"}>
                    SKILLS and Sub-skills
                  </TableCell>
                  <TableCell>Duration</TableCell>
                  <TableCell>Complexity</TableCell>
                </TableRow>
              </TableHead>
              {true ? (
                <TableBody>
                  {values?.skillsAndTechnology?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className={`ps-0`}>
                        <div className={`d-flex align-items-center`}>
                          <div className={`asset p-1 mg-r-10`}>
                            <img
                              className={"img-fluid"}
                              src={IMAGES.Java}
                              alt=""
                            />
                          </div>
                          <div>
                            <Typography
                              className={`font-size-15`}
                              variant={"h4"}
                              component={"h4"}
                            >
                              {getLabelFromId(item.skill)}
                            </Typography>
                            <Typography
                              className={`font-size-sm`}
                              variant={"body1"}
                              component={"p"}
                              color={"secondary"}
                            >
                              SubSkills:{" "}
                              {item.subSkill
                                ?.map((sub) => sub.label)
                                .join(", ")}
                            </Typography>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>15min</TableCell>
                      <TableCell>
                        {complexityTypeMap[item.complexity]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={5}>
                      No Data Found!
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <Card className={`card-assessment mg-b-30`}>
        <CardContent>
          <div
            className={`d-flex justify-content-between align-items-start mg-b-40`}
          >
            <div>
              <Typography
                variant={"h2"}
                component={"h2"}
                className={`title26 mg-b-8`}
              >
                Custom Questions
              </Typography>
              <Typography
                component={"p"}
                variant={"h3"}
                className={`font-weight-normal`}
                color={"secondary"}
              >
                If you need more info, please check out{" "}
                <Link className={`text-decoration-none`} to="/faq">
                  FAQ Page
                </Link>
              </Typography>
            </div>
            <CustomQuestionDrawer open={open} setOpen={setOpen} />
            <div className={"d-flex"}>
              <button
                type="button"
                className={`btn btn-light-primary btn-lg mg-l-12`}
                onClick={handleInsertMyQuestionsButton}
              >
                <Add /> Insert from my questions
              </button>
              <Link
                to={RoutingPaths.NewCustomQuestion}
                state={{ isCustomQuestion: true }}
              >
                <button
                  type="button"
                  className={`btn btn-light-primary btn-lg mg-l-12`}
                >
                  <Add /> New Question
                </button>
              </Link>
            </div>
          </div>
          <TableContainer className="custom-table without-border has-dashed-body">
            <Table border={0}>
              <TableHead>
                <TableRow>
                  <TableCell className={"text-dark"}>Name</TableCell>
                  <TableCell>Duration</TableCell>
                  <TableCell>Short Text</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              {true ? (
                <TableBody>
                  {customQuestions?.map((question, index) => (
                    <TableRow key={index}>
                      <TableCell className={`ps-0`}>
                        <div>
                          <Typography
                            className={`font-size-15`}
                            variant={"h4"}
                            component={"h4"}
                          >
                            {question.title}
                          </Typography>
                          <Typography
                            className={`font-size-sm`}
                            variant={"body1"}
                            component={"p"}
                            color={"secondary"}
                          >
                            Tags:{" "}
                            {question.tags?.length > 3
                              ? `${question.tags
                                  ?.map((item) => `#${item}`)
                                  .slice(0, 3)
                                  .join(", ")}...`
                              : `${question.tags
                                  ?.map((item) => `#${item}`)
                                  .join(", ")}`}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell>
                        {getTimeInMinSec(question.time)} Minutes
                      </TableCell>
                      <TableCell>
                        {" "}
                        {questionTypeMap[question.questionType]}
                      </TableCell>
                      <TableCell className={"pd-r-0"} align={"right"}>
                        <Link
                          to={`/edit-question/${question._id}`}
                          state={{ isUpdateCustomQuestion: true }}
                        >
                          <button
                            type={"button"}
                            className={"btn btn-snow btn-sm"}
                          >
                            <img src={IMAGES.EditSquare} alt="trash" />
                          </button>
                        </Link>
                        <button
                          type={"button"}
                          className={"btn btn-snow btn-sm mg-l-10"}
                          onClick={() =>
                            handleCustomQuestionDelete(question._id)
                          }
                        >
                          <img width={16} src={IMAGES.Trash} alt="trash" />
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={5}>
                      No Data Found!
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <Card className={`card-assessment mg-b-30`}>
        <CardContent>
          <div className={`mg-b-40`}>
            <Typography
              variant={"h2"}
              component={"h2"}
              className={`title26 mg-b-8`}
            >
              Advanced Options
            </Typography>
            <Typography
              component={"p"}
              variant={"h3"}
              className={`font-weight-normal`}
              color={"secondary"}
            >
              If you need more info, please check out{" "}
              <Link className={`text-decoration-none`} to="/faq">
                FAQ Page
              </Link>
            </Typography>
          </div>
          <Box className={"tabs-wrap pills-tab v-tabs tabs-primary"}>
            <TabContext value={languageTabValue} clas>
              <div className={"d-flex"}>
                <Box className={`pd-r-30 tabs-v-left`}>
                  <TabList
                    orientation={"vertical"}
                    onChange={handleTabChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      label="Qualifying Questions"
                      value="qualifyingQuestions"
                    />
                    <Tab label="Intro/Outro Video" value="introOutroVideo" />
                    <Tab
                      label="Anti-Cheating Settings"
                      value="antiCheatingSettings"
                    />
                  </TabList>
                </Box>
                <TabPanel value="qualifyingQuestions">
                  <Typography
                    variant={"h2"}
                    component={"h2"}
                    className={"mg-b-10"}
                  >
                    Qualifying Questions
                  </Typography>
                  <Typography
                    variant={"body1"}
                    component={"p"}
                    className={"mg-b-30"}
                  >
                    Qualifying questions are presented to candidates ahead of
                    the tests. The answers to these questions determine if
                    candidates satisfy the essential requirements of the job.
                    Only if all questions are answered as required, they proceed
                    to the tests. You can add up to 5 questions. You are only
                    being charged for candidates that qualify.
                  </Typography>
                  <ul className={`list-unstyled`}>
                    {qualifyingQuestions.map((question, index) => {
                      return (
                        <li key={index}>
                          <div className="row g-0 mg-b-10">
                            <div className="col">
                              <div className={`card`}>
                                <div className={`card-body pd-x-20 pd-y-12`}>
                                  <div className="row">
                                    <div className="col">
                                      <Typography
                                        variant={"h4"}
                                        component={"label"}
                                      >
                                        {question?.title}
                                      </Typography>
                                      <div className={"d-flex mg-t-16"}>
                                        {question.answers?.map((item, i) => {
                                          return (
                                            <div
                                              key={i}
                                              className="custom-checkbox mg-r-30"
                                            >
                                              <input
                                                className="custom-control-input"
                                                type="checkbox"
                                                id={i}
                                                name={`degree`}
                                              />
                                              <label
                                                className="custom-control-label text-black-11"
                                                htmlFor={i}
                                              >
                                                {item?.answer}
                                              </label>
                                            </div>
                                          );
                                        })}
                                        {/* <div className="custom-radio mg-r-30">
                                            <input
                                              className="custom-control-input"
                                              type="radio"
                                              id={`yes`}
                                              name={`degree`}
                                            />
                                            <label
                                              className="custom-control-label text-black-11"
                                              htmlFor={`yes`}
                                            >
                                              Yes
                                            </label>
                                          </div>
                                          <div className="custom-radio">
                                            <input
                                              className="custom-control-input"
                                              type="radio"
                                              id={`no`}
                                              name={`degree`}
                                            />
                                            <label
                                              className="custom-control-label text-black-11"
                                              htmlFor={`no`}
                                            >
                                              No
                                            </label>
                                          </div> */}
                                      </div>
                                    </div>
                                    {/* <div className="col-auto">
                                      <button
                                        type={"button"}
                                        className={"btn btn-snow btn-sm"}
                                      >
                                        Edit
                                      </button>
                                      <button
                                        type={"button"}
                                        className={
                                          "btn btn-snow btn-sm mg-l-10"
                                        }
                                      >
                                        Delete
                                      </button>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-auto">
                              <button
                                type={"button"}
                                className={"btn btn-link text-decoration-none"}
                                onClick={() =>
                                  handleQualifyingQuestionDelete(question._id)
                                }
                              >
                                <img src={IMAGES.Trash} alt="trash" />
                              </button>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                    {/* <li>
                      <div className="row g-0 mg-b-10">
                        <div className="col">
                          <div className={`card`}>
                            <div className={`card-body pd-x-20 pd-y-12`}>
                              <div className="row">
                                <div className="col">
                                  <Typography
                                    variant={"h4"}
                                    component={"label"}
                                  >
                                    Do you have a bachelor’s degree?
                                  </Typography>
                                  <div className={"d-flex mg-t-16"}>
                                    <div className="custom-radio mg-r-30">
                                      <input
                                        className="custom-control-input"
                                        type="radio"
                                        id={`yes`}
                                        name={`degree`}
                                      />
                                      <label
                                        className="custom-control-label text-black-11"
                                        htmlFor={`yes`}
                                      >
                                        Yes
                                      </label>
                                    </div>
                                    <div className="custom-radio">
                                      <input
                                        className="custom-control-input"
                                        type="radio"
                                        id={`no`}
                                        name={`degree`}
                                      />
                                      <label
                                        className="custom-control-label text-black-11"
                                        htmlFor={`no`}
                                      >
                                        No
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-auto">
                                  <button
                                    type={"button"}
                                    className={"btn btn-snow btn-sm"}
                                  >
                                    Edit
                                  </button>
                                  <button
                                    type={"button"}
                                    className={"btn btn-snow btn-sm mg-l-10"}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <button
                            type={"button"}
                            className={"btn btn-link text-decoration-none"}
                          >
                            <img src={IMAGES.Trash} alt="trash" />
                          </button>
                        </div>
                      </div>
                    </li> */}
                    {/* <li>
                      <div className="row g-0 mg-b-10">
                        <div className="col">
                          <div className={`card`}>
                            <div className={`card-body pd-x-20 pd-y-12`}>
                              <div className="row">
                                <div className="col">
                                  <Typography
                                    variant={"h4"}
                                    component={"label"}
                                  >
                                    Do you have a bachelor’s degree?
                                  </Typography>
                                  <div className={"d-flex mg-t-16"}>
                                    <div className="custom-radio mg-r-30">
                                      <input
                                        className="custom-control-input"
                                        type="radio"
                                        id={`yes`}
                                        name={`degree`}
                                      />
                                      <label
                                        className="custom-control-label text-black-11"
                                        htmlFor={`yes`}
                                      >
                                        Yes
                                      </label>
                                    </div>
                                    <div className="custom-radio">
                                      <input
                                        className="custom-control-input"
                                        type="radio"
                                        id={`no`}
                                        name={`degree`}
                                      />
                                      <label
                                        className="custom-control-label text-black-11"
                                        htmlFor={`no`}
                                      >
                                        No
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-auto">
                                  <button
                                    type={"button"}
                                    className={"btn btn-snow btn-sm"}
                                  >
                                    Edit
                                  </button>
                                  <button
                                    type={"button"}
                                    className={"btn btn-snow btn-sm mg-l-10"}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <button
                            type={"button"}
                            className={"btn btn-link text-decoration-none"}
                          >
                            <img src={IMAGES.Trash} alt="trash" />
                          </button>
                        </div>
                      </div>
                    </li> */}
                  </ul>
                  <div className={"d-flex mg-t-30"}>
                    <QualifyingQuestionsDrawer
                      open={drawerOpen}
                      setOpen={closeDrawer}
                    />
                    <button
                      type="button"
                      className={`btn btn-light-primary btn-lg mg-r-12`}
                      onClick={() => setDrawerOpen(!drawerOpen)}
                    >
                      <Add /> Insert from my questions
                    </button>
                    <Link
                      to={RoutingPaths.QualifyingQuestion}
                      state={{ isCustomQuestion: true }}
                    >
                      <button
                        type="button"
                        className={`btn btn-light-primary btn-lg mg-r-12`}
                      >
                        <Add /> New Question
                      </button>
                    </Link>
                  </div>
                </TabPanel>
                <IntroOutroVideoDrawer
                  open={openVideoLibrary}
                  setOpen={setOpenVideoLibrary}
                />
                <TabPanel value="introOutroVideo">
                  <Typography
                    variant={"h2"}
                    component={"h2"}
                    className={"mg-b-10"}
                  >
                    Intro/Outro Video
                  </Typography>
                  <Typography
                    variant={"body1"}
                    component={"p"}
                    className={"mg-b-30"}
                  >
                    You can include a pre-recorded video to welcome your
                    candidates to your assessment. By default, we'll display
                    your video at the beginning of the assessment. If you prefer
                    to show it at the end, tick the checkbox below:
                  </Typography>
                  <ul className={`list-unstyled ${styles.videoList}`}>
                    {videoQuestions?.map((question, index) => (
                      <li key={question._id}>
                        <div className="row g-3">
                          <div className="col">
                            <div className={`d-flex`}>
                              <div className={`img-asset mg-r-14`}>
                                <img src={question.videoThumbnail} alt="demo" />
                              </div>
                              <Typography variant={"body1"} component={"p"}>
                                {question?.title}
                              </Typography>
                            </div>
                          </div>
                          <div className="col-auto">
                            <div className={`${styles.introOutroWrap}`}>
                              <RHFRadioGroupController
                                label1="Intro Video"
                                label2="Outro Video"
                                handleRadioIntroOutro={handleRadioIntroOutro}
                                index={index}
                              />
                              {/* <div className="custom-radio mg-b-25">
                                <input
                                  className="custom-control-input"
                                  type="radio"
                                  id={`introVideo`}
                                  name={`introOutro`}
                                  value="INTRO"
                                  onChange={(e)=>handleRadioIntroOutro(e,index)}
                                />
                                <label
                                  className="custom-control-label text-black-11 text-nowrap"
                                  htmlFor={`introVideo`}
                                >
                                  Intro video
                                </label>
                              </div>
                              <div className="custom-radio">
                                <input
                                  className="custom-control-input"
                                  type="radio"
                                  id={`outroVideo`}
                                  name={`introOutro`}
                                  value="OUTRO"
                                  onChange={(e)=>handleRadioIntroOutro(e,index)}
                                />
                                <label
                                  className="custom-control-label text-black-11 text-nowrap"
                                  htmlFor={`outroVideo`}
                                >
                                  Outro video
                                </label>
                              </div> */}
                            </div>
                          </div>
                          <div className="col-auto">
                            <div className={`text-end ${styles.trashWrap}`}>
                              <button
                                type={"button"}
                                className={
                                  "btn btn-link text-decoration-none p-0"
                                }
                                onClick={() =>
                                  dispatch(
                                    removeVideoQuestionAction(question._id)
                                  )
                                }
                              >
                                <img src={IMAGES.Trash} alt="trash" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}

                    {/* <li>
                      <div className="row g-3">
                        <div className="col">
                          <div className={`d-flex`}>
                            <div className={`img-asset mg-r-14`}>
                              <img src={IMAGES.Profile} alt="demo" />
                            </div>
                            <Typography variant={"body1"} component={"p"}>
                              Introduce yourself in 60 seconds and explain why
                              you’re a great candidate for this role.
                            </Typography>
                          </div>
                        </div>
                        <div className="col-auto">
                          <div className={`${styles.introOutroWrap}`}>
                            <div className="custom-radio mg-b-25">
                              <input
                                className="custom-control-input"
                                type="radio"
                                id={`introVideo`}
                                name={`introOutro`}
                              />
                              <label
                                className="custom-control-label text-black-11 text-nowrap"
                                htmlFor={`introVideo`}
                              >
                                Intro video
                              </label>
                            </div>
                            <div className="custom-radio">
                              <input
                                className="custom-control-input"
                                type="radio"
                                id={`outroVideo`}
                                name={`introOutro`}
                              />
                              <label
                                className="custom-control-label text-black-11 text-nowrap"
                                htmlFor={`outroVideo`}
                              >
                                Outro video
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <div className={`text-end ${styles.trashWrap}`}>
                            <button
                              type={"button"}
                              className={
                                "btn btn-link text-decoration-none p-0"
                              }
                            >
                              <img src={IMAGES.Trash} alt="trash" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </li> */}
                  </ul>

                  {openUploadVideoModal && (
                    <RecordVideoDialog
                      open={openUploadVideoModal}
                      setOpen={setOpenUploadVideoModal}
                    />
                  )}
                  <div className={"d-flex mg-t-30"}>
                    <button
                      type="button"
                      className={`btn btn-light-primary btn-lg mg-l-12`}
                      onClick={handleVideoLibrary}
                    >
                      <Add /> Insert from your library
                    </button>

                    <button
                      type="button"
                      className={`btn btn-light-primary btn-lg mg-l-12`}
                      onClick={handleUploadVideoModal}
                    >
                      <Add /> Upload New Video
                    </button>
                  </div>
                </TabPanel>
                <TabPanel value="antiCheatingSettings">
                  <Typography
                    variant={"h2"}
                    component={"h2"}
                    className={"mg-b-10"}
                  >
                    Anti-Cheating Settings
                  </Typography>
                  <Typography
                    variant={"body1"}
                    component={"p"}
                    className={"mg-b-30"}
                  >
                    We take a screenshot of the candidate every 30 seconds while
                    they complete the assessment. These pictures will be
                    included in your anti-cheating monitor.We ask each candidate
                    for their permission before accessing their webcam.
                  </Typography>
                  <div className="custom-checkbox">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id={`snapshot`}
                      name={`snapshot`}
                      {...register("isAntiCheatingSettingEnabled")}
                    />
                    <label
                      className="custom-control-label text-black-11"
                      htmlFor={`snapshot`}
                    >
                      Snapshots of candidates will be taken every 30 seconds via
                      webcam.
                    </label>
                  </div>
                </TabPanel>
              </div>
            </TabContext>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default ReviewAndConfigure;
