import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { userService } from "../../services";
import { transformUser } from "../../utils/constants";
import RoutingPaths from "../../utils/RoutingPaths";

const GenericSignUp = () => {
  const { state } = useLocation();
  const { setUser } = useAuth();
  const { userObj } = state;
  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [companyName, setCompanyName] = useState("");

  const navigate = useNavigate();
  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      const tempUserObj = JSON.parse(JSON.stringify(userObj));
      tempUserObj.firstName = fName;
      tempUserObj.lastName = lName;
      const res = await userService.registerUser(tempUserObj);
      setUser(transformUser(res.data.data));
      navigate(RoutingPaths.Dashboard);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="Auth-form-container">
        <form className="Auth-form" onSubmit={onSubmit}>
          <div className="Auth-form-content">
            <div className="form-group mt-3">
              <label>First Name</label>
              <input
                type="text"
                value={fName}
                className="form-control mt-1"
                placeholder="Enter First Name"
                onChange={(e) => setFname(e.target.value)}
              />
            </div>
            <div className="form-group mt-3">
              <label>Last Name</label>
              <input
                type="text"
                value={lName}
                className="form-control mt-1"
                placeholder="Enter Last Name"
                onChange={(e) => setLname(e.target.value)}
              />
            </div>
            <div className="form-group mt-3">
              <label>Company Name</label>
              <input
                type="text"
                value={companyName}
                className="form-control mt-1"
                placeholder="Enter Company Name"
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="btn btn-primary">
                Continue
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default GenericSignUp;
