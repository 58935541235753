import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

import { useNavigate } from "react-router-dom";
import RoutingPaths from "../../utils/RoutingPaths";

export default function CardComponent(props) {
  let navigate = useNavigate();
  const handleClick = () => {
    if (props.questionType === "Multiple Choice") {
      navigate(RoutingPaths.MultipleChoice.slice(0, -9) + props.category);
    } else if (props.questionType === "Multiple Response") {
      navigate(RoutingPaths.MultipleResponse.slice(0, -9) + props.category);
    } else if (props.questionType === "True False") {
      navigate(RoutingPaths.TrueFalse.slice(0, -9) + props.category);
    } else if (props.questionType === "Short Text") {
      navigate(RoutingPaths.ShortText.slice(0, -9) + props.category);
    } else if (props.questionType === "Coding") {
      // if(props.category==='any'){
      //   navigate(RoutingPaths.Coding);
      // }else{
      //   navigate(RoutingPaths.CodingQuestion.slice(0, -9)  + props.category);
      // }
      navigate(RoutingPaths.CodingQuestion.slice(0, -9)  + props.category);
      
    }
    // else if (props.questionType === "Javascript") {
    //   navigate(RoutingPaths.CodingQuestion);
    // }
  };
  return (
    <Card
      sx={{
        width: 200,
        backgroundColor: "#F5F8FA",
        textAlign: "center",
        margin:3,
        ":hover": {
          boxShadow: 5,
          backgroundColor: "#F5F8FA",
        },
      }}
      onClick={handleClick}
    >
      <CardActionArea>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <CardMedia
            sx={{ height: 100, width: 100 }}
            component="img"
            height="200"
            image={props.image}
            alt="green iguana"
          />
        </div>

        <CardContent>
          <Typography gutterBottom variant="body1" component="div">
            {props.questionType}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {props.answerType}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
