import { Button, ClickAwayListener } from "@mui/material";
import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { Roles, truncateString } from "../../utils/constants";
import RoutingPaths from "../../utils/RoutingPaths";
import { AccountContext } from "../Auth/Account";
import NotificationIcon from "./../../Files/img/bell.png";
import Logo from "./../../Files/img/Logo.png";
import styles from "./Headers.module.scss";
import BasicMenu from "./MenuItems/BasicMenu";
import { Menu } from "@mui/icons-material";
import IMAGES from "../../assets/images/images";

const Header = () => {
  const { logout } = useContext(AccountContext);
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = () => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <>
      <nav className={`navbar navbar-expand-lg page-spacing ${styles.header}`}>
        <div className={`container-fluid`}>
          <div
            className={`w-100 d-flex align-items-center justify-content-between`}
          >
            <NavLink to="/" className={`navbar-brand ${styles.navbarBrand}`}>
              <img src={IMAGES.Logo} alt="" className="navbar_brand" />
            </NavLink>
            <Button
              className="btn btn-link text-white d-lg-none"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <Menu />
            </Button>
            <div
              className={`collapse navbar-collapse ${styles.navbar}`}
              id="navbarTogglerDemo02"
            >
              <div className={styles.navs}>
                <ul className={`w-100 list-unstyled ${styles.menu}`}>
                  <li>
                    <NavLink
                      className={styles.nav_link}
                      to={RoutingPaths.Dashboard}
                    >
                      Dashboard
                    </NavLink>
                  </li>
                  {(user.role === Roles.user) && (
                    <li>
                      <NavLink
                        to={RoutingPaths.Questions}
                        className={styles.nav_link}
                      >
                        My Questions
                      </NavLink>
                    </li>
                  )}
                  {(user.role === Roles.admin || user.role === Roles.contributor) && (
                    <li>
                      <NavLink
                        className={styles.nav_link}
                        to={RoutingPaths.QuestionBankList}
                      >
                        Question Bank
                      </NavLink>
                    </li>
                  )}
                  <li>
                    <NavLink
                      className={styles.nav_link}
                      to={RoutingPaths.TestLibrary}
                    >
                      Test Library
                    </NavLink>
                  </li>
                </ul>
                <div className={`d-flex align-items-center`}>
                  <div className={`${styles.search} mg-r-36`}>
                    <img src={IMAGES.Notification} alt="notification" />
                  </div>
                  <div className={styles.user_profile}>
                    <span className={`mg-r-10 text-nowrap`}>
                      Hi,
                      <b className={styles.bold}>
                        {`  ${truncateString(user?.firstName, 5)}`}
                      </b>
                    </span>
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <button
                        type="button"
                        onClick={handleClick("bottom-end")}
                        style={{ background: "none" }}
                      ></button>
                    </ClickAwayListener>
                    <BasicMenu
                      logout={logout}
                      firstLetter={user?.firstName.substr(0, 1)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
