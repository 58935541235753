import {Button, Menu, MenuItem, TableCell} from "@mui/material";
import {Link} from "react-router-dom";
import {Edit} from "@mui/icons-material";
import React, {useState} from "react";
import IMAGES from "../../../assets/images/images";

const TestLibraryMoreMenu = (props) => {
    const {_id, handleDelete} = props;
    const [moreAnchorEl, setMoreAnchorEl] = useState(null);
    const openMore = Boolean(moreAnchorEl);

    const handleMoreOpen = (event) => {
        setMoreAnchorEl(event.currentTarget);
    };

    const moreClose = () => {
        setMoreAnchorEl(null);
    };
    return(
        <>
            <Button variant={"text"}
                    className={`btn btn-link pd-x-16`}
                    id="basic-button"
                    onClick={handleMoreOpen}
                    aria-controls={openMore ? `${_id}` : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMore ? 'true' : undefined}
            >
                <img src={IMAGES.Dots} alt="dots" />
            </Button>
            <Menu
                id={_id}
                anchorEl={moreAnchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={openMore}
                onClose={moreClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem>
                    <span className={`d-inline-block`}>
                            <img width={18} height={18} src={IMAGES.Trash} alt="trash"/>
                    </span>
                    <span className={"text-dark mg-l-10"}>Delete</span>
                </MenuItem>
                <MenuItem>
                    <Link className={`text-decoration-none`} to={`/edit-question/${_id}`}>
                        <span className={`d-inline-block`}>
                            <img width={18} height={18} src={IMAGES.EditSquare} alt="trash"/>
                        </span>
                        <span className={"text-dark mg-l-10"}>Edit</span>
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link className={`text-decoration-none`} to={`/edit-question/${_id}`}>
                        <span className={`d-inline-block`}>
                            <img width={18} height={18} src={IMAGES.Eye} alt="trash"/>
                        </span>
                        <span className={"text-dark mg-l-10"}>View</span>
                    </Link>
                </MenuItem>
            </Menu>
        </>
    )
}

export default TestLibraryMoreMenu;