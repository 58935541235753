import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Menu,
    MenuItem,
    Pagination,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Avatar
  } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
const AuthorList = ({ authors, setUpdateAction, setDeleteAction, isLoading }) => {
    return(
          <Card className={'card-table'}>
              <CardContent className="pd-x-0 pd-y-0">
                <TableContainer className="custom-table">
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>SN</TableCell>
                        <TableCell>Profile Pic</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    {isLoading && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={12}>
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {!isLoading && authors.length && (
                      <TableBody>
                        {authors.map(
                          (author,index) => {
                            return (
                              <TableRow
                                key={author._id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>{index + 1}</TableCell>
                                <TableCell className="pd-l-0">
                                {author.profilePic ? 
                                    (<Avatar alt={author.name} src={author.profilePic} />) :
                                    (<Avatar>{author.name[0]}</Avatar>)
                                }
                                </TableCell>
                                <TableCell className="pd-l-0">
                                  <Box>
                                    {author.name}
                                  </Box>
                                </TableCell>
                                <TableCell className="pd-l-0">
                                  <button onClick={() => {
                                    setUpdateAction(author);
                                  }}>
                                    <EditIcon color="primary" />
                                  </button>
                                  <button onClick={() => setDeleteAction(author)}>
                                    <DeleteIcon color="primary" />
                                  </button>
                                </TableCell>
                              </TableRow>
                              
                            );
                          }
                        )}
                      </TableBody>
                    )}
                    {!isLoading && !authors.length && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={12}>
                            No Data Found!
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>)
}

export default AuthorList;