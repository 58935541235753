import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Modal,
  Tab,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import QuestionEditor from "../../Editor/QuestionEditor/QuestionEditor";
import styles from "./NewQuestionTop.module.scss";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import RHFInputController from "../../../RHFControllers/RHFInputController";
import { questionCategory } from "../../../../utils/constants";
import IMAGES from "../../../../assets/images/images";
import MuiMenu from "../../../common/MuiMenu/MuiMenu";
import TimeLimit from "../TimeLimit/TimeLimit";
import useWebCamRecorder from "../VideoQuestion/useWebCamRecorder";
import FileInput from "../../../Dropzone/FileInput";
import { useFormContext } from "react-hook-form";
import MuiMenuCommon from "../../../common/MuiMenuCommon/MuiMenuCommon";
import NewQuestionVideoDrawer from "../NewQuestionVideoDrawer/NewQuestionVideoDrawer";
import { useSelector } from "react-redux";

const NewQuestionTop = () => {
  const [questionTabValue, setQuestionTabValue] = useState("Question");
  const [openModal, setOpenModal] = useState(false);
  const { setValue } = useFormContext();
  const storedVideo = useSelector(
    (state) => state.videoQuestionSlice.videoQuestionsLibrary
  );


  const handleQuestionTabChange = (event, newValue) => {
    setQuestionTabValue(newValue);
  };
  const videoRef = useRef(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const { start, stop, mediaStream, videoBlob } = useWebCamRecorder();
  const [openVideoLibrary, setOpenVideoLibrary] = useState(false);
  const userConfirmRecording = useRef(false);
  const [hasNewBlob, setHasNewBlob] = useState(false);
  useEffect(() => {
    if (mediaStream) {
      let video = videoRef.current;
      video.srcObject = mediaStream;
      video.onloadedmetadata = function (e) {
        video.play();
      };
    }
  }, [mediaStream]);

  useEffect(() => {
    if (!openModal && mediaStream) {
      stop();
    }
    if (openModal ) {
      URL.revokeObjectURL(videoUrl)
      setVideoUrl("")
    }
    setHasNewBlob(!openModal);
  }, [openModal]);

  // it(useEffect) is only for verify recording
  useEffect(() => {
    if (videoBlob && userConfirmRecording.current) {
      //  let video = videoRef.current;
      userConfirmRecording.current = false;
      setHasNewBlob(true);
      const url = URL.createObjectURL(videoBlob);
      setVideoUrl(url);  
    }
    return () => {
      URL.revokeObjectURL(videoUrl);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoBlob]);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleVideoLibrary = () => {
    setOpenVideoLibrary(true);
  };


  const handleRecordStart = () => {
    if (mediaStream) {
      userConfirmRecording.current = true;
      stop();
    } else {
      userConfirmRecording.current = false;
      start();
    }
  };

  const handleOkButton = () => {
    // userConfirmRecording.current = true;
    // const url = URL.createObjectURL(videoBlob);
    setValue("videoBlob", videoBlob);
    setOpenModal(false);
    // handleCloseModal();
  };

  return (
    <>
      <div className="row mg-b-34">
        <div className="col">
          <RHFInputController
            RHFname="title"
            type="text"
            placeholder="Title"
            className="form-control form-control-sm"
            fieldName="title"
          />
        </div>
        <div className="col-auto">
          <MuiMenuCommon
            options={questionCategory}
            RHFName="selectedRole"
            placeholder=""
            useId={true}
          />
        </div>
      </div>
      <div className="mg-b-34">
        <Box className={"tabs-wrap question-tabs"}>
          <TabContext value={questionTabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "transparent" }}>
              <TabList
                onChange={handleQuestionTabChange}
                aria-label="question tabs"
              >
                <Tab label="Question" value="Question" />
                <Tab label="Video as a question" value="Video" />
              </TabList>
            </Box>
            <TabPanel value="Question">
              <QuestionEditor />
            </TabPanel>
            <TabPanel value="Video">
              <div className={styles.videoTab}>
                <Card className={`card rounded-6`}>
                  <CardContent className={`pd-y-30`}>
                    {true ? (
                      <>
                        <div
                          className={`d-flex align-items-center flex-column`}
                        >
                          {storedVideo.length > 0 ? (
                            <video
                              key={storedVideo[0]._id}
                              width="400"
                              controls
                            >
                              <source
                                src={storedVideo[0].videoUrl}
                                type="video/mp4"
                              />
                              Your browser does not support HTML video.
                            </video>
                          ) : (
                            <FileInput
                              videoUrl={videoUrl}
                              setVideoUrl={setVideoUrl}
                              accept="image/png, image/jpg, image/jpeg, image/gif,video/mp4"
                              name="videoBlob"
                              label="File Upload"
                              mode="append"
                              component={
                                <div
                                  className={`${styles.uploadCircle} d-flex align-items-center justify-content-center mg-b-10`}
                                >
                                  <i className="icon-arrow-solid-up"></i>
                                </div>
                              }
                            />
                          )}

                          {/* <Typography
                            variant={"body1"}
                            color={"secondary"}
                            className={`mg-b-10`}
                          >
                           
                            Click to Browse or Drag file to upload, or
                          </Typography> */}
                          <div className={"d-flex align-items-center pd-y-10"}>
                            {/* <div className="btn btn-link pd-x-0 pd-y-0 position-relative">
                              <Typography>Browse</Typography>
                              <input type="file" className="hidden-input" />
                            </div> */}
                            <Typography
                              variant={"body1"}
                              component={"span"}
                              className={`mg-x-10`}
                              color={"secondary"}
                            >
                              or
                            </Typography>
                            <button
                              type="button"
                              className={`btn btn-link pd-x-0 pd-y-0`}
                              onClick={handleOpenModal}
                            >
                              Record
                            </button>
                            <Typography
                              variant={"body1"}
                              component={"span"}
                              className={`mg-x-10`}
                              color={"secondary"}
                            >
                              or
                            </Typography>
                            <Button
                              variant={"contained"}
                              color={"primary"}
                              className={`text-white rounded-pill`}
                              onClick={handleVideoLibrary}
                            >
                              Choose from library
                            </Button>
                          </div>
                        </div>
                        <Modal
                          open={openModal}
                          onClose={handleCloseModal}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box className="modal-box">
                            <Box className="modal-content">
                              <Box
                                className={`d-flex align-items-center justify-content-between pd-x-30 pd-y-10 border-bottom`}
                              >
                                <Typography
                                  id="modal-modal-title"
                                  variant="h2"
                                  component="h2"
                                >
                                  Record Video
                                </Typography>
                                <button
                                  type={`button`}
                                  className={`btn btn-link text-decoration-none`}
                                  onClick={handleCloseModal}
                                >
                                  <img src={IMAGES.XIcon} alt="close" />
                                </button>
                              </Box>
                              <Box className={`pd-x-30 pd-t-10 pd-b-30`}>
                                {/* <input
                                  type="text"
                                  className="form-control form-control-lg mg-b-10"
                                  placeholder="Video title"
                                /> */}
                                <RHFInputController
                                  RHFname="videoDescription"
                                  type="text"
                                  placeholder="Enter Video Description"
                                  className="form-control form-control-sm mg-b-10"
                                  fieldName=""
                                />
                                <Box className={`video-record mg-b-10`}>
                                  <video
                                    ref={videoRef}
                                    key={videoUrl}
                                    className={`video`}
                                    width="400"
                                    controls
                                  >
                                    <source src={videoUrl} type="video/mp4" />
                                    Your browser does not support HTML video.
                                  </video>
                                  {/* <Box
                                    className={`record-control-bar text-center`}
                                  >
                                    <button
                                      type={"button"}
                                      className={
                                        "btn btn-link text-decoration-none"
                                      }
                                    >
                                      <img src={IMAGES.BtnPlay} alt="play" />
                                    </button>
                                    <Typography
                                      className={`stream-time text-white`}
                                    >
                                      00:00 / 1:00
                                    </Typography>
                                  </Box> */}
                                </Box>
                                <div className={`row g-2`}>
                                  <div className="col">
                                    <Button
                                      onClick={handleRecordStart}
                                      variant={"outlined"}
                                      // color={"secondary"}
                                      className={`text-nowrap font-weight-medium ${styles.recordCircle}`}
                                      sx={{
                                        backgroundColor: mediaStream
                                          ? "#C70039"
                                          : "#AFE1AF",
                                        color: mediaStream ? "white" : "black",
                                      }}
                                    >
                                      {mediaStream
                                        ? "Stop Recording"
                                        : "Start Recording"}
                                    </Button>
                                  </div>
                                  <div className="col-auto">
                                    <Button
                                      onClick={handleCloseModal}
                                      variant={"contained"}
                                      color={"error"}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      onClick={handleOkButton}
                                      variant={"contained"}
                                      color={"primary"}
                                      className={`mg-l-10`}
                                      disabled={!hasNewBlob}
                                    >
                                      Ok
                                    </Button>
                                  </div>
                                </div>
                              </Box>
                            </Box>
                          </Box>
                        </Modal>
                      </>
                    ) : (
                      <div className={styles.videoResult}>
                        <div className={styles.videoWrapper}>
                          <button
                            type={"button"}
                            className={`btn text-decoration-none ${styles.btnClose}`}
                          >
                            <Close className={styles.close} />
                          </button>
                          <button
                            type={"button"}
                            className={`btn text-decoration-none ${styles.btnPlay}`}
                          >
                            <i className={`icon-video-solid`}></i>
                          </button>
                          <video className={styles.video} width="400" controls>
                            <source src="" type="video/mp4" />
                            <source src="" type="video/ogg" />
                            Your browser does not support HTML video.
                          </video>
                        </div>
                        <Typography
                          className={"mg-t-8"}
                          variant={"body1"}
                          component={"p"}
                          color={"secondary"}
                        >
                          Video-file-name.mp4
                        </Typography>
                      </div>
                    )}
                  </CardContent>
                </Card>
              </div>
            </TabPanel>
            <NewQuestionVideoDrawer
              open={openVideoLibrary}
              setOpen={setOpenVideoLibrary}
              setVideoUrl={setVideoUrl}
            />
          </TabContext>
        </Box>
        <TimeLimit />
      </div>
    </>
  );
};

export default NewQuestionTop;
