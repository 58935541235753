import { Box, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { BootstrapInput } from "../../utils/constants";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useState } from "react";

import { useMemo } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function RHFMultiselectCheckboxNew({
  index,
  RHFName,
  placeholder,
  PrimarySkillRHFName,
  secondarySkillsListMap,
}) {
  const methods = useFormContext();
  const { setValue, watch, control } = methods;
  const [secondarySkills, setSecondarySkills] = React.useState([]);

  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = useState("");

  const primarySkillId = useWatch({
    control,
    name: PrimarySkillRHFName,
  });

  const secondarySkillsWatch = useWatch({
    control,
    name: RHFName,
  });
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (e) => {
    setOpen(true);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSecondarySkills(typeof value === "string" ? value.split(",") : value);
    setValue(RHFName, typeof value === "string" ? value.split(",") : value,{ shouldDirty: true });
  };

  const filteredSecondarySkills = useMemo(() => {
    if (search) {
      return secondarySkillsListMap[primarySkillId]?.filter(({ label }) =>
        label.toLowerCase().includes(search.toLowerCase())
      );
    }

    return secondarySkillsListMap[primarySkillId];
  }, [search, secondarySkillsListMap[primarySkillId]]);

  return (
    <Select
      id="demo-multiple-checkbox"
      className="multiSelect"
      displayEmpty
      multiple
      value={secondarySkills}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      onChange={handleChange}
      input={<BootstrapInput />}
      sx={{ width: "100%" }}
      IconComponent={() =>
        open ? (
          <KeyboardArrowUp className="toggle-icon" />
        ) : (
          <KeyboardArrowDown className="toggle-icon" />
        )
      }
      renderValue={() => {
        if (secondarySkillsWatch.length === 0) {
          return (
            <InputLabel id="demo-multiple-checkbox-label">
              Select skills
            </InputLabel>
          );
        }
        return(
          secondarySkillsWatch.map((item) => item.label).join(", ")
        )
      }}
      // renderValue={(selected) => {
      //   if (secondarySkillsWatch.length === 0) {
      //     return (
      //       <InputLabel id="demo-multiple-checkbox-label">
      //         Select skills
      //       </InputLabel>
      //     );
      //   }
      //   return (
      //     <Box className="d-flex align-items-center flex-wrap">
      //       {secondarySkillsWatch?.map((value) => (
      //         <Chip
      //           key={value._id}
      //           label={value.label}
      //           onClick={handleClick}
      //           className="chip-purple mg-r-2"
      //           size={"small"}
      //         />
      //       ))}
      //       {/* <InputLabel id="demo-multiple-checkbox-label">
      //         Select skills
      //       </InputLabel> */}
      //     </Box>
      //   );
      // }}
      MenuProps={MenuProps}
    >
      <MenuItem className="menu-item pd-y-0 mg-y-0" disableRipple={true}>
        <form className="w-100" onKeyDown={(e) => e.stopPropagation()}>
          <Box className="pd-y-24">
            <input
              type="search"
              className="form-control"
              placeholder="Search"
              autoComplete="off"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </Box>
        </form>
      </MenuItem>
      {filteredSecondarySkills?.map((item) => (
        <MenuItem
          className="menu-item"
          key={item._id}
          value={item}
          disableRipple={true}
        >
          <div className="custom-checkbox">
            <input
              className="custom-control-input"
              type="checkbox"
              id={`${item.label}`}
              name={`${item.label}`}
              checked={secondarySkillsWatch.find((o) => o._id === item._id)}
            />
            <label
              className="custom-control-label text-capitalize font-size-base"
              htmlFor={`${item.label}`}
            >
              {item.label}
            </label>
          </div>
        </MenuItem>
      ))}
    </Select>
  );
}

export default RHFMultiselectCheckboxNew;
