import React, {useState, useContext, useEffect} from "react";
import { AccountContext } from "./Account";
import {useNavigate, Link, NavLink} from "react-router-dom";
import RoutingPaths from "../../utils/RoutingPaths";
import styles from "./Login.module.scss";
import { Button, CircularProgress,Container, Typography, Divider} from "@mui/material";
import { useSnackbar } from "notistack";
import jwt_decode from "jwt-decode";
import { userService } from "../../services";
import { useAuth } from "../../hooks/useAuth";
import { transformUser } from "../../utils/constants";
import IMAGES from "../../assets/images/images";
import {Google} from "@mui/icons-material";

export default function Login({ authCode }) {
  const [isLogin, setLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let navigate = useNavigate();
  const { authenticate } = useContext(AccountContext);
  const { enqueueSnackbar } = useSnackbar();
  const { setUser } = useAuth();
  const changeAuthMode = () => {
    navigate(RoutingPaths.Signup);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setLogin(true);
    authenticate(email, password)
      .then((data) => {
        // console.log(data);
        const id_token = data.idToken.jwtToken;
        const refresh_token = data.refreshToken.token;
        // console.log(refresh_token);
        localStorage.setItem("refreshToken", JSON.stringify(refresh_token));
        localStorage.setItem("accessToken", JSON.stringify(id_token));
        // console.log(id_token);
        const decoded = jwt_decode(id_token);
        // console.log(decoded);
        const emailVerified = decoded["email_verified"];
        const userObj = {
          email: decoded["email"],
          userName: decoded["cognito:username"],
          firstName: "",
          lastName: "",
        };
        userService
          .search(userObj.email)
          .then((res) => {
            setUser(transformUser(res.data.data));
            enqueueSnackbar("Logged in successfully", {
              variant: "success",
            });
            navigate(RoutingPaths.Dashboard);
            setLogin(false);
          })
          .catch((error) => {
            // only register the user if email is verified
            if (emailVerified) {
              navigate(RoutingPaths.GenericSignUp, {
                state: { userObj: userObj, id_token: id_token },
              });
            }
          });
      })
      .catch((err) => {
        setLogin(false);
        enqueueSnackbar(`Login Failed!! ${err.message}`, {
          variant: "error",
        });
      });
  };

  const googleSignInUrl = () => {
    // window.location.href = `${process.env.REACT_APP_AWS_COGNITO_DOMAIN}/login?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;
    window.location.href = `${process.env.REACT_APP_AWS_COGNITO_DOMAIN}/oauth2/authorize?identity_provider=Google&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=CODE&client_id=${process.env.REACT_APP_CLIENT_ID}`;
  };

  useEffect(()=>{
      document.body.classList.add('bg-white')
      return () => {
          document.body.classList.remove('bg-white')
      }
  },[])
  return (
    <>
        {
            false ?
                <>
                    <div className={`row g-0`}>
                        <div className="col-md-6">
                            <div className={`${styles.loginBanner}`}>
                                <img className={`img-fluid`} src={IMAGES.LoginBanner} alt="desk"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <Container maxWidth={false}>
                                <div className={`${styles.loginPanel} d-flex justify-content-center align-items-center`}>
                                    <form className={`${styles.loginForm}`} onSubmit={onSubmit}>
                                        <div className={`mg-b-50 text-center`}>
                                            <img className={`img-fluid`} src={IMAGES.Logo} alt="logo"/>
                                        </div>
                                        <div className={`d-flex justify-content-between align-items-center mg-b-30`}>
                                            <Typography variant={"h2"} component={"h2"} className={`${styles.heading30}`}>
                                                Sign in
                                            </Typography>
                                            <Typography variant={"body1"} component={"p"} color={"secondary"} className={`font-size-lg font-weight-medium`}>
                                                or <button type={"button"} className={`btn btn-link p-0 text-decoration-none`}
                                                           onClick={changeAuthMode}>Create an account</button>
                                            </Typography>
                                        </div>
                                        <Button color={"secondary"} variant={"outlined"} className={`btn-lg w-100 text-dark`}
                                                onClick={googleSignInUrl}>
                                            <img className={`img-fluid mg-r-12`} src={IMAGES.Google} alt="google"/>
                                            Sign in with Google
                                        </Button>
                                        <div className={`${styles.dividerWithText} text-center`}>
                                            <Divider className={styles.divider}/>
                                            <Typography variant={"body1"} component={"span"} color={"secondary"} className={`${styles.text} font-size-lg font-weight-medium d-inline-block mg-y-20`}>
                                                or
                                            </Typography>
                                        </div>
                                        <div className={`mg-b-20`}>
                                            <input
                                                type="email"
                                                value={email}
                                                className="form-control form-control-lg"
                                                placeholder="Email*"
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                        <div className={`mg-b-20`}>
                                            <input
                                                type="password"
                                                value={password}
                                                className="form-control form-control-lg"
                                                placeholder="Password*"
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>
                                        <div className={`d-flex align-items-center justify-content-between mg-b-20`}>
                                            <div className="custom-checkbox">
                                                <input
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    id={`remind-me`}
                                                    name={`remind-me`}
                                                    defaultChecked={true}
                                                />
                                                <label
                                                    className="custom-control-label text-capitalize"
                                                    htmlFor={`remind-me`}
                                                >Remind me</label>
                                            </div>
                                            <button type={"submit"} className={'btn btn-primary text-white btn-lg'}>
                                                {!isLogin ? "Sign in" : "Logging In..."}
                                                <img className={`mg-l-10`} src={IMAGES.ArrowNext} alt="arrow-next"/>
                                            </button>
                                        </div>
                                        <NavLink className={`btn btn-link p-0 text-decoration-none font-weight-semi-bold`}
                                                 to={RoutingPaths.ForgotPassword}>
                                            Forgot your password?
                                        </NavLink>
                                    </form>
                                </div>
                            </Container>
                        </div>
                    </div>
                </>
                :
                <div className={`vh-100 d-flex align-items-center justify-content-center`}>
                    <form className="Auth-form" onSubmit={onSubmit}>
                        <div className="Auth-form-content">
                            <h3 className="Auth-form-title">Sign In</h3>
                            <div className="text-center">
                                Not registered yet?
                                <span
                                    className="link-primary"
                                    style={{ cursor: "pointer" }}
                                    onClick={changeAuthMode}
                                >
                Sign Up
              </span>
                            </div>
                            <div className="form-group mt-3">
                                <label>Email address</label>
                                <input
                                    type="email"
                                    value={email}
                                    className="form-control mt-1"
                                    placeholder="Enter email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label>Password</label>
                                <input
                                    type="password"
                                    value={password}
                                    className="form-control mt-1"
                                    placeholder="Enter password"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="d-grid gap-2 mt-3">
                                <button
                                    disabled={isLogin}
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    {!isLogin ? "Login" : <div>Logging In...</div>}
                                </button>
                            </div>
                            <p className="forgot-password text-right mt-2">
                                Forgot <Link to="/forgotpassword">Password?</Link>
                            </p>
                        </div>
                        <div>
                            <button
                                type="button"
                                className="mt-2 mx-5 border-radius: 2px cursor: pointer"
                                onClick={googleSignInUrl}
                            >
                                Google Sign In
                            </button>
                        </div>
                    </form>
                </div>
        }
    </>
  );
}
